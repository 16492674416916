import { axiosGet } from '../api/axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const featureFlagKeys = {
  featureFlags: ['featureFlags'] as const,
};
export interface FeatureFlags {
  count: number;
  data: { [key: string]: FeatureFlag };
}

export interface FeatureFlag {
  created_at: Date;
  description: string;
  enabled: boolean;
  id: number;
  key: string;
}

const getFeatureFlags = async (): Promise<FeatureFlags> => {
  return await axiosGet(`/feature_flags`, {}, 'v3').then((response) => response.data);
};

export enum SwitchboardFeatures {
  SB_KEYCLOAK_LOGIN = 'SB_KEYCLOAK_LOGIN',
}

export const useFeatureFlag = (key: keyof typeof SwitchboardFeatures) => {
  const queryClient = useQueryClient();
  const query = useQuery<FeatureFlags>(featureFlagKeys.featureFlags, () => getFeatureFlags(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
  });
  return {
    ...query,
    enabled: !!query.data?.data[key]?.enabled,
    setEnabled: (newValue: Boolean) => {
      queryClient.setQueryData(['featureFlags'], {
        ...query.data,
        data: {
          ...query.data?.data,
          [key]: {
            ...query.data?.data?.[key],
            enabled: newValue,
          },
        },
      });
    },
  };
};
