import React from 'react';
import { Button } from '../../components/common';
import Header from '../../components/common/global-top-bar';

import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import SwitchBarButton from './switch-bar-button';
import HeadBack from './redirect';

const MembersRewards: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <Header userName={'Jason'} heading={<HeadBack />} />

      <SwitchBar heading={Headeroptions} position={4} optionalButton={<SwitchBarButton />} />

      <div className="max-window-height  overflow-y-auto flex justify-between ">
        <div className="w-1/2 px-7 py-4">
          <div className="py-4">
            <div className="h-8 flex   border-b border-gray-dark py-2 ">
              <div className="w-1/3 text-gray-dark font-bold font-base ">Total Points</div>
              <div className="w-1/3 text-gray-dark font-bold font-base ">Current Points</div>
              <div className="w-1/3 text-gray-dark font-bold font-base ">Next Milestone</div>
            </div>
            <div className=" flex text-left ">
              <div className="w-1/3 blue-primary text-4xl font-normal py-2 ">459</div>
              <div className="w-1/3 blue-primary text-4xl font-normal py-2 ">325</div>
              <div className="w-1/3 blue-primary text-4xl font-normal py-2 ">645</div>
            </div>
          </div>
          <div className="py-4">
            <div className="flex justify-between py-4">
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">Rewards Earned</div>
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">
                Rewards Redeemed
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">Rewards Amount</div>
            </div>
            <div className=" flex text-left ">
              <div className="w-1/3  ">
                <p className="text-4xl font-normal py-2 blue-primary">$10.00</p>
                <p className="text-sm font-normal py-2 text-gray-dark">Total</p>
              </div>
              <div className="w-1/3  ">
                <p className="text-4xl font-normal py-2 blue-primary">$10.00</p>
                <p className="text-sm font-normal py-2 text-gray-dark">Total</p>
              </div>
              <div className="w-1/3  ">
                <p className="text-4xl font-normal py-2 blue-primary">$10.00</p>
                <p className="text-sm font-normal py-2 text-gray-dark">Total</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-7 mt-4 max-window-height  overflow-y-auto">
          <table className="table-fixed table-row-format w-full relative border-collapse ">
            <thead className="bg-white  h-full ">
              <tr className=" text-left  w-full px-7">
                <th className="px-2 py-2 w-2/12 sticky top-0  bg-white  h-full mb-4">Reward Log</th>
                <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4"></th>
                <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4"></th>
                <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2"></tr>
              {new Array(100).fill(1).map((item: any, index: any) => {
                return (
                  <tr className="bg-gray-200 rounded text-gray-dark text-left h-12 border-y border-white border-4 space-y-6">
                    <td className="px-2 py-1 w-1/12">July 17, 2021</td>
                    <td className="px-2 py-1 w-1/12">17:34 CT</td>

                    <td className="px-2 py-1 w-1/12">
                      <Button Label={'Check-in'} textColor={'text-white'} color={' bg-blue-primary '} />
                    </td>

                    <td className="px-2 py-1 w-1/12"> + 1 </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default MembersRewards;
