import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
// import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import {
  fetchTierRequest,
  fetchTiersListRequest,
  postTiersRequest,
  postTiersSuccess,
  removeTiersRequest,
  removeTiersSuccess,
  updateTiersRequest,
} from './redux-templates/actions';
import { CustomInput, UseFormDropDown, UseFormToggle } from '../../components/common';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { decimalOnChange, numberonBlur, onBlur } from '../../components/common/useForm';
import HeadBack from '../clients/redirect';

type props = {
  tiers: any;
  pending: any;
  newTier: any;
  removeTiers: any;
};

const TierListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const [isShowAlertmodel, setIsShowAlertModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const tierId = new URLSearchParams(search).get('tier-id');

  useEffect(() => {
    return () => {
      dispatch(postTiersSuccess({ Tiers: {} }));
      dispatch(removeTiersSuccess({ RemoveTiers: {} }));
    };
  }, []);

  useEffect(() => {
    if (tierId && tierId !== 'new') {
      dispatch(
        fetchTierRequest({
          id: tierId,
          payload: { all_tier_versions: true, include_unpublished: true },
        }),
      );
    }
  }, [tierId]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.tiers && tierId) {
        const tier = props.tiers;
        if (tierId === 'new') {
          return {
            status: 'unpublished',
            is_default: false,
            name: '',
            office_hour_rate: 0,
            minimum_office_hours: 5.0,
            max_daily_office_hours: 0,
            max_experience_payouts: 0,
            call_bonus: 0,
            call_earnings: 0.5,
            pay_office_hours: false,
            paid_office_hours_cap: 5,
            experience_listen_rate: 0,
            experience_bonus: 0,
          };
        } else {
          const {
            status,
            is_default,
            name,
            minimum_office_hours,
            max_daily_office_hours,
            max_experience_payouts,
            pay_office_hours,
            paid_office_hours_cap,
          } = tier;
          return {
            status,
            is_default,
            name,
            minimum_office_hours,
            max_daily_office_hours,
            max_experience_payouts,
            pay_office_hours,
            paid_office_hours_cap,
            office_hour_rate: tier.office_hour_rate / 100,
            call_earnings: tier.call_earnings / 100,
            call_bonus: tier.call_bonus / 100,
            experience_listen_rate: tier.experience_listen_rate / 100,
            experience_bonus: tier.experience_bonus / 100,
          };
        }
      }
    }, [props, tierId]),
  });

  useEffect(() => {
    if (tierId) {
      const tier = props.tiers;
      if (tierId === 'new') {
        reset({
          status: 'unpublished',
          is_default: false,
          name: '',
          office_hour_rate: 0,
          minimum_office_hours: 5.0,
          max_daily_office_hours: 0,
          max_experience_payouts: 0,
          call_bonus: 0,
          call_earnings: 0.5,
          pay_office_hours: false,
          paid_office_hours_cap: 5,
          experience_listen_rate: 0,
          experience_bonus: 0,
        });
      } else if (props.tiers) {
        const {
          status,
          is_default,
          name,
          minimum_office_hours,
          max_daily_office_hours,
          max_experience_payouts,
          pay_office_hours,
          paid_office_hours_cap,
        } = tier;
        reset({
          status,
          is_default,
          name,
          minimum_office_hours,
          max_daily_office_hours,
          max_experience_payouts,
          pay_office_hours,
          paid_office_hours_cap,
          office_hour_rate: tier.office_hour_rate / 100,
          call_earnings: tier.call_earnings / 100,
          call_bonus: tier.call_bonus / 100,
          experience_listen_rate: tier.experience_listen_rate / 100,
          experience_bonus: tier.experience_bonus / 100,
        });
      }
    }
  }, [props.tiers]);

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      office_hour_rate: data?.office_hour_rate * 100 || 0,
      call_earnings: data?.call_earnings * 100 || 0,
      call_bonus: data?.call_bonus * 100 || 0,
      experience_listen_rate: data?.experience_listen_rate * 100 || 0,
      experience_bonus: data?.experience_bonus * 100 || 0,
      minimum_office_hours: data.minimum_office_hours || 0,
      max_daily_office_hours: data.max_daily_office_hours || 0,
      max_experience_payouts: data.max_experience_payouts || 0,
    };
    if (tierId === 'new') {
      dispatch(postTiersRequest(payload));
    } else {
      dispatch(updateTiersRequest({ id: tierId, payload }));
    }
  };

  const handleDelete = () => {
    dispatch(removeTiersRequest({ id: tierId }));
    setIsShowAlertModel(false);
  };

  useEffect(() => {
    if (props.removeTiers) {
      history.push(ROUTE_PATH.TIERS);
    }
  }, [props.removeTiers]);

  useEffect(() => {
    if (props.newTier) {
      history.push(ROUTE_PATH.TIERS);
    }
  }, [props.newTier]);

  return (
    <div>
      <Header
        heading={
          <HeadBack clientName={tierId === 'new' ? 'Tiers' : props.tiers?.name || ''} headback={ROUTE_PATH.TIERS} />
        }
      />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <div className="flex">
          {' '}
          {tierId !== 'new' && (
            <button
              className=" rounded-full w-20 text-center h-6 text-sm text-white bg-gray-background-dark px-2"
              onClick={() => {
                setIsShowAlertModel(true);
              }}
            >
              REMOVE
            </button>
          )}
          <div className={` ${!isDirty && 'cursor-not-allowed'}`}>
            <button
              className={` rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                isDirty ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none  '
              }`}
              type="submit"
              form="member-profile-form"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height pb-10 overflow-y-auto ">
          <div className="w-full px-3">
            {props.pending ? (
              <Spinner />
            ) : (
              <div className="w-3/4 grid grid-cols-2 gap-x-6 mx-7 mt-5 gap-y-6">
                <CustomInput
                  inputType="text"
                  Label="Tier name"
                  register={{
                    ...register('name', {
                      required: 'Please provide tier name',
                      onBlur: (e: any) => onBlur(e, 'name', setValue),
                    }),
                  }}
                  //@ts-ignore
                  Error={errors?.name?.message?.length ? true : false}
                  ErrorMessage={errors.name?.message}
                />

                <UseFormDropDown label={'Status'}>
                  <select className="select-box w-full" defaultValue="published" {...register('status')}>
                    <option value="published">Published</option>
                    <option value="unpublished">Unpublished</option>
                  </select>
                </UseFormDropDown>

                <CustomInput
                  inputType="text"
                  Label="Experience Listens Earnings"
                  register={{
                    ...register('experience_listen_rate', {
                      valueAsNumber: true,
                      onChange: (e) => decimalOnChange(e, 'experience_listen_rate', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'experience_listen_rate', setValue),
                      min: {
                        value: 0,
                        message: 'Enter dollar amount of $0.00 or above',
                      },
                    }),
                  }}
                  //@ts-ignore
                  Error={errors?.experience_listen_rate?.message?.length ? true : false}
                  ErrorMessage={errors.experience_listen_rate?.message}
                  enableAdornment
                />
                <CustomInput
                  inputType="text"
                  Label="Experience Approval Earnings"
                  register={{
                    ...register('experience_bonus', {
                      valueAsNumber: true,
                      onChange: (e) => decimalOnChange(e, 'experience_bonus', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'experience_bonus', setValue),
                      min: {
                        value: 0,
                        message: 'Enter dollar amount of $0.00 or above',
                      },
                    }),
                  }}
                  Error={errors?.experience_bonus?.message?.length ? true : false}
                  ErrorMessage={errors.experience_bonus?.message}
                  enableAdornment
                />
                <CustomInput
                  inputType="text"
                  Label="Max experience payouts"
                  register={{
                    ...register('max_experience_payouts', {
                      onChange: (e) => decimalOnChange(e, 'max_experience_payouts', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'max_experience_payouts', setValue),
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Enter hour value 0 or above',
                      },
                    }),
                  }}
                  Error={
                    //@ts-ignore
                    errors?.max_experience_payouts?.message?.length ? true : false
                  }
                  ErrorMessage={errors.max_experience_payouts?.message}
                />
                <CustomInput
                  inputType="text"
                  Label="Max office hours per day"
                  register={{
                    ...register('max_daily_office_hours', {
                      onChange: (e) => decimalOnChange(e, 'max_daily_office_hours', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'max_daily_office_hours', setValue),
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Enter hour value between  0 and 24',
                      },
                      max: {
                        value: 24,
                        message: 'Enter hour value between  0 and 24',
                      },
                    }),
                  }}
                  Error={
                    //@ts-ignore
                    errors?.max_daily_office_hours?.message?.length ? true : false
                  }
                  ErrorMessage={errors.max_daily_office_hours?.message}
                />
                <CustomInput
                  inputType="text"
                  Label="Call Earnings"
                  register={{
                    ...register('call_earnings', {
                      valueAsNumber: true,
                      onChange: (e) => decimalOnChange(e, 'call_earnings', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'call_earnings', setValue),
                      min: {
                        value: 0,
                        message: 'Enter dollar amount of $10.00 or less',
                      },
                      max: {
                        value: 10,
                        message: 'Enter dollar amount of $10.00 or less',
                      },
                    }),
                  }}
                  Error={errors?.call_earnings?.message?.length ? true : false}
                  ErrorMessage={errors.call_earnings?.message}
                  enableAdornment
                />
                <CustomInput
                  inputType="text"
                  Label="Call Bonus"
                  register={{
                    ...register('call_bonus', {
                      onChange: (e) => decimalOnChange(e, 'call_bonus', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'call_bonus', setValue),
                      valueAsNumber: true,
                      min: {
                        value: 0.0,
                        message: 'Enter dollar amount of $40.00 or less',
                      },
                      max: {
                        value: 40,
                        message: 'Enter dollar amount of $40.00 or less',
                      },
                    }),
                  }}
                  Error={errors?.call_bonus?.message?.length ? true : false}
                  ErrorMessage={errors.call_bonus?.message}
                  enableAdornment
                />

                <CustomInput
                  inputType="text"
                  Label="Minimum Office Hours"
                  register={{
                    ...register('minimum_office_hours', {
                      onChange: (e) => decimalOnChange(e, 'minimum_office_hours', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'minimum_office_hours', setValue),
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Enter hour value between  0 and 24',
                      },
                      max: {
                        value: 24,
                        message: 'Enter hour value between  0 and 24',
                      },
                    }),
                  }}
                  Error={
                    //@ts-ignore
                    errors?.minimum_office_hours?.message?.length ? true : false
                  }
                  ErrorMessage={errors.minimum_office_hours?.message}
                />
                <CustomInput
                  enableAdornment
                  inputType="text"
                  Label="Office Hour Rate"
                  register={{
                    ...register('office_hour_rate', {
                      onChange: (e) => decimalOnChange(e, 'office_hour_rate', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'office_hour_rate', setValue),
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Enter Min dollar amount ',
                      },
                      max: {
                        value: 40,
                        message: 'Enter dollar amount of $40.00 or less',
                      },
                    }),
                  }}
                  Error={errors?.office_hour_rate?.message?.length ? true : false}
                  ErrorMessage={errors.office_hour_rate?.message}
                />

                <div className="flex justify-between">
                  <div>
                    <div className="w-full ">
                      <div className="flex justify-between items-center">
                        <label className="block text-sm leading-4   text-blue-dark mb-2 mr-2">Pay Office Hours </label>
                        <UseFormToggle
                          id={'payOfficeHours'}
                          register={{ ...register('pay_office_hours') }}
                          setStatus={(data) => {
                            setValue('pay_office_hours', data, {
                              shouldDirty: true,
                            });
                          }}
                          initialstatus={tierId === 'new' ? false : props.tiers?.pay_office_hours || false}
                        ></UseFormToggle>
                      </div>
                      <div className="flex justify-between items-center mt-2">
                        <label className="block text-sm leading-4   text-blue-dark mb-2">is Default? </label>
                        <UseFormToggle
                          id={'isDefault?'}
                          // value={getValues('is_default')}
                          disabled={props.tiers?.is_default}
                          register={{ ...register('is_default') }}
                          setStatus={(data: boolean) => {
                            setValue('is_default', data, {
                              shouldDirty: true,
                            });
                          }}
                          initialstatus={tierId === 'new' ? false : props.tiers?.is_default || false}
                        ></UseFormToggle>
                      </div>
                    </div>
                  </div>
                  <div className="ml-2">
                    <CustomInput
                      inputType="text"
                      Label="Paid Office Hours Cap Per Week"
                      register={{
                        ...register('paid_office_hours_cap', {
                          onChange: (e) => decimalOnChange(e, 'paid_office_hours_cap', setValue, 2),
                          onBlur: (e) => numberonBlur(e, 'paid_office_hours_cap', setValue),
                          required: 'Field is required',
                          valueAsNumber: true,
                          min: {
                            value: 0.5,
                            message: 'Enter hour value between  0.5 and 80',
                          },
                          max: {
                            value: 80,
                            message: 'Enter hour value between  0.5 and 80',
                          },
                        }),
                      }}
                      Error={
                        //@ts-ignore
                        errors?.paid_office_hours_cap?.message?.length ? true : false
                      }
                      ErrorMessage={errors.paid_office_hours_cap?.message}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <AlertBox
        visible={isShowAlertmodel}
        handlePrompt={handleDelete}
        closeModal={() => setIsShowAlertModel(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove ${getValues('name')}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiers: state.tiers.TIER?.data || null,
    newTier: state.tiers.newTier?.data || null, //
    removeTiers: state.tiers.RemoveTiers?.data || null, //
    pending: state.tiers?.pending || null,
  };
};

export default connect(mapStateToProps)(TierListing);
