import Modal from '../common/modal';
interface Props {
  visible?: boolean | undefined;
  handlePrompt: () => void;
  closeModal: () => void;
  handleContinueSave?: () => void;
  titleText: string;
  contentText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmSaveButtonText?: string;
}
const AlertBox = ({
  visible,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  confirmSaveButtonText,
  handlePrompt,
  closeModal,
  handleContinueSave,
}: Props) => {
  return (
    <Modal
      isModel={visible}
      InnerComponent={
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  {titleText}
                </h3>
                <div className="mt-2 flex">
                  <p className="text-sm text-gray-500 flex items-center">{contentText}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {confirmSaveButtonText && (
              <button
                type="button"
                onClick={handleContinueSave}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {confirmSaveButtonText}
              </button>
            )}
            <button
              type="button"
              onClick={handlePrompt}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {confirmButtonText}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              {cancelButtonText}
            </button>
          </div>
        </div>
      }
    ></Modal>
  );
};
export default AlertBox;
