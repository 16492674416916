import {
  UPDATE_LISTENER_DETAILS_REQUEST,
  UPDATE_LISTENER_DETAILS_FAILURE,
  UPDATE_LISTENER_DETAILS_SUCCESS,
  GET_LISTENER_DETAILS_REQUEST,
  GET_LISTENER_DETAILS_FAILURE,
  GET_LISTENER_DETAILS_SUCCESS,
  FETCH_LISTENERS_REQUEST,
  FETCH_LISTENERS_FAILURE,
  FETCH_LISTENERS_SUCCESS,
  GET_LISTEN_TOPIC_REPORT_REQUEST,
  GET_LISTEN_TOPIC_REPORT_FAILURE,
  GET_LISTEN_TOPIC_REPORT_SUCCESS,
  POST_LISTENER_GRADE_REQUEST,
  POST_LISTENER_GRADE_FAILURE,
  POST_LISTENER_GRADE_SUCCESS,
  FETCH_MESSAGE_QUALITY_REQUEST,
  FETCH_MESSAGE_QUALITY_SUCCESS,
  FETCH_MESSAGE_QUALITY_FAILURE,
  FETCH_LIST_CONNECTIONS_REQUEST,
  FETCH_LIST_CONNECTIONS_FAILURE,
  FETCH_LIST_CONNECTIONS_SUCCESS,
  FETCH_CALL_LOG_REQUEST,
  FETCH_CALL_LOG_FAILURE,
  FETCH_CALL_LOG_SUCCESS,
  FETCH_CONNECTIONS_COUNT_REQUEST,
  FETCH_CONNECTIONS_COUNT_FAILURE,
  FETCH_CONNECTIONS_COUNT_SUCCESS,
  POST_LISTENER_PROFILE_REJECTED_REQUEST,
  POST_LISTENER_PROFILE_REJECTED_FAILURE,
  POST_LISTENER_PROFILE_REJECTED_SUCCESS,
  FETCH_LISTENERS_COUNT_REQUEST,
  FETCH_LISTENERS_COUNT_FAILURE,
  FETCH_LISTENERS_COUNT_SUCCESS,
  FETCH_LISTENER_AVAILABILTY_REQUEST,
  FETCH_LISTENER_AVAILABILTY_SUCCESS,
  FETCH_LISTENER_AVAILABILTY_FAILURE,
  FETCH_ENGAGEMENT_REQUEST,
  FETCH_ENGAGEMENT_FAILURE,
  FETCH_ENGAGEMENT_SUCCESS,
  FETCH_LOGS_REQUEST,
  FETCH_LOGS_FAILURE,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGSCOUNT_REQUEST,
  FETCH_LOGSCOUNT_FAILURE,
  FETCH_LOGSCOUNT_SUCCESS,
  UPDATE_LISTENER_TAGS_REQUEST,
  UPDATE_LISTENER_TAGS_FAILURE,
  UPDATE_LISTENER_TAGS_SUCCESS,
  GET_LISTENER_TAGS_REQUEST,
  GET_LISTENER_TAGS_FAILURE,
  GET_LISTENER_TAGS_SUCCESS,
  REMOVE_LISTENER_TAGS_SUCCESS,
  REMOVE_LISTENER_TAGS_FAILURE,
  REMOVE_LISTENER_TAGS_REQUEST,
  UPDATE_LISTENER_TAGS_LIST_SUCCESS,
  UPDATE_LISTENER_TAGS_LIST_FAILURE,
  UPDATE_LISTENER_TAGS_LIST_REQUEST,
  POST_AUDIO_REQUEST,
  POST_AUDIO_FAILURE,
  POST_AUDIO_SUCCESS,
  FETCH_AUDIO_LIST_REQUEST,
  FETCH_AUDIO_LIST_FAILURE,
  FETCH_AUDIO_LIST_SUCCESS,
  UPDATE_AUDIO_REQUEST,
  UPDATE_AUDIO_FAILURE,
  UPDATE_AUDIO_SUCCESS,
  FETCH_MONEY_REPORT_FAILURE,
  FETCH_MONEY_REPORT_REQUEST,
  FETCH_MONEY_REPORT_SUCCESS,
  UPDATE_PEER_STATUS_FAILURE,
  UPDATE_PEER_STATUS_REQUEST,
  UPDATE_PEER_STATUS_SUCCESS,
} from './actionTypes';

import {
  FetchLogsRequest,
  FetchLogsSuccess,
  FetchLogsSuccessPayload,
  FetchLogsFailure,
  FetchLogsFailurePayload,
  FetchListenersRequest,
  FetchListenersSuccess,
  FetchListenersSuccessPayload,
  FetchListenersFailure,
  FetchListenersFailurePayload,
  GetListenTopicReportRequest,
  GetListenTopicReportSuccess,
  GetListenTopicReportSuccessPayload,
  GetListenTopicReportFailure,
  GetListenTopicReportFailurePayload,
  PostListenerGradeRequest,
  PostListenerGradeSuccess,
  PostListenerGradeSuccessPayload,
  PostListenerGradeFailure,
  PostListenerGradeFailurePayload,
  FetchMessageQualityRequest,
  FetchMessageQualitySuccess,
  FetchMessageQualityFailure,
  FetchMessageQualitySuccessPayload,
  FetchMessageQualityFailurePayload,
  FetchListConnectionsRequest,
  FetchListConnectionsSuccess,
  FetchListConnectionsSuccessPayload,
  FetchListConnectionsFailure,
  FetchListConnectionsFailurePayload,
  FetchCallLogRequest,
  FetchCallLogSuccess,
  FetchCallLogSuccessPayload,
  FetchCallLogFailure,
  FetchCallLogFailurePayload,
  FetchConnectionCountRequest,
  FetchConnectionCountSuccess,
  FetchConnectionCountFailure,
  FetchConnectionCountFailurePayload,
  UpdateListenerDetailsRequest,
  UpdateListenerDetailsSuccess,
  UpdateListenerDetailsSuccessPayload,
  UpdateListenerDetailsFailure,
  UpdateListenerDetailsFailurePayload,
  GetListenerDetailsRequest,
  GetListenerDetailsSuccess,
  GetListenerDetailsSuccessPayload,
  GetListenerDetailsFailure,
  GetListenerDetailsFailurePayload,
  UpdateListenersProfileRejectedRequest,
  UpdateListenersProfileRejectedSuccess,
  UpdateListenersProfileRejectedSuccessPayload,
  UpdateListenersProfileRejectedFailure,
  UpdateListenersProfileRejectedFailurePayload,
  FetchListenerCountRequest,
  FetchListenerCountSuccess,
  FetchListenerCountFailure,
  FetchListenerCountFailurePayload,
  FetchEngagementRequest,
  FetchEngagementSuccess,
  FetchEngagementFailure,
  FetchEngagementFailurePayload,
  FetchLogsCountRequest,
  FetchLogsCountSuccess,
  FetchLogsCountSuccessPayload,
  FetchLogsCountFailure,
  FetchLogsCountFailurePayload,
  FetchListenerAvailabilityRequest,
  FetchListenerAvailabilitySuccess,
  FetchListenerAvailabilityFailure,
  FetchListenerAvailabilitySuccessPayload,
  FetchListenerAvailabilityFailurePayload,
  UpdateListenersTagsRequest,
  UpdateListenersTagsSuccess,
  UpdateListenersTagsSuccessPayload,
  UpdateListenersTagsFailure,
  UpdateListenersTagsFailurePayload,
  GetListenerTagsRequest,
  GetListenerTagsSuccess,
  GetListenerTagsSuccessPayload,
  GetListenerTagsFailure,
  GetListenerTagsFailurePayload,
  RemoveListenerTagsFailurePayload,
  RemoveListenerTagsSuccessPayload,
  RemoveListenerTagsFailure,
  RemoveListenerTagsSuccess,
  RemoveListenerTagsRequest,
  UpdateListenerTagsListRequest,
  UpdateListenerTagsListSuccess,
  UpdateListenerTagsListFailure,
  UpdateListenerTagsListSuccessPayload,
  UpdateListenerTagsListFailurePayload,
  PostAudioRequest,
  PostAudioSuccess,
  PostAudioSuccessPayload,
  PostAudioFailure,
  PostAudioFailurePayload,
  FetchAudioListRequest,
  FetchAudioListSuccess,
  FetchAudioListSuccessPayload,
  FetchAudioListFailure,
  FetchAudioListFailurePayload,
  UpdateAudioRequest,
  UpdateAudioSuccess,
  UpdateAudioSuccessPayload,
  UpdateAudioFailure,
  UpdateAudioFailurePayload,
  FetchMoneyDetailsFailure,
  FetchMoneyDetailsFailurePayload,
  FetchMoneyDetailsRequest,
  FetchMoneyDetailsSuccess,
  FetchMoneyDetailsSuccessPayload,
  UpdatePeerStatusFailure,
  UpdatePeerStatusFailurePayload,
  UpdatePeerStatusRequest,
  UpdatePeerStatusSuccess,
  UpdatePeerStatusSuccessPayload,
} from '../common/types';

export const updateListenersRequest = (payload: any): UpdateListenerDetailsRequest => ({
  type: UPDATE_LISTENER_DETAILS_REQUEST,
  payload,
});

export const updateListenersSuccess = (payload: UpdateListenerDetailsSuccessPayload): UpdateListenerDetailsSuccess => ({
  type: UPDATE_LISTENER_DETAILS_SUCCESS,
  payload,
});

export const updateListenersFailure = (payload: UpdateListenerDetailsFailurePayload): UpdateListenerDetailsFailure => ({
  type: UPDATE_LISTENER_DETAILS_FAILURE,
  payload,
});

export const getListenerDetailsRequest = (payload: any): GetListenerDetailsRequest => ({
  type: GET_LISTENER_DETAILS_REQUEST,
  payload,
});

export const getListenerDetailsSuccess = (payload: GetListenerDetailsSuccessPayload): GetListenerDetailsSuccess => ({
  type: GET_LISTENER_DETAILS_SUCCESS,
  payload,
});

export const getListenerDetailsFailure = (payload: GetListenerDetailsFailurePayload): GetListenerDetailsFailure => ({
  type: GET_LISTENER_DETAILS_FAILURE,
  payload,
});

export const updateListenerDetailsRequest = (payload: any): UpdateListenerDetailsRequest => ({
  type: UPDATE_LISTENER_DETAILS_REQUEST,
  payload,
});

export const updateListenerDetailsSuccess = (
  payload: UpdateListenerDetailsSuccessPayload,
): UpdateListenerDetailsSuccess => ({
  type: UPDATE_LISTENER_DETAILS_SUCCESS,
  payload,
});

export const updateListenerDetailsFailure = (
  payload: UpdateListenerDetailsFailurePayload,
): UpdateListenerDetailsFailure => ({
  type: UPDATE_LISTENER_DETAILS_FAILURE,
  payload,
});

export const fetchLogsRequest = (payload: any): FetchLogsRequest => ({
  type: FETCH_LOGS_REQUEST,
  payload,
});

export const fetchLogsSuccess = (payload: FetchLogsSuccessPayload): FetchLogsSuccess => ({
  type: FETCH_LOGS_SUCCESS,
  payload,
});

export const fetchLogsFailure = (payload: FetchLogsFailurePayload): FetchLogsFailure => ({
  type: FETCH_LOGS_FAILURE,
  payload,
});

export const fetchListenersRequest = (payload: any): FetchListenersRequest => ({
  type: FETCH_LISTENERS_REQUEST,
  payload,
});

export const fetchListenersSuccess = (payload: FetchListenersSuccessPayload): FetchListenersSuccess => ({
  type: FETCH_LISTENERS_SUCCESS,
  payload,
});

export const fetchListenersFailure = (payload: FetchListenersFailurePayload): FetchListenersFailure => ({
  type: FETCH_LISTENERS_FAILURE,
  payload,
});

export const getListenTopicReportRequest = (payload: any): GetListenTopicReportRequest => ({
  type: GET_LISTEN_TOPIC_REPORT_REQUEST,
  payload,
});

export const getListenTopicReportSuccess = (
  payload: GetListenTopicReportSuccessPayload,
): GetListenTopicReportSuccess => ({
  type: GET_LISTEN_TOPIC_REPORT_SUCCESS,
  payload,
});

export const getListenTopicReportFailure = (
  payload: GetListenTopicReportFailurePayload,
): GetListenTopicReportFailure => ({
  type: GET_LISTEN_TOPIC_REPORT_FAILURE,
  payload,
});

export const postListenerGradeRequest = (payload: any): PostListenerGradeRequest => ({
  type: POST_LISTENER_GRADE_REQUEST,
  payload,
});

export const postListenerGradeSuccess = (payload: PostListenerGradeSuccessPayload): PostListenerGradeSuccess => ({
  type: POST_LISTENER_GRADE_SUCCESS,
  payload,
});

export const postListenerGradeFailure = (payload: PostListenerGradeFailurePayload): PostListenerGradeFailure => ({
  type: POST_LISTENER_GRADE_FAILURE,
  payload,
});

export const fetchMessageQualityRequest = (payload: any): FetchMessageQualityRequest => ({
  type: FETCH_MESSAGE_QUALITY_REQUEST,
  payload,
});

export const fetchMessageQualitySuccess = (payload: FetchMessageQualitySuccessPayload): FetchMessageQualitySuccess => ({
  type: FETCH_MESSAGE_QUALITY_SUCCESS,
  payload,
});

export const fetchMessageQualityFailure = (payload: FetchMessageQualityFailurePayload): FetchMessageQualityFailure => ({
  type: FETCH_MESSAGE_QUALITY_FAILURE,
  payload,
});

export const fetchListConnectionsRequest = (payload: any): FetchListConnectionsRequest => ({
  type: FETCH_LIST_CONNECTIONS_REQUEST,
  payload,
});

export const fetchListConnectionsSuccess = (
  payload: FetchListConnectionsSuccessPayload,
): FetchListConnectionsSuccess => ({
  type: FETCH_LIST_CONNECTIONS_SUCCESS,
  payload,
});

export const fetchListConnectionsFailure = (
  payload: FetchListConnectionsFailurePayload,
): FetchListConnectionsFailure => ({
  type: FETCH_LIST_CONNECTIONS_FAILURE,
  payload,
});

export const fetchCallLogRequest = (payload: any): FetchCallLogRequest => ({
  type: FETCH_CALL_LOG_REQUEST,
  payload,
});

export const fetchCallLogSuccess = (payload: FetchCallLogSuccessPayload): FetchCallLogSuccess => ({
  type: FETCH_CALL_LOG_SUCCESS,
  payload,
});

export const fetchCallLogFailure = (payload: FetchCallLogFailurePayload): FetchCallLogFailure => ({
  type: FETCH_CALL_LOG_FAILURE,
  payload,
});

export const fetchConnectionCountRequest = (payload: any): FetchConnectionCountRequest => ({
  type: FETCH_CONNECTIONS_COUNT_REQUEST,
  payload,
});

export const fetchConnectionCountSuccess = (payload: any): FetchConnectionCountSuccess => ({
  type: FETCH_CONNECTIONS_COUNT_SUCCESS,
  payload,
});

export const fetchConnectionCountFailure = (
  payload: FetchConnectionCountFailurePayload,
): FetchConnectionCountFailure => ({
  type: FETCH_CONNECTIONS_COUNT_FAILURE,
  payload,
});

export const fetchEngagementRequest = (payload: any): FetchEngagementRequest => ({
  type: FETCH_ENGAGEMENT_REQUEST,
  payload,
});

export const fetchEngagementSuccess = (payload: any): FetchEngagementSuccess => ({
  type: FETCH_ENGAGEMENT_SUCCESS,
  payload,
});

export const fetchEngagementFailure = (payload: FetchEngagementFailurePayload): FetchEngagementFailure => ({
  type: FETCH_ENGAGEMENT_FAILURE,
  payload,
});

export const updateListenersProfileRejectedRequest = (payload: any): UpdateListenersProfileRejectedRequest => ({
  type: POST_LISTENER_PROFILE_REJECTED_REQUEST,
  payload,
});

export const updateListenersProfileRejectedSuccess = (
  payload: UpdateListenersProfileRejectedSuccessPayload,
): UpdateListenersProfileRejectedSuccess => ({
  type: POST_LISTENER_PROFILE_REJECTED_SUCCESS,
  payload,
});

export const updateListenersProfileRejectedFailure = (
  payload: UpdateListenersProfileRejectedFailurePayload,
): UpdateListenersProfileRejectedFailure => ({
  type: POST_LISTENER_PROFILE_REJECTED_FAILURE,
  payload,
});

export const fetchListenerCountRequest = (payload: any): FetchListenerCountRequest => ({
  type: FETCH_LISTENERS_COUNT_REQUEST,
  payload,
});

export const fetchListenerCountSuccess = (payload: any): FetchListenerCountSuccess => ({
  type: FETCH_LISTENERS_COUNT_SUCCESS,
  payload,
});

export const fetchListenerCountFailure = (payload: FetchListenerCountFailurePayload): FetchListenerCountFailure => ({
  type: FETCH_LISTENERS_COUNT_FAILURE,
  payload,
});

export const fetchListenerAvailabilityRequest = (payload: any): FetchListenerAvailabilityRequest => ({
  type: FETCH_LISTENER_AVAILABILTY_REQUEST,
  payload,
});

export const fetchListenerAvailabilitySuccess = (
  payload: FetchListenerAvailabilitySuccessPayload,
): FetchListenerAvailabilitySuccess => ({
  type: FETCH_LISTENER_AVAILABILTY_SUCCESS,
  payload,
});

export const fetchListenerAvailabilityFailure = (
  payload: FetchListenerAvailabilityFailurePayload,
): FetchListenerAvailabilityFailure => ({
  type: FETCH_LISTENER_AVAILABILTY_FAILURE,
  payload,
});

export const fetchLogsCountRequest = (payload: any): FetchLogsCountRequest => ({
  type: FETCH_LOGSCOUNT_REQUEST,
  payload,
});

export const fetchLogsCountSuccess = (payload: FetchLogsCountSuccessPayload): FetchLogsCountSuccess => ({
  type: FETCH_LOGSCOUNT_SUCCESS,
  payload,
});

export const fetchLogsCountFailure = (payload: FetchLogsCountFailurePayload): FetchLogsCountFailure => ({
  type: FETCH_LOGSCOUNT_FAILURE,
  payload,
});

export const getListenerTagsRequest = (payload: any): GetListenerTagsRequest => ({
  type: GET_LISTENER_TAGS_REQUEST,
  payload,
});

export const getListenerTagsSuccess = (payload: GetListenerTagsSuccessPayload): GetListenerTagsSuccess => ({
  type: GET_LISTENER_TAGS_SUCCESS,
  payload,
});

export const getListenerTagsFailure = (payload: GetListenerTagsFailurePayload): GetListenerTagsFailure => ({
  type: GET_LISTENER_TAGS_FAILURE,
  payload,
});

export const updateListenerTagsRequest = (payload: any): UpdateListenersTagsRequest => ({
  type: UPDATE_LISTENER_TAGS_REQUEST,
  payload,
});

export const updateListenerTagsSuccess = (payload: UpdateListenersTagsSuccessPayload): UpdateListenersTagsSuccess => ({
  type: UPDATE_LISTENER_TAGS_SUCCESS,
  payload,
});

export const updateListenerTagsFailure = (payload: UpdateListenersTagsFailurePayload): UpdateListenersTagsFailure => ({
  type: UPDATE_LISTENER_TAGS_FAILURE,
  payload,
});

export const updateListenerTagsListRequest = (payload: any): UpdateListenerTagsListRequest => ({
  type: UPDATE_LISTENER_TAGS_LIST_REQUEST,
  payload,
});

export const updateListenerTagsListSuccess = (
  payload: UpdateListenerTagsListSuccessPayload,
): UpdateListenerTagsListSuccess => ({
  type: UPDATE_LISTENER_TAGS_LIST_SUCCESS,
  payload,
});

export const updateListenerTagsListFailure = (
  payload: UpdateListenerTagsListFailurePayload,
): UpdateListenerTagsListFailure => ({
  type: UPDATE_LISTENER_TAGS_LIST_FAILURE,
  payload,
});

export const removeListenerTagsRequest = (payload: any): RemoveListenerTagsRequest => ({
  type: REMOVE_LISTENER_TAGS_REQUEST,
  payload,
});

export const removeListenerTagsSuccess = (payload: RemoveListenerTagsSuccessPayload): RemoveListenerTagsSuccess => ({
  type: REMOVE_LISTENER_TAGS_SUCCESS,
  payload,
});

export const removeListenerTagsFailure = (payload: RemoveListenerTagsFailurePayload): RemoveListenerTagsFailure => ({
  type: REMOVE_LISTENER_TAGS_FAILURE,
  payload,
});

export const postAudioRequest = (payload: any): PostAudioRequest => ({
  type: POST_AUDIO_REQUEST,
  payload,
});

export const postAudioSuccess = (payload: PostAudioSuccessPayload): PostAudioSuccess => ({
  type: POST_AUDIO_SUCCESS,
  payload,
});

export const postAudioFailure = (payload: PostAudioFailurePayload): PostAudioFailure => ({
  type: POST_AUDIO_FAILURE,
  payload,
});

export const fetchAudioListRequest = (payload: any): FetchAudioListRequest => ({
  type: FETCH_AUDIO_LIST_REQUEST,
  payload,
});

export const fetchAudioListSuccess = (payload: FetchAudioListSuccessPayload): FetchAudioListSuccess => ({
  type: FETCH_AUDIO_LIST_SUCCESS,
  payload,
});

export const fetchAudioListFailure = (payload: FetchAudioListFailurePayload): FetchAudioListFailure => ({
  type: FETCH_AUDIO_LIST_FAILURE,
  payload,
});

export const updateAudioRequest = (payload: any): UpdateAudioRequest => ({
  type: UPDATE_AUDIO_REQUEST,
  payload,
});

export const updateAudioSuccess = (payload: UpdateAudioSuccessPayload): UpdateAudioSuccess => ({
  type: UPDATE_AUDIO_SUCCESS,
  payload,
});

export const updateAudioFailure = (payload: UpdateAudioFailurePayload): UpdateAudioFailure => ({
  type: UPDATE_AUDIO_FAILURE,
  payload,
});

export const fetchMoneyDetailsRequest = (payload: any): FetchMoneyDetailsRequest => ({
  type: FETCH_MONEY_REPORT_REQUEST,
  payload,
});

export const fetchMoneyDetailsSuccess = (payload: FetchMoneyDetailsSuccessPayload): FetchMoneyDetailsSuccess => ({
  type: FETCH_MONEY_REPORT_SUCCESS,
  payload,
});

export const fetchMoneyDetailsFailure = (payload: FetchMoneyDetailsFailurePayload): FetchMoneyDetailsFailure => ({
  type: FETCH_MONEY_REPORT_FAILURE,
  payload,
});

export const updatePeerStatusRequest = (payload: any): UpdatePeerStatusRequest => ({
  type: UPDATE_PEER_STATUS_REQUEST,
  payload,
});

export const updatePeerStatusSuccess = (payload: UpdatePeerStatusSuccessPayload): UpdatePeerStatusSuccess => ({
  type: UPDATE_PEER_STATUS_SUCCESS,
  payload,
});

export const updatePeerStatusFailure = (payload: UpdatePeerStatusFailurePayload): UpdatePeerStatusFailure => ({
  type: UPDATE_PEER_STATUS_FAILURE,
  payload,
});
