import ToggleButton from '../../../components/common/toggle-button';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import { CustomInput } from '../../../components/common';
import { postCreatePackageProductRequest, postUpdatePackageProductRequest } from '../clients-redux/actions';

type props = {
  client: any;
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

const UnlimitedAcess: React.FunctionComponent<props> = (props): JSX.Element => {
  const [hidePayment, sethidePayment] = useState(false);
  const [isCallUnit, setisCallUnit] = useState(false);
  const [unlimitedAccess, setunlimitedAccess] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<any>({});
  const [popUperrormessage, setpopUperrormessage] = useState('');
  const [id, setid] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;

      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'UNLIMITED_ACCESS') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setisCallUnit(selectedItem.configuration?.is_call_units);
        setunlimitedAccess(selectedItem?.status === 'enabled' ? true : false);
        const configuration = selectedItem.configuration;
        sethidePayment(configuration?.hide_payment_tiles);
        setAccountBalance(selectedItem.minute_balance);
        setReservedBalance(selectedItem.unused_minute_balance);
        setproductId(selectedItem.product_id);
        setid(selectedItem.id);
      } else {
        setunlimitedAccess(false);
        const configuration = props.default.configuration;
        sethidePayment(configuration?.hide_payment_tiles);
        setAccountBalance(0);
        setReservedBalance(0);
        setpackageId(props.data.id);
        setid(props.default.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    const payload = {
      status: unlimitedAccess ? 'enabled' : 'disabled',
      configuration: {
        hide_payment_tiles: hidePayment,
        is_call_units: isCallUnit,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (productId) {
      dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
    } else {
      dispatch(postCreatePackageProductRequest({ linkInput, payload }));
    }
  };

  return (
    <>
      <AccordionTile
        setStatus={(data: boolean) => {
          !enableSaveOption && setenableSaveOption(true);
          setunlimitedAccess(data);
        }}
        Label={'UNLIMITED ACCESS'}
        status={unlimitedAccess}
        id={'UnlimitedAcess'}
        buttonText={'SAVE'}
        initialstatus={true}
        disableButton={enableSaveOption}
        onButtonClick={() => {
          if (enableSaveOption) onSave();
        }}
        InnerComponent={
          <div>
            <div className="w-full flex justify-start pt-2 ">
              <div className="w-1/3 flex  items-center px-2">
                <p className="text-blue-dark font-medium text-sm">Reserved Balance: {reservedBalance} minutes</p>
              </div>
              <div className="flex w-1/3 items-center">
                <p className="text-blue-dark font-medium text-sm px-4">Hide Payment Setup Requests</p>
                <ToggleButton
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    sethidePayment(data);
                  }}
                  status={hidePayment}
                  id={'hidepaymentStatus'}
                  initialstatus={true}
                />
                <p className="text-blue-dark font-medium text-sm px-4">Using Call Units</p>
                <ToggleButton
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    setisCallUnit(data);
                  }}
                  status={isCallUnit}
                  id={'is_call_unit'}
                  initialstatus={false}
                />
              </div>
            </div>
            {!productId && (
              <p className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                You must save this product to add credit
              </p>
            )}
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(UnlimitedAcess);
