import React from 'react';

type props = {
  heading: String;
};

const Heading: React.FunctionComponent<props> = ({ heading }): JSX.Element => {
  return (
    <div>
      <div className="relative ml-7 flex w-12/12 pt-3 border-b gray-border-line">
        <p className="font-bold  text-center  text-gray-light text-xxs">{heading.toUpperCase()}</p>
      </div>
    </div>
  );
};

export default Heading;
