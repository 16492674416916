import * as React from 'react';
import { Chart } from 'react-google-charts';

type props = {
  error: false;
};

const Charts: React.FunctionComponent<props> = ({ error }): JSX.Element => {
  return (
    <div>
      <div>
        <Chart
          width={'15vw'}
          height={'100px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['x', 'Profit Curve'],
            [0, 2],
            [1, 4],
            [2, 7],
            [3, 6],
            [4, 9],
            [5, 11],
            [6, 14],
            [7, 16],
          ]}
          options={{
            chartArea: {
              height: '100%',
              width: '100%',
              top: 32,
              left: 32,
              bottom: 32,
              right: 16,
            },
            curveType: 'function',
            pointSize: 5,
            backgroundColor: 'white',
            colors: ['#1D1183'],
            hAxis: {
              gridlines: {
                color: 'transparent',
              },
              baselineColor: '#fff',
              gridlineColor: '#fff',
              textPosition: 'none',
            },
            vAxis: {
              gridlines: {
                color: 'transparent',
              },
              baselineColor: '#868686',
              gridlineColor: '#f0f',
              textPosition: 'none',
            },
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    </div>
  );
};

export default Charts;
