import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface SearchQuery {
  id: number;
  query: string;
  created_at: string;
  score_results?: number[];
}

export const useSearchQueries = () => {
  return useQuery<SearchQuery[]>(['searchQueries'], async () => {
    const { data } = await axiosGet('/search', {});
    return data;
  });
};
