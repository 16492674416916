import React, { useState, useRef, useEffect } from 'react';
import '../../styles/components/audio-player-slider.css';
import { PlayButton, PauseButton } from '../../assets/images';

type Audioprops = {
  audioUrl: string;
  audioLength: number;
};

const AudioPlayer: React.FunctionComponent<Audioprops> = ({ audioUrl, audioLength }) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // This is used to actually stop the audio.
  const displayDuration = Math.floor(duration); // This is used to show nice, even seconds.

  // references
  const audioPlayer: any = useRef(); // reference our audio component
  const progressBar: any = useRef(); // reference our progress bar
  const animationRef: any = useRef(); // reference the animation

  useEffect(() => {
    if (audioPlayer?.current && progressBar?.current) {
      audioPlayer?.current?.addEventListener('loadedmetadata', (e: any) => {
        const seconds = e.target.duration;
        setDuration(seconds);
        progressBar?.current && (progressBar.current.max = seconds);
      });
    }
    return () => {
      if (audioPlayer?.removeEventListener) {
        audioPlayer.removeEventListener('loadedmetadata');
      }
    };
  }, [audioPlayer]);

  useEffect(() => {
    resetDefault();
  }, [audioUrl]);

  useEffect(() => {
    if (progressBar?.current) {
      progressBar.current.max = audioLength;
    }
  }, [audioLength]);

  const calculateTime = (secs: any) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const resetDefault = () => {
    audioPlayer.current.pause();
    setCurrentTime(0);
    setIsPlaying(false);
    audioPlayer.current.currentTime = 0;
    progressBar.current.value = 0;
  };

  const whilePlaying = () => {
    const { currentTime } = audioPlayer.current;
    if (+currentTime >= duration) {
      resetDefault();
    } else {
      progressBar.current.value = currentTime;
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
    changePlayerCurrentTime();
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${(progressBar.current.value / duration) * 100}%`);
    setCurrentTime(progressBar.current.value);
  };

  return (
    <div className=" w-full audioPlayer ">
      <audio ref={audioPlayer} src={audioUrl} preload="metadata"></audio>

      <img
        className={isPlaying ? 'w-25' : 'w-24'}
        src={isPlaying ? PauseButton : PlayButton}
        role="button"
        alt="play-button"
        onClick={togglePlayPause}
      />

      <div className="w-full  pl-12 mt-7">
        {/* progress bar */}

        <input type="range" className="progressBar " defaultValue="0" ref={progressBar} onChange={changeRange} />
        {/* current time */}
        <div className="flex justify-between pt-4">
          <p>{calculateTime(currentTime)}</p>
          <p>{displayDuration && !isNaN(displayDuration) && calculateTime(displayDuration)}</p>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
