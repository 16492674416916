import { Button, Dialog, Input } from '@kindlyhuman/component-library';
import { RedirectModalDTO } from './links';
import { useCreateRedirect, useDeleteRedirect, useUpdateRedirect } from '../../hooks/useRedirects';
import { toast } from 'react-toastify';

export interface LinksModalProps {
  redirect: RedirectModalDTO;
  open: boolean;
  onClose: () => void;
}

export const LinksModal = ({ open, onClose, redirect }: LinksModalProps) => {
  const createRedirect = useCreateRedirect();
  // fun fact. you can't use the same mutation in different ways on the same page. so we have to make a new one
  const deleteRedirectMutation = useDeleteRedirect();
  const updateRedirectMutation = useUpdateRedirect();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let key = formData.get('key') as string;
    key = key.trim();
    let hash = formData.get('hash') as string;
    hash = hash.trim();
    // value needs to be undefined or the API will use this as something different than a redirect
    const data = { key, hash, value: undefined };

    if (!redirect.isNew) {
      // Update the redirect
      updateRedirectMutation.mutate(
        { oldHash: redirect.hash, redirect: data },
        {
          onSuccess: () => {
            toast.success('Link Updated!');
            onClose();
          },
          onError: (err: any) => {
            toast.error(err.response.data.description);
          },
        },
      );
    } else {
      createRedirect.mutate(data, {
        onSuccess: () => {
          toast.success('Link Created!');
          onClose();
        },
        onError: (err: any) => {
          toast.error(err.response.data.error);
        },
      });
    }
  };

  const deleteRedirect = (redirect: RedirectModalDTO) => {
    // Delete the redirect
    deleteRedirectMutation.mutate(redirect, {
      onSuccess: () => {
        toast.success('Link Deleted!');
        onClose();
      },
      onError: (err: any) => {
        toast.error(err.response.data.description || err.response.data.error);
      },
    });
  };

  // don't allow rendering of the modal if there is no redirect selected
  if (!redirect) return null;

  return (
    <Dialog key={redirect.hash ?? ''} className="p-4 w-2/3" open={open} onClose={onClose}>
      <h1 className="text-2xl pb-4"> {redirect.isNew ? 'Add' : 'Edit'} Link</h1>
      <form onSubmit={submit} className="mx-10 flex flex-col justify-center gap-y-4 gap-x-4">
        <Input
          key={redirect.key}
          onIconClick={() => {}}
          id="key"
          name="key"
          defaultValue={redirect?.key}
          placeholder="Enter Redirect URL"
          label="key"
        />
        <Input
          key={redirect.hash}
          onIconClick={() => {}}
          id="hash"
          name="hash"
          defaultValue={redirect?.hash}
          placeholder="Custom Hash"
          label="hash (optional)"
        />
        <div className="pt-10 pb-4 flex justify-between">
          {!redirect.isNew ? (
            <button
              type="button"
              className="bg-redSalsa text-white px-4 py-2 rounded"
              onClick={() => deleteRedirect(redirect)}
            >
              Delete
            </button>
          ) : (
            <div />
          )}
          <div className="flex gap-x-4">
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
