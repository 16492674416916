import * as React from 'react';
import SideBar from './side-bar';

type props = {
  selectedId: number;
};

const SideBarContainer: React.FunctionComponent<props> = (props): JSX.Element => {
  return (
    <div>
      <SideBar selectedId={props.selectedId} />
    </div>
  );
};
export default SideBarContainer;
