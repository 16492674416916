import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/common/global-top-bar';

import { CustomInput } from '../../components/common';

import { HeaderOptions } from './clients-constants';
import RewardGiftCard from './packages/reward-gift-card';
import ConsumerSubscriptionModel from './packages/consumer-subsription-model';
import Promotion from './packages/promotion';
import CheckIns from './packages/check-ins';
import RewardsPTO from './packages/reward-pto';
import HeadBack from './redirect';
import Comms from './packages/comms';
import { connect, useDispatch } from 'react-redux';
import {
  fetchCampaignRequest,
  fetchEmailTemplateRequest,
  FetchIncentiveRequest,
  fetchSystemMessagesRequest,
  getClientDetailsRequest,
  getProductTemplateRequest,
  fetchWebAppUrlSettingRequest,
  postCreatePackageRequest,
  deletePackageRequest,
  fetchAppSettingsRequest,
  fetchExternalProviderPlansRequest,
  getDefaultSubscriptionsRequest,
} from './clients-redux/actions';
import CappedAccess from './packages/capped-access';
import UnlimitedAccess from './packages/unlimited-access';
import Discount from './packages/discount';
import PackageDetails from './packages/package-details';
import Spinner from '../../components/common/spinner';
import { useHistory } from 'react-router-dom';
import useOutsideAlerter from '../../components/common/outside-check';
import { toast } from 'react-toastify';
import Modal from '../../components/common/modal';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import Dependents from './packages/dependents';
import EligibilityConfiguration from './packages/eligibility-configuration';
import RichTextEditor from '../../components/common/rich-text-editor';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import MemberPay from './packages/member-pay';
import { set } from 'react-hook-form';

type props = {
  client: any;
  productTemplate: any;
  EmailTemplates: any;
  Campaigns: any;
  Incentives: any;
  systemMessages: any;
  webAppUrl: any;
  appSettings: any;
  error: any;
  pending: any;
};

type SwitchBarprops = {
  heading: header[];
  position: number;
  optionalButton?: React.ReactNode;
  isDisabled?: any;
};

interface header {
  path: string;
  heading: string;
}

const SwitchBar: React.FunctionComponent<SwitchBarprops> = ({
  heading,
  position,
  optionalButton,
  isDisabled,
}): JSX.Element => {
  const history = useHistory();
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
      <div className="flex justify-center items-center   gray-background-dark h-full">
        {heading.map((item: any, index) => (
          <button
            className={
              ' text-sm  px-5 py-2 ' +
              (index === position ? ' text-white font-bold bg-blue-primary h-full' : ' text-gray-dark h-full')
            }
            onClick={() => {
              history.push(item.path.toString());
            }}
            disabled={isDisabled}
          >
            {item.heading}
          </button>
        ))}
      </div>
      {optionalButton}
    </div>
  );
};

const Packages: React.FunctionComponent<props> = (props): JSX.Element => {
  const [packageName, setpackageName] = useState('');
  const [packageCode, setpackageCode] = useState('');
  const [internalNotes, setinternalNotes] = useState<any>();
  const [webAppUrl, setwebAppUrl] = useState<any>(null);
  const [addNewClientToggle, setAddNewClientToggle] = useState(false);
  const clientId = localStorage.getItem('clientid');
  const [savedataPopUp, setsavedataPopUp] = useState(false);
  const [error, seterror] = useState(false);

  const [saveWebAppUrl, setsaveWebAppUrl] = useState<any>('');
  const [webappDropDown, setwebappDropDown] = useState(false);
  const [dropDownoption, setdropDownoption] = useState('');
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [isDelete, setIsDelete] = useState(false);
  const [packageData, setPackageData] = useState<any>();

  useOutsideAlerter(wrapperRef, setwebappDropDown);

  const updatesaveDataPopUp = (data: boolean) => {
    setsavedataPopUp(data);
  };

  const isURL = (str: string) => {
    var urlRegex =
      '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str?.length < 2083 && url.test(str);
  };

  const Validation = () => {
    if (
      !packageName ||
      !packageCode ||
      (saveWebAppUrl !== null && saveWebAppUrl?.trim() !== '' && saveWebAppUrl && !isURL(saveWebAppUrl?.trim()))
    ) {
      seterror(true);
      return false;
    }
    seterror(false);
    return true;
  };

  const handleDeletePlan = () => {
    const input = {
      clientId: packageData?.client_id,
      packageId: packageData?.id,
      packageName: packageData?.name,
    };
    dispatch(deletePackageRequest(input));
    setIsDelete(!isDelete);
  };

  const handleDeletePlanModel = (data: any) => {
    setPackageData(data);
    setIsDelete(!isDelete);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (props.error) {
      const error = props.error;
      if (error.status === 400 && error?.data?.description) {
        toast.error(error.data.description);
      }
    }
  }, [props.error]);

  useEffect(() => {
    dispatch(getProductTemplateRequest({}));
    dispatch(fetchEmailTemplateRequest({}));
    dispatch(fetchCampaignRequest());
    dispatch(FetchIncentiveRequest({}));
    dispatch(fetchSystemMessagesRequest({}));
    dispatch(fetchWebAppUrlSettingRequest({}));
    dispatch(fetchAppSettingsRequest({}));
    dispatch(fetchExternalProviderPlansRequest());
    dispatch(getDefaultSubscriptionsRequest({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientId) {
      dispatch(getClientDetailsRequest({ clientId }));
    }
  }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.client?.packages.length < 1) {
      setAddNewClientToggle(true);
    } else {
      setAddNewClientToggle(false);
    }
  }, [props.client]);

  const handleSave = () => {
    const linkInput = {
      clientId,
    };

    const payload = {
      benefits_description: internalNotes ? draftToHtml(convertToRaw(internalNotes.getCurrentContent())) : null,
      code: packageCode?.trim(),
      name: packageName?.trim(),
      web_app_url:
        saveWebAppUrl === null || saveWebAppUrl?.trim() === '' || !saveWebAppUrl ? null : saveWebAppUrl?.trim(),
      dependents_configuration: {
        enabled: false,
        dependent_count: 3,
        members_can_add_dependents: true,
      },
    };
    if (clientId) {
      if (Validation()) {
        dispatch(postCreatePackageRequest({ payload, linkInput }));
      }
    } else {
      toast.error('Please create a client Before creating a package');
    }
  };

  const sorting = (pre: any, next: any) => {
    return pre.id - next.id;
  };

  useEffect(() => {
    if (props.client?.packages) {
      setsavedataPopUp(false);
    }
  }, [props.client?.packages]); // eslint-disable-line

  const findTemplate = (option: string) => {
    const template = props.productTemplate?.find((item: any) => item.key === option);
    return template;
  };

  useEffect(() => {
    if (props.webAppUrl) {
      setsaveWebAppUrl(props.webAppUrl?.LOC_WEB_APP_URL);
    }
  }, [props.webAppUrl]);

  const clearfields = () => {
    setwebAppUrl(`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})` || '');
    setsaveWebAppUrl(null);
    setpackageCode('');
    setpackageName('');
    setinternalNotes('');
    seterror(false);
  };

  return (
    <>
      <RouteLeavingGuard
        when={savedataPopUp}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.GROUPS_PACKAGES) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
      />
      {props.pending && <Spinner />}
      <Header userName={'Jason'} heading={<HeadBack clientName={props.client?.name || null} />} />

      <SwitchBar
        heading={HeaderOptions}
        position={2}
        isDisabled={clientId ? false : true}
        optionalButton={
          <div className="w-1/12">
            <div className="w-full">
              <button
                className="stacked-bar-blue-400 rounded-full w-full text-center h-6 text-sm text-white px-2"
                onClick={() => {
                  if (!addNewClientToggle) {
                    clearfields();
                    setAddNewClientToggle(true);
                  } else if (clientId) {
                    handleSave();
                  }
                }}
              >
                {!addNewClientToggle ? 'ADD' : clientId && 'SAVE'}
              </button>
            </div>
          </div>
        }
      />
      <Modal
        isModel={isDelete}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Alert
                  </h3>
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500 flex items-center">
                      Are you sure you want to remove this {packageData?.name}?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleDeletePlan}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Remove
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      ></Modal>
      <div className="max-window-height pb-32 overflow-y-auto px-7 pt-7">
        {props.client?.packages.length > 0 && !addNewClientToggle && clientId && props.productTemplate?.length > 0 ? (
          props.client.packages.sort(sorting).map((item: any) => {
            return (
              <div className="pb-12">
                <div className="h-10  w-full bg-black  flex justify-between items-center">
                  <div className="flex">
                    <div className="px-2 text-white">{item.name}</div>
                    <div className="px-4 font-bold text-white">{item.code}</div>
                  </div>
                  <div>
                    <div className="w-full pr-2">
                      <button
                        className="bg-gray-300 rounded-full w-full text-center h-6 text-sm text-white px-7"
                        onClick={() => handleDeletePlanModel(item)}
                      >
                        REMOVE
                      </button>
                    </div>
                    {/* <div className="w-full pr-2">
                                        <button className="bg-gray-dark rounded-full w-full text-center h-6 text-sm text-white px-7">
                                            CREATE TEMPLATE
                                        </button>
                                    </div> */}
                  </div>
                </div>
                <PackageDetails data={item} webAppUrl={props.webAppUrl} UpdateSaveOption={updatesaveDataPopUp} />
                <EligibilityConfiguration
                  data={item}
                  eligibilityConfig={props.client?.eligibility_config?.default_package}
                  UpdateSaveOption={updatesaveDataPopUp}
                />
                <MemberPay data={item} Campaigns={props.Campaigns} />
                <Dependents data={item} UpdateSaveOption={updatesaveDataPopUp} />
                <CappedAccess
                  data={item}
                  default={findTemplate('CAPPED_ACCESS')}
                  UpdateSaveOption={updatesaveDataPopUp}
                />
                <CheckIns
                  data={item}
                  default={findTemplate('CHECKIN_WITH_INTERIM')}
                  SystemMessages={props.systemMessages}
                  UpdateSaveOption={updatesaveDataPopUp}
                />
                <RewardGiftCard
                  data={item}
                  default={findTemplate('REWARD_GIFT_CARD')}
                  Incentives={props.Incentives}
                  UpdateSaveOption={updatesaveDataPopUp}
                />
                <Discount data={item} default={findTemplate('DISCOUNT')} UpdateSaveOption={updatesaveDataPopUp} />
                <UnlimitedAccess
                  data={item}
                  default={findTemplate('UNLIMITED_ACCESS')}
                  UpdateSaveOption={updatesaveDataPopUp}
                />

                <RewardsPTO data={item} default={findTemplate('REWARD_PTO')} UpdateSaveOption={updatesaveDataPopUp} />
                <Comms data={item} Campaigns={props.Campaigns} />
                <ConsumerSubscriptionModel
                  data={item}
                  default={findTemplate('SUBSCRIPTION')}
                  EmailTemplates={props.EmailTemplates}
                  UpdateSaveOption={updatesaveDataPopUp}
                />
                <Promotion data={item} UpdateSaveOption={updatesaveDataPopUp} />
              </div>
            );
          })
        ) : clientId && addNewClientToggle ? (
          <>
            <div className="h-10  w-full bg-transparent px-2 flex justify-between items-center border-b text-gray-dark gray-border-line text-sm font-bold mb-2">
              PLAN CONFIGURATION
            </div>
            <div className="flex justify-evenly gap-7 my-10">
              <div className="py-4  w-full">
                <CustomInput
                  inputType={'text'}
                  value={packageName}
                  setValue={setpackageName}
                  Label={'Plan Name'}
                  Error={error && !packageName}
                  ErrorMessage={'Field is Required'}
                />
              </div>
              <div className="py-4  w-full">
                <CustomInput
                  inputType={'text'}
                  value={packageCode}
                  setValue={setpackageCode}
                  Label={'Plan Code'}
                  Error={error && !packageCode}
                  ErrorMessage={'Field is Required'}
                />
              </div>
            </div>
            <div className="flex justify-evenly gap-7 my-10">
              <div className="py-4  w-full">
                <label className="block leading-4 text-dark-blue font-medium mb-2">Benefits Description</label>
                <RichTextEditor
                  RichText={internalNotes}
                  setRichText={(data) => {
                    setinternalNotes(data);
                  }}
                />
                {/* <textarea
                                    value={internalNotes}
                                    className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                                    onChange={(e) => setinternalNotes(e.target.value)}
                                /> */}
              </div>
              <div className="py-4  w-full">
                <div className=" relative ">
                  <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Web App URL</label>
                  <input
                    name="browser"
                    onFocus={() => {
                      setwebappDropDown(true);
                    }}
                    value={webAppUrl}
                    onChange={(e) => setwebAppUrl(e.target.value)}
                    onKeyDown={(event) => {
                      if (
                        event.key === 'Enter' &&
                        webAppUrl !== `Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})` &&
                        webAppUrl !== 'Native App Only'
                      ) {
                        setdropDownoption(webAppUrl);
                        setsaveWebAppUrl(webAppUrl);
                        setwebappDropDown(true);
                      }
                    }}
                    id="browser"
                    className="py-2 px-3 w-full h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                  />
                  {error &&
                    saveWebAppUrl !== null &&
                    saveWebAppUrl?.trim() !== '' &&
                    saveWebAppUrl &&
                    !isURL(saveWebAppUrl?.trim()) &&
                    !webappDropDown && <p className="text-red -z-10 ">Please enter valid URL</p>}
                  {webappDropDown && (
                    <div
                      className="z-10 overflow-visible absolute h-auto w-full text-white opacity-90 bg-gray-dark rounded-md py-2"
                      ref={wrapperRef}
                    >
                      <div
                        className="  w-full h-8 z-10 px-2 py-2 rounded-md hover:bg-blue-primary flex items-center"
                        role="button"
                        onClick={() => {
                          setwebAppUrl(`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`);
                          setdropDownoption('');
                          setsaveWebAppUrl(null);
                          setwebappDropDown(false);
                        }}
                      >
                        {' '}
                        <p>{`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`}</p>
                      </div>
                      <div
                        className=" w-full h-8 z-10 p-2 hover:bg-blue-primary rounded-md flex items-center"
                        role="button"
                        onClick={() => {
                          setwebAppUrl('Native App Only');
                          setsaveWebAppUrl('https://listeners.app.link');
                          setdropDownoption('');
                          setwebappDropDown(false);
                        }}
                      >
                        <p>Native App Only</p>
                      </div>
                      {dropDownoption?.length > 0 && (
                        <div
                          className=" w-full h-8 z-10 p-2 hover:bg-blue-primary flex items-center rounded-md "
                          role="button"
                          onClick={() => {
                            setwebAppUrl(dropDownoption);
                            setsaveWebAppUrl(dropDownoption);
                            setwebappDropDown(false);
                          }}
                        >
                          <p>{dropDownoption}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <button
              className={'stacked-bar-blue-400 rounded-sm  text-center  text-sm text-white  px-4 py-2 disabled:'}
              onClick={() => {
                setAddNewClientToggle(false);
                clearfields();
              }}
            >
              CANCEL
            </button>
          </>
        ) : (
          !props.pending &&
          !clientId &&
          !addNewClientToggle && (
            <p className=" font-4xl font-extrabold leading-4 text-2xl ">
              No Client id Found , Please create the Client in order to create a plan
            </p>
          )
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
    productTemplate: state.Client.GetProductTemplate?.data || null,
    EmailTemplates: state.Client.emailTemplate?.data || null,
    Campaigns: state.Client.Campaign?.data || null,
    Incentives: state.Client.incentives?.data || null,
    systemMessages: state.Client.systemMessages?.data || null,
    webAppUrl: state.Client.WebAppUrlSetting?.data || null,
    appSettings: state.Client.AppSettings?.data || null,
    // EmailTemplates : state.Client.emailTemplate?.data|| null, incentives
    error: state.Client?.error || null,
    pending: state.Client?.pending || null,
  };
};

export default connect(mapStateToProps)(Packages);
