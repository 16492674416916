import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { NumericLabel, SectionLabel, Spinner } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import { fetchMoneyDetailsRequest, fetchMoneyDetailsSuccess } from './listeners-redux/actions';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { leftArrow } from '../../assets/images';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import { ROUTE_PATH } from '../../routes/paths';
import { paramsToObjects } from '../../components/common/create-params';
import HeadBack from './redirect';

type props = {
  MoneyDetails: any;
  pending: boolean;
};

type callData = {
  data: any;
  enableOffice: boolean;
  multipleTiers: boolean;
};

type payoutProps = {
  data: any;
};

const calculateWeeklyTotal = (weekpayout: any) =>
  (weekpayout.office_hours_pay +
    weekpayout.call_time_earnings +
    weekpayout.bonus_earned +
    weekpayout.experiences_bonus_pay +
    weekpayout.experiences_listened_pay) /
  100;

const CallData: React.FunctionComponent<callData> = ({ data, enableOffice, multipleTiers }): JSX.Element => {
  return (
    <>
      <SectionLabel Label={'Listener Tier'} disableLine />
      {Array.isArray(data.tier_name) ? (
        data.tier_name.map((tier: any, index: number) =>
          index === data.tier_name?.length ? <p>{`${tier} (Current Tier)`}</p> : <p>{tier}</p>,
        )
      ) : (
        <p>{data.tier_name}</p>
      )}
      {enableOffice && (
        <>
          <SectionLabel Label={'Office Hours'} disableLine />
          {multipleTiers && <p>{`Office Hour Pay Rate:	 $${(data.tier_office_hour_rate / 100).toFixed(2)} per hour`}</p>}
          <p>
            {`Office Hour Earnings: `}
            <span className="font-bold">${(data.office_hours_pay / 100).toFixed(2)}</span>
          </p>
        </>
      )}
      <SectionLabel Label={'Call Time'} disableLine />
      <p>{`Total Call Minutes:  ${data.total_call_minutes}`} </p>
      {multipleTiers && <p>{`Earnings Per Minute: $${(data.tier_earnings_per_minute / 100).toFixed(2)}`}</p>}
      <p>
        {`Call Time Earnings: `} <span className="font-bold">${(data.call_time_earnings / 100).toFixed(2)}</span>
      </p>
      <SectionLabel Label={'Call Bonuses'} disableLine />
      <p>{`Number of Qualifying Calls: ${data.qualifying_calls_count}`}</p>
      {multipleTiers && <p>{`Bonus Per Call: $${(data.tier_bonus_per_call / 100).toFixed(2)}`}</p>}
      <p>
        {`Call Bonus Earnings: `} <span className="font-bold">${(data.bonus_earned / 100).toFixed(2)}</span>
      </p>
      <SectionLabel Label={'Earnings'} disableLine />
      <p>
        {`Experience Listens Earnings: `}{' '}
        <span className="font-bold">${(data.experiences_listened_pay / 100).toFixed(2)}</span>
      </p>
      <p>
        {`Experience Approval Earnings: `}{' '}
        <span className="font-bold">${(data.experiences_bonus_pay / 100).toFixed(2)}</span>
      </p>
    </>
  );
};

const Payout: React.FunctionComponent<payoutProps> = ({ data }) => {
  const [hideDetails, sethideDetails] = useState(false);
  return (
    <>
      <div className="flex justify-between mt-2   py-3">
        <div>
          <p className="blue-primary font-medium">Week of</p>
          <p>{`${moment(data.pay_period_start).utc().format('MM/DD')} - ${moment(data.pay_period_end)
            .utc()
            .format('MM/DD')}`}</p>
        </div>
        <div>
          <p className="text-right">{`$${calculateWeeklyTotal(data).toFixed(2)}`}</p>
          <div
            className="blue-primary font-bold cursor-pointer flex justify-center h-4 items-center min-w-"
            onClick={() => {
              sethideDetails((prev) => !prev);
            }}
          >
            {hideDetails ? 'Hide Details  ' : 'See Details  '}
            <div
              className={
                '  transform   rounded-full ml-2' + (hideDetails ? ' rotate-0 rotate-down' : ' rotate-180 rotate-up')
              }
            >
              <img className="h-3 w-3" src={leftArrow} alt="Right Arrow"></img>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          ' accordion-panel-close border-b border-gray-500 pb-2 px-2' +
          (hideDetails ? ' max-h-screen accordion-panel' : ' max-h-0 ')
        }
      >
        <CallData data={data} enableOffice multipleTiers />
      </div>
    </>
  );
};

const defaultValue = {
  office_hours_pay: 0,
  bonus_earned: 0,
  pay_period_start: moment(new Date()).startOf('W'),
  pay_period_end: moment(new Date()).endOf('W'),
  id: -1,
  qualifying_calls_count: 0,
  total_call_minutes: 0,
  call_time_earnings: 0,
  tier_bonus_per_call: 0,
  tier_earnings_per_minute: 0,
  tier_name: [],
  tier_office_hour_rate: 0,
  experiences_bonus_pay: 0,
  experiences_listened_pay: 0,
};

enum reportType {
  WEEK = 'This Week',
  ALLTIME = 'All-Time',
}

const PayoutDetails: React.FunctionComponent<props> = (props): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const [summation, setSummation] = useState({ defaultValue, total: 0 });
  const dispatch = useDispatch();
  const [AccordionStatus, setAccordionStatus] = useState({
    highlightedId: 0,
    type: reportType.WEEK,
    filteredList: [],
  });
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchMoneyDetailsRequest({ id: listenerId }));
    return () => {
      dispatch(fetchMoneyDetailsSuccess({ MoneyDetails: [] }));
    };
  }, []); // eslint-disable-line

  const SummateTotal = (data: any) => {
    const res = data.reduce(
      (prev: any, item: any) => {
        return {
          defaultValue: {
            ...prev.defaultValue,
            office_hours_pay: item.office_hours_pay + prev.defaultValue.office_hours_pay,
            bonus_earned: item.bonus_earned + prev.defaultValue.bonus_earned,
            qualifying_calls_count: item.qualifying_calls_count + prev.defaultValue.qualifying_calls_count,
            total_call_minutes: item.total_call_minutes + prev.defaultValue.total_call_minutes,
            call_time_earnings: item.call_time_earnings + prev.defaultValue.call_time_earnings,
            experiences_listened_pay: item.experiences_listened_pay + prev.defaultValue.experiences_listened_pay,
            experiences_bonus_pay: item.experiences_bonus_pay + prev.defaultValue.experiences_bonus_pay,
            //@ts-ignore
            tier_name: prev.defaultValue.tier_name.includes(item.tier_name)
              ? prev.defaultValue.tier_name
              : [...prev.defaultValue.tier_name, item.tier_name],
          },
          total: prev.total + calculateWeeklyTotal(item),
        };
      },
      {
        defaultValue: {
          ...defaultValue,
          tier_bonus_per_call: data?.length > 0 ? data[0].tier_bonus_per_call : 0,
          tier_earnings_per_minute: data?.length > 0 ? data[0].tier_earnings_per_minute : 0,
          tier_office_hour_rate: data?.length > 0 ? data[0].tier_office_hour_rate : 0,
        },
        total: 0,
      },
    );
    setSummation(res);
  };

  useEffect(() => {
    if (props.MoneyDetails && props.MoneyDetails?.listener_payout_reports) {
      const payoutList = props.MoneyDetails?.listener_payout_reports;
      const filteredList =
        AccordionStatus.type === reportType.WEEK
          ? payoutList.filter((item: any) => {
              return moment().isBetween(moment(item.pay_period_start), moment(item.pay_period_end));
            })
          : payoutList;

      filteredList?.length > 0 && SummateTotal(filteredList);
      setAccordionStatus((prev) => ({ ...prev, filteredList }));
    }
  }, [props.MoneyDetails, AccordionStatus.type]);

  const getThisWeek = () => {
    return `Week of ${moment().startOf('W').format('MM/DD')} - ${moment().endOf('W').add(1, 'day').format('MM/DD')}`;
  };

  return (
    <div className="px-">
      {props.pending && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />

      <SwitchBar
        heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions}
        position={listenerType === 'peer' ? 3 : 5}
        forwardParams
        optionalButton={
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(reportType).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (reportType[item] === AccordionStatus.type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setAccordionStatus((filter) => {
                    //@ts-ignore
                    return { ...filter, type: reportType[item] };
                  });
                }}
              >
                {/* @ts-ignore */}
                {reportType[item]}
              </button>
            ))}
          </div>
        }
      />

      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="flex ">
          <div className="w-1/2 px-7">
            <SectionLabel Label={'Earnings'} disableLine />

            <NumericLabel numericValue={`$${summation.total.toFixed(2)}` || '0'} label={''} />
            <p>Total Earnings </p>

            <SectionLabel Label={AccordionStatus.type === reportType.WEEK ? getThisWeek() : 'All-Time'} disableLine />

            {AccordionStatus.filteredList.length > 0 ? (
              <CallData
                data={summation.defaultValue}
                enableOffice
                multipleTiers={summation.defaultValue.tier_name?.length === 1}
              />
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="w-1/2 px-7">
            <SectionLabel Label={'Payout History'} />
            {AccordionStatus.filteredList.length > 0 ? (
              AccordionStatus.filteredList.map((item: any) => (
                <Payout data={item} key={item.id + AccordionStatus.type} />
              ))
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    MoneyDetails: state.Listeners?.MoneyDetails?.data || null,
    pending: state.Listeners.pending || false,
  };
};

export default connect(mapStateToProps)(PayoutDetails);
