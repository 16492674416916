import React, { useCallback, useEffect, useState } from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100)?.toFixed(0)}%`}
    </text>
  );
};

type props = {
  data: any;
  header: string;
  pending?: boolean;
};

const ReactChart: React.FunctionComponent<props> = ({ data, header, pending }): JSX.Element => {
  const [sum, setSum] = useState(1);

  useEffect(() => {
    const sum = data.reduce((sum: number, current: any) => sum + current.value, 0);
    setSum(sum);
  }, [data]);

  return (
    <div className="flex mx-5">
      <div>
        <p className="text-gray-dark font-bold mt-8"> {header} </p>
        <p className="mt-8">
          {data.map((item: any) => (
            <div className="flex my-2 ">
              {' '}
              <div className="w-6 h-6 " style={{ background: item.color }}></div> <p className="ml-2">{item.name} </p>{' '}
              <p className="text-gray-dark font-bold ml-2 ">
                {' '}
                - {(isNaN(item.value / sum) ? 0 : (item.value / sum) * 100).toFixed(0)} %
              </p>{' '}
            </div>
          ))}
        </p>
      </div>
      <div>
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx={160}
            cy={160}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={110}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default ReactChart;
