import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  postResourcesSuccess,
  postResourcesFailure,
  fetchResourcesListSuccess,
  fetchResourcesListFailure,
  removeResourcesSuccess,
  removeResourcesFailure,
  fetchResourceSuccess,
  fetchResourceFailure,
  fetchResourceRequest,
} from './actions';
import {
  POST_RESOURCES_REQUEST,
  FETCH_RESOURCES_LIST_REQUEST,
  UPDATE_RESOURCES_REQUEST,
  REMOVE_RESOURCES_REQUEST,
  FETCH_RESOURCE_REQUEST,
} from './action-types';
import { toast } from 'react-toastify';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* postResourcesSaga(action: any): any {
  try {
    const response = yield call(Api.createResources(action.payload));
    yield put(postResourcesSuccess(response));
    toast.success('Resource Created Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postResourcesFailure(JSON.parse(e)));
  }
}

function* fetchResourcesListSaga(action: any): any {
  try {
    const response = yield call(Api.getAllResources(action.payload));
    yield put(fetchResourcesListSuccess({ ResourcesList: response }));
  } catch (e: any) {
    yield put(fetchResourcesListFailure(JSON.parse(e)));
  }
}

function* UpdateResourcesSaga(action: any): any {
  try {
    yield call(Api.updateResources(action.payload));
    toast.success('Resource Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(fetchResourceRequest({ id, payload: {} }));
}
function* removeResourcesSaga(action: any): any {
  try {
    const response = yield call(Api.deleteResources(action.payload));
    yield put(removeResourcesSuccess(response));
    toast.success(`resource removed sucessfully`);
    if (action.payload?.from === 'resource') {
      const response = yield call(Api.getAllResources({ ...action.payload.filters }));
      yield put(fetchResourcesListSuccess({ ResourcesList: response }));
    }
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeResourcesFailure(JSON.parse(e)));
  }
}

function* fetchResourceSaga(action: any): any {
  try {
    const res = Api.fetchResource(action.payload);
    const response = yield call(res);
    yield put(fetchResourceSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchResourceFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(POST_RESOURCES_REQUEST, postResourcesSaga),
    takeLatest(FETCH_RESOURCES_LIST_REQUEST, fetchResourcesListSaga),
    takeLatest(UPDATE_RESOURCES_REQUEST, UpdateResourcesSaga),
    takeLatest(REMOVE_RESOURCES_REQUEST, removeResourcesSaga),
    takeLatest(FETCH_RESOURCE_REQUEST, fetchResourceSaga),
  ]);
}

export default paymentSaga;
