export const POST_RESOURCES_REQUEST = 'POST_RESOURCES_REQUEST';
export const POST_RESOURCES_SUCCESS = 'POST_RESOURCES_SUCCESS';
export const POST_RESOURCES_FAILURE = 'POST_RESOURCES_FAILURE';

//partner Code Redemption
export const FETCH_RESOURCES_LIST_REQUEST = 'FETCH_RESOURCES_LIST_REQUEST';
export const FETCH_RESOURCES_LIST_SUCCESS = 'FETCH_RESOURCES_LIST_SUCCESS';
export const FETCH_RESOURCES_LIST_FAILURE = 'FETCH_RESOURCES_LIST_FAILURE';

// activate Subscription
export const UPDATE_RESOURCES_REQUEST = 'UPDATE_RESOURCES_REQUEST';
export const UPDATE_RESOURCES_SUCCESS = 'UPDATE_RESOURCES_SUCCESS';
export const UPDATE_RESOURCES_FAILURE = 'UPDATE_RESOURCES_FAILURE';

// remove payment
export const REMOVE_RESOURCES_REQUEST = 'REMOVE_RESOURCES_REQUEST';
export const REMOVE_RESOURCES_SUCCESS = 'REMOVE_RESOURCES_SUCCESS';
export const REMOVE_RESOURCES_FAILURE = 'REMOVE_RESOURCES_FAILURE';

export const POST_TEST_RESOURCES_REQUEST = 'POST_TEST_RESOURCES_REQUEST';
export const POST_TEST_RESOURCES_SUCCESS = 'POST_TEST_RESOURCES_SUCCESS';
export const POST_TEST_RESOURCES_FAILURE = 'POST_TEST_RESOURCES_FAILURE';

export const FETCH_RESOURCE_REQUEST = 'FETCH_RESOURCE_REQUEST';
export const FETCH_RESOURCE_SUCCESS = 'FETCH_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_FAILURE = 'FETCH_RESOURCE_FAILURE';
