import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LOCLogo, CancelIcon } from '../../assets/images';
import { CustomInput, Spinner } from '../../components/common';
import { postRedeemPasswordResetRequest } from './login-redux/actions';

type resetProps = {
  redeemPasswordReset: any;
  redeemPasswordResetError: string;
  pending: boolean;
};

const ResetPassword: React.FunctionComponent<resetProps> = ({
  pending,
  redeemPasswordReset,
  redeemPasswordResetError,
}): JSX.Element => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<any>();

  const handlePasswordReset = (): void => {
    if (password === undefined || password?.trim() === '') {
      return setError({ password: 'Enter password' });
    }
    if (passwordConfirmation !== password) {
      return setError({ passwordConfirmation: 'password does not match' });
    } else {
      setError({});
    }
    const resetToken = queryParams.get('token');
    const input = {
      token: resetToken,
      password,
    };
    dispatch(postRedeemPasswordResetRequest(input));
  };

  useEffect(() => {
    if (redeemPasswordReset !== undefined) {
      if (redeemPasswordReset?.data) {
        history.push('/');
      }
    }
    if (redeemPasswordResetError) {
      const error =
        // @ts-ignore
        JSON.parse(JSON.parse(redeemPasswordResetError.redeemPasswordResetError))?.data?.description ?? undefined;
      if (error) {
        setError({ password: error.data.description });
      } else {
        setError({});
      }
    }
  }, [redeemPasswordReset, redeemPasswordResetError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {pending && <Spinner />}
      <div className="flex justify-center  h-screen items-center bg-gray-background-light ">
        <div>
          {pending && <Spinner />}
          <div className="w-full h-screen bg-gray-background-light flex justify-center items-center">
            <div className="w-96 bg-white px-8 pt-8 pb-12">
              <div className="w-full flex justify-end h-8 ">
                <img
                  src={CancelIcon}
                  role="button"
                  alt="close"
                  className="h-8 w-8"
                  onClick={() => {
                    history.push('/');
                  }}
                />
              </div>
              <div className="text-primary w-full">
                <div className="flex justify-center pt-4">
                  <img src={LOCLogo} alt="kindly-logo" className="w-56" />
                </div>
                <div className="flex justify-center pt-6 pb-4 text-2xl font-bold " style={{ color: '#1D1183' }}>
                  Password Reset
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={password}
                    setValue={setPassword}
                    Label={'Password'}
                    Error={error?.password}
                    ErrorMessage={error?.password}
                    labelClass="font-normal"
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={passwordConfirmation}
                    setValue={setPasswordConfirmation}
                    Label={'Confirm Password'}
                    Error={error?.passwordConfirmation}
                    ErrorMessage={error?.passwordConfirmation}
                    labelClass="font-normal"
                  />
                </div>
                <div className="flex justify-center mt-8">
                  <button
                    type="button"
                    className=" rounded-full py-1.5 px-12 text-white"
                    style={{ backgroundColor: '#1D1183' }}
                    onClick={handlePasswordReset}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    pending: state.login.pending,
    redeemPasswordReset: state.login.redeemPasswordReset,
    redeemPasswordResetError: state.login.redeemPasswordResetError,
  };
};

export default connect(mapStateToProps)(ResetPassword);
