import RightArrow from '../../assets/images/left-arrow-symbol.svg';

export const Paginator: React.FC<{
  count: number;
  limit: number;
  currentPage: number;
  handlePageChange: (newPage: number) => void;
}> = ({ count, limit, currentPage, handlePageChange }) => {
  const totalPages = Math.ceil(count / limit);

  const generatePages = () => {
    const visiblePages = 5;
    const pages = [];
    const firstPage = 1;
    const lastPage = totalPages;

    if (totalPages <= visiblePages) {
      for (let i = firstPage; i <= lastPage; i++) {
        pages.push(i);
      }
    } else {
      let startPage = currentPage - 2;
      let endPage = currentPage + 2;

      pages.push(firstPage);

      if (startPage < firstPage + 1) {
        startPage = firstPage + 1;
        endPage = visiblePages;
      }

      if (endPage > lastPage - 1) {
        endPage = lastPage - 1;
        startPage = lastPage - visiblePages + 1;
      }

      if (startPage >= firstPage + 2) {
        pages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage <= lastPage - 2) {
        pages.push('...');
      }

      pages.push(lastPage);
    }

    return pages;
  };

  const pages = generatePages();

  const handleLeft = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };
  const handleRight = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <div className="w-full">
      <div className="relative mx-7 py-3 flex flex-nowrap justify-between items-center">
        <div className="flex items-center">
          <button className="rounded-full h-6 w-6 blue-primary " onClick={handleLeft}>
            <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
          </button>
        </div>
        {pages.map((page) => (
          <button
            key={page}
            disabled={typeof page === 'string' || currentPage === page}
            onClick={() => typeof page === 'number' && handlePageChange(page)}
            className={`text-sm ${currentPage === page ? ' blue-primary font-bold' : ' text-gray-dark '}`}
          >
            {page}
          </button>
        ))}
        <div className="flex items-center">
          <button className="rounded-full h-6 w-6 blue-primary  transform rotate-180 " onClick={handleRight}>
            <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
          </button>
        </div>
        <p className="text-xs">
          Showing {currentPage * limit - (limit - 1)} - {count < currentPage * limit ? count : currentPage * limit} of{' '}
          {count} results{' '}
        </p>
      </div>
    </div>
  );
};
