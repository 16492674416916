import React from 'react';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import Header from '../../components/common/global-top-bar';
import { Button } from '../../components/common';
import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import SwitchBarButton from './switch-bar-button';
import HeadBack from './redirect';

const Comms: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <Header userName={'Jason'} heading={<HeadBack />} />
      <SwitchBar heading={Headeroptions} position={5} optionalButton={<SwitchBarButton />} />

      <div className="max-window-height  overflow-y-auto  px-7 ">
        <table className="table-fixed  w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              <th className="px-2 py-1 w-1/12 sticky top-0  bg-white  h-full mb-4"></th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4"></th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4"></th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4"></th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4"></th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4"></th>
              <th className="px-2 py-1  w-1/12 sticky top-0 bg-white  h-full mb-4"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {new Array(100).fill(1).map((item: any, index: any) => {
              return (
                <tr className="bg-gray-200 rounded text-gray-dark  text-left h-12 border-y border-white border-4 space-y-6">
                  <td className="px-2 py-1 w-1/12 ">July 17,20 </td>
                  <td className="px-2 py-1 w-1/12 ">17:34</td>
                  <td className="px-2 py-1 w-2/12">
                    <div className="px-7">
                      <Button Label={'TEXT'} textColor={'text-white'} color={'magenta'} />
                    </div>
                  </td>
                  <td className="px-2 py-1 w-2/12">Campaign name </td>
                  <td className="px-2 py-1 w-2/12">Comm ID</td>
                  <td className="px-2 py-1 w-1/12"></td>
                  <td className="px-2 py-1 w-1/12">
                    <div className="flex items-center justify-end">
                      <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                        <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Comms;
