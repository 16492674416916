import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as Api from '../../../../api';

import {
  fetchAboutLocTosSuccess,
  fetchClientAdminTermsOfServiceTosSuccess,
  fetchPrivacyPolicyTosSuccess,
  fetchTermsOfServiceTosSuccess,
  fetchTosRequest,
  fetchTosFailure,
  fetchListnerFAQSuccess,
  fetchPeersFAQSuccess,
  fetchSystemWelcomeVideoSuccess,
} from './actions';
import { FETCH_TOS_REQUEST, UPDATE_TOS_REQUEST } from './action-types';
import { toast } from 'react-toastify';

function* fetchTosSaga(action: any): any {
  try {
    const { payload } = action;

    if (payload.type === 'ABOUT_LOC') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchAboutLocTosSuccess(response));
    } else if (payload.type === 'TERMS_OF_SERVICE') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchTermsOfServiceTosSuccess(response));
    } else if (payload.type === 'PRIVACY_POLICY') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchPrivacyPolicyTosSuccess(response));
    } else if (payload.type === 'CLIENT_ADMIN_TERMS_OF_SERVICE') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchClientAdminTermsOfServiceTosSuccess(response));
    } else if (payload.type === 'LISTENER_FAQ') {
      //PEER_FAQ
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchListnerFAQSuccess(response));
    } else if (payload.type === 'PEER_FAQ') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchPeersFAQSuccess(response));
    } else if (payload.type === 'app') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchClientAdminTermsOfServiceTosSuccess(response));
    } else if (payload.type === 'WELCOME_VIDEO') {
      const response = yield call(Api.getTos(action.payload));
      yield put(fetchSystemWelcomeVideoSuccess(response));
    }
  } catch (e: unknown) {
    yield put(
      fetchTosFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* UpdateTosSaga(action: any): any {
  try {
    yield call(Api.updateTos(action.payload));
    yield put(fetchTosRequest({ type: action.payload.type }));
    toast.success('Successfully updated ');
  } catch (e: unknown) {
    yield put(
      fetchTosFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* Tos() {
  yield all([takeEvery(FETCH_TOS_REQUEST, fetchTosSaga)]);
  yield all([takeLatest(UPDATE_TOS_REQUEST, UpdateTosSaga)]);
}

export default Tos;
