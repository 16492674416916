import React from 'react';

type props = {
  isModel?: boolean;
  InnerComponent: React.ReactNode;
};

const Modal: React.FunctionComponent<props> = ({ isModel, InnerComponent }): JSX.Element => {
  return (
    <div className="">
      {isModel && (
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0  bg-opacity-0 transition-opacity" aria-hidden="true"></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            {InnerComponent}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
