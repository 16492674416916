import { convertToRaw, EditorState, Modifier, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import '../../styles/components/react-draft-wysiwyg.css';

type props = {
  RichText: any;
  setRichText: (data: any) => void;
};

type custom = {
  onChange: any;
  editorState: any;
  heading: string;
};

const styles = {
  'color-#222833': {
    color: '#222833',
  },
  'color-#1D1183': {
    color: '#1D1183',
  },
  'fontfamily-Manrope': {
    fontFamily: 'manrope',
  },
  'fontsize-18px': {
    fontSize: '18px',
  },
  'fontsize-22px': {
    fontSize: '22px',
  },
  'fontsize-15px': {
    fontSize: '15px',
  },
};

const styleParams = {
  H1: ['color-#1D1183', 'fontsize-22px', 'fontfamily-Manrope', 'BOLD'],
  H2: ['color-#222833', 'fontsize-18px', 'fontfamily-Manrope', 'BOLD'],
  BODY: ['color-#222833', 'fontsize-15px', 'fontfamily-Manrope'],
};

const CustomOption: React.FunctionComponent<custom> = (props): JSX.Element => {
  const editStyle = () => {
    const { editorState, onChange } = props;
    //@ts-ignore
    const selection = editorState.getSelection();

    const nextContentState = ['BOLD', ...Object.keys(styles)].reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    }, editorState.getCurrentContent());

    const initialState = EditorState.push(editorState, nextContentState, 'change-inline-style');

    //@ts-ignore
    const UpdateState = styleParams[props.heading].reduce((state: any, style: string) => {
      return RichUtils.toggleInlineStyle(state, style);
    }, initialState);

    onChange(UpdateState);
  };

  return (
    <div onClick={editStyle} className="px-2 py-1 cursor-pointer bg-gray-200 mx-2 mb-1">
      {props.heading}
    </div>
  );
};

const RichTextEditor: React.FunctionComponent<props> = ({ RichText, setRichText }): JSX.Element => {
  // const [RichText, setRichText] = useState(EditorState.createEmpty());
  const options = {
    options: [
      'inline',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Manrope'],
    },
    list: {
      inDropdown: true,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    colorPicker: {
      //   icon: color,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: [
        'rgb(97,189,109)',
        'rgb(26,188,156)',
        'rgb(84,172,210)',
        'rgb(44,130,201)',
        'rgb(147,101,184)',
        'rgb(71,85,119)',
        'rgb(204,204,204)',
        'rgb(65,168,95)',
        'rgb(0,168,133)',
        'rgb(61,142,185)',
        'rgb(41,105,176)',
        'rgb(85,57,130)',
        'rgb(40,50,78)',
        'rgb(0,0,0)',
        'rgb(247,218,100)',
        'rgb(251,160,38)',
        'rgb(235,107,86)',
        'rgb(226,80,65)',
        'rgb(163,143,132)',
        'rgb(239,239,239)',
        'rgb(255,255,255)',
        'rgb(250,197,28)',
        'rgb(243,121,52)',
        'rgb(209,72,65)',
        'rgb(184,49,47)',
        'rgb(124,112,107)',
        'rgb(209,213,216)',
      ],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      linkCallback: undefined,
    },
    emoji: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      emojis: [
        '😀',
        '😁',
        '😂',
        '😃',
        '😉',
        '😋',
        '😎',
        '😍',
        '😗',
        '🤗',
        '🤔',
        '😣',
        '😫',
        '😴',
        '😌',
        '🤓',
        '😛',
        '😜',
        '😠',
        '😇',
        '😷',
        '😈',
        '👻',
        '😺',
        '😸',
        '😹',
        '😻',
        '😼',
        '😽',
        '🙀',
        '🙈',
        '🙉',
        '🙊',
        '👼',
        '👮',
        '🕵',
        '💂',
        '👳',
        '🎅',
        '👸',
        '👰',
        '👲',
        '🙍',
        '🙇',
        '🚶',
        '🏃',
        '💃',
        '⛷',
        '🏂',
        '🏌',
        '🏄',
        '🚣',
        '🏊',
        '⛹',
        '🏋',
        '🚴',
        '👫',
        '💪',
        '👈',
        '👉',
        '👉',
        '👆',
        '🖕',
        '👇',
        '🖖',
        '🤘',
        '🖐',
        '👌',
        '👍',
        '👎',
        '✊',
        '👊',
        '👏',
        '🙌',
        '🙏',
        '🐵',
        '🐶',
        '🐇',
        '🐥',
        '🐸',
        '🐌',
        '🐛',
        '🐜',
        '🐝',
        '🍉',
        '🍄',
        '🍔',
        '🍤',
        '🍨',
        '🍪',
        '🎂',
        '🍰',
        '🍾',
        '🍷',
        '🍸',
        '🍺',
        '🌍',
        '🚑',
        '⏰',
        '🌙',
        '🌝',
        '🌞',
        '⭐',
        '🌟',
        '🌠',
        '🌨',
        '🌩',
        '⛄',
        '🔥',
        '🎄',
        '🎈',
        '🎉',
        '🎊',
        '🎁',
        '🎗',
        '🏀',
        '🏈',
        '🎲',
        '🔇',
        '🔈',
        '📣',
        '🔔',
        '🎵',
        '🎷',
        '💰',
        '🖊',
        '📅',
        '✅',
        '❎',
        '💯',
      ],
    },
    embedded: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      embedCallback: undefined,
      defaultSize: {
        height: '100px',
        width: 'auto',
      },
    },
    image: {
      //   icon: image,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: { className: undefined, component: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: {
        //   icon: ,
        className: undefined,
      },
      redo: {
        //   icon: ,
        className: undefined,
      },
    },
  };
  return (
    <div className=" border-black border min-h ">
      <Editor
        //   toolbarHidden
        wrapperClassName="wrapper-class"
        editorClassName="editor-class px-2"
        toolbarClassName="toolbar-class"
        editorState={RichText}
        onEditorStateChange={setRichText}
        customStyleMap={styles}
        //@ts-ignore
        toolbarCustomButtons={[
          //@ts-ignore
          <CustomOption heading={'H1'} />,
          //@ts-ignore
          <CustomOption heading={'H2'} />,
          //@ts-ignore
          <CustomOption heading={'BODY'} />,
        ]}
        toolbar={options}
      />
      {/* @ts-ignore */}
      {/* {draftToHtml(convertToRaw(RichText.getCurrentContent()))} */}
    </div>
  );
};

export default RichTextEditor;
