import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPost, axiosPut } from '../api/axios';

export interface ExternalProviderPlan {
  code: string;
  display_name?: string;
  id?: number;
  name: string;
  provider: string;
  provider_types: string[];
  recuro_metatag3: string;
}

const getExternalProviderPlans = async (): Promise<ExternalProviderPlan[]> => {
  return await axiosGet(`/external_provider_plans/`, {}, 'v3').then(
    (externalProviderPlansResponse) => externalProviderPlansResponse.data,
  );
};

const addExternalProviderPlanRequest = async (externalProviderPlan: ExternalProviderPlan) => {
  return await axiosPost(`/external_provider_plans/`, externalProviderPlan, 'v3').then(
    (externalProviderPlanResponse) => externalProviderPlanResponse.data,
  );
};

const updateExternalProviderPlanRequest = async (planId: number, externalProviderPlan: ExternalProviderPlan) => {
  return await axiosPut(`/external_provider_plans/${planId}/`, externalProviderPlan, 'v3').then(
    (externalProviderPlanResponse) => externalProviderPlanResponse.data,
  );
};

export const useExternalProviderPlans = () => {
  const { data, isLoading, error, refetch } = useQuery<ExternalProviderPlan[]>(
    ['external_provider_plans'],
    getExternalProviderPlans,
  );

  return { data, isLoading, error, refetch };
};

export const useAddExternalProviderPlan = () => {
  const queryClient = useQueryClient();
  const addExternalProviderPlan = useMutation(
    (externalProviderPlan: ExternalProviderPlan) => addExternalProviderPlanRequest(externalProviderPlan),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['external_provider_plans']);
      },
      onError: (error) => {
        queryClient.invalidateQueries(['external_provider_plans']);
        console.error(error);
      },
      onMutate: (externalProviderPlan) => {
        queryClient.setQueryData(['external_provider_plans'], (oldData: any) => {
          if (oldData) {
            return [...oldData, externalProviderPlan];
          }
          return oldData;
        });
      },
    },
  );

  return { addExternalProviderPlan };
};

export const useUpdateExternalProviderPlan = (planId: number) => {
  const queryClient = useQueryClient();
  const updateExternalProviderPlan = useMutation(
    (externalProviderPlan: ExternalProviderPlan) => updateExternalProviderPlanRequest(planId, externalProviderPlan),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['external_provider_plans']);
      },
      onError: (error) => {
        queryClient.invalidateQueries(['external_provider_plans']);
        console.error(error);
      },
      onMutate: (externalProviderPlan) => {
        queryClient.setQueryData(['external_provider_plans'], (oldData: any) => {
          if (oldData) {
            return oldData.map((externalProviderPlanItem: ExternalProviderPlan) => {
              if (externalProviderPlanItem.id === externalProviderPlan.id) {
                return { ...externalProviderPlanItem, ...externalProviderPlan };
              }
              return externalProviderPlanItem;
            });
          }
          return oldData;
        });
      },
    },
  );

  return { updateExternalProviderPlan };
};
