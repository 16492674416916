import { type } from 'os';
import * as React from 'react';
import SpinnerSVG from '../../assets/images/spinner.svg';

type props = {
  alignmentClass?: string;
};

const Spinner: React.FunctionComponent<props> = ({ alignmentClass }): JSX.Element => {
  return (
    <div
      className={alignmentClass ? alignmentClass : 'fixed z-100 inset-0 overflow-y-auto'}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex justify-center items-center w-full h-full  bg-overlay">
        <img src={SpinnerSVG} alt="spinner" className="antiClockwiseSpin w-14 h-14" />
      </div>
    </div>
  );
};
export default Spinner;
