export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';

export const FETCH_MEMBERS_CONNECTIONS_REQUEST = 'FETCH_MEMBERS_CONNECTIONS_REQUEST';
export const FETCH_MEMBERS_CONNECTIONS_SUCCESS = 'FETCH_MEMBERS_CONNECTIONS_SUCCESS';
export const FETCH_MEMBERS_CONNECTIONS_FAILURE = 'FETCH_MEMBERS_CONNECTIONS_FAILURE';

export const FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST = 'FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST';
export const FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS = 'FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS';
export const FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE = 'FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE';

export const GET_MEMBER_PROFILE_REQUEST = 'GET_MEMBER_PROFILE_REQUEST';
export const GET_MEMBER_PROFILE_SUCCESS = 'GET_MEMBER_PROFILE_SUCCESS';
export const GET_MEMBER_PROFILE_FAILURE = 'GET_MEMBER_PROFILE_FAILURE';

// Fetch topics list
export const FETCH_TOPICS_REQUEST = 'FETCH_TOPICS_REQUEST';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';

// Post GroupId
export const POST_REDEEM_PARTNER_CODE_REQUEST = 'POST_REDEEM_PARTNER_CODE_REQUEST';
export const POST_REDEEM_PARTNER_CODE_SUCCESS = 'POST_REDEEM_PARTNER_CODE_SUCCESS';
export const POST_REDEEM_PARTNER_CODE_FAILURE = 'POST_REDEEM_PARTNER_CODE_FAILURE';

export const FETCH_MEMBERS_COUNT_REQUEST = 'FETCH_MEMBERS_COUNT_REQUEST';
export const FETCH_MEMBERS_COUNT_SUCCESS = 'FETCH_MEMBERS_COUNT_SUCCESS';
export const FETCH_MEMBERS_COUNT_FAILURE = 'FETCH_MEMBERS_COUNT_FAILURE';

//
export const GET_MEMBER_LOGS_REQUEST = 'GET_MEMBER_LOGS_REQUEST';
export const GET_MEMBER_LOGS_SUCCESS = 'GET_MEMBER_LOGS_SUCCESS';
export const GET_MEMBER_LOGS_FAILURE = 'GET_MEMBER_LOGS_FAILURE';

export const FETCH_MEMBERS_LOGS_COUNT_REQUEST = 'FETCH_MEMBERS_LOGS_COUNT_REQUEST';
export const FETCH_MEMBERS_LOGS_COUNT_SUCCESS = 'FETCH_MEMBERS_LOGS_COUNT_SUCCESS';
export const FETCH_MEMBERS_LOGS_COUNT_FAILURE = 'FETCH_MEMBERS_LOGS_COUNT_FAILURE';
