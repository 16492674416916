import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import SwitchBar from '../../components/switch-bar';
import { Client, Package, useListClients } from '../../hooks/useClients';
import {
  GlobalDashboardResponse,
  AudioCountResponse,
  useReportingOverview,
  useReportingWithDateSearch,
  useAudioCount,
} from '../../hooks/useReporting';
import moment from 'moment';
import { CancelIcon } from '../../assets/images';
import DashboardOverviewPage from './global-dashboard-overview';
import GlobalDashboardEngagement from './global-dashboard-engagement';

const NavLinks = [
  { heading: 'Overview', path: '/dashboard/overview' },
  { heading: 'Engagement', path: '/dashboard/engagement' },
  // { heading: 'Topics', path: '/dashboard/topics' },
  // { heading: 'Peers', path: '/dashboard/peers' },
  // { heading: 'Navigation', path: '/dashboard/navigation' },
];

const GlobalDashboard: React.FC = (): JSX.Element => {
  const DASHBOARD_DAYS_AGO_TO = 1; // 1 day ago = yesterday; our analytics are 1 day delayed
  const DASHBOARD_DAYS_AGO_FROM = 91; // how wide the initial window is, in days

  const [clientId, setClientId] = useState<number>();
  const [packageId, setPackageId] = useState<number>();
  const [clientName, setClientName] = useState<string>('');
  const [packageName, setPackageName] = useState<string>('');
  const [reportType, setReportType] = useState<string>('all_groups');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(DASHBOARD_DAYS_AGO_FROM, 'days').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string>(moment().subtract(DASHBOARD_DAYS_AGO_TO, 'days').format('YYYY-MM-DD'));

  const [position, setPosition] = useState<number>(0);
  switch (window.location.pathname) {
    case '/dashboard/overview':
      position !== 0 && setPosition(0);
      break;
    case '/dashboard/engagement':
      position !== 1 && setPosition(1);
      break;
    default:
      position !== 0 && setPosition(0);
      break;
  }

  const { data: allClients } = useListClients();
  const {
    data: reportingOverviewData,
    refetch: refetchOverview,
    isFetching: isOverviewFetching,
  } = useReportingOverview(clientId, packageId);

  const {
    data: reportingDataExtras,
    refetch,
    isFetching,
  } = useReportingWithDateSearch(startDate, endDate, clientId, packageId);

  const { data: audioCountData, isFetching: isFetchingAudioCount } = useAudioCount();

  const changeDate = (startDate: string | null, endDate: string | null) => {
    startDate && setStartDate(startDate);
    endDate && setEndDate(endDate);
  };

  const handleReporting = (e: BaseSyntheticEvent) => {
    const key = e.target.selectedOptions[0].getAttribute('a-key');
    if (key === 'all_groups') {
      setClientId(undefined);
      setPackageId(undefined);
      setReportType('all_groups');
      setClientName('');
      setPackageName('');
    } else if (key === 'client') {
      const foundClient = allClients?.find((client: Client) => {
        return client.id === parseInt(e.target.value);
      });
      setClientId(e.target.value);
      setPackageId(undefined);
      setReportType('client');
      setClientName(foundClient?.name ?? '');
      setPackageName('');
    } else if (key === 'package') {
      const foundClient = allClients?.find((client: Client) => {
        return client.packages?.find((packageItem: Package) => {
          return packageItem.id === parseInt(e.target.value);
        });
      });
      const foundPackage = foundClient?.packages?.find((packageItem: Package) => {
        return packageItem.id === parseInt(e.target.value);
      });
      setClientId(foundClient?.id);
      setPackageId(e.target.value);
      setReportType('package');
      setClientName('');
      setPackageName(foundPackage?.name ?? '');
    }
    refetch();
    refetchOverview();
  };

  useEffect(() => {
    refetch();
    refetchOverview();
  }, [startDate, endDate, clientId, packageId, clientName, packageName, reportType]);

  return (
    <div className="pb-12">
      {!(isFetching || isOverviewFetching || isFetchingAudioCount) && <SystemRestrictionBanner />}
      <DashboardHeader
        allClients={allClients}
        handleReporting={handleReporting}
        changeDate={changeDate}
        startDate={startDate}
        endDate={endDate}
        position={position}
        setPosition={setPosition}
      />
      {(isFetching || isOverviewFetching || isFetchingAudioCount) && (
        <Spinner alignmentClass={'left-64 top-30 fixed z-100 inset-0 overflow-y-auto'} />
      )}
      <DashboardBody
        clientName={clientName}
        packageName={packageName}
        reportType={reportType}
        reportingOverviewData={reportingOverviewData}
        reportingDataExtras={reportingDataExtras}
        audioCountData={audioCountData}
        position={position}
      />
    </div>
  );
};

const DashboardHeader: React.FC<{
  allClients?: Client[];
  handleReporting: (e: any) => void;
  changeDate: (startdate: string | null, enddate: string | null) => void;
  startDate: string;
  endDate: string;
  position: number;
  setPosition: any;
}> = ({ allClients, handleReporting, changeDate, startDate, endDate, position, setPosition }) => (
  <>
    <Header
      heading={
        allClients ? (
          <div className="flex items-center">
            <select
              className={`w-auto h-11 block bg-gray-background-light font-bold font-['Montserrat'] ${
                allClients?.length === 1 && 'appearance-none'
              }`}
              onChange={(e) => handleReporting(e)}
            >
              <option a-key="all_groups" value="">
                All Groups
              </option>
              {allClients?.map((client: Client) => (
                <>
                  <option a-key="client" key={client.id} value={client.id}>
                    {client.name}
                  </option>
                  {client.packages?.map((packageItem: Package) => (
                    <option a-key="package" key={packageItem.id} value={packageItem.id}>
                      {client.name}: {packageItem.name}
                    </option>
                  ))}
                </>
              ))}
            </select>
          </div>
        ) : (
          'All Groups'
        )
      }
      userName={'Jason'}
    />
    <SwitchBar
      heading={NavLinks}
      position={position}
      optionalButton={
        <div className="">
          <DesktopDatePicker
            value={startDate}
            onChange={(value) => changeDate(moment(value).format('YYYY-MM-DD'), null)}
            onOpen={() => {}}
            InputProps={{
              disableUnderline: true,
              className:
                'rounded-5px text-gray-900 text-sm font-sans leading-normal h-12.5 px-3 bg-white border border-lightSilver w-full',
            }}
            renderInput={(params) => (
              <span>
                <span className="align-text-bottom">From: </span>
                <TextField id="start_date" {...params} variant="standard" sx={{ mr: 2, width: '9rem' }} />
              </span>
            )}
          />
          <DesktopDatePicker
            value={endDate}
            onChange={(value) => changeDate(null, moment(value).format('YYYY-MM-DD'))}
            onOpen={() => {}}
            InputProps={{
              disableUnderline: true,
              className:
                'rounded-5px text-gray-900 text-sm font-sans leading-normal h-12.5 px-3 bg-white border border-lightSilver w-full',
            }}
            renderInput={(params) => (
              <span>
                <span className="align-text-bottom">Until: </span>
                <TextField id="end_date" {...params} variant="standard" sx={{ mr: 2, width: '9rem' }} />
              </span>
            )}
          />
        </div>
      }
      setPosition={setPosition}
    />
  </>
);

const DashboardBody: React.FC<{
  clientName: string;
  packageName: string;
  reportType: string;
  reportingOverviewData?: GlobalDashboardResponse;
  reportingDataExtras?: GlobalDashboardResponse;
  audioCountData?: AudioCountResponse;
  position: number;
}> = ({
  clientName,
  packageName,
  reportType,
  reportingOverviewData,
  reportingDataExtras,
  audioCountData,
  position,
}) => {
  let page: any;
  switch (position) {
    case 0:
      page = (
        <DashboardOverviewPage
          clientName={clientName}
          packageName={packageName}
          reportType={reportType}
          reportingOverviewData={reportingOverviewData}
          audioCountData={audioCountData}
        />
      );
      break;
    case 1:
      page = (
        <GlobalDashboardEngagement
          reportingDataExtras={reportingDataExtras}
          reportingOverviewData={reportingOverviewData}
        />
      );
      break;
  }
  return <div className="mx-8 mt-4">{page}</div>;
};

enum BannerStorageKeys {
  SystemRestrictionKey = 'system-restriction-banner',
}

export const SystemRestrictionBanner: React.FC<{}> = () => {
  const currentValue = localStorage.getItem(BannerStorageKeys.SystemRestrictionKey);
  // NOTE: `token` guaranteed to exist because if not the app will short circuit
  // back to the log in route
  const token = localStorage.getItem('authorization_token')!;
  // display if the token has changed since the last time this was displayed e.g.
  // a new auth session
  const [isVisible, setIsVisible] = useState(currentValue !== token);

  const closeWindow = () => {
    setIsVisible(false);
    localStorage.setItem(BannerStorageKeys.SystemRestrictionKey, token);
  };

  return isVisible ? (
    <Paper
      sx={{
        backgroundColor: '#96D600',
        borderRadius: '10px',
        margin: 3,
      }}
    >
      <Box p={3} pb={4}>
        <Box display={'flex'} pb={1}>
          <Typography fontWeight={600} color={'text.primary'} variant="h5" textTransform={'uppercase'}>
            Welcome Back
          </Typography>
          <img
            onClick={closeWindow}
            src={CancelIcon}
            style={{
              marginLeft: 'auto',
              marginBottom: 'auto',
              padding: '.5rem',
              cursor: 'pointer',
            }}
          />
        </Box>
        <Typography pt={2} color={'text.primary'}>
          Our lawyers want us to remind you that access to Kindly Human Switchboard is restricted to authorized
          individuals, and that any impersonation or misuse could result in prosecution. As long as you are who you say
          you are, it&rsquo;s all good and you can dismiss this reminder.
        </Typography>
      </Box>
    </Paper>
  ) : null;
};

export default GlobalDashboard;
