import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  postValidateEligibiltySuccess,
  postValidateEligibiltyFailure,
  fetchEligibiltySuccess,
  fetchEligibiltyFailure,
  postEligibilitySuccess,
  postEligibilityFailure,
  fetchEligibiltyListSuccess,
  fetchEligibiltyListFailure,
  fetchEligibiltyActiveListSuccess,
  fetchEligibiltyActiveListFailure,
  fetchEligibiltyScheduledListSuccess,
  fetchEligibiltyScheduledListFailure,
  updateEligibiltyFailure,
  updateEligibiltySuccess,
  removeEligibiltySuccess,
  removeEligibiltyFailure,
  processEligibilitySuccess,
  processEligibilityFailure,
  downloadEligibilityMemebersSuccess,
  downloadEligibilityMemebersFailure,
  processEligibilityReportSuccess,
  processEligibilityReportFailure,
  transferEligibilityReportSuccess,
  transferEligibilityReportFailure,
} from './actions';
import {
  POST_VALIDATE_ELIGIBILTY_REQUEST,
  FETCH_ELIGILITY_REQUEST,
  POST_ELIGIBILTY_REQUEST,
  FETCH_ELIGIBILTY_LIST_REQUEST,
  UPDATE_ELIGIBILTY_REQUEST,
  REMOVE_ELIGIBILTY_REQUEST,
  PROCESS_ELIGIBILTY_REQUEST,
  DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST,
  PROCESS_ELIGIBILITY_REPORT_REQUEST,
  TRANSFER_ELIGIBILITY_REPORT_REQUEST,
} from './action-types';

function* postEligibiltySaga(action: any): any {
  try {
    const response = yield call(Api.createEligibilty(action.payload));
    yield put(postEligibilitySuccess(response));
  } catch (e: any) {
    yield put(postEligibilityFailure(JSON.parse(e)));
  }
}

function* fetchEligibiltySaga(action: any): any {
  try {
    const response = yield call(Api.getEligibility(action.payload));
    yield put(fetchEligibiltySuccess(response));
  } catch (e: any) {
    yield put(fetchEligibiltyFailure(JSON.parse(e)));
  }
}
function* PostValidateEligibiltySaga(action: any): any {
  try {
    const response = yield call(Api.validateEligibilty(action.payload));
    yield put(postValidateEligibiltySuccess(response));
  } catch (e: any) {
    yield put(postValidateEligibiltyFailure(JSON.parse(e)));
  }
}

function* fetchEligibiltyListSaga(action: any): any {
  try {
    if (action.payload.active) {
      const response = yield call(Api.getAllEligibilty(action.payload));
      yield put(
        fetchEligibiltyActiveListSuccess({
          eligibiltyActiveList: response,
        }),
      );
    } else if (action.payload.scheduled) {
      const response = yield call(Api.getAllEligibilty(action.payload));
      yield put(
        fetchEligibiltyScheduledListSuccess({
          eligibiltyScheduledList: response,
        }),
      );
    } else {
      const response = yield call(Api.getAllEligibilty(action.payload));
      yield put(
        fetchEligibiltyListSuccess({
          eligibiltyList: response,
        }),
      );
    }
  } catch (e: any) {
    if (action.payload.active) {
      yield put(fetchEligibiltyActiveListFailure(JSON.parse(e)));
    } else if (action.payload.scheduled) {
      yield put(fetchEligibiltyScheduledListFailure(JSON.parse(e)));
    } else {
      yield put(fetchEligibiltyListFailure(JSON.parse(e)));
    }
  }
}

function* UpdateEligibiltySaga(action: any): any {
  try {
    const response = yield call(Api.updateEligibilty(action.payload));
    yield put(updateEligibiltySuccess(response));
  } catch (e: any) {
    yield put(updateEligibiltyFailure(JSON.parse(e)));
  }
}
function* removeEligibiltySaga(action: any): any {
  try {
    const response = yield call(Api.deleteEligibilty(action.payload));
    yield put(removeEligibiltySuccess(response));
  } catch (e: any) {
    yield put(removeEligibiltyFailure(JSON.parse(e)));
  }
}

function* processEligibilitySaga(action: any): any {
  try {
    const response = yield call(Api.processEligibility(action.payload));
    yield put(processEligibilitySuccess(response));
  } catch (e: any) {
    yield put(processEligibilityFailure(JSON.parse(e)));
  }
}

function* downloadEligibilityMemberSaga(action: any): any {
  try {
    const response = yield call(Api.downloadEligibilityMembers(action.payload));
    yield put(downloadEligibilityMemebersSuccess(response));
  } catch (e: any) {
    yield put(downloadEligibilityMemebersFailure(JSON.parse(e)));
  }
}

function* processEligibilityReport(action: any): any {
  try {
    const response = yield call(Api.processEligibilityReport(action.payload));
    yield put(processEligibilityReportSuccess(response));
  } catch (e: any) {
    yield put(processEligibilityReportFailure(JSON.parse(e)));
  }
}

function* transferEligibilityReport(action: any): any {
  try {
    const response = yield call(Api.transferEligibilityReport(action.payload));
    yield put(transferEligibilityReportSuccess(response));
  } catch (e: any) {
    yield put(transferEligibilityReportFailure(JSON.parse(e)));
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(POST_ELIGIBILTY_REQUEST, postEligibiltySaga),
    takeLatest(FETCH_ELIGILITY_REQUEST, fetchEligibiltySaga),
    takeLatest(POST_VALIDATE_ELIGIBILTY_REQUEST, PostValidateEligibiltySaga),
    takeEvery(FETCH_ELIGIBILTY_LIST_REQUEST, fetchEligibiltyListSaga),
    takeLatest(UPDATE_ELIGIBILTY_REQUEST, UpdateEligibiltySaga),
    takeLatest(REMOVE_ELIGIBILTY_REQUEST, removeEligibiltySaga),
    takeLatest(PROCESS_ELIGIBILTY_REQUEST, processEligibilitySaga),
    takeLatest(DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST, downloadEligibilityMemberSaga),
    takeLatest(PROCESS_ELIGIBILITY_REPORT_REQUEST, processEligibilityReport),
    takeLatest(TRANSFER_ELIGIBILITY_REPORT_REQUEST, transferEligibilityReport),
  ]);
}

export default paymentSaga;
