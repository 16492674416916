import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import axios from 'axios';
import mime from 'mime';
import Header from '../../components/common/global-top-bar';
import Spinner from '../../components/common/spinner';
import { DropDown } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import FilterBar from '../peer-listing/components/filter-bar';
import { ROUTE_PATH } from '../../routes/paths';
import { convertObjectToParams } from '../../components/common/create-params';
import { FilterOptionsType } from './common/review-page-controls';
import { HeaderOptions } from './common/listener-review-constants';
import { useUpdateTag } from '../../hooks/useListenerTags';
import {
  BackgroundTag,
  ListenerRole,
  ListenerRoleResponse,
  PeerOptions,
  TagStatus,
  usePeers,
} from '../../hooks/usePeers';

const BackgroundTagApproval: React.FC = (): JSX.Element => {
  const [filters, setFilters] = useState<PeerOptions>({
    limit: 25,
    page: 1,
    hasBackgroundTags: true,
  });
  const { limit, page, backgroundTagApproved, ...rest } = filters;
  const {
    data: peers,
    isLoading,
    refetch,
  } = usePeers({
    limit,
    page,
    hasBackgroundTags: true,
    backgroundTagApproved: backgroundTagApproved,
    ...rest,
  });

  const backgroundTagCount = (backgroundTags: BackgroundTag[], filterValue: boolean | undefined) => {
    if (filterValue === true) {
      return backgroundTags?.filter((tag) => tag.status === 'APPROVED');
    } else if (filterValue === false) {
      return backgroundTags?.filter((tag) => tag.status === 'UNGRADED');
    } else {
      return backgroundTags;
    }
  };

  const totalTags = peers?.data?.reduce(
    (acc, peer) => acc + (backgroundTagCount(peer?.background_tags, filters.backgroundTagApproved).length ?? 0),
    0,
  );

  return (
    <>
      <Header heading={'Background Tags'} />
      <SwitchBar heading={HeaderOptions} position={4} />
      <FilterBar count={totalTags} setFilters={setFilters} filters={filters} />
      {isLoading && <Spinner alignmentClass={'left-64 top-40 fixed z-100 inset-0 overflow-y-auto'} />}
      <BackgroundTagBody
        peers={peers?.data}
        filters={filters}
        setFilters={setFilters}
        backgroundTagCount={backgroundTagCount}
        refetch={refetch}
      />
    </>
  );
};

interface BackgroundTagBodyProps {
  peers: ListenerRole[] | undefined;
  filters: PeerOptions;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
  backgroundTagCount: (backgroundTags: BackgroundTag[], filterValue: boolean | undefined) => BackgroundTag[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<ListenerRoleResponse, unknown>>;
}

const BackgroundTagBody: React.FC<BackgroundTagBodyProps> = ({
  peers,
  filters,
  setFilters,
  backgroundTagCount,
  refetch,
}) => {
  const [filterOptions] = useState<FilterOptionsType>({
    all: 'All',
    approved: 'Approved',
    pending: 'Pending',
  });

  const checkApprovalFilter = (filterValue: boolean | undefined) => {
    if (filterValue === undefined) {
      return 'all';
    } else if (filterValue === true) {
      return 'approved';
    } else if (filterValue === false) {
      return 'pending';
    }
  };

  return (
    <div className="max-window-height-connections overflow-y-auto px-7 font-['Montserrat']">
      <table className="sticky top-0 table-fixed table-row-format w-full relative border-collapse mb-2 bg-white fixed z-50">
        <thead className="bg-white h-full">
          <tr className="text-left w-full px-7">
            <th className="sticky top-0 bg-white h-full mb-4 text-left w-4/124">Peer</th>
            <th className="sticky top-0 bg-white h-full mb-4 text-left w-3/12">Proof</th>
            <th className="sticky top-0 bg-white h-full mb-4 text-left w-5/12">
              <div className="flex items-center justify-between">
                <p>Tag</p>
                <div className="flex justify-end">
                  <div className="flex justify-between items-center py-4 gray-background-dark">
                    {Object.keys(filterOptions).map((item: string) => (
                      <button
                        key={item}
                        className={
                          'text-sm px-5 py-1 ' +
                          (item === checkApprovalFilter(filters.backgroundTagApproved)
                            ? 'bg-blue-primary text-white'
                            : 'bg-gray-background-dark text-gray-dark')
                        }
                        onClick={() => {
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            backgroundTagApproved: item === 'all' ? undefined : item === 'approved' ? true : false,
                          }));
                        }}
                      >
                        {filterOptions[item]}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
      </table>
      {peers &&
        peers.length > 0 &&
        peers.map((peer: ListenerRole) => {
          return (
            <>
              {backgroundTagCount(peer?.background_tags, filters.backgroundTagApproved).map((tag) => {
                return <BackgroundTagRow peer={peer} tag={tag} filters={filters} refetch={refetch} />;
              })}
            </>
          );
        })}
    </div>
  );
};

interface BackgroundTagRowProps {
  peer: ListenerRole;
  tag: BackgroundTag;
  filters: PeerOptions;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<ListenerRoleResponse, unknown>>;
}

const BackgroundTagRow: React.FC<BackgroundTagRowProps> = ({ peer, tag, filters, refetch }) => {
  const history = useHistory();
  const dateRequested = new Date(tag?.created_at).toDateString();
  const { updateTag } = useUpdateTag(peer.id, tag.id);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [tagApprovedStatus, setApprovalStatus] = useState<TagStatus>(tag.status);

  const getUrlFileType = (url: string) => {
    const newUrl = new URL(url);
    const ext: any = newUrl.pathname.split('.').pop();
    return ext === '/' ? undefined : mime?.getType(ext.toLowerCase());
  };

  const handleImage = async (file_url: string, key: string) => {
    if (key === 'VERIFICATION') {
      const authenticateToken = localStorage.getItem('authorization_token');
      const response: any = await axios.get(file_url, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + authenticateToken,
          'X-Requested-With': 'XMLHTTPRequest',
          Accept: 'application/json',
        },
      });
      const blob: Blob = new Blob([response.data], {
        type: getUrlFileType(file_url) || undefined,
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } else {
      window.open(file_url, '_blank');
    }
  };

  const handleSave = (tagApprovedStatus: TagStatus) => {
    const payload = {
      tag_id: tag.tag_id,
      status: tagApprovedStatus,
    };
    updateTag.mutate(payload);
    setEnableSave(false);
    refetch();
  };

  return (
    <div className="w-full bg-gray-200 rounded text-gray-dark text-left h-36 border-b-8 border-white space-y-2 space-x-2 flex">
      <div className="w-4/12">
        <div className="pl-4 flex w-full h-full items-center">
          <div className="w-full h-full flex flex-col space-y-1 pl-6 pt-4 text-black font-normal truncate font-['Montserrat'] font-bold">
            {peer?.user?.first_name || peer?.user?.last_name ? (
              <p className="text-base">
                {peer.user.first_name} {peer.user.last_name}
              </p>
            ) : (
              <p className="text-base">No name on file</p>
            )}
            <p>
              {peer.user.display_name ? <span className="text-sm">aka </span> : ''}
              <span
                className={`${peer.user.display_name ? `text-base` : `text-sm italic`} underline cursor-pointer`}
                onClick={() => {
                  localStorage.setItem('peerListingFilters', JSON.stringify(filters));
                  history.push(
                    ROUTE_PATH.PEERS_PROFILE +
                      convertObjectToParams({
                        listenerRoleId: peer.id,
                        listenerId: peer.user.id,
                        listenerType: 'peer',
                        listenerName: peer.user.first_name,
                      }),
                  );
                }}
              >
                {peer.user.display_name ? peer.user.display_name : 'No display name'}
              </span>
            </p>
            <>
              <p>{peer?.user?.email_address}</p>
            </>
            <div className="text-neutral-600 text-xs flex justify-between w-3/4 space-x-2">
              <p>User ID: #{peer.user.id}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/12">
        <div className="flex flex-col justify-center col-span-3 w-full mt-11">
          <>
            {tag?.media ? (
              tag?.media?.map((media) => {
                return (
                  <div>
                    <button
                      className="whitespace-nowrap overflow-hidden overflow-ellipsis w-full text-left"
                      onClick={() => handleImage(media?.file_url, media?.file_key)}
                    >
                      <p className="underline cursor-pointer truncate">
                        {media?.file_name ? media?.file_name : media?.file_url}
                      </p>
                    </button>
                  </div>
                );
              })
            ) : (
              <p>No File Found </p>
            )}
          </>
        </div>
      </div>
      <div className="w-4/12">
        <div className="w-full flex flex-col space-y-3 font-normal truncate font-['Montserrat']">
          <div
            className={`${
              tag?.status === 'APPROVED' ? ' bg-citrus ' : ' stacked-bar-orange-primary '
            } text-white rounded-md text-sm font-bold w-max`}
          >
            <div className="flex px-3 py-2">
              <div className="grow pr-2">{tag.tag.name}</div>
            </div>
          </div>
          <>
            <p className="text-xs">Submitted on: {dateRequested}</p>
          </>
          <div className="w-1/2 min-w-max -z-10">
            <DropDown
              Label={''}
              value={tagApprovedStatus}
              setValue={(value) => {
                setApprovalStatus(value);
                setEnableSave(true);
              }}
            >
              {tag.status === 'APPROVED' ? (
                <>
                  <option value="APPROVED">Approved</option>
                  <option value="UNGRADED">Needs Approval</option>
                  <option value="REJECTED">Reject</option>
                </>
              ) : tag.status === 'REJECTED' ? (
                <>
                  <option value="REJECTED">Rejected</option>
                  <option value="UNGRADED">Needs Approval</option>
                  <option value="APPROVED">Approve</option>
                </>
              ) : (
                <>
                  <option value="UNGRADED">Needs Approval</option>
                  <option value="APPROVED">Approve</option>
                  <option value="REJECTED">Reject</option>
                </>
              )}
            </DropDown>
          </div>
        </div>
      </div>
      <div className="w-1/12 flex items-center pb-6">
        <button
          type="button"
          className={`${
            enableSave ? 'bg-bright-blue text-white' : 'bg-gray-background-dark text-gray-dark'
          } rounded-lg py-1 px-3  w-auto`}
          onClick={() => {
            handleSave(tagApprovedStatus);
          }}
          disabled={!enableSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default BackgroundTagApproval;
