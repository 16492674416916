import {
  POST_BLACKLIST_REQUEST,
  POST_BLACKLIST_SUCCESS,
  POST_BLACKLIST_FAILURE,
  FETCH_BLACKLIST_LIST_REQUEST,
  FETCH_BLACKLIST_LIST_SUCCESS,
  FETCH_BLACKLIST_LIST_FAILURE,
  UPDATE_BLACKLIST_REQUEST,
  UPDATE_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_FAILURE,
  REMOVE_BLACKLIST_REQUEST,
  REMOVE_BLACKLIST_SUCCESS,
  REMOVE_BLACKLIST_FAILURE,
  FETCH_BLACKLIST_COUNT_REQUEST,
  FETCH_BLACKLIST_COUNT_SUCCESS,
  FETCH_BLACKLIST_COUNT_FAILURE,
  FETCH_USERLIST_REQUEST,
  FETCH_USERLIST_SUCCESS,
  FETCH_USERLIST_FAILURE,
  FETCH_BLOCKED_COUNT_FAILURE,
  FETCH_BLOCKED_COUNT_REQUEST,
  FETCH_BLOCKED_COUNT_SUCCESS,
  FETCH_BLOCKED_LIST_FAILURE,
  FETCH_BLOCKED_LIST_REQUEST,
  FETCH_BLOCKED_LIST_SUCCESS,
  POST_BLOCKED_FAILURE,
  POST_BLOCKED_REQUEST,
  POST_BLOCKED_SUCCESS,
  REMOVE_BLOCKED_FAILURE,
  REMOVE_BLOCKED_REQUEST,
  REMOVE_BLOCKED_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  FETCH_BLOCKED_FAILURE,
  FETCH_BLOCKED_REQUEST,
  FETCH_BLOCKED_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from './action-types';

import { HomeActions, TemplateInfoState } from './types';

const initialState: TemplateInfoState = {
  pending: false,
  BlackList: [],
  BlackListList: [],
  UserList: null,
  UpdateBlackList: null,
  RemoveBlackList: null,
  newUserList: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_BLACKLIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_BLACKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        newUserList: action.payload,
        error: null,
      };
    case POST_BLACKLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_BLACKLIST_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BLACKLIST_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        BlackListList: action.payload.BlackListList,
        error: null,
      };
    case FETCH_BLACKLIST_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_BLACKLIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_BLACKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateBlackList: action.payload,
        error: null,
      };
    case UPDATE_BLACKLIST_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateBlackList: null,
        error: action.payload.error,
      };
    case REMOVE_BLACKLIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_BLACKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveBlackList: action.payload,
        error: null,
      };
    case REMOVE_BLACKLIST_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveBlackList: null,
        error: action.payload.error,
      };

    case FETCH_BLACKLIST_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BLACKLIST_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        BlackListCount: action.payload,
        error: null,
      };
    case FETCH_BLACKLIST_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_USERLIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_USERLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        UserList: action.payload,
        error: null,
      };
    case FETCH_USERLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_BLOCKED_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_BLOCKED_SUCCESS:
      return {
        ...state,
        pending: false,
        newUserListGroup: action.payload,
        error: null,
      };
    case POST_BLOCKED_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_BLOCKED_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BLOCKED_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        BlockedList: action.payload.BlockedList,
        error: null,
      };
    case FETCH_BLOCKED_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateUser: action.payload,
        error: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateUser: null,
        error: action.payload.error,
      };
    case REMOVE_BLOCKED_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_BLOCKED_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveBlocked: action.payload,
        error: null,
      };
    case REMOVE_BLOCKED_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveBlocked: null,
        error: action.payload.error,
      };

    case FETCH_BLOCKED_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BLOCKED_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        BlockedCount: action.payload,
        error: null,
      };
    case FETCH_BLOCKED_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_BLOCKED_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BLOCKED_SUCCESS:
      return {
        ...state,
        pending: false,
        Blocked: action.payload.Blocked,
        error: null,
      };
    case FETCH_BLOCKED_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateSettiings: action.payload,
        error: null,
      };
    case UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateSettiings: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
