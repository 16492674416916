import React, { useEffect, useState } from 'react';
import { SectionLabel, NumericLabel, Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import SwitchBar from '../../components/switch-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import CalendarPaginator from '../../components/calender-paginator';
import { connect, useDispatch } from 'react-redux';
import { fetchEngagementRequest } from './listeners-redux/actions';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ROUTE_PATH } from '../../routes/paths';
import { paramsToObjects } from '../../components/common/create-params';
import HeadBack from './redirect';

type callprops = {
  count: number;
  percentage?: number;
};

const Calls: React.FunctionComponent<callprops> = ({ count, percentage }): JSX.Element => {
  return (
    <div className=" flex justify-start h-24 w-full px-7 gap-x-24">
      <NumericLabel numericValue={count.toString()} label={''} />
      {percentage && <NumericLabel numericValue={`${percentage?.toString()}%`} label={'of the Requests'} />}
    </div>
  );
};

type props = {
  listenerEngagement: any;
  pending: boolean;
};

const Engagement: React.FunctionComponent<props> = ({ listenerEngagement, pending }): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const history = useHistory();
  const [grade, setgrade] = useState('F');
  const dispatch = useDispatch();

  const changedDate = (startdate: string, enddate: string) => {
    const input = {
      id: listenerId,
      payload: {
        start_date: startdate,
        end_date: enddate,
      },
    };
    dispatch(fetchEngagementRequest(input));
  };

  useEffect(() => {
    const calculategrade = (grade: number) => {
      if (grade >= 0.9) {
        return 'A';
      } else if (grade >= 0.6) {
        return 'B';
      } else if (grade >= 0.4) {
        return 'C';
      } else if (grade >= 0.1) {
        return 'D';
      } else {
        return 'F';
      }
    };
    let gradeScrore = 0;
    if (listenerEngagement?.requests) {
      gradeScrore =
        listenerEngagement.requests.successes?.count ||
        0 / (listenerEngagement.requests?.total || 0 - listenerEngagement.requests?.passes?.count || 0);
      setgrade(calculategrade(gradeScrore));
    } else {
      setgrade('F');
    }
  }, [listenerEngagement]);

  return (
    <div>
      {pending && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />
      <SwitchBar heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions} position={1} forwardParams />
      <CalendarPaginator changedDate={changedDate} disableDownload={true} />

      <div className="max-window-height  overflow-y-auto flex justify-between ">
        {listenerType !== 'peer' && (
          <div className="w-1/2 ">
            <div className="px-7">
              <SectionLabel Label={'Connection Requests'} />
            </div>
            <Calls count={listenerEngagement?.requests?.total || 0} />
            <div className="px-7">
              <SectionLabel Label={'Call Successes'} />
            </div>
            <Calls
              count={listenerEngagement?.requests?.successes?.count || 0}
              percentage={listenerEngagement?.requests?.successes?.percent?.toFixed(2) || '0.00'}
            />
            <div className="px-7">
              <SectionLabel Label={'Connection Passes'} />
            </div>
            <Calls
              count={listenerEngagement?.requests?.passes?.count || 0}
              percentage={listenerEngagement?.requests?.passes?.percent?.toFixed(2) || '0.00'}
            />

            <div className="px-7">
              <SectionLabel Label={'Connection Misses'} />
            </div>
            <Calls
              count={listenerEngagement?.requests?.misses?.count || 0}
              percentage={listenerEngagement?.requests?.misses?.percent?.toFixed(2) || '0.00'}
            />
          </div>
        )}
        <div className={`${listenerType !== 'peer' ? 'pr-7' : 'px-7'} w-1/2`}>
          {listenerType !== 'peer' && (
            <div className="grid grid-cols-2 ">
              <div className="h-36">
                <SectionLabel Label={'Success Rating'} />
                <NumericLabel
                  numericValue={`${listenerEngagement?.success_rating?.toFixed(2) || '0.00'}%`}
                  label={''}
                />
              </div>
              <div className="h-36">
                <SectionLabel Label={'Avg. Imapact Score'} />
                <NumericLabel
                  numericValue={`${
                    listenerEngagement?.avg_impact_score === null
                      ? 0
                      : listenerEngagement?.avg_impact_score?.toFixed(2) || 0
                  } `}
                  label={''}
                />
              </div>
              <div>
                <SectionLabel Label={'Earnings'} />
                <NumericLabel
                  numericValue={`$${
                    listenerEngagement?.total_earnings ? (listenerEngagement?.total_earnings / 100)?.toFixed(2) : '0.00'
                  } `}
                  label={''}
                />
              </div>

              <div className="h-36">
                <SectionLabel Label={'Grade'} />

                <NumericLabel numericValue={grade} label={''} />
              </div>
              <div className="h-36">
                <SectionLabel Label={'Avg. Call Length'} />
                <NumericLabel
                  numericValue={`${
                    listenerEngagement?.avg_call_length
                      ? moment.utc(listenerEngagement.avg_call_length * 1000).format('HH:mm:ss')
                      : '00:00:00'
                  }`}
                  label={''}
                />
              </div>
              <div className="h-36">
                <SectionLabel Label={'Call Hours'} />
                <NumericLabel
                  numericValue={`${
                    listenerEngagement?.total_call_seconds
                      ? moment.utc(listenerEngagement.total_call_seconds * 1000).format('HH:mm:ss')
                      : '00:00:00'
                  }`}
                  label={''}
                />
              </div>
            </div>
          )}
          <div className="w-full">
            <div className="w-full border-b gray-border-line grid grid-cols-5 text-md font-bold text-gray-dark py-3">
              <h3 className="col-span-2">Total Experience Plays</h3>
              <h3 className="col-span-2">Unique Experience Plays</h3>
              <h3>Favorited</h3>
            </div>
            <div className="w-full grid grid-cols-5">
              <div className="col-span-2">
                <NumericLabel numericValue={`${listenerEngagement?.total_experience_plays || 0}`} label={''} />
              </div>
              <div className="col-span-2">
                <NumericLabel numericValue={`${listenerEngagement?.unique_experience_plays || 0}`} label={''} />
              </div>
              <NumericLabel numericValue={`${listenerEngagement?.total_experience_favorites || 0}`} label={''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listenerEngagement: state.Listeners?.listenerEngagement?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(Engagement);
