import React, { useState } from 'react';
type props = {
  fileSrc: any;
  setFileSrc: (data: any) => void;
  enableSaveOption: (data: any) => void;
};

const MediaInput: React.FunctionComponent<props> = ({ fileSrc, setFileSrc, enableSaveOption }): JSX.Element => {
  const fileInput: any = React.createRef();
  const [fileType, setFileType] = useState<any>();

  const onFileChange = async (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        const image = event.target.files[0].type.split('/')[0];
        setFileType(image);
        // setimage(event.target.files[0]);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          // setpreloadedImage(false);
          const result = e.target.result;
          setFileSrc(result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }

      // Update the state
    } catch (error) {
      console.error('err--', error);
    }
  };

  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  const hadleImageinput = (e: any) => {
    fileInput.current.click();
  };

  return (
    <div className="w-full flex">
      <div
        className="bg-gray-200 w-full aspect-ratio-container rounded-sm flex justify-center items-center cursor-pointer"
        onClick={hadleImageinput}
      >
        {(fileSrc && fileType === 'image') || isImage(fileSrc) ? (
          <img className="w-full h-full" src={fileSrc} alt="clinetImage" />
        ) : fileSrc ? (
          <div>
            <video width="100%" controls key={fileSrc}>
              <source src={fileSrc} />
              Your browser does not support HTML5 video.
            </video>
          </div>
        ) : (
          <div className="h-56 flex justify-center items-center">
            <button className="rounded-full h-6 w-6 text-3xl bg-white flex justify-center items-center">+</button>
            <input
              ref={fileInput}
              id="file-upload"
              type="file"
              accept="image/*,video/mp4,video/x-m4v,video/*"
              className="hidden"
              onClick={() => {
                fileInput.current.value = null;
              }}
              onChange={(data) => {
                onFileChange(data);
                enableSaveOption(true);
              }}
            />
          </div>
        )}
      </div>
      <div className="pl-4">
        {fileSrc && (
          <div className=" w-full aspect-ratio-container ">
            <button
              className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
              onClick={() => {
                setFileSrc(null);
                enableSaveOption(true);
              }}
            >
              REMOVE
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaInput;
