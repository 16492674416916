import React, { useEffect, useState } from 'react';
import { User, useUsers } from '../../hooks/useUsers';
import { DebounceInput } from 'react-debounce-input';
import { normalizeMobilePhone } from '../../components/common/phone-validation';
import { convertObjectToParams } from '../../components/common/create-params';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { appSettings } from '../../api';
import { fetchTosRequest } from '../clients/text-blocks/text-blocks-redux/actions';
import { ROUTE_PATH } from '../../routes/paths';
import Header from '../../components/common/global-top-bar';
import EditUserDetails from './components/user-edit';
import { Spinner } from '../../components/common';
import { XCricle } from '../../assets/images';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';

export const Users: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const { data: users, isLoading, refetch } = useUsers({ page, search });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTosRequest({ type: 'app' }));
  }, [appSettings]);

  const handleCancelSearch = () => {
    setSearch('');
    setPage(1);
    setTimeout(() => {
      refetch();
    }, 300);
  };

  const handleSearch = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearch(event.target.value);
    setPage(1);
    refetch();
  };

  const handleUserRedirect = (user: User) => {
    if (user.caller_role !== null) {
      history.push(
        ROUTE_PATH.MEMBERS_PROFILE +
          convertObjectToParams({
            name: user.first_name,
            id: user.id,
            callerRoleId: user.caller_role_id,
          }),
      );
    } else if (user.listener_role !== null) {
      history.push(
        ROUTE_PATH.LISTENERS_PROFILE +
          convertObjectToParams({
            listenerRoleId: user.listener_role_id,
            listenerId: user.id,
          }),
      );
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <Header heading={'Users'} />
      <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7 h-10">
        <div className="w-2/4">
          <div className="w-full h-8">
            <div className="w-full bg-white rounded-full  h-full  flex ">
              <DebounceInput
                minLength={2}
                className=" text-center focus:outline-none w-5/6 rounded-l-full h-full "
                placeholder="SEARCH"
                debounceTimeout={300}
                onChange={handleSearch}
                value={search}
              />
              <div
                className="w-1/6 flex items-center justify-center rounded-r-full h-full pr-2"
                onClick={() => {
                  handleCancelSearch();
                }}
              >
                {search.length > 0 && <img src={XCricle} alt="closeButton"></img>}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="relative mx-7 py-3 flex justify-between items-center">
            <div className="flex justify-between items-center px-7 py-3">
              <div className="flex items-center">
                {page > 1 ? (
                  <button
                    className="rounded-full h-6 w-6 blue-primary"
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  >
                    <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
                  </button>
                ) : (
                  <button className="rounded-full h-6 w-6 blue-primary" disabled></button>
                )}
              </div>
              {search === '' ? (
                <div className="flex w-full relative justify-evenly p-2 space-x-2">
                  {page > 1 && page <= 5 ? (
                    <div
                      role="button"
                      className="text-gray-dark"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      {page - 1}
                    </div>
                  ) : page > 5 ? (
                    <div
                      role="button"
                      className="text-gray-dark"
                      onClick={() => {
                        setPage(page - 5);
                      }}
                    >
                      {page - 5}...
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="blue-primary font-bold">{page}</div>
                  <div
                    role="button"
                    className="text-gray-dark"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    {page + 1}
                  </div>
                  <div
                    role="button"
                    className="text-gray-dark"
                    onClick={() => {
                      setPage(page + 2);
                    }}
                  >
                    {page + 2}
                  </div>
                  <div
                    role="button"
                    className="text-gray-dark"
                    onClick={() => {
                      setPage(page + 3);
                    }}
                  >
                    {page + 3}
                  </div>
                  <div
                    role="button"
                    className="text-gray-dark"
                    onClick={() => {
                      setPage(page + 4);
                    }}
                  >
                    {page + 4}
                  </div>
                </div>
              ) : (
                <div className="flex w-full relative justify-evenly p-2 space-x-2">
                  <div className="blue-primary font-bold">{page}</div>
                </div>
              )}
              <div className="flex items-center">
                {users?.length === 10 ? (
                  <button
                    className="rounded-full h-6 w-6 blue-primary transform rotate-180"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
                  </button>
                ) : (
                  <button className="rounded-full h-6 w-6 blue-primary transform rotate-180" disabled></button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-window-height  overflow-y-auto  px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              <th className="px-2 py-1 w-3/12 sticky top-0  bg-white  h-full mb-4">Users</th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4">User ID</th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 ">
                <p className="-pl-4">Role</p>
              </th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4">
                <p className="-pl-4"></p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {users?.map((user: User) => {
              return (
                <tr
                  key={user.id}
                  role="button"
                  className="bg-gray-200 rounded text-gray-dark text-left h-24 border-y border-white border-4 space-y-6"
                  onClick={() => {
                    handleUserRedirect(user);
                  }}
                >
                  <td className="  w-3/12 ">
                    <div className="flex w-full h-full items-center">
                      <div className="px-5 py-1 text-gray-dark truncate ">
                        <p>
                          <span className="font-extrabold">
                            {user.first_name} {user.last_name}
                          </span>{' '}
                          {user.display_name ? `(${user.display_name})` : ''}
                        </p>
                        {user.email_address ? <p className="font-semibold"> {user.email_address}</p> : ''}
                        {user.mobile_phone ? (
                          <p className="font-semibold"> {normalizeMobilePhone(user.mobile_phone.replace('1', ''))}</p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-1 w-1/12">{`#${user?.id}`}</td>
                  <td className=" py-1 w-1/12 truncate pl-1">
                    <div className="w-2/3">
                      {user.caller_role !== null ? (
                        <p>
                          <button className="stacked-bar-green-primary text-white rounded-full px-2 py-1 text-sm w-full">
                            Caller
                          </button>
                        </p>
                      ) : (
                        ''
                      )}
                      {user.listener_role !== null ? (
                        <p>
                          <button className="stacked-bar-blue-100 text-white rounded-full px-2 py-1 text-sm w-full">
                            Listener
                          </button>
                        </p>
                      ) : (
                        ''
                      )}
                      {user.administrator_role !== null ? (
                        <p>
                          <button className="stacked-bar-red-primary text-white rounded-full px-2 py-1 text-sm w-full">
                            Admin
                          </button>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                  <td>
                    <EditUserDetails user={user} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
