import { useHistory } from 'react-router';
import { Spinner } from '../../../components/common';
import { ROUTE_PATH } from '../../../routes/paths';
import Header from '../../../components/common/global-top-bar';
import { connect, useDispatch } from 'react-redux';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import { fetchTosRequest, updateTosRequest } from './text-blocks-redux/actions';
import draftToHtml from 'draftjs-to-html';
import RichTextEditor from '../../../components/common/rich-text-editor';
import { Location } from 'history';

//@ts-ignore
import htmlToDraft from 'html-to-draftjs';
import RouteLeavingGuard from '../../../components/route-leaving-prompt';

type props = {
  pending: boolean;
  clientAdmin: any;
};

const ClientAdminTOS: React.FunctionComponent<props> = ({ pending, clientAdmin }): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [richText, setRichText] = useState<EditorState | undefined>(EditorState.createEmpty());
  const [needSave, setNeedSave] = useState(true);

  const handleSave = (messageType: string, data: any) => {
    const payload = {
      type: messageType,
      payload: {
        value: convertHTMLtoText(data),
      },
    };
    dispatch(updateTosRequest(payload));
  };

  const convertTextToHTML = (data: string) => {
    if (!data) {
      return;
    }
    const contentBlock = htmlToDraft(data);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  };

  const convertHTMLtoText = (data: any) => {
    return draftToHtml(convertToRaw(data.getCurrentContent()));
  };

  useEffect(() => {
    if (clientAdmin?.status === 200) {
      setRichText(convertTextToHTML(clientAdmin.data?.CLIENT_ADMIN_TERMS_OF_SERVICE));
      setNeedSave(true);
    }
  }, [clientAdmin]);

  useEffect(() => {
    dispatch(fetchTosRequest({ type: 'CLIENT_ADMIN_TERMS_OF_SERVICE' }));
  }, []);

  return (
    <div>
      {pending && <Spinner />}
      <RouteLeavingGuard
        when={!needSave}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: Location) => {
          if (!needSave) {
            if (location.pathname !== ROUTE_PATH.CLIENTADMINTOC) {
              return true;
            }
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Continue and lose changes'}
      />
      <Header heading={'Client Administrators '} />

      <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
        <div className="   gray-background-dark h-full">
          <button
            className={' text-sm  px-5 py-2 '}
            onClick={() => {
              history.push(ROUTE_PATH.MEMBERSTOC);
            }}
          >
            Members
          </button>
          <button
            className={' text-sm  px-5 py-2 bg-blue-primary text-white h-full  font-bold'}
            onClick={() => {
              history.push(ROUTE_PATH.CLIENTADMINTOC);
            }}
          >
            Client Administrators
          </button>
          <button
            className={' text-sm  px-5 py-2   '}
            onClick={() => {
              history.push(ROUTE_PATH.LISTENRFAQTOC);
            }}
          >
            Listeners
          </button>
        </div>
      </div>

      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div>
          <div className="mt-4 w-full px-7">
            <div className="flex justify-between items-end py-2 border-b border-gray-500">
              <p className="pt-4 font-bold text-gray-dark">CLIENT ADMIN TERMS OF SERVICE</p>
              <button
                className=" rounded-full  text-center h-6 text-sm text-white px-2 stacked-bar-blue-400 disabled:bg-gray-400"
                onClick={() => {
                  handleSave('CLIENT_ADMIN_TERMS_OF_SERVICE', richText);
                }}
                disabled={needSave}
              >
                SAVE
              </button>
            </div>
            <div className="mt-4 ">
              <RichTextEditor
                RichText={richText}
                setRichText={(data: EditorState) => {
                  setRichText(data);
                  setNeedSave(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending: state.Tos?.pending || null,
    clientAdmin: state.Tos.clientAdmin || null,
  };
};

export default connect(mapStateToProps)(ClientAdminTOS);
