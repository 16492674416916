import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import Header from '../../components/common/global-top-bar';
import { GreenArrow, Handler } from '../../assets/images';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  fetchEligibiltyListRequest,
  removeEligibiltyRequest,
  processEligibilityRequest,
  fetchEligibiltySuccess,
  fetchEligibiltyFailure,
  updateEligibiltyFailure,
  processEligibilityFailure,
  removeEligibiltyFailure,
  postEligibilityFailure,
  downloadEligibilityMemebersRequest,
  downloadEligibilityMemebersSuccess,
  postEligibilityRequest,
  transferEligibilityReportRequest,
} from '../clients/eligibility-redux/actions';
import { useDispatch, connect } from 'react-redux';
import EligibilityFileUpload from '../../components/eligibility/eligibility-file-upload';
import EligibilityTableList from '../../components/eligibility/eligibility-table-list';
import EligibilityFileViewModel from '../../components/eligibility/eligibility-file-view-model';
import { getClientDetailsRequest } from '../clients/clients-redux/actions';
import HeadBack from '../clients/redirect';
import { UserRole } from '../navigation-bar/roles';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from '../clients/clients-constants';
import Spinner from '../../components/common/spinner';
import Modal from '../../components/common/modal';
import { ClientAdminGroupDropDown, DropDown } from '../../components/common';
import { toast } from 'react-toastify';
import { Dialog } from '@kindlyhuman/component-library';
import { useUpdateClient } from '../../hooks/useClients';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';

const elements = [
  {
    id: 1,
    name: 'Group',
  },
  { id: 2, name: '' },
  { id: 3, name: 'Name' },
  { id: 4, name: 'Last' },
  { id: 5, name: 'Email' },
  { id: 6, name: 'Mobile' },
  { id: 7, name: '' },
  { id: 8, name: 'Gender' },
  { id: 9, name: '' },
  { id: 10, name: 'Age' },
  { id: 11, name: 'Program' },
  { id: 12, name: 'Dependants' },
];

type props = {
  eligibility: any;
  eligibilityError: any;
  eligibiltyActiveList: any;
  eligibiltyScheduledList: any;
  eligibiltyList: any;
  removeEligibility: any;
  removeEligibilityError: any;
  processEligibility: any;
  processEligibilityError: any;
  updateEligibility: any;
  updateEligibilityError: any;
  downloadEligibilityMembers: any;
  downloadEligibilityMembersError: any;
  transferEligibilityReport: any;
  client: any;
  pending: boolean;
  clientDetialsPending: boolean;
};
const EligibilityFile: React.FunctionComponent<props> = (props): JSX.Element => {
  const {
    client,
    eligibility,
    eligibilityError,
    eligibiltyActiveList,
    eligibiltyScheduledList,
    eligibiltyList,
    removeEligibility,
    removeEligibilityError,
    processEligibility,
    processEligibilityError,
    updateEligibility,
    updateEligibilityError,
    downloadEligibilityMembers,
    downloadEligibilityMembersError,
    pending,
    clientDetialsPending,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isEligibilityFileViewModel, setIsEligibilityFileViewModel] = useState<boolean>(false);
  const [eligibilityId, seteligibilityId] = useState<number>(0);
  const [showFixModal, setFixModal] = useState(false);
  const [eligibilityFiledsArray, setEligibilityFiledsArray] = useState<any>(elements);
  const [eligibilityValidationData, setEligibilityValidationData] = useState<any>([]);
  const [uploadLiveTime, setUploadLiveTime] = useState<any>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [tableType, setTableType] = useState<string>('');
  const userType = localStorage.getItem('role_type');
  const [clientId, setClientId] = useState<any>(
    userType === UserRole.client_admin ? null : localStorage.getItem('clientid'),
  );
  const startdate = moment(new Date()).startOf('month');
  const [scheduledDate, setscheduledDate] = useState('');
  const [liveDates, setLiveDates] = useState<any>([]);
  const [eligibilityActiveAndScheduleList, setEligibilityActiveAndScheduleList] = useState<any>([]);
  const [scheduleList, setScheduleList] = useState<any>([]);
  const [historicalList, setHistoricalList] = useState<any>([]);
  const [scheduleModalShowing, setScheduleModalShowing] = useState(false);
  const [isScheduleModified, setIsScheduleModified] = useState(false);
  const [scheduleFrequency, setScheduleFrequency] = useState('monthly');
  const [scheduleOrdinal, setScheduleOrdinal] = useState(1);
  const [scheduleHour, setScheduleHour] = useState(0);
  const [scheduleText, setScheduleText] = useState('No Schedule Set');
  const { updateClient } = useUpdateClient(parseInt(clientId!));
  const [saveLocationPath, setSaveLocationPath] = useState('');

  const onBeforeCapture = () => {
    /*...*/
  };
  const onBeforeDragStart = () => {
    /*...*/
  };
  const onDragStart = () => {
    /*...*/
  };
  const onDragUpdate = () => {
    /*...*/
  };
  const onDragEnd = (result: any) => {
    const items = Array.from(eligibilityFiledsArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEligibilityFiledsArray(items);
  };

  const handleEligibilityFileViewModel = (id: number, type: string) => {
    seteligibilityId(id);
    setTableType(type);
    // setEligibilityValidationData({});
    setIsEligibilityFileViewModel(!isEligibilityFileViewModel);
  };

  const handleValidationViewModel = (data: any) => {
    setEligibilityValidationData(data);
    seteligibilityId(0);
    dispatch(fetchEligibiltySuccess({}));
    setIsEligibilityFileViewModel(!isEligibilityFileViewModel);
  };
  const handleDeleteEligibilityModel = (id: number) => {
    seteligibilityId(id);
    setIsDelete(!isDelete);
    //dispatch(removeEligibiltyRequest({ clientId, eligibilityId: id }));
  };
  const handleDeleteEligibility = () => {
    dispatch(removeEligibiltyRequest({ clientId, eligibilityId: eligibilityId }));
    setIsDelete(!isDelete);
  };
  const handleProcessEligibility = (id: number) => {
    dispatch(processEligibilityRequest({ clientId, eligibilityId: id }));

    // TO DO: this is a temporary fix to refresh the active list after processing to account for small eligibility files,
    // as they should show "processed" quickly without asking admin to reload
    // Will replace with a more hook oriented version once we redo the eligibility process

    // Set up an interval for refetching
    const intervalId = setInterval(() => {
      handleRefresh('active');
      handleRefresh('historical');
    }, 15000); // 15 seconds

    // Stop the interval after 1 minute
    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000); // 1 minute
  };

  const handleReplaceEligibility = (id: number, date: string) => {
    seteligibilityId(id);
    setUploadLiveTime(date);
    setShowModal(true);
  };

  const handleDownloadMembers = () => {
    dispatch(downloadEligibilityMemebersRequest({ clientId }));
  };

  const handleTranferEligibility = (id: number) => {
    dispatch(transferEligibilityReportRequest({ clientId, eligibilityId: id }));
  };

  const fetchLists = () => {
    if (clientId && clientId !== undefined) {
      dispatch(getClientDetailsRequest({ clientId }));
      if (userType === UserRole.admin) {
        dispatch(fetchEligibiltyListRequest({ clientId, active: true }));
        dispatch(fetchEligibiltyListRequest({ clientId, scheduled: true }));
        dispatch(fetchEligibiltyListRequest({ clientId, historical: true }));
      } else {
        dispatch(fetchEligibiltyListRequest({ clientId }));
      }
    }
  };

  const handleRefresh = (type: string) => {
    const typeTable =
      type === 'active' ? 'active' : type === 'scheduled' ? 'scheduled' : type === 'historical' ? 'historical' : '';
    if (clientId) {
      dispatch(fetchEligibiltyListRequest({ clientId, [typeTable]: true }));
    }
  };

  const sorting = (pre: any, next: any) => {
    return next.id - pre.id;
  };

  const datewiseSoarting = (pre: any, next: any) => {
    const datePrev: any = new Date(pre.billing_cycle);
    const dateNext: any = new Date(next.billing_cycle);
    return datePrev - dateNext;
  };

  const completedDatewiseSoarting = (pre: any, next: any) => {
    const datePrev: any = new Date(pre.completed_at);
    const dateNext: any = new Date(next.completed_at);
    return dateNext - datePrev;
  };

  const handleFileUploadModelToggle = () => {
    setShowModal(true);
    seteligibilityId(0);
    const isDefaultTime =
      eligibiltyActiveList?.data?.length === 0 &&
      eligibiltyScheduledList?.data?.length === 0 &&
      eligibiltyList?.data?.length === 0;
    if (isDefaultTime) {
      const date = moment.tz('US/Central').set({ hour: 4, minute: 0, second: 0 }).local().format('YYYY-MM-DD HH:mm:ss');
      setUploadLiveTime(date);
    } else {
      if (eligibiltyScheduledList?.data?.length) {
        setUploadLiveTime(eligibiltyScheduledList.data.sort(sorting)[0].scheduled_at);
      } else if (eligibiltyActiveList?.data?.length) {
        setUploadLiveTime(eligibiltyActiveList.data.sort(sorting)[0].scheduled_at);
      } else if (eligibiltyList?.data?.length) {
        setUploadLiveTime(eligibiltyList.data.sort(sorting)[0].scheduled_at);
      }

      setEligibilityActiveAndScheduleList([...eligibiltyActiveList?.data, ...eligibiltyScheduledList?.data]);
    }
  };

  const handleFileImport = () => {
    let linkInput: any = {
      clientId,
    };
    const payload: any = {
      billing_cycle: scheduledDate,
    };
    dispatch(postEligibilityRequest({ linkInput, payload }));
  };

  const handleClientChange = (data: any) => {
    setClientId(data.clientId);
  };

  const handleSave = () => {
    const payload = {
      schedule_frequency: scheduleFrequency,
      schedule_ordinal: scheduleOrdinal,
      schedule_hour: scheduleHour,
    };
    if (clientId) {
      updateClient.mutate(payload, {
        onSuccess: () => {
          setIsScheduleModified(false);
          saveLocationPath !== '' && history.push(saveLocationPath);
          toast.success('Saved processing schedule');
        },
        onError: (error: any) => {
          toast.error(error.response.data.description);
        },
      });
    } else {
      toast.error('No client loaded');
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchLists();
    }
  }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.client) {
      return;
    }

    const clientDetails = props.client;

    setScheduleFrequency(clientDetails.schedule_frequency);
    setScheduleOrdinal(clientDetails.schedule_ordinal);
    setScheduleHour(clientDetails.schedule_hour);
  }, [clientId, props.client]);

  // This gets its own useEffect because it doesn't like to update when set
  // immediately after its dependencies get set in the other useEffect.
  useEffect(() => {
    setScheduleText(getScheduleText());
  }, [scheduleFrequency, scheduleOrdinal, scheduleHour]);

  // Technically, you could allow translation between 1/Sunday, 2/Monday, etc.,
  // but I think this is the better UX: just set to the first available option.
  useEffect(() => {
    if (scheduleFrequency === 'weekly') {
      setScheduleOrdinal(0);
    } else if (scheduleFrequency === 'monthly') {
      setScheduleOrdinal(1);
    }
  }, [scheduleFrequency]);

  useEffect(() => {
    if (eligibiltyList?.data) {
      let future: any = [];
      let historical: any = [];
      eligibiltyList.data.forEach((dataset: any) => {
        if (moment(dataset.billing_cycle, 'YYYY-MM-DD').isBefore(moment()) || dataset.completed_at != null) {
          historical.push(dataset);
        } else {
          future.push(dataset);
        }
      });
      setScheduleList(future);
      setHistoricalList(historical);
      // this.future_datasets = future;
      // this.historical_datasets = historical;
    }
  }, [eligibiltyList]);

  useEffect(() => {
    if (removeEligibility?.status === 200) {
      fetchLists();
    }
    if (removeEligibilityError?.data) {
      toast.error(removeEligibilityError?.data?.description);
    }
  }, [removeEligibility, removeEligibilityError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (eligibility?.status === 200) {
      fetchLists();
      setShowModal(false);
      dispatch(fetchEligibiltyFailure({ error: '' }));
    }
  }, [eligibility]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (eligibilityError?.data) {
      toast.error(eligibilityError?.data?.description);
    }
  }, [eligibilityError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processEligibility?.status === 200) {
      fetchLists();
    } else {
      if (processEligibilityError && processEligibilityError?.data) {
        toast.error(processEligibilityError?.data?.description);
      }
    }
  }, [processEligibility, processEligibilityError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateEligibility?.status === 200) {
      fetchLists();
      setShowModal(false);
    }
  }, [updateEligibility]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateEligibilityError?.data) {
      toast.error(updateEligibilityError?.data?.description);
    }
  }, [updateEligibilityError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (downloadEligibilityMembers?.data) {
      var downloadLink = document.createElement('a');
      var blob = new Blob(['\ufeff', downloadEligibilityMembers?.data]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${props.client?.name
        .replace(' ', '_')
        .toLowerCase()}_${moment().format('YYYY-MM-DD')}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      toast.success(`file ${downloadLink.download} downloaded successfully`);
    }
  }, [downloadEligibilityMembers, downloadEligibilityMembersError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.transferEligibilityReport?.status === 200) {
      fetchLists();
    }
  }, [props.transferEligibilityReport]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let monthArray = [];
    let month: any = startdate.month();
    const lastMonth: number = month + 11;
    for (month; month < lastMonth; month++) {
      monthArray.push(startdate.add(1, 'M').format('YYYY-MM-DD'));
    }
    setLiveDates(monthArray);
    setscheduledDate(monthArray[0]);
    return () => {
      dispatch(updateEligibiltyFailure({ updateEligibilityError: '' }));
      dispatch(processEligibilityFailure({ processEligibilityError: '' }));
      dispatch(removeEligibiltyFailure({ removeEligibilityError: '' }));
      dispatch(postEligibilityFailure({ eligibilityError: '' }));
      dispatch(downloadEligibilityMemebersSuccess({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getOrdinal(n: number): string {
    let ord = 'th';
    if (n % 10 === 1 && n % 100 !== 11) {
      ord = 'st';
    } else if (n % 10 === 2 && n % 100 !== 12) {
      ord = 'nd';
    } else if (n % 10 === 3 && n % 100 !== 13) {
      ord = 'rd';
    }
    return n + ord;
  }

  function getScheduleText() {
    switch (scheduleFrequency) {
      case 'daily':
        return `Daily @ ${scheduleHour}:00`;
      case 'weekly':
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return `Weekly on ${days[scheduleOrdinal]} @ ${scheduleHour}:00`;
      case 'monthly':
        return `Monthly on the ${scheduleOrdinal === -1 ? 'last day of the month' : getOrdinal(scheduleOrdinal)} @ ${scheduleHour}:00`;
    }
    console.log(`Not found: ${scheduleFrequency}, ${scheduleOrdinal}, ${scheduleHour}:00`);
    return 'Error: see console';
  }

  return (
    <div>
      <RouteLeavingGuard
        when={isScheduleModified}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.ELIGIBILTY) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          handleSave();
          setSaveLocationPath(path);
          setIsScheduleModified(false);
        }}
      />
      {pending && <Spinner />}
      {userType === UserRole.client_admin ? (
        <Header
          disableText={true}
          heading={
            <div className="w-full px-2 text-xl">
              <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Group</label>
              <div className="relative w-3/4">
                <ClientAdminGroupDropDown type="eligibility" handleChange={handleClientChange} />
              </div>
            </div>
          }
        />
      ) : (
        <Header heading={<HeadBack clientName={props.client?.name || null} />} />
      )}
      {userType === UserRole.admin && (
        <SwitchBar heading={HeaderOptions} position={3} isDisabled={clientId ? false : true} />
      )}
      {client?.packages?.length ? (
        <div className="max-window-height pb-32 overflow-y-auto ">
          <div className="flex justify-end py-7 px-7">
            {userType === UserRole.admin && (
              <button type="button" className="px-7 py-1 text-sm btn-darkblue mr-4" onClick={handleDownloadMembers}>
                DOWNLOAD MEMBERS
              </button>
            )}
            <a
              className="px-7 py-1 text-sm btn-darkblue mr-4"
              onClick={() => {
                // Eligibility-File-Master.xlsx

                toast.success(`file Eligibility-File-Master.xlsx downloaded successfully`);
              }}
              href={`${process.env.REACT_APP_DOMAIN_NAME}/clients/eligibility_template/xlsx`}
              rel="noreferrer"
            >
              DOWNLOAD EXCEL FILE
            </a>
            <a
              className="px-7 py-1 text-sm btn-darkblue"
              onClick={() => {
                toast.success(`file Eligibility-File-Master.csv downloaded successfully`);
              }}
              href={`${process.env.REACT_APP_DOMAIN_NAME}/clients/eligibility_template/csv`}
              rel="noreferrer"
            >
              DOWNLOAD CSV FILE
            </a>
          </div>
          {client?.eligibility_config?.transfer_type === 'file' && userType === UserRole.admin && (
            <div>
              <div className="text-2xl py-3 px-7 blue-primary">
                Force file import and schedule:
                <div className="flex">
                  <div className="w-1/4">
                    <DropDown Label={''} value={scheduledDate} setValue={setscheduledDate}>
                      {liveDates.length && liveDates.map((date: any) => <option value={date}>{date}</option>)}
                    </DropDown>
                  </div>
                  <div className="flex items-center">
                    <button className="px-7 py-1 text-sm btn-darkblue mx-5" onClick={handleFileImport}>
                      IMPORT FILE
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-2xl pt-3 px-7 blue-primary">Eligibility file schedule</div>
              <div className="px-7 flex gap-4">
                <div className="pb-4 flex-row">
                  <span
                    className="font-bold underline text-blue-900 hover:text-blue-700 cursor-pointer"
                    onClick={() => {
                      setScheduleModalShowing(true);
                    }}
                  >
                    {scheduleText}
                  </span>
                  {isScheduleModified && (
                    <button className="px-7 py-1 text-sm btn-darkblue mx-5" onClick={handleSave}>
                      SAVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {showFixModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-4xl min-w-2xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-162.5 overflow-y-auto">
                    <div className="flex items-start justify-between p-5">
                      <div className="text-2xl px-10 pt-10 blue-primary">Fix Eligibility File</div>
                    </div>
                    <div className="relative px-16 py-1 flex-auto">
                      <p className="text-sm leading-relaxed h-full mb-4 text-gray-dark text-base font-normal">
                        Drag the fields on the right to match the fields on the left.
                      </p>
                    </div>

                    <div className="flex justify-between p-0 px-10 w-full items-center">
                      <div className="border-b w-full gray-border-line text-gray-dark text-base font-normal py-2 px-3 mx-5 my-2 mr-10">
                        FIELDS IN FILE
                      </div>
                      <div className="h-10 w-8 p-4"></div>
                      <div className="border-b w-full gray-border-line text-gray-dark text-base font-normal py-2 px-3 mx-5 my-2 ml-10">
                        REQUIRED FIELDS
                      </div>
                    </div>
                    <div className="grid grid-cols-11 gap-4">
                      <div className="col-span-5 flex justify-center items-center">
                        <div className="w-4/5 ml-14">
                          <DragDropContext
                            onBeforeCapture={onBeforeCapture}
                            onBeforeDragStart={onBeforeDragStart}
                            onDragStart={onDragStart}
                            onDragUpdate={onDragUpdate}
                            onDragEnd={onDragEnd}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="grid grid-cols-1 gap-1"
                                >
                                  {eligibilityFiledsArray.map((item: any, index: number) => (
                                    <Draggable draggableId={item.name} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div
                                            className={`${
                                              index === 6 ? 'mt-20' : ''
                                            } flex justify-between items-center font-bold text-base py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm my-1 h-10 w-full`}
                                          >
                                            {item.name}
                                            {item.name !== '' && (
                                              <img
                                                className="w-4 transform rotate-180"
                                                src={Handler}
                                                alt="left Arrow"
                                              ></img>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </div>
                      <div className="col-span-1 flex justify-center">
                        <div className="">
                          {eligibilityFiledsArray[0].id === 1 ? (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[0].id === 1 ? (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[2].id === 3 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[3].id === 4 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[4].id === 5 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[5].id === 6 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[0].id === 1 ? (
                            <img className="h-10 w-8 mt-22" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[0].id === 1 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                          {eligibilityFiledsArray[0].id === 1 ? (
                            <img className="h-10 w-8 mt-3" src={GreenArrow} alt="left Arrow"></img>
                          ) : (
                            <img className="h-10 w-8 mt-2" src={GreenArrow} alt="left Arrow" />
                          )}
                        </div>
                      </div>
                      <div className="col-span-5 flex justify-center">
                        <div className="w-4/5 mr-12 grid grid-cols-1 gap-1">
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm  h-10 w-full">
                            GroupID
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm  h-10 w-full">
                            MemberID
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light  h-10 w-full">
                            First Name
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm  h-10 w-full">
                            Last Name
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm  h-10 w-full">
                            Email
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm  h-10 w-full">
                            MobilePhone
                          </div>
                          <div className="border-b w-full gray-border-line text-gray-dark text-base font-normal py-2 px-3 mx-5 my-2 ml-10">
                            OPTIONAL FIELDS
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm h-10 w-full">
                            DOB
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm h-10 w-full">
                            Gender
                          </div>
                          <div className="font-bold  py-2 px-3 rounded-md text-gray-dark bg-gray-background-light text-sm h-10 w-full">
                            Time Zone
                          </div>
                          <div className="w-full">
                            <div className="mx-5 my-2 ml-10 mt-10">
                              <button
                                className=" text-sm   px-3 py-2 text-white text-center justify-center rounded-md flex items-center  bg-gray-light w-24"
                                onClick={() => setFixModal(false)}
                              >
                                CANCEL
                              </button>
                            </div>
                            <div className="mx-5 my-2 ml-10">
                              <button className=" btn-darkblue text-sm  px-3 py-2 text-white text-center justify-center rounded-md flex items-center w-24">
                                SAVE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          {userType === UserRole.admin ? (
            <div className="py-3">
              <h3 className="text-2xl blue-primary px-7">Active Eligibility List</h3>
              <EligibilityTableList
                data={eligibiltyActiveList?.data?.sort(sorting)}
                clientId={clientId}
                handleEligibilityFileViewModel={handleEligibilityFileViewModel}
                isACtiveAndScheduleShow={true}
                handleDeleteEligibility={handleDeleteEligibilityModel}
                handleProcessEligibility={handleProcessEligibility}
                handleReplaceEligibility={handleReplaceEligibility}
                tableType={'active'}
                handleTranferEligibility={handleTranferEligibility}
                handleRefresh={handleRefresh}
              />
            </div>
          ) : null}
          <div className="text-2xl py-3 px-7 pt-10 blue-primary">
            <div className="flex item-center">
              <h3>Scheduled Eligibility List</h3>
              <div className="flex item-center">
                <button className="px-7 py-1 h-full text-sm btn-darkblue mx-5" onClick={handleFileUploadModelToggle}>
                  UPLOAD
                </button>
              </div>
            </div>
            {showModal && (
              <EligibilityFileUpload
                clientId={clientId}
                handleModel={setShowModal}
                handleUpload={fetchLists}
                eligibilityId={eligibilityId}
                handleValidationViewModel={handleValidationViewModel}
                uploadLiveTime={uploadLiveTime}
                display={isEligibilityFileViewModel}
                eligibilityActiveAndScheduleList={eligibilityActiveAndScheduleList}
              />
            )}
          </div>
          <EligibilityTableList
            data={
              userType === UserRole.admin
                ? eligibiltyScheduledList?.data?.sort(datewiseSoarting)
                : scheduleList?.sort(datewiseSoarting)
            }
            clientId={clientId}
            handleEligibilityFileViewModel={handleEligibilityFileViewModel}
            isACtiveAndScheduleShow={true}
            handleDeleteEligibility={handleDeleteEligibilityModel}
            handleProcessEligibility={handleProcessEligibility}
            handleReplaceEligibility={handleReplaceEligibility}
            tableType={'scheduled'}
            handleTranferEligibility={handleTranferEligibility}
            handleRefresh={handleRefresh}
          />
          <div className="text-2xl py-3 px-7 pt-10 blue-primary">Historical Eligibility Lists</div>
          <EligibilityTableList
            data={
              userType === UserRole.admin
                ? eligibiltyList?.data?.sort(completedDatewiseSoarting)
                : historicalList?.sort(completedDatewiseSoarting)
            }
            clientId={clientId}
            handleEligibilityFileViewModel={handleEligibilityFileViewModel}
            tableType={'historical'}
            handleRefresh={handleRefresh}
          />
          {isEligibilityFileViewModel && (
            <EligibilityFileViewModel
              eligibilityId={eligibilityId}
              handleEligibilityFileViewModel={handleEligibilityFileViewModel}
              eligibilityValidationData={eligibilityValidationData}
              tableType={tableType}
              clientId={clientId}
            />
          )}
        </div>
      ) : !clientDetialsPending && userType === UserRole.admin ? (
        <div className="text-center p-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            You must create a package to begin importing members to this client.
          </h3>
        </div>
      ) : null}
      <Modal
        isModel={isDelete}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Remove Scheduled Eligibility Data?
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to remove this scheduled eligibility transfer?
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDeleteEligibility}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  REMOVE
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        }
      />
      <Dialog
        open={scheduleModalShowing}
        onClose={function () {
          setScheduleModalShowing(false);
        }}
      >
        <div className="p-6 flex flex-col">
          <h2 className="text-2xl px-10 pb-12 blue-primary">Schedule Setting for {props.client?.name}</h2>
          <DropDown
            Label={'Frequency'}
            labelClass="font-medium text-normal"
            setValue={setScheduleFrequency}
            value={scheduleFrequency}
          >
            <option value={'monthly'}>Monthly</option>
            <option value={'weekly'}>Weekly</option>
            <option value={'daily'}>Daily</option>
          </DropDown>
          {scheduleFrequency === 'monthly' && (
            <DropDown
              Label={'Day Of the Month'}
              labelClass="font-medium text-normal"
              setValue={setScheduleOrdinal}
              value={scheduleOrdinal}
            >
              <>
                {Array.from(Array(28).keys()).map((i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </>
              <option key={-1} value={-1}>
                Last Day of the Month
              </option>
            </DropDown>
          )}
          {scheduleFrequency === 'weekly' && (
            <DropDown
              Label={'Day Of the Week'}
              labelClass="font-medium text-normal"
              setValue={setScheduleOrdinal}
              value={scheduleOrdinal}
            >
              <option value={0}>Sunday</option>
              <option value={1}>Monday</option>
              <option value={2}>Tuesday</option>
              <option value={3}>Wednesday</option>
              <option value={4}>Thursday</option>
              <option value={5}>Friday</option>
              <option value={6}>Saturday</option>
            </DropDown>
          )}
          <DropDown Label={'Time of Day (CT)'} value={scheduleHour} setValue={setScheduleHour}>
            <>
              {Array.from(Array(24).keys()).map((i) => (
                <option key={i} value={i}>
                  {i}:00
                </option>
              ))}
            </>
          </DropDown>
          <span className="flex flex-row space-x-4 justify-end pt-12">
            <button
              className={'px-5 py-1 text-white rounded-full font-medium stacked-bar-blue-400'}
              onClick={() => {
                setScheduleText(getScheduleText());
                setScheduleModalShowing(false);
                setIsScheduleModified(true);
              }}
            >
              OK
            </button>
          </span>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    eligibility: state.eligibility.eligibility,
    eligibilityError: state.eligibility.eligibilityError,
    eligibiltyActiveList: state.eligibility.eligibiltyActiveList,
    eligibiltyScheduledList: state.eligibility.eligibiltyScheduledList,
    eligibiltyList: state.eligibility.eligibiltyList,
    removeEligibility: state.eligibility.removeEligibility,
    removeEligibilityError: state.eligibility.removeEligibilityError,
    processEligibility: state.eligibility.processEligibility,
    processEligibilityError: state.eligibility.processEligibilityError,
    updateEligibility: state.eligibility.updateEligibility,
    updateEligibilityError: state.eligibility.updateEligibilityError,
    downloadEligibilityMembers: state.eligibility.downloadEligibilityMembers,
    downloadEligibilityMembersError: state.eligibility.downloadEligibilityMembersError,
    transferEligibilityReport: state.eligibility.transferEligibilityReport,
    transferEligibilityReportError: state.eligibility.transferEligibilityReportError,
    client: state.Client.ClientDetials?.data || null,
    pending: state.eligibility.pending || state.Client.pending,
    clientDetialsPending: state.Client.pending,
  };
};

export default connect(mapStateToProps)(EligibilityFile);
