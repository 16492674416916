import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENT_COUNT_REQUEST,
  FETCH_CLIENT_COUNT_SUCCESS,
  FETCH_CLIENT_COUNT_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_REPORTING_REQUEST,
  FETCH_REPORTING_SUCCESS,
  FETCH_REPORTING_FAILURE,
  POST_AVAILABLE_CREDIT_REQUEST,
  POST_AVAILABLE_CREDIT_SUCCESS,
  POST_AVAILABLE_CREDIT_FAILURE,
  GET_ADMIN_DETAILS_REQUEST,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_ADMIN_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE,
  POST_CREATE_PACKAGE_REQUEST,
  POST_CREATE_PACKAGE_SUCCESS,
  POST_CREATE_PACKAGE_FAILURE,
  POST_UPDATE_PACKAGE_REQUEST,
  POST_UPDATE_PACKAGE_SUCCESS,
  POST_UPDATE_PACKAGE_FAILURE,
  POST_UPDATE_PACKAGE_PRODUCT_REQUEST,
  POST_UPDATE_PACKAGE_PRODUCT_SUCCESS,
  POST_UPDATE_PACKAGE_PRODUCT_FAILURE,
  POST_CREATE_PACKAGE_PRODUCT_REQUEST,
  POST_CREATE_PACKAGE_PRODUCT_SUCCESS,
  POST_CREATE_PACKAGE_PRODUCT_FAILURE,
  POST_ADD_COMMS_REQUEST,
  POST_ADD_COMMS_SUCCESS,
  POST_ADD_COMMS_FAILURE,
  FETCH_WEB_APP_URL_SETTING_REQUEST,
  FETCH_WEB_APP_URL_SETTING_SUCCESS,
  FETCH_WEB_APP_URL_SETTING_FAILURE,
  POST_FORCE_CHECK_IN_REQUEST,
  POST_FORCE_CHECK_IN_SUCCESS,
  POST_FORCE_CHECK_IN_FAILURE,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_PRODUCT_TEMPLATE_REQUEST,
  FETCH_PRODUCT_TEMPLATE_SUCCESS,
  FETCH_PRODUCT_TEMPLATE_FAILURE,
  REMOVED_COMMS_REQUEST,
  REMOVED_COMMS_SUCCESS,
  REMOVED_COMMS_FAILURE,
  POST_CREATE_CLIENT_REQUEST,
  POST_CREATE_CLIENT_SUCCESS,
  POST_CREATE_CLIENT_FAILURE,
  POST_UPDATE_CLIENT_REQUEST,
  POST_UPDATE_CLIENT_SUCCESS,
  POST_UPDATE_CLIENT_FAILURE,
  FETCH_EMAIL_TEMPLATE_REQUEST,
  FETCH_EMAIL_TEMPLATE_SUCCESS,
  FETCH_EMAIL_TEMPLATE_FAILURE,
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_INCENTIVES_REQUEST,
  FETCH_INCENTIVES_SUCCESS,
  FETCH_INCENTIVES_FAILURE,
  FETCH_SYSTEM_MESSAGES_REQUEST,
  FETCH_SYSTEM_MESSAGES_SUCCESS,
  FETCH_SYSTEM_MESSAGES_FAILURE,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  DELETE_CLIENT_DETAILS_REQUEST,
  DELETE_CLIENT_DETAILS_SUCCESS,
  DELETE_CLIENT_DETAILS_FAILURE,
  FETCH_APP_SETTINGS_FAILURE,
  FETCH_APP_SETTINGS_REQUEST,
  FETCH_APP_SETTINGS_SUCCESS,
  FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE,
  FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST,
  FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS,
  POST_SUBSCRIPTIONS_FAILURE,
  POST_SUBSCRIPTIONS_REQUEST,
  POST_SUBSCRIPTIONS_SUCCESS,
  REMOVE_SUBSCRIPTIONS_FAILURE,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  REMOVE_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTIONS_REQUEST,
  UPDATE_SUBSCRIPTIONS_SUCCESS,
  GET_DEFAULT_SUBSCRIPTIONS_REQUEST,
  GET_DEFAULT_SUBSCRIPTIONS_SUCCESS,
  GET_DEFAULT_SUBSCRIPTIONS_FAILURE,
} from './actionTypes';

import { ClientsActions, ClientsState } from './types';

const initialState: ClientsState = {
  pending: false,
  clientPending: false,
  clients: [],
  clientCount: [],
  admindetail: [],
  clientDetials: [],
  tags: [],
  AddComms: [],
  Campaign: [],
  WebAppUrlSetting: null,
  GetProductTemplate: null,
  incentives: [],
  campaigns: [],
  systemMessages: [],
  Reporting: [],
  deletePackage: [],
  deletePackageError: null,
  deleteClient: [],
  deleteClientError: null,
  error: null,
  defaultSubscriptions: [],
  defaultSubscriptionsError: null,
};

const Reducer = (state = initialState, action: ClientsActions) => {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        clientPending: true,
      };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clientPending: false,
        clients: action.payload,
        error: null,
      };
    case FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        clientPending: false,
        error: action.payload.error,
      };
    case FETCH_CLIENT_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLIENT_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        clientCount: action.payload.CLIENT_COUNT,
        error: null,
      };
    case FETCH_CLIENT_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        tags: action.payload,
        error: null,
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_AVAILABLE_CREDIT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_AVAILABLE_CREDIT_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case POST_AVAILABLE_CREDIT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        ClientDetials: action.payload,
        error: null,
      };
    case GET_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteClient: action.payload,
        deleteClientError: null,
      };
    case DELETE_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        deleteClientError: action.payload,
      };
    case POST_CREATE_PACKAGE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_CREATE_PACKAGE_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case POST_CREATE_PACKAGE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_UPDATE_PACKAGE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case POST_UPDATE_PACKAGE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_UPDATE_PACKAGE_PRODUCT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_UPDATE_PACKAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case POST_UPDATE_PACKAGE_PRODUCT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_CREATE_PACKAGE_PRODUCT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_CREATE_PACKAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        pending: false,
        LOGINs: action.payload,
        error: null,
      };
    case POST_CREATE_PACKAGE_PRODUCT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_ADD_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_ADD_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        AddComms: action.payload,
        error: null,
      };
    case POST_ADD_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_WEB_APP_URL_SETTING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_WEB_APP_URL_SETTING_SUCCESS:
      return {
        ...state,
        pending: false,
        WebAppUrlSetting: action.payload,
        error: null,
      };
    case FETCH_WEB_APP_URL_SETTING_FAILURE:
      return {
        ...state,
        pending: false,
        WebAppUrlSetting: null,
        error: action.payload.error,
      };
    case POST_FORCE_CHECK_IN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_FORCE_CHECK_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        ForceCheckIn: action.payload,
        error: null,
      };
    case POST_FORCE_CHECK_IN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        pending: false,
        Campaign: action.payload.Campaign,
        error: null,
      };
    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        GetProductTemplate: action.payload,
        error: null,
      };
    case FETCH_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case REMOVED_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVED_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        removeComms: action.payload,
        error: null,
      };
    case REMOVED_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        removeComms: null,
        error: action.payload.error,
      };
    case POST_CREATE_CLIENT_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case POST_CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        pending: false,
        createClient: action.payload,
        error: null,
      };
    case POST_CREATE_CLIENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        pending: false,
        updateClient: action.payload,
        error: null,
      };
    case POST_UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        emailTemplate: action.payload,
        error: null,
      };
    case FETCH_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    case FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        pending: false,
        campaigns: action.payload,
        error: null,
      };
    case FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_INCENTIVES_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case FETCH_INCENTIVES_SUCCESS:
      return {
        ...state,
        pending: false,
        incentives: action.payload,
        error: null,
      };
    case FETCH_INCENTIVES_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_SYSTEM_MESSAGES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SYSTEM_MESSAGES_SUCCESS:
      return {
        ...state,
        pending: false,
        systemMessages: action.payload,
      };
    case FETCH_SYSTEM_MESSAGES_FAILURE:
      return {
        ...state,
        pending: false,
        experiencesFavoritesError: action.payload,
      };
    case FETCH_REPORTING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_REPORTING_SUCCESS:
      return {
        ...state,
        pending: false,
        Reporting: action.payload,
        error: null,
      };
    case FETCH_REPORTING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_PACKAGE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        pending: false,
        deletePackage: action.payload,
        deletePackageError: null,
      };
    case DELETE_PACKAGE_FAILURE:
      return {
        ...state,
        pending: false,
        deletePackageError: action.payload,
      };
    case FETCH_APP_SETTINGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        pending: false,
        AppSettings: action.payload,
        error: null,
      };
    case FETCH_APP_SETTINGS_FAILURE:
      return {
        ...state,
        pending: false,
        AppSettings: null,
        error: action.payload.error,
      };
    case FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS:
      return {
        ...state,
        pending: false,
        ExternalProviderPlans: action.payload.ExternalProviderPlans,
        error: null,
      };
    case FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        newResource: action.payload,
        error: null,
      };
    case POST_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case UPDATE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateSubscriptions: action.payload,
        error: null,
      };
    case UPDATE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateSubscriptions: null,
        error: action.payload.error,
      };
    case REMOVE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveSubscriptions: action.payload,
        error: null,
      };
    case REMOVE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveSubscriptions: null,
        error: action.payload.error,
      };
    case GET_DEFAULT_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_DEFAULT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultSubscriptions: action.payload,
        error: null,
      };
    case GET_DEFAULT_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        defaultSubscriptionsError: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
