import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';

import {
  fetchAllConnectionsSuccess,
  fetchAllConnectionsFailure,
  fetchAllConnectionsCountFailure,
  fetchAllConnectionsCountSuccess,
} from './actions';
import { FETCH_ALL_CONNECTIONS_COUNT_REQUEST, FETCH_ALL_CONNECTIONS_REQUEST } from './actionTypes';

function* fetchAllConnectionsSaga(action: any): any {
  try {
    yield delay(50);
    const res = Api.getAllConnections(action.payload);
    const response = yield call(res);
    yield put(fetchAllConnectionsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchAllConnectionsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchAllConnectionsCountSaga(action: any): any {
  try {
    yield delay(50);
    const res = Api.getAllConnectionCounts(action.payload);
    const response = yield call(res);

    yield put(fetchAllConnectionsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchAllConnectionsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* MembersSaga() {
  yield all([
    takeLatest(FETCH_ALL_CONNECTIONS_REQUEST, fetchAllConnectionsSaga),
    takeLatest(FETCH_ALL_CONNECTIONS_COUNT_REQUEST, fetchAllConnectionsCountSaga),
  ]);
}
export default MembersSaga;
