import React from 'react';
import Header from '../../components/common/global-top-bar';
import { SectionLabel } from '../../components/common';
import { VerticalBarChart } from '../../components/charts';
import CalendarPaginator from '../../components/calender-paginator';

const Stories: React.FunctionComponent = (): JSX.Element => {
  const data = [
    ['Topic', '', { role: 'annotation' }],
    ['Topic', 8175500, '8175500'],
    ['Topic', 7155300, '7155300'],
    ['Topic', 6144000, '6144000'],
    ['Topic', 5944000, '5944000'],
    ['Topic', 4792200, '4792200'],
    ['Topic', 4091000, '4091000'],
    ['Topic', 3095900, '3095900'],
    ['Topic', 2695400, '2695400'],
    ['Topic', 1695100, '1695100'],
    ['Topic', 998000, '998000'],
    ['Topic', 797000, '797000'],
    ['Topic', 696000, '696000'],
    ['Topic', 526000, '526000'],
    ['Topic', 525000, '525000'],
    ['Topic', 524000, '524000'],
  ];

  const topicsOptions = {
    title: '',
    chartArea: { width: '70%', height: '90%' },
    colors: ['#1D1183'],
    legend: { position: 'none' },
    hAxis: {
      title: '',
      minValue: 0,
    },
    vAxis: {
      textStyle: {
        color: '#4e4e4e',
        fontSize: 18,
        bold: false,
      },
    },
  };

  const lessonsOptions = {
    title: '',
    chartArea: { width: '80%', height: '90%' },
    colors: ['#173564'],
    legend: { position: 'none' },
    hAxis: {
      title: '',
      minValue: 0,
    },
    vAxis: {
      textPosition: 'none',
    },
  };

  return (
    <div>
      <Header userName={'Jason'} heading={'Topics'} />
      <CalendarPaginator />
      <div className="max-window-height pb-32 overflow-y-auto">
        <div className="flex justify-evenly ">
          <div className="px-7">
            <SectionLabel Label="Members Following Each Topic" />
            <VerticalBarChart options={topicsOptions} data={data} />
          </div>
          <div className="px-7">
            <div className="w-full px-7">
              <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark flex">
                <p>Listener Lessons</p>
                <p className="text-gray-light font-normal pl-2">(Qty)</p>
              </div>
            </div>
            <VerticalBarChart options={lessonsOptions} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
