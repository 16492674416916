import React from 'react';
import { Redirect, Route } from 'react-router';
import { NavigationBar } from '../containers';
import useAuth from '../hooks/useAuth';

type props = {
  exact: boolean;
  path: string;
  component: any;
  role: string;
  selectedId: number;
};

const PrivateRoute: React.FunctionComponent<props> = ({
  exact,
  path,
  component: Component,
  role: allowedRole,
  selectedId,
}): JSX.Element => {
  const { authToken, setAuthToken, roleType, setRoleType } = useAuth();
  // api-handler.ts::authenticateWithToken sets localStorage['authorization_token'] outside the react context
  // this is a bandaid to fix the case where it sets auth token outside the scope of auth-context.tsx
  const currentLocalStorageAuthToken = localStorage.getItem('authorization_token');
  if (!authToken && currentLocalStorageAuthToken) {
    setAuthToken(currentLocalStorageAuthToken);
    setRoleType(localStorage.getItem('role_type'));
  }
  const isAllowedToSeePage = authToken && roleType === allowedRole;

  return isAllowedToSeePage ? (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <div className="flex  max-h-screen">
          <div className="flex">
            <NavigationBar selectedId={selectedId} />
          </div>
          <div className="flex-1 max-h-screen pl-64">
            <Component />
          </div>
        </div>
      )}
    />
  ) : (
    // NOTE: this redirect logs the user out, it seems to be how Alpine implemented session timeout
    <Redirect to="/" />
  );
};

export default PrivateRoute;
