import { Button, PencilIcon } from '@kindlyhuman/component-library';
import Header from '../../components/common/global-top-bar';
import { Redirect, useRedirects } from '../../hooks/useRedirects';
import { useState } from 'react';
import { LinksModal } from './links-modal';
import { toast } from 'react-toastify';
export interface RedirectModalDTO extends Redirect {
  isNew?: boolean;
}

export const LinksPage = () => {
  const [currentLink, setCurrentLink] = useState<RedirectModalDTO>();
  const { data: redirects } = useRedirects();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  return (
    <div>
      <Header heading="Links" />
      <div className="my-4 flex justify-end pr-8">
        <Button variant="primary" onClick={() => setCurrentLink({ hash: '', key: '', value: '', isNew: true })}>
          Add Link
        </Button>
      </div>
      <div className="flex justify-center pr-8">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="text-center">Destination</th>
              <th className="text-center">Slug</th>
              <th className="text-center">Link</th>
              <th className="text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {redirects?.map((redirect) => (
              <tr key={redirect.hash}>
                <td className="text-center">{redirect.key}</td>
                <td className="text-center">{redirect.hash}</td>
                <td className="text-center">
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      copyToClipboard(
                        `${process.env.REACT_APP_DOMAIN_NAME}${process.env.REACT_APP_API_VERSION3}/redirects/r/${redirect.hash}`,
                      )
                    }
                  >{`${process.env.REACT_APP_DOMAIN_NAME}${process.env.REACT_APP_API_VERSION3}/redirects/r/${redirect.hash}`}</div>{' '}
                </td>
                <td className="text-center">
                  <button onClick={() => setCurrentLink(redirect)}>
                    <PencilIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <LinksModal redirect={currentLink!} open={!!currentLink} onClose={() => setCurrentLink(undefined)} />
    </div>
  );
};
