import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { CustomInput } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './clients-constants';
import HeadBack from './redirect';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { toast } from 'react-toastify';
import {
  deleteClientAdminAccessRequest,
  deleteClientAdminAccessSuccess,
  deleteRevokeClientAdminAccessRequest,
  deleteRevokeClientAdminAccessSuccess,
  fetchClientAdminstratorsRequest,
  postInviteClientAdminRequest,
  postInviteViaEmailRequest,
  postInviteViaEmailSuccess,
} from './admin-redux/actions';
import moment from 'moment';
import Modal from '../../components/common/modal';
import { getClientDetailsRequest } from './clients-redux/actions';
type props = {
  adminList: any;
  successEmail: any;
  revokeAdmin: any;
  deleteAdmin: any;
  client: any;
  pending: any;
};

const Admin: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const clientId = localStorage.getItem('clientid');
  const [emailAddress, setemailAddress] = useState('');
  const [popUp, setpopUp] = useState<any>({});

  useEffect(() => {
    dispatch(fetchClientAdminstratorsRequest({ clientId }));
    dispatch(getClientDetailsRequest({ clientId }));
  }, []);

  useEffect(() => {
    if (props.successEmail === 200) {
      dispatch(postInviteViaEmailSuccess({ inviteEmail: {} }));
      setemailAddress('');
    }
  }, [props.successEmail]);

  const handleAlert = (payload: any) => {
    const { id, email_address, type } = payload;
    if (type === 'remove') {
      dispatch(
        deleteClientAdminAccessRequest({
          id,
          clientId,
          payload: {
            email_address,
            client_id: clientId,
          },
        }),
      );
    } else {
      dispatch(
        deleteRevokeClientAdminAccessRequest({
          id,
          payload: {
            email_address,
            client_id: clientId,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (props.deleteAdmin) {
      dispatch(deleteClientAdminAccessSuccess(null));
    }
    if (props.revokeAdmin) {
      dispatch(deleteRevokeClientAdminAccessSuccess(null));
    }
    setpopUp({});
  }, [props.deleteAdmin, props.revokeAdmin]);

  const resendLink = (id: any) => {
    dispatch(
      postInviteClientAdminRequest({
        id,
        clientId,
      }),
    );
  };

  const sendInvite = () => {
    const trimEmailAddress = emailAddress?.trim();
    setemailAddress(trimEmailAddress);
    const re = /\S+@\S+\.\S+/;
    if (trimEmailAddress?.length && re.test(trimEmailAddress)) {
      dispatch(
        postInviteViaEmailRequest({
          clientId,
          payload: {
            email_address: trimEmailAddress,
          },
        }),
      );
    } else {
      if (!trimEmailAddress) {
        toast.error('Please enter mail address');
      } else if (!re.test(trimEmailAddress)) {
        toast.error('Please enter a valid mail address');
      }
    }
  };

  return (
    <div>
      {props.pending && <Spinner />}
      <Header userName={''} heading={<HeadBack clientName={props?.client?.name || ''} />} />
      <SwitchBar heading={HeaderOptions} position={5} />

      <div className=" max-window-height pb-30 overflow-y-auto  pt-7">
        <div className="w-full px-7">
          <p className="py-2 font-bold text-gray-dark">Group Admins</p>
          <hr className="bg-gray-dark"></hr>
        </div>
        <table className="mx-7 text-md">
          <thead></thead>
          <tbody>
            {props.adminList?.length > 0 ? (
              props.adminList.map((item: any) => (
                <tr className="w-full px-7">
                  <td className="w-3/12 p-2">
                    {(item.first_name + item.last_name).trim()?.length > 0
                      ? item.first_name + ' ' + item.last_name
                      : 'Name Unavailable'}
                  </td>
                  <td className="w-4/12 p-2">{item.email_address}</td>
                  <td className="w-2/12 p-2">{moment(item.created_at).format('L')}</td>

                  <td className="w-1/12 p-2">
                    {item.client_administrator_role?.status !== 'enabled' && (
                      <button
                        className="rounded-full bg-gray-200 text-gray-900 text-xs px-4 py-1"
                        onClick={() => {
                          resendLink(item.client_administrator_role?.id);
                        }}
                      >
                        RESEND
                      </button>
                    )}
                  </td>
                  <td className="w-1/12 p-2">
                    <button
                      className="rounded-full bg-gray-200 text-gray-900 text-xs px-4 py-1"
                      onClick={() => {
                        setpopUp({
                          firstName: item.first_name,
                          paylaod: {
                            type: 'remove',
                            id: item.client_administrator_role?.id,
                            email_address: item.email_address,
                          },
                        });
                      }}
                    >
                      REMOVE
                    </button>
                  </td>
                  <td className="w-1/12 p-2">
                    <button
                      className="rounded-full bg-gray-200 text-gray-900 text-xs px-4 py-1"
                      onClick={() => {
                        setpopUp({
                          firstName: item.first_name,
                          paylaod: {
                            type: 'revoke',
                            id: item.id,
                            email_address: item.email_address,
                          },
                        });
                        // revokeAdmin(item.id, item.email_address);
                      }}
                    >
                      REVOKE
                    </button>
                  </td>
                  <td className="w-1/12 p-2">
                    <div
                      className={
                        'rounded-md text-xs px-4 py-1 text-white w-full' +
                        (item.client_administrator_role?.status === 'enabled'
                          ? ' stacked-bar-green-primary '
                          : item.client_administrator_role?.status === 'invited'
                            ? ' stacked-bar-orange-primary '
                            : '')
                      }
                    >
                      {item.client_administrator_role?.status.toUpperCase()}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="py-2">No Data Available</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="w-full px-7 mt-10">
          <p className="py-2 font-bold text-gray-dark">Invite Admin</p>
          <hr className="bg-gray-dark"></hr>
        </div>
        <div className="py-4 w-full px-7 flex items-end pb-20">
          <div className="w-5/12">
            <CustomInput
              inputType={'text'}
              value={emailAddress}
              setValue={setemailAddress}
              Label={'New Group Admin Email'}
            />
          </div>
          <div className="px-4 ">
            <button className="btn-blue text-white px-4 py-2 text-xs w-full rounded-full" onClick={sendInvite}>
              INVITE AS GROUP ADMIN
            </button>
          </div>
        </div>
      </div>

      <Modal
        isModel={popUp?.paylaod?.type ? true : false}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 ">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Alert
                  </h3>
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500 flex items-center">
                      {`${
                        popUp?.paylaod?.type[0].toUpperCase() + popUp?.paylaod?.type.slice(1)
                      } User alert - Are you sure you want to ${popUp?.paylaod?.type} this user from administering ${
                        popUp?.paylaod?.type === 'revoke' ? `all clients` : `this client`
                      }?`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => {
                  handleAlert(popUp?.paylaod);
                }}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {`Yes, ${popUp?.paylaod?.type[0].toUpperCase() + popUp?.paylaod?.type.slice(1)}`}
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setpopUp({});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      ></Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    adminList: state.admins.adminstrators?.data || null,
    successEmail: state.admins.inviteEmail?.status || null,
    deleteAdmin: state.admins.deleteClientAdmin || null,
    revokeAdmin: state.admins.revokeClient || null,
    pending: state.admins?.pending || state.Client.pending || null,
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(Admin);
