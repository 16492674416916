import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
// import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import {
  fetchResourceRequest,
  fetchResourceSuccess,
  postResourcesRequest,
  postResourcesSuccess,
  removeResourcesRequest,
  removeResourcesSuccess,
  updateResourcesRequest,
} from './redux-resources/actions';
import { CustomInput, UseFormDropDown, UseFormToggle } from '../../components/common';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { decimalOnChange, numberonBlur, onBlur } from '../../components/common/useForm';
import HeadBack from '../clients/redirect';
import { toast } from 'react-toastify';

type props = {
  resources: any;
  pending: any;
  newResource: any;
  removeResources: any;
};

const ResourceListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const [isShowAlertmodel, setIsShowAlertModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const resourceId = new URLSearchParams(search).get('resource-id');
  const fileInput: any = React.createRef();
  const [image, setimgPreview] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(postResourcesSuccess({ Resources: {} }));
      dispatch(removeResourcesSuccess({ RemoveResources: {} }));
      dispatch(fetchResourceSuccess({ resource: {} }));
    };
  }, []);

  useEffect(() => {
    if (resourceId && resourceId !== 'new') {
      dispatch(
        fetchResourceRequest({
          id: resourceId,
        }),
      );
    }
  }, [resourceId]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.resources && resourceId) {
        const resource = props.resources;
        return {
          description: resource?.description || '',
          image_url: resource?.image_url || null,
          name: resource?.name || '',
          phone_number: resource?.phone_number || '',
          sort_weight: resource?.sort_weight || 0,
          status: resource?.status || 'live',
          url: resource?.url || '',
        };
      }
    }, [props, resourceId]),
  });

  useEffect(() => {
    if (resourceId) {
      const resource = props.resources;
      reset({
        description: resource?.description || '',
        image_url: resource?.image_url || null,
        name: resource?.name || '',
        phone_number: resource?.phone_number || '',
        sort_weight: resource?.sort_weight || 0,
        status: resource?.status || 'live',
        url: resource?.url || '',
      });
      setimgPreview(resource?.image_url || null);
      setenableSaveOption(false);
    }
  }, [props.resources]);

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      image_url: data?.image_url ? (enableSaveOption ? data?.image_url : undefined) : null,
    };
    if (resourceId === 'new') {
      dispatch(postResourcesRequest(payload));
    } else {
      dispatch(updateResourcesRequest({ id: resourceId, payload }));
    }
  };

  const handleDelete = () => {
    dispatch(removeResourcesRequest({ id: resourceId }));
    setIsShowAlertModel(false);
  };

  const onFileChange = async (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = () => {
            // @ts-ignore
            const height = image.height;
            // @ts-ignore
            const width = image.width;
            if (height === width) {
              setimgPreview(image.src);
              setValue('image_url', image.src);
              setenableSaveOption(true);
            } else {
              toast.error('Image must be equal height and width.');
              return new Error('Image must be equal height and width.');
            }
          };
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };
  const hadleImageinput = (e: any) => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (props.removeResources) {
      history.push(ROUTE_PATH.RESOURCES);
    }
  }, [props.removeResources]);

  useEffect(() => {
    if (props.newResource) {
      history.push(ROUTE_PATH.RESOURCES);
    }
  }, [props.newResource]);

  return (
    <div>
      <Header
        heading={
          <HeadBack
            clientName={resourceId === 'new' ? 'Resources' : props.resources?.name || ''}
            headback={ROUTE_PATH.RESOURCES}
          />
        }
      />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <div className="flex">
          {' '}
          {resourceId !== 'new' && (
            <button
              className=" rounded-full w-20 text-center h-6 text-sm text-white bg-blue-primary px-2"
              onClick={() => {
                setIsShowAlertModel(true);
              }}
            >
              REMOVE
            </button>
          )}
          <div className={` ${!isDirty && !enableSaveOption && 'cursor-not-allowed'}`}>
            <button
              className={` rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                isDirty || enableSaveOption ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none  '
              }`}
              type="submit"
              form="member-profile-form"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height pb-10 overflow-y-auto ">
          <div className="w-full px-3">
            {props.pending ? (
              <Spinner />
            ) : (
              <div className="w-3/4 grid grid-cols-2 gap-x-6 mx-7 mt-5 gap-y-6">
                <CustomInput
                  inputType="text"
                  Label="Resource name"
                  register={{
                    ...register('name', {
                      required: 'Please provide resource name',
                      onBlur: (e: any) => onBlur(e, 'name', setValue),
                    }),
                  }}
                  //@ts-ignore
                  Error={!!errors?.name?.message?.length}
                  ErrorMessage={errors.name?.message}
                />

                <UseFormDropDown label={'Status'}>
                  <select className="select-box w-full" defaultValue="published" {...register('status')}>
                    <option value="live">Live</option>
                    <option value="hidden">Hidden</option>
                  </select>
                </UseFormDropDown>
                <div>
                  <h3 className="block text-sm leading-4   text-blue-dark mb-2">Description</h3>

                  <div className="w-full  my-2">
                    <textarea
                      className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                      {...register('description', {
                        required: 'Please provide resource description',
                        onBlur: (e: any) => onBlur(e, 'description', setValue),
                      })}
                    />
                    {
                      //@ts-ignore
                      !!errors?.description?.message?.length > 0 && (
                        <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                          {/* @ts-ignore */}
                          {errors.description?.message}
                        </label>
                      )
                    }
                  </div>
                </div>

                <CustomInput
                  inputType="text"
                  Label="Sort Order"
                  register={{
                    ...register('sort_weight', {
                      onChange: (e) => decimalOnChange(e, 'sort_weight', setValue, 2),
                      onBlur: (e) => numberonBlur(e, 'sort_weight', setValue),
                      valueAsNumber: true,
                    }),
                  }}
                  //@ts-ignore
                  Error={!!errors?.sort_weight?.message?.length}
                  ErrorMessage={errors.sort_weight?.message}
                />
                <div className="flex flex-col space-y-6">
                  <CustomInput
                    inputType="text"
                    Label="Link"
                    register={{
                      ...register('url', {
                        required: 'Please provide resource description',
                        onBlur: (e: any) => onBlur(e, 'url', setValue),
                      }),
                    }}
                    //@ts-ignore
                    Error={!!errors?.url?.message?.length}
                    ErrorMessage={errors.url?.message}
                  />
                  <CustomInput
                    inputType="text"
                    Label="Phone "
                    register={{
                      ...register('phone_number', {
                        onBlur: (e: any) => onBlur(e, 'phone_number', setValue),
                      }),
                    }}
                    //@ts-ignore
                    Error={!!errors?.phone_number?.message?.length}
                    ErrorMessage={errors.phone_number?.message}
                  />
                </div>
                <div className="w-full">
                  <label className={`block leading-4 text-gray-dark mb-2`}>Resource Image</label>
                  <div className="w-full flex">
                    <div
                      className="bg-gray-200 w-full aspect-ratio-container rounded-sm flex justify-center items-center cursor-pointer"
                      onClick={hadleImageinput}
                    >
                      {image ? (
                        <img className="w-full h-full" src={image} alt="clinetImage" />
                      ) : (
                        <div className="h-56 flex justify-center items-center">
                          <button
                            className="rounded-full h-6 w-6 text-3xl bg-white flex justify-center items-center"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                          >
                            +
                          </button>
                          <input
                            ref={fileInput}
                            id="file-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onClick={() => {
                              fileInput.current.value = null;
                            }}
                            onChange={(data) => {
                              onFileChange(data);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="pl-6">
                      {image && (
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setimgPreview(null);
                            setValue('image_url', null);
                            setenableSaveOption(true);
                          }}
                        >
                          REMOVE
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <AlertBox
        visible={isShowAlertmodel}
        handlePrompt={handleDelete}
        closeModal={() => setIsShowAlertModel(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove ${getValues('name')}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    resources: state.resources.resource?.data || null,
    newResource: state.resources.newResource?.data || null, //
    removeResources: state.resources.RemoveResources?.data || null, //
    pending: state.resources?.pending || null,
  };
};

export default connect(mapStateToProps)(ResourceListing);
