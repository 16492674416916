export const POST_COMMS_REQUEST = 'POST_COMMS_REQUEST';
export const POST_COMMS_SUCCESS = 'POST_COMMS_SUCCESS';
export const POST_COMMS_FAILURE = 'POST_COMMS_FAILURE';

//User details
export const FETCH_ELIGILITY_REQUEST = 'FETCH_ELIGILITY_REQUEST';
export const FETCH_ELIGILITY_SUCCESS = 'FETCH_ELIGILITY_SUCCESS';
export const FETCH_ELIGILITY_FAILURE = 'FETCH_ELIGILITY_FAILURE';

export const FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST = 'FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST';
export const FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS = 'FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS';
export const FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE = 'FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE';

//partner Code Redemption
export const FETCH_COMMS_LIST_REQUEST = 'FETCH_COMMS_LIST_REQUEST';
export const FETCH_COMMS_LIST_SUCCESS = 'FETCH_COMMS_LIST_SUCCESS';
export const FETCH_COMMS_LIST_FAILURE = 'FETCH_COMMS_LIST_FAILURE';

// activate Subscription
export const UPDATE_COMMS_REQUEST = 'UPDATE_COMMS_REQUEST';
export const UPDATE_COMMS_SUCCESS = 'UPDATE_COMMS_SUCCESS';
export const UPDATE_COMMS_FAILURE = 'UPDATE_COMMS_FAILURE';

// remove payment
export const REMOVE_COMMS_REQUEST = 'REMOVE_COMMS_REQUEST';
export const REMOVE_COMMS_SUCCESS = 'REMOVE_COMMS_SUCCESS';
export const REMOVE_COMMS_FAILURE = 'REMOVE_COMMS_FAILURE';

//repair
export const POST_REPAIR_TEMPLATE_REQUEST = 'POST_REPAIR_TEMPLATE_REQUEST';
export const POST_REPAIR_TEMPLATE_SUCCESS = 'POST_REPAIR_TEMPLATE_SUCCESS';
export const POST_REPAIR_TEMPLATE_FAILURE = 'POST_REPAIR_TEMPLATE_FAILURE';

export const POST_TEST_COMMS_REQUEST = 'POST_TEST_COMMS_REQUEST';
export const POST_TEST_COMMS_SUCCESS = 'POST_TEST_COMMS_SUCCESS';
export const POST_TEST_COMMS_FAILURE = 'POST_TEST_COMMS_FAILURE';

export const POST_CAMPAIGNS_REQUEST = 'POST_CAMPAIGNS_REQUEST';
export const POST_CAMPAIGNS_SUCCESS = 'POST_CAMPAIGNS_SUCCESS';
export const POST_CAMPAIGNS_FAILURE = 'POST_CAMPAIGNS_FAILURE';

//partner Code Redemption
export const FETCH_CAMPAIGNS_LIST_REQUEST = 'FETCH_CAMPAIGNS_LIST_REQUEST';
export const FETCH_CAMPAIGNS_LIST_SUCCESS = 'FETCH_CAMPAIGNS_LIST_SUCCESS';
export const FETCH_CAMPAIGNS_LIST_FAILURE = 'FETCH_CAMPAIGNS_LIST_FAILURE';

// activate Subscription
export const UPDATE_CAMPAIGNS_REQUEST = 'UPDATE_CAMPAIGNS_REQUEST';
export const UPDATE_CAMPAIGNS_SUCCESS = 'UPDATE_CAMPAIGNS_SUCCESS';
export const UPDATE_CAMPAIGNS_FAILURE = 'UPDATE_CAMPAIGNS_FAILURE';

// remove payment
export const REMOVE_CAMPAIGNS_REQUEST = 'REMOVE_CAMPAIGNS_REQUEST';
export const REMOVE_CAMPAIGNS_SUCCESS = 'REMOVE_CAMPAIGNS_SUCCESS';
export const REMOVE_CAMPAIGNS_FAILURE = 'REMOVE_CAMPAIGNS_FAILURE';

export const POST_TEST_CAMPAIGNS_REQUEST = 'POST_TEST_CAMPAIGNS_REQUEST';
export const POST_TEST_CAMPAIGNS_SUCCESS = 'POST_TEST_CAMPAIGNS_SUCCESS';
export const POST_TEST_CAMPAIGNS_FAILURE = 'POST_TEST_CAMPAIGNS_FAILURE';
