import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
// import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import {
  fetchTagRequest,
  postTagsRequest,
  postTagsSuccess,
  removeTagsRequest,
  removeTagsSuccess,
  updateTagsRequest,
} from './redux-tags/actions';
import { CustomInput, UseFormDropDown, UseFormToggle } from '../../components/common';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { onBlur } from '../../components/common/useForm';
import HeadBack from '../clients/redirect';

type props = {
  tags: any;
  pending: any;
  newTag: any;
  removeTags: any;
};

const TagListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const [isShowAlertmodel, setIsShowAlertModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const TagId = new URLSearchParams(search).get('tag-id');
  const fileInput: any = React.createRef();
  const [image, setimgPreview] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [tagType, settagType] = useState('');

  useEffect(() => {
    return () => {
      dispatch(postTagsSuccess({ Tags: {} }));
      dispatch(removeTagsSuccess({ RemoveTags: {} }));
    };
  }, []);

  useEffect(() => {
    if (TagId && TagId !== 'new') {
      dispatch(
        fetchTagRequest({
          id: TagId,
          payload: { include_hidden: true },
        }),
      );
    }
  }, [TagId]);

  const getDefaultValues = (TagId: string, Tag: any) => {
    if (TagId === 'new') {
      return {
        is_default: false,
        is_required: false,
        is_background: false,
        is_visible: 'unpublished',
        media: null,
        name: '',
        sort_order: 0,
        tag_type: undefined,
      };
    } else {
      return {
        ...Tag,
        is_visible: Tag?.is_visible ? 'published' : 'unpublished',
      };
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.tags && TagId) {
        return getDefaultValues(TagId, props.tags);
      }
    }, [props, TagId]),
  });

  useEffect(() => {
    if (TagId) {
      reset(getDefaultValues(TagId, props.tags));
      settagType(props.tags?.tag_type);
    }
  }, [props.tags]);

  const onSubmit = (data: any) => {
    const is_visible = !!(data.is_visible === 'published');
    const payload = {
      ...data,
      is_visible,
    };
    if (TagId === 'new') {
      dispatch(
        postTagsRequest({
          is_visible,
          tag_type: data.tag_type,
          name: data.name,
          sort_order: 0,
          media: null,
        }),
      );
    } else {
      dispatch(updateTagsRequest({ id: TagId, payload }));
    }
  };

  const handleDelete = () => {
    setIsShowAlertModel(false);
    dispatch(removeTagsRequest({ id: TagId }));
  };

  useEffect(() => {
    if (props.removeTags) {
      history.push(ROUTE_PATH.TAGS);
    }
  }, [props.removeTags]);

  useEffect(() => {
    if (props.newTag) {
      history.push(ROUTE_PATH.TAGS);
    }
  }, [props.newTag]);

  return (
    <div>
      <Header heading={<HeadBack clientName={TagId === 'new' ? 'Tags' : getValues('name')} headback="/tags" />} />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <div className="flex">
          {' '}
          {TagId !== 'new' && (
            <button
              className=" rounded-full w-20 text-center h-6 text-sm text-white bg-gray-background-dark px-2"
              onClick={() => {
                setIsShowAlertModel(true);
              }}
            >
              REMOVE
            </button>
          )}
          <div className={` ${!isDirty && !enableSaveOption && 'cursor-not-allowed'}`}>
            <button
              className={` rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                isDirty || enableSaveOption ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none  '
              }`}
              type="submit"
              form="member-profile-form"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height pb-10 overflow-y-auto ">
          <div className="w-full px-3">
            {props.pending ? (
              <Spinner />
            ) : (
              <div className="w-3/4 grid grid-cols-2 gap-x-6 mx-7 mt-5 gap-y-6">
                <CustomInput
                  inputType="text"
                  Label="Tag Name"
                  register={{
                    ...register('name', {
                      required: 'Please provide Tag name',
                      onBlur: (e: any) => onBlur(e, 'name', setValue),
                    }),
                  }}
                  //@ts-ignore
                  Error={errors?.name?.message?.length ? true : false}
                  ErrorMessage={errors.name?.message}
                />
                <div>
                  {TagId === 'new' ? (
                    <>
                      <UseFormDropDown label={'Type'}>
                        <select
                          className="select-box w-full"
                          {...register('tag_type', {
                            required: 'Please provide Tag type',
                          })}
                          onChange={(e) => {
                            const data = e.target.value;
                            register('tag_type').onChange(e);
                            settagType(data);
                          }}
                        >
                          <option value={''}>Select</option>
                          <option value="topic">Topic</option>
                          <option value="trait">Trait</option>
                        </select>
                      </UseFormDropDown>
                      {/* @ts-ignore */}
                      {errors.tag_type?.message?.length && (
                        <label className="block text-xs leading-4  font-medium text-red ">
                          {/* @ts-ignore */}
                          {errors.tag_type?.message}
                        </label>
                      )}
                    </>
                  ) : (
                    <>
                      <p className=" ">Type</p>
                      <p className=" font-bold capitalize">{tagType}</p>
                    </>
                  )}
                </div>
                <UseFormDropDown label={'Status'}>
                  <select className="select-box w-full" defaultValue="unpublished" {...register('is_visible')}>
                    <option value={'published'}>Visible</option>
                    <option value={'unpublished'}>Hidden</option>
                  </select>
                </UseFormDropDown>
                {TagId !== 'new' && (
                  <>
                    <div>
                      <div className="w-3/4">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm leading-4   text-blue-dark mb-2 mr-2">is Required?</label>
                          <UseFormToggle
                            id={'is_required'}
                            register={{ ...register('is_required') }}
                            setStatus={(data) => {
                              setValue('is_required', data, {
                                shouldDirty: true,
                              });
                            }}
                            initialstatus={props.tags?.is_required || false}
                          ></UseFormToggle>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                          <label className="block text-sm leading-4   text-blue-dark mb-2">is Default? </label>
                          <UseFormToggle
                            id={'isDefault?'}
                            // value={getValues('is_default')}
                            register={{ ...register('is_default') }}
                            setStatus={(data: boolean) => {
                              setValue('is_default', data, {
                                shouldDirty: true,
                              });
                            }}
                            initialstatus={props.tags?.is_default || false}
                          ></UseFormToggle>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                          <label className="block text-sm leading-4   text-blue-dark mb-2">
                            Requires Documentation
                          </label>
                          <UseFormToggle
                            id={'isDefault?'}
                            // value={getValues('is_default')}
                            register={{ ...register('is_background') }}
                            setStatus={(data: boolean) => {
                              setValue('is_background', data, {
                                shouldDirty: true,
                              });
                            }}
                            initialstatus={props.tags?.is_background || false}
                          ></UseFormToggle>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {tagType === 'topic' && TagId === 'new' && <div></div>}
              </div>
            )}
          </div>
        </div>
      </form>
      <AlertBox
        visible={isShowAlertmodel}
        handlePrompt={handleDelete}
        closeModal={() => setIsShowAlertModel(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove ${getValues('name')}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tags: state.tags.TAG?.data || null,
    newTag: state.tags.newTag?.data || null, //
    removeTags: state.tags.RemoveTags?.data || null, //
    pending: state.tags?.pending || null,
  };
};

export default connect(mapStateToProps)(TagListing);
