import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../api/axios';

export interface Redirect {
  hash: string;
  key: string;
  value: any;
}

export const useRedirects = () =>
  useQuery<Redirect[]>(['links'], async () => {
    return axiosGet('/redirects/all', {}, 'v3').then((redirectResponse) => redirectResponse.data);
  });

export const useCreateRedirect = () => {
  const queryClient = useQueryClient();
  return useMutation((redirect: Redirect) => axiosPost('/redirects/hash', redirect, 'v3'), {
    onSuccess: () => {
      // Invalidate the query
      queryClient.invalidateQueries(['links']);
    },
  });
};

export const useDeleteRedirect = () => {
  const queryClient = useQueryClient();
  return useMutation((redirect: Redirect) => axiosDelete(`/redirects/${redirect.hash}`, {}, 'v3'), {
    onSettled: () => {
      // Invalidate the query
      queryClient.invalidateQueries(['links']);
    },
  });
};

export interface UpdateRedirectPayload {
  oldHash: string;
  redirect: Redirect;
}

export const useUpdateRedirect = () => {
  const queryClient = useQueryClient();
  return useMutation((updateRedirectPayload: UpdateRedirectPayload) => axiosPut(`/redirects/${updateRedirectPayload.oldHash}`, updateRedirectPayload.redirect, 'v3'), {
    onSettled: () => {
      // Invalidate the query
      queryClient.invalidateQueries(['links']);
    },
  });
}
