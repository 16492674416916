export const POST_BLACKLIST_REQUEST = 'POST_BLACKLIST_REQUEST';
export const POST_BLACKLIST_SUCCESS = 'POST_BLACKLIST_SUCCESS';
export const POST_BLACKLIST_FAILURE = 'POST_BLACKLIST_FAILURE';

export const FETCH_BLACKLIST_LIST_REQUEST = 'FETCH_BLACKLIST_LIST_REQUEST';
export const FETCH_BLACKLIST_LIST_SUCCESS = 'FETCH_BLACKLIST_LIST_SUCCESS';
export const FETCH_BLACKLIST_LIST_FAILURE = 'FETCH_BLACKLIST_LIST_FAILURE';

export const UPDATE_BLACKLIST_REQUEST = 'UPDATE_BLACKLIST_REQUEST';
export const UPDATE_BLACKLIST_SUCCESS = 'UPDATE_BLACKLIST_SUCCESS';
export const UPDATE_BLACKLIST_FAILURE = 'UPDATE_BLACKLIST_FAILURE';

// remove BLACKLIST
export const REMOVE_BLACKLIST_REQUEST = 'REMOVE_BLACKLIST_REQUEST';
export const REMOVE_BLACKLIST_SUCCESS = 'REMOVE_BLACKLIST_SUCCESS';
export const REMOVE_BLACKLIST_FAILURE = 'REMOVE_BLACKLIST_FAILURE';

export const FETCH_BLACKLIST_COUNT_REQUEST = 'FETCH_BLACKLIST_COUNT_REQUEST';
export const FETCH_BLACKLIST_COUNT_SUCCESS = 'FETCH_BLACKLIST_COUNT_SUCCESS';
export const FETCH_BLACKLIST_COUNT_FAILURE = 'FETCH_BLACKLIST_COUNT_FAILURE';

export const FETCH_USERLIST_REQUEST = 'FETCH_USERLIST_REQUEST';
export const FETCH_USERLIST_SUCCESS = 'FETCH_USERLIST_SUCCESS';
export const FETCH_USERLIST_FAILURE = 'FETCH_USERLIST_FAILURE';

export const POST_BLOCKED_REQUEST = 'POST_BLOCKED_REQUEST';
export const POST_BLOCKED_SUCCESS = 'POST_BLOCKED_SUCCESS';
export const POST_BLOCKED_FAILURE = 'POST_BLOCKED_FAILURE';

export const FETCH_BLOCKED_LIST_REQUEST = 'FETCH_BLOCKED_LIST_REQUEST';
export const FETCH_BLOCKED_LIST_SUCCESS = 'FETCH_BLOCKED_LIST_SUCCESS';
export const FETCH_BLOCKED_LIST_FAILURE = 'FETCH_BLOCKED_LIST_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// remove BLACKLIST
export const REMOVE_BLOCKED_REQUEST = 'REMOVE_BLOCKED_REQUEST';
export const REMOVE_BLOCKED_SUCCESS = 'REMOVE_BLOCKED_SUCCESS';
export const REMOVE_BLOCKED_FAILURE = 'REMOVE_BLOCKED_FAILURE';

export const FETCH_BLOCKED_COUNT_REQUEST = 'FETCH_BLOCKED_COUNT_REQUEST';
export const FETCH_BLOCKED_COUNT_SUCCESS = 'FETCH_BLOCKED_COUNT_SUCCESS';
export const FETCH_BLOCKED_COUNT_FAILURE = 'FETCH_BLOCKED_COUNT_FAILURE';

export const FETCH_BLOCKED_REQUEST = 'FETCH_BLOCKED_REQUEST';
export const FETCH_BLOCKED_SUCCESS = 'FETCH_BLOCKED_SUCCESS';
export const FETCH_BLOCKED_FAILURE = 'FETCH_BLOCKED_FAILURE';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
