import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import debounce from './debounce';
import { XCricle } from '../../assets/images';

type searchBarProps = {
  search: (data: string) => void;
  initialValue?: string;
};

interface refProps {
  setSearch: (search: string) => void;
}

const Searchbar = forwardRef<refProps, searchBarProps>(({ search, initialValue }, ref) => {
  //  const Searchbar: React.FunctionComponent<searchBarProps> = ({ search , initialValue }): JSX.Element => {
  const [value, setvalue] = useState(initialValue || '');
  const callBack = useRef(true);
  const SearchValue = debounce(value, 500);

  useEffect(() => {
    if (search !== undefined && callBack.current) {
      search(value);
    }
  }, [SearchValue]);

  const setSearch = (search: string) => {
    callBack.current = false;
    setvalue(search);
  };

  useImperativeHandle(ref, () => ({
    setSearch,
  }));

  return (
    <div className="w-full bg-white rounded-full  h-full  flex ">
      <input
        type="text"
        className=" text-center focus:outline-none w-5/6 rounded-l-full h-full "
        value={value}
        onChange={(e) => {
          callBack.current = true;
          setvalue(e.target.value);
        }}
        placeholder="SEARCH"
      />
      <div
        className="w-1/6 flex items-center justify-center rounded-r-full h-full pr-2"
        onClick={() => {
          setvalue('');
          search('');
        }}
      >
        {value.length > 0 && <img src={XCricle} alt="closeButton"></img>}
      </div>
    </div>
  );
});

export default Searchbar;
