import axios from 'axios';
import {
  TopicsApi,
  ListenersApi,
  LoginApi,
  ClientsApi,
  MembersApi,
  ClientPortalApi,
  EligibiltyApi,
  CommsApi,
  AdminApi,
  TiersApi,
  PartnerApi,
  TagsApi,
  ResourcesApi,
  SafetyApi,
} from './api-handler';

const domain = process.env.REACT_APP_DOMAIN_NAME;
const apiVersion = process.env.REACT_APP_API_VERSION2;
const apiVersion3 = process.env.REACT_APP_API_VERSION3;
const domainUrl = `${domain}${apiVersion}`;
const domainUrlV3 = `${domain}${apiVersion3}`;

// Build HTTP authorized headers
async function httpHeadersGet() {
  try {
    const authorization_token = localStorage.getItem('authorization_token');
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHTTPRequest',
      Authorization: 'Bearer ' + authorization_token,
    };
  } catch (e) {
    console.error('axios-handler::httpHeadersGet:Error', e);
  }
}
// Build HTTP unauthorized headers
async function httpHeadersUnauthorized() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHTTPRequest',
  };
}

//Authorized post
export const axiosPost = (url: string, data: any, type: string = 'v2') => {
  return httpHeadersGet()
    .then((headers) => {
      return axios.post((type === 'v2' ? domainUrl : domainUrlV3) + url, data, { headers });
    })
    .then();
};

//Authorized delete
export const axiosDelete = (url: string, data: any, type: string = 'v2') => {
  return httpHeadersGet()
    .then((headers) => {
      return axios.delete((type === 'v2' ? domainUrl : domainUrlV3) + url, {
        data: data,
        headers: headers,
      });
    })
    .then();
};

//Authorized put
export const axiosPut = (url: string, data: any, type: string = 'v2') => {
  return httpHeadersGet()
    .then((headers) => {
      return axios.put((type === 'v2' ? domainUrl : domainUrlV3) + url, data, { headers });
    })
    .then();
};

//Authorized get
export const axiosGet = (url: string, data: any, type: string = 'v2') => {
  return httpHeadersGet()
    .then((headers) => axios.get((type === 'v2' ? domainUrl : domainUrlV3) + url, { headers, params: data }))
    .then();
};

//Unauthorized get
const axiosUnauthorizedGet = (url: string) => {
  return httpHeadersUnauthorized()
    .then((headers) => axios.get(domainUrl + url, { headers }))
    .then();
};

//Unauthorized post
const axiosUnauthorisedPost = (url: string, data: any) => {
  return httpHeadersUnauthorized()
    .then((headers) => {
      return axios.post(domainUrl + url, data, { headers });
    })
    .then();
};

export const Topics = () => {
  const client: any = {
    get: axiosGet,
    post: axiosPost,
    unzuthorisedGet: axiosUnauthorizedGet,
    UnauthorisedPost: axiosUnauthorisedPost,
  };
  return TopicsApi(client);
};

export const Listeners = () => {
  const client: any = {
    get: axiosGet,
    post: axiosPost,
    put: axiosPut,
  };
  return ListenersApi(client);
};

export const Members = () => {
  const client: any = {
    get: axiosGet,
    post: axiosPost,
  };
  return MembersApi(client);
};

export const Login = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
  };
  return LoginApi(client);
};

export const Clients = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return ClientsApi(client);
};

export const ClientsPortal = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return ClientPortalApi(client);
};

export const Eligibilty = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return EligibiltyApi(client);
};

export const Comms = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return CommsApi(client);
};

export const Tiers = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return TiersApi(client);
};

export const Tags = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return TagsApi(client);
};

export const Safety = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return SafetyApi(client);
};

export const Admins = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    delete: axiosDelete,
  };
  return AdminApi(client);
};

export const Partner = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    delete: axiosDelete,
  };
  return PartnerApi(client);
};
export const Resources = () => {
  const client: any = {
    post: axiosPost,
    get: axiosGet,
    put: axiosPut,
    delete: axiosDelete,
  };
  return ResourcesApi(client);
};
