import { combineReducers } from 'redux';
import loginReducer from '../components/account-login/login-redux/reducer';
import ClientReducer from '../containers/clients/clients-redux/reducer';
import ListenerReducer from '../containers/peer-profiles/listeners-redux/reducer';
import MembersReducer from '../containers/members/members-redux/reducer';
import ClientPortalReducer from '../containers/client-configuration/client-portal-redux/reducer';
import EligibilityReducer from '../containers/clients/eligibility-redux/reducer';
import CommsReducer from '../containers/templates/redux-templates/reducer';
import AdminReducer from '../containers/clients/admin-redux/reducer';
import { DESTROY_SESSION } from '../components/account-login/login-redux/actionTypes';
import TosReducer from '../containers/clients/text-blocks/text-blocks-redux/reducer';
import TiersReducer from '../containers/listener-tiers/redux-templates/reducer';
import TagsReducer from '../containers/tags-management/redux-tags/reducer';
import ResourceReducer from '../containers/resources/redux-resources/reducer';
import SafetyReducer from '../containers/user-safety/redux-tags/reducer';
import ConnectionsReducer from '../containers/connections/connections-redux/reducer';
import { axiosPost } from '../api/axios';

const appReducer = combineReducers({
  login: loginReducer,
  Client: ClientReducer,
  Listeners: ListenerReducer,
  Members: MembersReducer,
  ClientPortal: ClientPortalReducer,
  eligibility: EligibilityReducer,
  Comms: CommsReducer,
  admins: AdminReducer,
  Tos: TosReducer,
  tiers: TiersReducer,
  tags: TagsReducer,
  resources: ResourceReducer,
  safety: SafetyReducer,
  connections: ConnectionsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === DESTROY_SESSION) {
    axiosPost(`/users/deauthenticate`, {});
    state = undefined;
  }
  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
