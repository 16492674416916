import React from 'react';

type props = {
  Label: string;
  disableLine?: boolean;
};

const SectionLabel: React.FunctionComponent<props> = ({ Label, disableLine }): JSX.Element => {
  return (
    <div className="w-full ">
      <div className={'relative w-full py-3  gray-border-line ' + (disableLine ? '' : ' border-b ')}>
        <div className="text-md font-bold text-gray-dark">{Label}</div>
      </div>
    </div>
  );
};

export default SectionLabel;
