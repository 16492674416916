import ToggleButton from '../../../components/common/toggle-button';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import { postCreatePackageProductRequest, postUpdatePackageProductRequest } from '../clients-redux/actions';
import { DropDown } from '../../../components/common';

type props = {
  client: any;
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

type RenewalPeriod = 'monthly' | 'yearly' | 'no_renewal';

const CappedAccess: React.FunctionComponent<props> = (props): JSX.Element => {
  const [hidePaymentSetupNotice, setHidePaymentSetupNotice] = useState(true);
  const [cappedAccessTab, setCappedAccessTab] = useState(false);
  const [isCallUnit, setisCallUnit] = useState(false);
  const [monthlyRenewals, setMonthlyRenewals] = useState<number>(0);
  const [minutesPerMember, setMinutesPerMember] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [enableSaveOption, setEnableSaveOption] = useState(false);
  const [productId, setProductId] = useState();
  const [packageId, setPackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [id, setId] = useState();
  const [renewalPeriod, setRenewalPeriod] = useState<RenewalPeriod>('no_renewal');

  const dispatch = useDispatch();

  const Validation = () => {
    if (cappedAccessTab) {
      if (isCallUnit && minutesPerMember! % 30) {
        setErrorMessage({ minutesPerMember: 'Minutes per member must be a multiple of 30' });
        return false;
      }
      if (!minutesPerMember) {
        setErrorMessage({ minutesPerMember: 'Field is required' });
        return false;
      } else if (minutesPerMember.toString().indexOf('.') !== -1) {
        setErrorMessage({
          minutesPerMember: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (+minutesPerMember <= 0) {
        setErrorMessage({ minutesPerMember: 'Must be a number greater than 0' });
        return false;
      } else if (renewalPeriod !== 'no_renewal' && !monthlyRenewals && monthlyRenewals !== 0) {
        setErrorMessage({ monthlyRenewals: 'Field is required' });
        return false;
      } else if (monthlyRenewals.toString().indexOf('.') !== -1) {
        setErrorMessage({
          monthlyRenewals: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (renewalPeriod !== 'no_renewal' && (monthlyRenewals < 0 || monthlyRenewals > 60)) {
        setErrorMessage({ monthlyRenewals: 'Must be a number between 1 and 60' });
        return false;
      }
    }
    setErrorMessage({});
    return true;
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;
      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'CAPPED_ACCESS') {
          return true;
        }
        return false;
      });
      setPackageId(selectedItem ? selectedItem.package_id : props.data.id);
      setProductId(selectedItem ? selectedItem.product_id : null);
      setId(selectedItem ? selectedItem.id : props.default.id);
      const configuration = selectedItem ? selectedItem.configuration : props.default.configuration;
      setCappedAccessTab(selectedItem?.status === 'enabled' ? true : false);
      setisCallUnit(configuration.is_call_units);
      setHidePaymentSetupNotice(configuration.hide_payment_tiles);
      setMinutesPerMember(Number(configuration.maximum_minutes_per_user));
      setMonthlyRenewals(Number(configuration.auto_renew_maximum_uses || 0));
      setReservedBalance(selectedItem ? selectedItem.unused_minute_balance : 0);
      setRenewalPeriod(configuration.auto_renew_frequency || 'no_renewal');
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setEnableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (renewalPeriod === 'no_renewal' && monthlyRenewals > 0) {
      setMonthlyRenewals(0);
    }
  }, [renewalPeriod, monthlyRenewals]);

  useEffect(() => {
    props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    if (Validation()) {
      const payload = {
        status: cappedAccessTab ? 'enabled' : 'disabled',
        configuration: {
          expires_after: null,
          hide_payment_tiles: hidePaymentSetupNotice,
          auto_renew_frequency: renewalPeriod,
          auto_renew_maximum_uses: Number(monthlyRenewals),
          maximum_minutes_per_user: Number(minutesPerMember),
          maximum_redemptions_allowed: 0,
          is_call_units: isCallUnit,
        },
      };

      const linkInput = {
        clientId,
        packageId,
        productId,
        id,
      };
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  const getMinutesPerPeriodLabel = () => {
    const suffix = ['monthly', 'yearly'].includes(renewalPeriod) ? ` per ${renewalPeriod.replace('ly', '')}` : '';
    return `MAX member usage${suffix}`;
  };
  const minutePeriodOptions = [
    { label: 'Monthly', value: 'weekly' },
    { label: 'Yearly', value: 'yearly' },
    { label: 'No Renewal', value: 'no_renewal' },
  ];
  return (
    <>
      <AccordionTile
        setStatus={(data: boolean) => {
          if ((!data && Validation()) || data) {
            !enableSaveOption && setEnableSaveOption(true);
            setCappedAccessTab(data);
          }
        }}
        // disableToggleButton={Object.keys(errorMessage).length > 0 ? true : false}
        Label={'CAPPED ACCESS'}
        buttonText={'SAVE'}
        status={cappedAccessTab}
        id={'CappedAcess'}
        disableButton={enableSaveOption}
        onButtonClick={() => {
          if (enableSaveOption) onSave();
        }}
        initialstatus={true}
        InnerComponent={
          <div className="pb-10">
            <div className="flex">
              <div className="w-1/3 pt-2 px-2">
                <div>
                  <label className="block text-sm leading-4 font-medium text-blue-dark mb-2 pt-2">
                    {getMinutesPerPeriodLabel()}
                  </label>
                  <input
                    type="text"
                    onWheel={(e: any) => e.target.blur()}
                    min={0}
                    value={minutesPerMember}
                    className="py-2 px-3 w-1/2 rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                    onChange={(e) => {
                      const val = e.target.value.replace(/\D/g, '');
                      if (val !== minutesPerMember.toString()) {
                        !enableSaveOption && setEnableSaveOption(true);
                      }
                      setMinutesPerMember(Number(val));
                    }}
                  />
                  {errorMessage?.minutesPerMember && (
                    <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                      {errorMessage.minutesPerMember}
                    </label>
                  )}
                </div>
              </div>
              <div className="w-1/3 flex flex-between px-2">
                <div className="mx-2 mt-2">
                  <label className="block text-sm leading-4 font-medium text-blue-dark mb-2 pt-2">Renewal Period</label>
                  <DropDown
                    Label=""
                    value={renewalPeriod}
                    setValue={(data) => {
                      !enableSaveOption && setEnableSaveOption(true);
                      setRenewalPeriod(data);
                    }}
                  >
                    {minutePeriodOptions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </DropDown>
                </div>
              </div>
              <div
                className={'w-1/3 flex flex-start px-2 ' + (renewalPeriod === 'no_renewal' && ' cursor-not-allowed ')}
              >
                <div className="mx-2 mt-2">
                  <label className="block text-sm leading-4 font-medium text-blue-dark mb-2 pt-2">
                    {renewalPeriod === 'no_renewal'
                      ? 'No'
                      : minutePeriodOptions.find((item) => item.value === renewalPeriod)?.label}{' '}
                    Renewals
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      onWheel={(e: any) => e.target.blur()}
                      min={0}
                      disabled={renewalPeriod === 'no_renewal'}
                      value={monthlyRenewals}
                      className={
                        'py-2 px-3 w-1/6 rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none ' +
                        (renewalPeriod === 'no_renewal' && ' cursor-not-allowed pointer-events-none')
                      }
                      onChange={(e: any) => {
                        const val = Number(e.target.value.replace(/\D/g, ''));
                        if (val !== monthlyRenewals) {
                          !enableSaveOption && setEnableSaveOption(true);
                        }
                        setMonthlyRenewals(Number(val));
                      }}
                    />
                    <p className="pl-3 pt-1 text-gray-dark font-medium ">Enter 0 for no renewal limit</p>
                  </div>
                  {errorMessage?.monthlyRenewals && (
                    <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                      {errorMessage.monthlyRenewals}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-start pt-2 px-2">
              <div className="w-1/3 flex  items-center ">
                <p className="text-blue-dark font-medium text-sm">Reserved Balance: {reservedBalance} minutes</p>
              </div>
              <div className="flex w-1/3 items-center">
                <p className="text-blue-dark font-medium text-sm px-4">Hide Payment Setup Notice</p>
                <ToggleButton
                  setStatus={(data) => {
                    !enableSaveOption && setEnableSaveOption(true);
                    setHidePaymentSetupNotice(data);
                  }}
                  status={hidePaymentSetupNotice}
                  id={'hidepaymentStatus'}
                  initialstatus={true}
                />
              </div>
              <div className="flex w-1/3 items-center">
                <p className="py-2 text-blue-dark font-medium text-sm px-4">Using Call Units</p>
                <ToggleButton
                  status={isCallUnit}
                  setStatus={(data) => {
                    !enableSaveOption && setEnableSaveOption(true);
                    setisCallUnit(data);
                  }}
                  initialstatus={false}
                  id={'Is Call Units'}
                />
              </div>
            </div>
            {!productId && (
              <p className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                You must save this product to add credit
              </p>
            )}
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(CappedAccess);
