import { toast } from 'react-toastify';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import { getMemberProfileSuccess } from '../../members/members-redux/actions';
import { fetchTagRequest } from '../../tags-management/redux-tags/actions';

import {
  fetchListenersSuccess,
  fetchListenersFailure,
  postListenerGradeSuccess,
  postListenerGradeFailure,
  getListenTopicReportSuccess,
  getListenTopicReportFailure,
  fetchMessageQualitySuccess,
  fetchMessageQualityFailure,
  fetchListConnectionsSuccess,
  fetchListConnectionsFailure,
  fetchCallLogSuccess,
  fetchCallLogFailure,
  fetchConnectionCountSuccess,
  fetchConnectionCountFailure,
  updateListenersSuccess,
  updateListenersFailure,
  getListenerDetailsSuccess,
  getListenerDetailsFailure,
  updateListenersProfileRejectedSuccess,
  updateListenersProfileRejectedFailure,
  getListenerDetailsRequest,
  fetchListenerCountSuccess,
  fetchListenerCountFailure,
  fetchListenerAvailabilitySuccess,
  fetchListenerAvailabilityFailure,
  fetchEngagementSuccess,
  fetchEngagementFailure,
  fetchLogsSuccess,
  fetchLogsFailure,
  fetchLogsCountSuccess,
  fetchLogsCountFailure,
  getListenerTagsRequest,
  getListenerTagsSuccess,
  getListenerTagsFailure,
  fetchAudioListFailure,
  fetchAudioListSuccess,
  postAudioFailure,
  postAudioSuccess,
  fetchMoneyDetailsFailure,
  fetchMoneyDetailsSuccess,
  updatePeerStatusSuccess,
  updatePeerStatusFailure,
  fetchListenersAudioListSuccess,
  fetchListenersAudioListFailure,
  fetchListenersAudioListCountFailure,
  fetchListenersAudioListCountSuccess,
} from './actions';
import {
  FETCH_LISTENERS_REQUEST,
  GET_LISTEN_TOPIC_REPORT_REQUEST,
  POST_LISTENER_GRADE_REQUEST,
  FETCH_MESSAGE_QUALITY_REQUEST,
  FETCH_LIST_CONNECTIONS_REQUEST,
  FETCH_CALL_LOG_REQUEST,
  FETCH_CONNECTIONS_COUNT_REQUEST,
  UPDATE_LISTENER_DETAILS_REQUEST,
  GET_LISTENER_DETAILS_REQUEST,
  POST_LISTENER_PROFILE_REJECTED_REQUEST,
  FETCH_LISTENERS_COUNT_REQUEST,
  FETCH_LISTENER_AVAILABILTY_REQUEST,
  FETCH_ENGAGEMENT_REQUEST,
  FETCH_LOGS_REQUEST,
  FETCH_LOGSCOUNT_REQUEST,
  UPDATE_LISTENER_TAGS_REQUEST,
  GET_LISTENER_TAGS_REQUEST,
  REMOVE_LISTENER_TAGS_REQUEST,
  UPDATE_LISTENER_TAGS_LIST_REQUEST,
  FETCH_AUDIO_LIST_REQUEST,
  POST_AUDIO_REQUEST,
  UPDATE_AUDIO_REQUEST,
  FETCH_MONEY_REPORT_REQUEST,
  UPDATE_PEER_STATUS_REQUEST,
  FETCH_LISTENERS_AUDIO_LIST_REQUEST,
  FETCH_LISTENERS_AUDIO_LIST_COUNT_REQUEST,
} from './actionTypes';

function* UpdateListenersProfileRejectedSaga(action: any): any {
  try {
    const { filters, from, userId } = action.payload;
    const response = yield call(Api.UpdateListenersProfileRejected(action.payload));
    if (response.status === 200) {
      if (from === 'profile') {
        yield put(getListenerDetailsRequest({ id: userId }));
      } else {
        const response = yield call(Api.fetchListeners(filters));
        yield put(fetchListenersSuccess(response));
      }
      toast.success(' Profile updated Succesfully');
    }
  } catch (e: unknown) {
    yield put(
      updateListenersProfileRejectedFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* updateListenersSaga(action: any): any {
  try {
    const { listenerId, memberId, packageCode, peerChange, id } = action.payload;
    const peerType = { payload: peerChange };
    delete action.payload['peerChange'];
    const response = yield call(Api.UpdateListenerDetails(action.payload));
    yield put(updateListenersSuccess(response));
    toast.success('Profile updated successfully');
    if (response.status === 200) {
      if (memberId) {
        const input = {
          partnerCode: packageCode,
          userId: action.payload.id,
        };
        const planResponse = packageCode ? yield call(Api.postRedeemPartnerCode(input)) : true;
        if (planResponse) {
          const memberResponse = yield call(Api.getMemberProfile({ memberId }));
          yield put(getMemberProfileSuccess(memberResponse));
        }
      } else if (listenerId) {
        if (peerType.payload) {
          yield UpdatePeerStatusSaga(peerType);
        }
        yield put(getListenerDetailsRequest({ id }));
      }
    }
  } catch (e: any) {
    yield put(
      updateListenersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
    const message = JSON.parse(e);
    toast.error(message?.data?.description);
  }
}

function* GetListenerDetailsSaga(action: any): any {
  try {
    const response = yield call(Api.getListenerDetails(action.payload));
    yield put(getListenerDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      getListenerDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchListenersSaga(action: any): any {
  try {
    yield delay(300);
    const response = yield call(Api.fetchListeners(action.payload));

    yield put(fetchListenersSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchListenersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchLogsSaga(action: any): any {
  try {
    yield delay(300);
    const res = Api.getLogs(action.payload);
    const response = yield call(res);

    yield put(
      fetchLogsSuccess({
        LOGS: response,
      }),
    );
  } catch (e: unknown) {
    yield put(
      fetchLogsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* GetListenTopicReportSaga(action: any): any {
  try {
    const response = yield call(Api.getListenersReport(action.payload));

    yield put(getListenTopicReportSuccess(response));
  } catch (e: unknown) {
    yield put(
      getListenTopicReportFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* postListenerGradeSaga(action: any): any {
  try {
    const response = yield call(Api.gradeListener(action.payload));

    yield put(postListenerGradeSuccess(response));
  } catch (e: unknown) {
    console.error('err--', e);
    yield put(
      postListenerGradeFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchMessageQuality(action: any): any {
  try {
    const response = yield call(Api.getMessageQualityOptions(action.payload));
    yield put(fetchMessageQualitySuccess(response));
  } catch (e: unknown) {
    console.error('experience:fetchMessageQuality::error--', e);
    yield put(
      fetchMessageQualityFailure({
        experiencesFavoritesError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchListConnectionsSaga(action: any): any {
  try {
    const res = Api.getConnections(action.payload);
    const response = yield call(res);

    yield put(fetchListConnectionsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchListConnectionsFailure({
        loginError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchCallLogSaga(action: any): any {
  try {
    const res = Api.getCallLog(action.payload);
    const response = yield call(res);

    yield put(fetchCallLogSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchCallLogFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchConnectionCount(action: any): any {
  try {
    const response = yield call(Api.getConnectionsCount(action.payload));
    yield put(fetchConnectionCountSuccess(response));
  } catch (e: unknown) {
    console.error('home:fetchConnectionCount::error--', e);
    yield put(
      fetchConnectionCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchListenerCount(action: any): any {
  try {
    yield delay(300);
    const response = yield call(Api.fetchListenersCount(action.payload));
    yield put(fetchListenerCountSuccess(response));
  } catch (e: unknown) {
    console.error('home:fetchListenerCount::error--', e);
    yield put(
      fetchListenerCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}
function* fetchListenerAvailability(action: any): any {
  try {
    const response = yield call(Api.getListenerAvailabilty(action.payload));
    yield put(fetchListenerAvailabilitySuccess(response));
  } catch (e: unknown) {
    console.error('experience:fetchListenerAvailability::error--', e);
    yield put(
      fetchListenerAvailabilityFailure({
        listenerAvailabilty: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}
function* fetchEngagement(action: any): any {
  try {
    const response = yield call(Api.getEngagement(action.payload));
    yield put(fetchEngagementSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchEngagementFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}
function* fetchLogsCountSaga(action: any): any {
  try {
    yield delay(500);
    const res = Api.getLogsCount(action.payload);

    const response = yield call(res);

    yield put(
      fetchLogsCountSuccess({
        LOGSCOUNT: response,
      }),
    );
  } catch (e: unknown) {
    yield put(
      fetchLogsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* GetListenerTagsSaga(action: any): any {
  try {
    const response = yield call(Api.getListenerTags(action.payload));
    yield put(getListenerTagsSuccess(response));
  } catch (e: unknown) {
    yield put(
      getListenerTagsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* updateListenersTagsSaga(action: any): any {
  const { id } = action.payload;
  try {
    const response = yield call(Api.updateListenersTags(action.payload));
    toast.success('Tag updated successfully');
  } catch (e: any) {
    yield put(
      updateListenersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
    const message = JSON.parse(e);
    toast.error(message?.data?.description);
  }
  yield put(getListenerTagsRequest({ id, payload: { include_hidden: true } }));
}

function* removeListenersTagsSaga(action: any): any {
  try {
    const { id } = action.payload;
    const response = yield call(Api.removeListenersTags(action.payload));
    if (response.status === 200) {
      yield put(getListenerTagsRequest({ id, payload: { include_hidden: true } }));
    }
    toast.success('Tag Removed Successfully');
  } catch (e: any) {
    yield put(
      updateListenersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
    const message = JSON.parse(e);
    toast.error(message?.data?.description);
  }
}

function* updateListenerTagsListSaga(action: any): any {
  const { id } = action.payload;
  try {
    const response = yield call(Api.updateListenerTagsList(action.payload));
    yield put(updateListenersSuccess(response));
  } catch (e: any) {
    yield put(
      updateListenersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
  yield put(getListenerTagsRequest({ id, payload: { include_hidden: true } }));
}

function* postAudioSaga(action: any): any {
  const { id, type, filters } = action.payload;
  try {
    const response = yield call(Api.postGradeAudio(action.payload));
    if (response.status === 200) {
      toast.success('Audio Updated Successfully');
    }
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  if (type === 'messages') {
    const response = yield call(Api.fetchListenrsAudioList(filters));
    yield put(fetchListenersAudioListSuccess(response));
  } else {
    const response = yield call(Api.getAudioList({ id }));
    yield put(
      fetchAudioListSuccess({
        AudioList: response,
      }),
    );
  }
}

function* fetchAudioListSaga(action: any): any {
  try {
    const response = yield call(Api.getAudioList(action.payload));
    yield put(
      fetchAudioListSuccess({
        AudioList: response,
      }),
    );
  } catch (e: any) {
    yield put(fetchAudioListFailure(JSON.parse(e)));
  }
}

function* UpdateAudioSaga(action: any): any {
  const { id, type, filters } = action.payload;
  try {
    const res = yield call(Api.updateAudio(action.payload));
    if (res.status === 200) {
      if (type === 'messages') {
        const response = yield call(Api.fetchListenrsAudioList(filters));
        yield put(fetchListenersAudioListSuccess(response));
      } else {
        const response = yield call(Api.getAudioList({ id }));
        yield put(
          fetchAudioListSuccess({
            AudioList: response,
          }),
        );
      }
      toast.success('Audio Updated Successfully');
    }
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  if (type === 'messages') {
    const response = yield call(Api.fetchListenrsAudioList({ limit: 25, page: 1 }));
    yield put(fetchListenersAudioListSuccess(response));
  } else {
    const response = yield call(Api.getAudioList({ id }));
    yield put(
      fetchAudioListSuccess({
        AudioList: response,
      }),
    );
  }
}

function* fetchMoneyDetailsSaga(action: any): any {
  try {
    const response = yield call(Api.GetMoneyReport(action.payload));
    yield put(fetchMoneyDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMoneyDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* UpdatePeerStatusSaga(action: any): any {
  try {
    const response = yield call(Api.UpdatePeerStatus(action.payload));
    yield put(
      updatePeerStatusSuccess({
        RemovePayment: response,
      }),
    );
    return response;
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
      return error;
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(
      updatePeerStatusFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchListenersAudioListSaga(action: any): any {
  try {
    yield delay(100);
    const response = yield call(Api.fetchListenrsAudioList(action.payload));
    yield put(fetchListenersAudioListSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchListenersAudioListFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchListenersAudioListCountSaga(action: any): any {
  try {
    yield delay(100);
    const response = yield call(Api.fetchListenrsAudioListCount(action.payload));
    yield put(fetchListenersAudioListCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchListenersAudioListCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* ListenersSaga() {
  yield all([
    takeLatest(FETCH_LISTENERS_REQUEST, fetchListenersSaga),
    takeLatest(GET_LISTEN_TOPIC_REPORT_REQUEST, GetListenTopicReportSaga),
    takeLatest(POST_LISTENER_GRADE_REQUEST, postListenerGradeSaga),
    takeLatest(FETCH_MESSAGE_QUALITY_REQUEST, fetchMessageQuality),
    takeLatest(FETCH_LIST_CONNECTIONS_REQUEST, fetchListConnectionsSaga),
    takeLatest(FETCH_CALL_LOG_REQUEST, fetchCallLogSaga),
    takeLatest(FETCH_CONNECTIONS_COUNT_REQUEST, fetchConnectionCount),
    takeLatest(UPDATE_LISTENER_DETAILS_REQUEST, updateListenersSaga),
    takeLatest(GET_LISTENER_DETAILS_REQUEST, GetListenerDetailsSaga),
    takeLatest(POST_LISTENER_PROFILE_REJECTED_REQUEST, UpdateListenersProfileRejectedSaga),
    takeLatest(FETCH_LISTENERS_COUNT_REQUEST, fetchListenerCount),
    takeLatest(FETCH_LISTENER_AVAILABILTY_REQUEST, fetchListenerAvailability),
    takeLatest(FETCH_ENGAGEMENT_REQUEST, fetchEngagement),
    takeLatest(FETCH_LOGS_REQUEST, fetchLogsSaga),
    takeLatest(FETCH_LOGSCOUNT_REQUEST, fetchLogsCountSaga),
    takeLatest(UPDATE_LISTENER_TAGS_REQUEST, updateListenersTagsSaga),
    takeLatest(GET_LISTENER_TAGS_REQUEST, GetListenerTagsSaga),
    takeLatest(REMOVE_LISTENER_TAGS_REQUEST, removeListenersTagsSaga),
    takeLatest(UPDATE_LISTENER_TAGS_LIST_REQUEST, updateListenerTagsListSaga),
    takeLatest(FETCH_AUDIO_LIST_REQUEST, fetchAudioListSaga),
    takeLatest(UPDATE_AUDIO_REQUEST, UpdateAudioSaga),
    takeLatest(POST_AUDIO_REQUEST, postAudioSaga),
    takeLatest(FETCH_MONEY_REPORT_REQUEST, fetchMoneyDetailsSaga),
    takeLatest(UPDATE_PEER_STATUS_REQUEST, UpdatePeerStatusSaga),
    takeLatest(FETCH_LISTENERS_AUDIO_LIST_REQUEST, fetchListenersAudioListSaga),
    takeLatest(FETCH_LISTENERS_AUDIO_LIST_COUNT_REQUEST, fetchListenersAudioListCountSaga),
  ]);
}

export default ListenersSaga;
