export const POST_ELIGIBILTY_REQUEST = 'POST_ELIGIBILTY_REQUEST';
export const POST_ELIGIBILTY_SUCCESS = 'POST_ELIGIBILTY_SUCCESS';
export const POST_ELIGIBILTY_FAILURE = 'POST_ELIGIBILTY_FAILURE';

//User details
export const FETCH_ELIGILITY_REQUEST = 'FETCH_ELIGILITY_REQUEST';
export const FETCH_ELIGILITY_SUCCESS = 'FETCH_ELIGILITY_SUCCESS';
export const FETCH_ELIGILITY_FAILURE = 'FETCH_ELIGILITY_FAILURE';

export const POST_VALIDATE_ELIGIBILTY_REQUEST = 'POST_VALIDATE_ELIGIBILTY_REQUEST';
export const POST_VALIDATE_ELIGIBILTY_SUCCESS = 'POST_VALIDATE_ELIGIBILTY_SUCCESS';
export const POST_VALIDATE_ELIGIBILTY_FAILURE = 'POST_VALIDATE_ELIGIBILTY_FAILURE';

//eligibilty list
export const FETCH_ELIGIBILTY_LIST_REQUEST = 'FETCH_ELIGIBILTY_LIST_REQUEST';
export const FETCH_ELIGIBILTY_LIST_SUCCESS = 'FETCH_ELIGIBILTY_LIST_SUCCESS';
export const FETCH_ELIGIBILTY_LIST_FAILURE = 'FETCH_ELIGIBILTY_LIST_FAILURE';

//eligibilty active list
export const FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST = 'FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST';
export const FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS = 'FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS';
export const FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE = 'FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE';

//eligibilty active list
export const FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST = 'FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST';
export const FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS = 'FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS';
export const FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE = 'FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE';

// activate eligibilty
export const UPDATE_ELIGIBILTY_REQUEST = 'UPDATE_ELIGIBILTY_REQUEST';
export const UPDATE_ELIGIBILTY_SUCCESS = 'UPDATE_ELIGIBILTY_SUCCESS';
export const UPDATE_ELIGIBILTY_FAILURE = 'UPDATE_ELIGIBILTY_FAILURE';

// remove eligibilty
export const REMOVE_ELIGIBILTY_REQUEST = 'REMOVE_ELIGIBILTY_REQUEST';
export const REMOVE_ELIGIBILTY_SUCCESS = 'REMOVE_ELIGIBILTY_SUCCESS';
export const REMOVE_ELIGIBILTY_FAILURE = 'REMOVE_ELIGIBILTY_FAILURE';

// process eligibility
export const PROCESS_ELIGIBILTY_REQUEST = 'PROCESS_ELIGIBILTY_REQUEST';
export const PROCESS_ELIGIBILTY_SUCCESS = 'PROCESS_ELIGIBILTY_SUCCESS';
export const PROCESS_ELIGIBILTY_FAILURE = 'PROCESS_ELIGIBILTY_FAILURE';

// download eligibility members
export const DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST = 'DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST';
export const DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS = 'DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS';
export const DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE = 'DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE';

// process eligibility report
export const PROCESS_ELIGIBILITY_REPORT_REQUEST = 'PROCESS_ELIGIBILITY_REPORT_REQUEST';
export const PROCESS_ELIGIBILITY_REPORT_SUCCESS = 'PROCESS_ELIGIBILITY_REPORT_SUCCESS';
export const PROCESS_ELIGIBILITY_REPORT_FAILURE = 'PROCESS_ELIGIBILITY_REPORT_FAILURE';

// transfer eligibility report
export const TRANSFER_ELIGIBILITY_REPORT_REQUEST = 'TRANSFER_ELIGIBILITY_REPORT_REQUEST';
export const TRANSFER_ELIGIBILITY_REPORT_SUCCESS = 'TRANSFER_ELIGIBILITY_REPORT_SUCCESS';
export const TRANSFER_ELIGIBILITY_REPORT_FAILURE = 'TRANSFER_ELIGIBILITY_REPORT_FAILURE';
