import React from 'react';

type props = {
  Label: string;
  color: string;
  textColor: string;
};

const Button: React.FunctionComponent<props> = ({ Label, color, textColor }): JSX.Element => {
  const textcolor = ` ${textColor.toString()} `;
  const bgcolor = ` ${color.toString()} `;
  return <button className={'w-full h-full rounded-full ' + bgcolor + textcolor}>{Label}</button>;
};

export default Button;
