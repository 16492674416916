import { toast } from 'react-toastify';
import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../../../api';

import {
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  fetchUserDetailsByIdSuccess,
  fetchUserDetailsByIdFailure,
  postRequestPasswordResetSuccess,
  postRequestPasswordResetFailure,
  postRedeemPasswordResetSuccess,
  postRedeemPasswordResetFailure,
  fetchAuthenticateWithTokenSuccess,
  fetchAuthenticateWithTokenFailure,
  postResendSwitchboardOnboardingLinkSuccess,
  postResendSwitchboardOnboardingLinkFailure,
  postClinetAdminOnboardCompleteSuccess,
  postClinetAdminOnboardCompleteFailure,
  fetchEmailAvailableSuccess,
  fetchEmailAvailableFailure,
  fetchMobileValidSuccess,
  fetchMobileValidFailure,
} from './actions';
import {
  FETCH_LOGIN_REQUEST,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_BY_ID_REQUEST,
  POST_REQUEST_PASSWORD_REQUEST,
  POST_REDEEM_PASSWORD_RESET_REQUEST,
  FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST,
  FETCH_EMAIL_AVAILABLE_REQUEST,
  FETCH_MOBILE_VALID_REQUEST,
} from './actionTypes';

function* fetchLoginSaga(action: any): any {
  try {
    const res = Api.authenticate(action.payload);
    const response = yield call(res);

    yield put(fetchLoginSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchLoginFailure({
        loginError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchUserDetailsSaga(action: any): any {
  try {
    const res = Api.fetchUserdetails(action.payload);
    const response = yield call(res);

    yield put(fetchUserDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchUserDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchMobileValid(action: any): any {
  try {
    yield delay(500);
    yield call(Api.MobileValid(action.payload));
    yield put(
      fetchMobileValidSuccess({
        mobileValid: true,
      }),
    );
  } catch (e: any) {
    console.error('onBoardingSaga:fetchMobileValid::error--', e);
    yield put(
      fetchMobileValidSuccess({
        mobileValid: false,
      }),
    );
  }
}

function* fetchUserDetailsById(action: any): any {
  try {
    const response = yield call(Api.fetchUserDetailsById(action.payload));
    yield put(fetchUserDetailsByIdSuccess(response));
  } catch (e: unknown) {
    console.error('home:fetchUserDetailsById::error--', e);
    yield put(
      fetchUserDetailsByIdFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* postRestPassword(action: any): any {
  try {
    const response = yield call(Api.postRequestPassword(action.payload));
    yield put(postRequestPasswordResetSuccess(response));
    toast.success(
      'Thank you. We have sent you an email with instructions to retrieve your password. If you have not received our message, check that you have entered the correct email address',
    );
  } catch (e: any) {
    yield put(
      postRequestPasswordResetFailure({
        requestPasswordResetError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
    toast.error(
      'Something went wrong. Please make sure your email address is correct, and that you have completed onboarding. If this problem persists please contact support.',
    );
  }
}

function* postRedeemPasswordReset(action: any): any {
  try {
    const response = yield call(Api.postRedeemPasswordReset(action.payload));
    yield put(postRedeemPasswordResetSuccess(response));
    toast.success(' Password Has Been Changed Successfully ');
  } catch (e: any) {
    console.error('err--', e);
    const message = JSON.parse(e);
    toast.error(message?.data?.description);
    yield put(
      postRedeemPasswordResetFailure({
        redeemPasswordResetError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchAuthenticateWithToken(action: any): any {
  try {
    const response = yield call(Api.AuthenticateWithToken(action.payload));
    yield put(fetchAuthenticateWithTokenSuccess(response));
  } catch (e: any) {
    console.error('LoginSaga:fetchAuthenticateWithToken::error--', e);
    yield put(fetchAuthenticateWithTokenFailure(JSON.parse(e)));
  }
}

function* postResendSwitchboardOnboardingLink(action: any): any {
  try {
    const response = yield call(Api.ResendSwitchBoardOnBoardingLink(action.payload));
    yield put(postResendSwitchboardOnboardingLinkSuccess(response));
  } catch (e: any) {
    console.error('LoginSaga:postResendSwitchboardOnboardingLink::error--', e);
    yield put(postResendSwitchboardOnboardingLinkFailure(JSON.parse(e)));
  }
}

function* postClinetAdminOnboardComplete(action: any): any {
  try {
    const response = yield call(Api.ClientAdminOnboardingComplete(action.payload));
    if (response.status === 200) {
      localStorage.setItem('role_type', 'client_administrator');
    }
    yield put(postClinetAdminOnboardCompleteSuccess(response));
  } catch (e: any) {
    console.error('LoginSaga:postClinetAdminOnboardComplete::error--', e);
    yield put(postClinetAdminOnboardCompleteFailure(JSON.parse(e)));
  }
}

function* fetchEmailAvailable(action: any): any {
  try {
    yield delay(500);
    const response = yield call(Api.EmailAvailable(action.payload));
    yield put(fetchEmailAvailableSuccess(response));
  } catch (e: any) {
    console.error('LoginSaga:fetchEmailAvailable::error--', e);
    yield put(fetchEmailAvailableFailure(e));
  }
}

function* LoginSaga() {
  yield all([
    takeLatest(FETCH_LOGIN_REQUEST, fetchLoginSaga),
    takeLatest(FETCH_USER_DETAILS_REQUEST, fetchUserDetailsSaga),
    takeLatest(FETCH_USER_DETAILS_BY_ID_REQUEST, fetchUserDetailsById),
    takeLatest(POST_REQUEST_PASSWORD_REQUEST, postRestPassword),
    takeLatest(POST_REDEEM_PASSWORD_RESET_REQUEST, postRedeemPasswordReset),
    takeLatest(FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST, fetchAuthenticateWithToken),
    takeLatest(POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST, postResendSwitchboardOnboardingLink),
    takeLatest(POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST, postClinetAdminOnboardComplete),
    takeLatest(FETCH_EMAIL_AVAILABLE_REQUEST, fetchEmailAvailable),
    takeLatest(FETCH_MOBILE_VALID_REQUEST, fetchMobileValid),
  ]);
}

export default LoginSaga;
