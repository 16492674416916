import * as React from 'react';
import LoginDetails from '../../components/account-login';

const Login: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <LoginDetails />
    </div>
  );
};
export default Login;
