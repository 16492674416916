export const POST_TAGS_REQUEST = 'POST_TAGS_REQUEST';
export const POST_TAGS_SUCCESS = 'POST_TAGS_SUCCESS';
export const POST_TAGS_FAILURE = 'POST_TAGS_FAILURE';

export const FETCH_TAGS_LIST_REQUEST = 'FETCH_TAGS_LIST_REQUEST';
export const FETCH_TAGS_LIST_SUCCESS = 'FETCH_TAGS_LIST_SUCCESS';
export const FETCH_TAGS_LIST_FAILURE = 'FETCH_TAGS_LIST_FAILURE';

export const UPDATE_TAGS_REQUEST = 'UPDATE_TAGS_REQUEST';
export const UPDATE_TAGS_SUCCESS = 'UPDATE_TAGS_SUCCESS';
export const UPDATE_TAGS_FAILURE = 'UPDATE_TAGS_FAILURE';

// remove tags
export const REMOVE_TAGS_REQUEST = 'REMOVE_TAGS_REQUEST';
export const REMOVE_TAGS_SUCCESS = 'REMOVE_TAGS_SUCCESS';
export const REMOVE_TAGS_FAILURE = 'REMOVE_TAGS_FAILURE';

export const FETCH_TAGS_COUNT_REQUEST = 'FETCH_TAGS_COUNT_REQUEST';
export const FETCH_TAGS_COUNT_SUCCESS = 'FETCH_TAGS_COUNT_SUCCESS';
export const FETCH_TAGS_COUNT_FAILURE = 'FETCH_TAGS_COUNT_FAILURE';

export const FETCH_TAG_REQUEST = 'FETCH_TAG_REQUEST';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE';

export const FETCH_TAG_SORT_REQUEST = 'FETCH_TAG_SORT_REQUEST';
export const FETCH_TAG_SORT_SUCCESS = 'FETCH_TAG_SORT_SUCCESS';
export const FETCH_TAG_SORT_FAILURE = 'FETCH_TAG_SORT_FAILURE';

export const POST_TAGS_GROUP_REQUEST = 'POST_TAGS_GROUP_REQUEST';
export const POST_TAGS_GROUP_SUCCESS = 'POST_TAGS_GROUP_SUCCESS';
export const POST_TAGS_GROUP_FAILURE = 'POST_TAGS_GROUP_FAILURE';

export const FETCH_TAGS_GROUP_LIST_REQUEST = 'FETCH_TAGS_GROUP_LIST_REQUEST';
export const FETCH_TAGS_GROUP_LIST_SUCCESS = 'FETCH_TAGS_GROUP_LIST_SUCCESS';
export const FETCH_TAGS_GROUP_LIST_FAILURE = 'FETCH_TAGS_GROUP_LIST_FAILURE';

export const UPDATE_TAGS_GROUP_REQUEST = 'UPDATE_TAGS_GROUP_REQUEST';
export const UPDATE_TAGS_GROUP_SUCCESS = 'UPDATE_TAGS_GROUP_SUCCESS';
export const UPDATE_TAGS_GROUP_FAILURE = 'UPDATE_TAGS_GROUP_FAILURE';

// remove tags
export const REMOVE_TAGS_GROUP_REQUEST = 'REMOVE_TAGS_GROUP_REQUEST';
export const REMOVE_TAGS_GROUP_SUCCESS = 'REMOVE_TAGS_GROUP_SUCCESS';
export const REMOVE_TAGS_GROUP_FAILURE = 'REMOVE_TAGS_GROUP_FAILURE';

export const FETCH_TAGS_GROUP_COUNT_REQUEST = 'FETCH_TAGS_GROUP_COUNT_REQUEST';
export const FETCH_TAGS_GROUP_COUNT_SUCCESS = 'FETCH_TAGS_GROUP_COUNT_SUCCESS';
export const FETCH_TAGS_GROUP_COUNT_FAILURE = 'FETCH_TAGS_GROUP_COUNT_FAILURE';

export const FETCH_TAGS_GROUP_REQUEST = 'FETCH_TAGS_GROUP_REQUEST';
export const FETCH_TAGS_GROUP_SUCCESS = 'FETCH_TAGS_GROUP_SUCCESS';
export const FETCH_TAGS_GROUP_FAILURE = 'FETCH_TAGS_GROUP_FAILURE';
