import React from 'react';
import { SectionLabel, NumericLabel, PieChartLabel } from '../../components/common';
import { StackedBarChart } from '../../components/charts';
import ReactChart from '../../components/charts/pie-chart';
import Header from '../../components/common/global-top-bar';
import CalendarPaginator from '../../components/calender-paginator';
import PieChartSingle from '../../components/charts/pie-chart-single';
import SwitchBar from '../../components/switch-bar';
import { connect } from 'react-redux';

const gender = [
  { name: 'Female', value: 61, color: '#CA498C' },
  { name: 'Male', value: 36, color: '#E071AB' },
  { name: 'Others', value: 3, color: '#E6BFCE' },
];

const age = [
  { name: "20'S", value: 42, color: '#1D1183' },
  { name: "30'S", value: 30, color: '#2D19D1' },
  { name: "40'S", value: 19, color: '#6452FF' },
  { name: "50'S", value: 6, color: '#988CFF' },
  { name: "60'S", value: 3, color: '#C5BEFF' },
];
const race_enthnicity = [
  { name: 'A', value: 30, color: '#178A0D' },
  { name: 'B', value: 19, color: '#27B61A' },
  { name: 'C', value: 42, color: '#42DD34' },
  { name: 'D', value: 6, color: '#7EF174' },
  { name: 'E', value: 3, color: '#B6FFAF' },
];

const spirituality = [
  { name: 'A', value: 30, color: '#E5C646' },
  { name: 'B', value: 19, color: '#F9F5E5' },
  { name: 'C', value: 42, color: '#F4E9BD' },
  { name: 'D', value: 6, color: '#EFDE96' },
  { name: 'E', value: 3, color: '#EAD26E' },
];

type props = {
  props: boolean;
};

const GlobalDashboardTopics = ({}: props) => {
  return (
    <div>
      <Header heading={'Global Dashboard'} userName={'Jason'} />
      {/* <SwitchBar
        heading={GlobalHeaderoptions}
        position={2}
        optionalButton={
          <div className="w-6/12">
            <CalendarPaginator changedDate={() => {}} />
          </div>
        }
      /> */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending: state.Comms.pending || state.Listeners.pending || state.Client.pending || false,
  };
};

export default connect(mapStateToProps)(GlobalDashboardTopics);
