export const POST_TIERS_REQUEST = 'POST_TIERS_REQUEST';
export const POST_TIERS_SUCCESS = 'POST_TIERS_SUCCESS';
export const POST_TIERS_FAILURE = 'POST_TIERS_FAILURE';

//partner Code Redemption
export const FETCH_TIERS_LIST_REQUEST = 'FETCH_TIERS_LIST_REQUEST';
export const FETCH_TIERS_LIST_SUCCESS = 'FETCH_TIERS_LIST_SUCCESS';
export const FETCH_TIERS_LIST_FAILURE = 'FETCH_TIERS_LIST_FAILURE';

// activate Subscription
export const UPDATE_TIERS_REQUEST = 'UPDATE_TIERS_REQUEST';
export const UPDATE_TIERS_SUCCESS = 'UPDATE_TIERS_SUCCESS';
export const UPDATE_TIERS_FAILURE = 'UPDATE_TIERS_FAILURE';

// remove payment
export const REMOVE_TIERS_REQUEST = 'REMOVE_TIERS_REQUEST';
export const REMOVE_TIERS_SUCCESS = 'REMOVE_TIERS_SUCCESS';
export const REMOVE_TIERS_FAILURE = 'REMOVE_TIERS_FAILURE';

export const POST_TEST_TIERS_REQUEST = 'POST_TEST_TIERS_REQUEST';
export const POST_TEST_TIERS_SUCCESS = 'POST_TEST_TIERS_SUCCESS';
export const POST_TEST_TIERS_FAILURE = 'POST_TEST_TIERS_FAILURE';

export const FETCH_TIER_REQUEST = 'FETCH_TIER_REQUEST';
export const FETCH_TIER_SUCCESS = 'FETCH_TIER_SUCCESS';
export const FETCH_TIER_FAILURE = 'FETCH_TIER_FAILURE';
