import {
  Topics,
  Listeners,
  Login,
  Clients,
  Members,
  ClientsPortal,
  Eligibilty,
  Comms,
  Admins,
  Tiers,
  Partner,
  Tags,
  Resources,
  Safety,
} from './axios';

const topic = Topics();
const listener = Listeners();
const login = Login();
const clients = Clients();
const members = Members();
const clientPortal = ClientsPortal();
const eligibility = Eligibilty();
const comms = Comms();
const admins = Admins();
const tiers = Tiers();
const partners = Partner();
const tags = Tags();
const resources = Resources();
const safety = Safety();

export const fetchTopics = () => topic.getTopics();
export const authenticate = (input: any) => login.authenticate(input);
export const fetchUserdetails = (input: any) => login.fetchUserdetails(input);
export const fetchUserDetailsById = (input: any) => login.fetchUserDetailsById(input);
export const postRequestPassword = (input: any) => login.postRequestPassword(input);
export const postRedeemPasswordReset = (input: any) => login.postRedeemPasswordReset(input);
export const AuthenticateWithToken = (input: any) => login.authenticateWithToken(input);
export const ResendSwitchBoardOnBoardingLink = (input: any) => login.resendSwitchBoardOnBoardingLink(input);
export const ClientAdminOnboardingComplete = (input: any) => login.clientAdminOnboardingComplete(input);
export const EmailAvailable = (input: any) => login.emailAvailable(input);
export const MobileValid = (input: any) => login.mobileValid(input);

export const getClientsList = (input: any) => clients.getClientsList(input);
export const getClientCount = (input: any) => clients.getClientsCount(input);
export const getClient = (input: any) => clients.getClient(input);
export const deleteClient = (input: any) => clients.deleteClient(input);
export const getTags = (input: any) => clients.getTags(input);
export const updatePackageProduct = (input: any) => clients.updatePackageProduct(input);
export const createPackageProduct = (input: any) => clients.createPackageProduct(input);
export const updatePackage = (input: any) => clients.updatePackaget(input);
export const createPackage = (input: any) => clients.createPackage(input);
export const addComms = (input: any) => clients.AddComms(input);
export const deleteComms = (input: any) => clients.deleteComms(input);
export const forceCheckIn = (input: any) => clients.forceCheckIn(input);
export const createClient = (input: any) => clients.createClient(input);
export const updateClient = (input: any) => clients.updateClient(input);
export const addCredits = (input: any) => clients.addCredits(input);
export const getcampaigns = (input: any) => clients.getCampaigns(input);
export const getproductTemplate = (input: any) => clients.getProductTemplate(input);
export const getincentive = (input: any) => clients.getIncentives(input);
export const getSystemMessages = (input: any) => clients.getSystemMessages(input);
export const getEmailTemplate = (input: any) => clients.getEmailTemplates(input);
export const getReports = (input: any) => clients.getReports(input);
export const getPackageReports = (input: any) => clients.getPackageReports(input);
export const appSettings = (input: any) => clients.appSettings(input);
export const fetchappSettings = (input: any) => clients.fetchappSettings(input);
export const deletePackage = (input: any) => clients.deletePackage(input);
export const FetchExternalProviderPlans = (input: any) => clients.FetchExternalProviderPlans(input);
export const createSubscriptions = (input: any) => clients.createSubscriptions(input);
export const deleteSubscriptions = (input: any) => clients.deleteSubscriptions(input);
export const updateSubscriptions = (input: any) => clients.updateSubscriptions(input); //
export const getDefaultSubscriptions = (input: any) => clients.getDefaultSubscriptions(input);

//Eligibilty
export const getEligibility = (input: any) => eligibility.getEligibilty(input);
export const getAllEligibilty = (input: any) => eligibility.getAllEligibilty(input);
export const createEligibilty = (input: any) => eligibility.createEligibilty(input);
export const updateEligibilty = (input: any) => eligibility.updateEligibilty(input);
export const validateEligibilty = (input: any) => eligibility.validateEligibilty(input);
export const deleteEligibilty = (input: any) => eligibility.deleteEligibilty(input);
export const processEligibility = (input: any) => eligibility.processEligibility(input);
export const downloadEligibilityMembers = (input: any) => eligibility.downloadEligibilityMembers(input);
export const processEligibilityReport = (input: any) => eligibility.processEligibilityReport(input);
export const transferEligibilityReport = (input: any) => eligibility.transferEligibilityReport(input);

//client portal
export const getSnapShot = (input: any) => clientPortal.getSnapShot(input);

// Comms
export const getCommsList = (input: any) => comms.getAllComms(input);
export const createComms = (input: any) => comms.createComms(input);
export const updateComms = (input: any) => comms.updateComms(input);
export const deleteCommsTemplate = (input: any) => comms.deleteComms(input);
export const fetchSystemMessagesGroups = (input: any) => comms.fetchSystemMessagesGroups(input);
export const testComms = (input: any) => comms.testComms(input);
export const repairTemplates = (input: any) => comms.RepairTemplates(input);

//listeners
export const fetchListeners = (input: any) => listener.getListeners(input);
export const fetchListenersCount = (input: any) => listener.getListenersCount(input);
export const getLogs = (input: any) => listener.getLogs(input);
export const getLogsCount = (input: any) => listener.getLogsCount(input);
export const gradeListener = (input: any) => listener.gradeListener(input);
export const getListenersReport = (input: any) => listener.getListenersReport(input);
export const getMessageQualityOptions = (input: any) => listener.getMessageQualityOptions(input);
export const getConnections = (input: any) => listener.getConnections(input);
export const getCallLog = (input: any) => listener.getCallLog(input);
export const getConnectionsCount = (input: any) => listener.getConnectionsCount(input); //  getListenerDetails: (input: any) => getListenerDetails(client, input),
export const getListenerDetails = (input: any) => listener.getListenerDetails(input);
export const UpdateListenerDetails = (input: any) => listener.UpdateListenerDetails(input);
export const UpdateListenersProfileRejected = (input: any) => listener.UpdateListenersProfileRejected(input); //UpdateListenersProfileRejected
export const getListenerAvailabilty = (input: any) => listener.getListenerAvailabilty(input); //
export const getEngagement = (input: any) => listener.getEngagement(input); //
export const getAudioList = (input: any) => listener.getAudioList(input); //
export const postGradeAudio = (input: any) => listener.postGradeAudio(input); //
export const updateAudio = (input: any) => listener.updateAudio(input); //
export const GetMoneyReport = (input: any) => listener.getMoneyreport(input);
export const UpdatePeerStatus = (input: any) => listener.UpdatePeerStatus(input);
export const fetchListenrsAudioList = (input: any) => listener.fetchListenrsAudioList(input);
export const fetchListenrsAudioListCount = (input: any) => listener.fetchListenrsAudioListCount(input);

//members
export const fetchMembers = (input: any) => members.getMembers(input); //
export const fetchMembersConnections = (input: any) => members.fetchMembersConnections(input); //
export const fetchMembersConnectionsCount = (input: any) => members.fetchMembersConnectionsCount(input); //
export const getMemberProfile = (input: any) => members.getMemberProfile(input);
export const getMembersCount = (input: any) => members.getMembersCount(input);
export const getMembersLogs = (input: any) => members.getMembersLogs(input);
export const getMembersLogsCount = (input: any) => members.getMembersLogsCount(input);

//Campaigns
export const getAllCampaigns = (input: any) => comms.getAllCampaigns(input);
export const updateCampaigns = (input: any) => comms.updateCampaigns(input);
export const createCampaigns = (input: any) => comms.createCampaigns(input);
export const deleteCampaigns = (input: any) => comms.deleteCampaigns(input);
export const testCampaigns = (input: any) => comms.testCampaigns(input);

//admins
export const fetchAdmindetails = (input: any) => admins.fetchAdmindetails(input);
export const fetchAdminCount = (input: any) => admins.fetchAdminCount(input);
export const deleteAdminAccess = (input: any) => admins.deleteAdminAccess(input);
export const revokeAdminAccess = (input: any) => admins.revokeAdminAccess(input);
export const resendAdminInvite = (input: any) => admins.resendAdminInvite(input);
export const inviteViaEmail = (input: any) => admins.inviteViaEmail(input);
//TOS
export const getTos = (input: any) => clients.getTos(input);
export const updateTos = (input: any) => clients.updateTos(input);

//tiers
export const getAlltiers = (input: any) => tiers.getAllTiers(input);
export const createTiers = (input: any) => tiers.createTiers(input);
export const deleteTiers = (input: any) => tiers.deleteTiers(input);
export const updateTiers = (input: any) => tiers.updateTiers(input); //
export const fetchTier = (input: any) => tiers.fetchTier(input); //
export const postRedeemPartnerCode = (input: any) => partners.postRedeemPartnerCode(input);

//tags
export const getAlltags = (input: any) => tags.getAllTags(input);
export const createTags = (input: any) => tags.createTags(input);
export const deleteTags = (input: any) => tags.deleteTags(input);
export const updateTags = (input: any) => tags.updateTags(input); //
export const fetchTags = (input: any) => tags.fetchTag(input); //
export const fetchTagsCount = (input: any) => tags.fetchTagsCount(input); //
export const updateTagsSort = (input: any) => tags.updateTagsSort(input); //
export const getListenerTags = (input: any) => tags.getListenerTags(input); //
export const updateListenersTags = (input: any) => tags.updateListenersTags(input); //
export const removeListenersTags = (input: any) => tags.removeListenersTags(input); //
export const updateListenerTagsList = (input: any) => tags.updateListenerTagsList(input); //

//tags Groups
export const getAlltagGroups = (input: any) => tags.getAllTagsGroup(input);
export const createTagsGroup = (input: any) => tags.createTagsGroup(input);
export const deleteTagsGroup = (input: any) => tags.deleteTagsGroup(input);
export const updateTagsGroup = (input: any) => tags.updateTagsGroup(input); //
export const fetchTagsGroup = (input: any) => tags.fetchTagsGroup(input); //
export const fetchTagsGroupCount = (input: any) => tags.fetchTagsGroupCount(input); //

export const getAllResources = (input: any) => resources.getAllResources(input);
export const createResources = (input: any) => resources.createResources(input);
export const deleteResources = (input: any) => resources.deleteResources(input);
export const updateResources = (input: any) => resources.updateResources(input); //
export const fetchResource = (input: any) => resources.fetchResource(input); //

// safety
export const getAllBlackList = (input: any) => safety.getAllBlackList(input);
export const createBlackList = (input: any) => safety.createBlackList(input);
export const deleteBlackList = (input: any) => safety.deleteBlackList(input);
export const fetchBlackListCount = (input: any) => safety.fetchBlackListCount(input); //

export const getAllBlocked = (input: any) => safety.getAllBlocked(input);
export const createBlocked = (input: any) => safety.createBlocked(input);
export const deleteBlocked = (input: any) => safety.deleteBlocked(input);
export const fetchBlockedCount = (input: any) => safety.fetchBlockedCount(input);

export const getAllUsers = (input: any) => safety.getAllUsers(input);
export const updateUser = (input: any) => safety.updateUser(input); //
export const getAllConnections = (input: any) => safety.getAllConnections(input);
export const getAllConnectionCounts = (input: any) => safety.getAllConnectionCounts(input); //
export const UpdateSettiings = (input: any) => safety.UpdateSettiings(input); //
