import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import HeadBack from './redirect';
import SwitchBar from '../../components/switch-bar';
import SaveButton from './switch-bar-button';
import { HeaderOptions } from './clients-constants';
import { clientQueryKeys, useClient } from '../../hooks/useClients';
import { Spinner, ToggleButton } from '../../components/common';
import { normalizeMobilePhone } from '../../components/common/phone-validation';
import EditClientResourceDetails from './components/resource-edit';
import { useQueryClient } from '@tanstack/react-query';
import { Resource, useResources, resourceQueryKeys } from '../../hooks/useResources';
import { Checkbox } from '@mui/material';
import { useUpdateClient } from '../../hooks/useClients';

const ClientResources: React.FC = () => {
  const clientId = +localStorage.getItem('clientid')!;
  const { data: client } = useClient(clientId);
  const [includeHidden, setIncludeHidden] = useState(false);
  const [filter, setFilter] = useState<string>('client');
  const [enabledResourceIds, setEnabledResourceIds] = useState<number[]>(client?.enabled_resource_ids || []);
  const [isDirty, setIsDirty] = useState(false);
  const queryClient = useQueryClient();
  const { updateClient } = useUpdateClient(clientId);

  const { data: resources, isLoading } = useResources(
    includeHidden,
    undefined,
    filter === 'client' ? clientId : undefined,
    true,
  );

  const switchBarSelectedTab = 4;

  const newResource = {
    name: '',
    description: '',
    long_description: '',
    meta_keywords: '',
    phone_number: '',
    url: '',
    url_description: '',
    image_url: '',
    sort_weight: 0,
    status: 'live',
    text_number: '',
    notes: '',
    client_id: clientId,
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setEnabledResourceIds(resources!.map((x) => x.id!));
    } else {
      setEnabledResourceIds([]);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, resourceId: number) => {
    if (event.target.checked) {
      setEnabledResourceIds((prev) => [...prev, resourceId]);
    } else {
      setEnabledResourceIds(enabledResourceIds?.filter((x) => x !== resourceId));
    }
  };

  const handleSave = () => {
    updateClient.mutate(
      {
        enabled_resource_ids: enabledResourceIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['clients']);
          setIsDirty(false);
        },
      },
    );
  };

  useEffect(() => {
    if (client && client.enabled_resource_ids) {
      setEnabledResourceIds(client.enabled_resource_ids);
    }
  }, [client]);

  const isArraysIdentical = (arr1: number[], arr2: number[]) => {
    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
  };

  useEffect(() => {
    if (enabledResourceIds && client?.enabled_resource_ids) {
      setIsDirty(!isArraysIdentical(enabledResourceIds, client?.enabled_resource_ids!));
    } else if (!client?.enabled_resource_ids) {
      setIsDirty(enabledResourceIds.length > 0);
    }
  }, [enabledResourceIds, client]);

  return (
    <div>
      <Header userName={''} heading={<HeadBack clientName={client?.name || ''} />} />
      <SwitchBar
        heading={HeaderOptions}
        position={switchBarSelectedTab}
        optionalButton={
          filter === 'global' && (
            <div className="w-1/12">
              <SaveButton onSave={handleSave} disable={!isDirty} />
            </div>
          )
        }
      />
      <Filters
        clientId={clientId}
        omitGlobal={client?.use_global_resources}
        includeHidden={includeHidden}
        setIncludeHidden={setIncludeHidden}
        filter={filter}
        setFilter={setFilter}
      />
      {isLoading && <Spinner />}
      <div className="max-window-height-table overflow-y-auto px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white sticky top-0">
            <tr className="text-left px-7">
              <th className="w-2/12">
                {filter === 'client' ? (
                  <div className="my-auto">
                    <EditClientResourceDetails resource={newResource} clientId={clientId} />
                  </div>
                ) : (
                  <div className="my=auto">
                    <Checkbox
                      defaultChecked={false}
                      checked={enabledResourceIds && enabledResourceIds.length === resources?.length}
                      onChange={(event) => handleAllChange(event)}
                    ></Checkbox>
                    All
                  </div>
                )}
              </th>
              <th className="w-2/12"></th>
              <th className="w-3/12">Name</th>
              <th className="w-3/12">Phone</th>
              <th className="w-3/12">URL</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {resources?.length ? (
              resources?.map((resource: Resource) => {
                return (
                  // @ts-ignore
                  <Fragment key={resource.id}>
                    <tr key={resource.id} className="bg-gray-200 text-gray-dark text-left border-t-8 border-white">
                      {filter === 'client' ? (
                        <td className="px-5 pt-5 w-2/12">
                          <EditClientResourceDetails resource={resource} clientId={clientId} />
                        </td>
                      ) : (
                        <td className="px-5 pt-5 w-2/12">
                          <Checkbox
                            defaultChecked={false}
                            checked={enabledResourceIds.includes(resource.id!)}
                            onChange={(event) => handleCheckboxChange(event, resource.id!)}
                          />
                        </td>
                      )}
                      <td className="pt-5 w-2/12">
                        {resource?.image_url ? (
                          <img width="60" src={resource?.image_url} alt="Resource Image" />
                        ) : (
                          <div className="w-24" />
                        )}
                      </td>
                      <td className="pt-5 w-3/12">
                        <div className="text-gray-dark font-extrabold truncate">{resource?.name}</div>
                      </td>
                      <td className="pt-5 w-3/12">{normalizeMobilePhone(resource?.phone_number?.replace('+1', ''))}</td>
                      <td className="pt-5 pl-1 w-3/12">
                        <div className="truncate">{resource?.url}</div>
                      </td>
                    </tr>
                    <tr className="bg-gray-200 text-gray-dark text-left h-6 group">
                      <td />
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate">{resource?.description}</div>
                      </td>
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate">{resource?.long_description}</div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="flex justify-center items-center">
                    <p className="text-gray-dark text-2xl">No Resources Found</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Filters: React.FC<{
  clientId: number;
  omitGlobal?: boolean;
  includeHidden: boolean;
  setIncludeHidden: React.Dispatch<React.SetStateAction<boolean>>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}> = ({ clientId, omitGlobal, includeHidden, setIncludeHidden, filter, setFilter }) => {
  const queryClient = useQueryClient();
  const { updateClient } = useUpdateClient(clientId);

  const handleUseGlobal = (data: boolean) => {
    updateClient.mutate(
      {
        use_global_resources: data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['clients']);
          queryClient.invalidateQueries([resourceQueryKeys.resources]);
          queryClient.invalidateQueries([resourceQueryKeys.resource]);
        },
      },
    );
  };

  const filterOptions = {
    client: 'Client',
    global: 'Global',
  };

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7">
      <div className="flex items-center py-4">
        <span className="px-3">Use Global Resources</span>{' '}
        <ToggleButton
          id={'omitGlobal'}
          status={omitGlobal}
          initialstatus={false}
          setStatus={(data: boolean) => {
            handleUseGlobal(data);
          }}
        />
      </div>
      <div className="flex items-center py-4">
        <span className="px-3">Only Hidden</span>{' '}
        <ToggleButton
          id={'includeHidden'}
          status={includeHidden}
          initialstatus={false}
          setStatus={(data: boolean) => {
            data ? setIncludeHidden(true) : setIncludeHidden(false);
          }}
        />
      </div>
      {omitGlobal && (
        <div className="w-1/3">
          <div className="flex justify-end">
            <div className="flex justify-between items-center py-4 gray-background-dark">
              {Object.keys(filterOptions).map((item: string) => (
                <button
                  key={item}
                  className={
                    'text-sm px-5 py-1 ' +
                    (item === filter ? 'bg-blue-primary text-white' : 'bg-gray-background-dark text-gray-dark')
                  }
                  onClick={() => {
                    setFilter(item);
                  }}
                >
                  {
                    // @ts-ignore
                    filterOptions[item]
                  }
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientResources;
