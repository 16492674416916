export const FETCH_LISTENERS_REQUEST = 'FETCH_LISTENERS_REQUEST';
export const FETCH_LISTENERS_SUCCESS = 'FETCH_LISTENERS_SUCCESS';
export const FETCH_LISTENERS_FAILURE = 'FETCH_LISTENERS_FAILURE';

export const GET_LISTEN_TOPIC_REPORT_REQUEST = 'GET_LISTEN_TOPIC_REPORT_REQUEST';
export const GET_LISTEN_TOPIC_REPORT_SUCCESS = 'GET_LISTEN_TOPIC_REPORT_SUCCESS';
export const GET_LISTEN_TOPIC_REPORT_FAILURE = 'GET_LISTEN_TOPIC_REPORT_FAILURE';

//Listener Grade
export const POST_LISTENER_GRADE_REQUEST = 'POST_LISTENER_GRADE_REQUEST';
export const POST_LISTENER_GRADE_SUCCESS = 'POST_LISTENER_GRADE_SUCCESS';
export const POST_LISTENER_GRADE_FAILURE = 'POST_LISTENER_GRADE_FAILURE';

//Message Quality
export const FETCH_MESSAGE_QUALITY_REQUEST = 'FETCH_MESSAGE_QUALITY_REQUEST';
export const FETCH_MESSAGE_QUALITY_SUCCESS = 'FETCH_MESSAGE_QUALITY_SUCCESS';
export const FETCH_MESSAGE_QUALITY_FAILURE = 'FETCH_MESSAGE_QUALITY_FAILURE';

export const FETCH_LIST_CONNECTIONS_REQUEST = 'FETCH_LIST_CONNECTIONS_REQUEST';
export const FETCH_LIST_CONNECTIONS_SUCCESS = 'FETCH_LIST_CONNECTIONS_SUCCESS';
export const FETCH_LIST_CONNECTIONS_FAILURE = 'FETCH_LIST_CONNECTIONS_FAILURE';

export const FETCH_CALL_LOG_REQUEST = 'FETCH_CALL_LOG_REQUEST';
export const FETCH_CALL_LOG_SUCCESS = 'FETCH_CALL_LOG_SUCCESS';
export const FETCH_CALL_LOG_FAILURE = 'FETCH_CALL_LOG_FAILURE';

//Fetch user me
export const FETCH_CONNECTIONS_COUNT_REQUEST = 'FETCH_CONNECTIONS_COUNT_REQUEST';
export const FETCH_CONNECTIONS_COUNT_SUCCESS = 'FETCH_CONNECTIONS_COUNT_SUCCESS';
export const FETCH_CONNECTIONS_COUNT_FAILURE = 'FETCH_CONNECTIONS_COUNT_FAILURE';

export const UPDATE_LISTENER_DETAILS_REQUEST = 'UPDATE_LISTENER_DETAILS_REQUEST';
export const UPDATE_LISTENER_DETAILS_SUCCESS = 'UPDATE_LISTENER_DETAILS_SUCCESS';
export const UPDATE_LISTENER_DETAILS_FAILURE = 'UPDATE_LISTENER_DETAILS_FAILURE';

export const GET_LISTENER_DETAILS_REQUEST = 'GET_LISTENER_DETAILS_REQUEST';
export const GET_LISTENER_DETAILS_SUCCESS = 'GET_LISTENER_DETAILS_SUCCESS';
export const GET_LISTENER_DETAILS_FAILURE = 'GET_LISTENER_DETAILS_FAILURE';

export const POST_LISTENER_PROFILE_REJECTED_REQUEST = 'POST_LISTENER_PROFILE_REJECTED_REQUEST';
export const POST_LISTENER_PROFILE_REJECTED_SUCCESS = 'POST_LISTENER_PROFILE_REJECTED_SUCCESS';
export const POST_LISTENER_PROFILE_REJECTED_FAILURE = 'POST_LISTENER_PROFILE_REJECTED_FAILURE';

export const FETCH_LISTENERS_COUNT_REQUEST = 'FETCH_LISTENERS_COUNT_REQUEST';
export const FETCH_LISTENERS_COUNT_SUCCESS = 'FETCH_LISTENERS_COUNT_SUCCESS';
export const FETCH_LISTENERS_COUNT_FAILURE = 'FETCH_LISTENERS_COUNT_FAILURE';

export const FETCH_LISTENER_AVAILABILTY_REQUEST = 'FETCH_LISTENER_AVAILABILTY_REQUEST';
export const FETCH_LISTENER_AVAILABILTY_SUCCESS = 'FETCH_LISTENER_AVAILABILTY_SUCCESS';
export const FETCH_LISTENER_AVAILABILTY_FAILURE = 'FETCH_LISTENER_AVAILABILTY_FAILURE';
// enagagement
export const FETCH_ENGAGEMENT_REQUEST = 'FETCH_ENGAGEMENT_REQUEST';
export const FETCH_ENGAGEMENT_SUCCESS = 'FETCH_ENGAGEMENT_SUCCESS';
export const FETCH_ENGAGEMENT_FAILURE = 'FETCH_ENGAGEMENT_FAILURE';

export const FETCH_LOGS_REQUEST = 'FETCH_LOGS_REQUEST';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE';

export const FETCH_LOGSCOUNT_REQUEST = 'FETCH_LOGSCOUNT_REQUEST';
export const FETCH_LOGSCOUNT_SUCCESS = 'FETCH_LOGSCOUNT_SUCCESS';
export const FETCH_LOGSCOUNT_FAILURE = 'FETCH_LOGSCOUNT_FAILURE';

export const UPDATE_LISTENER_TAGS_REQUEST = 'UPDATE_LISTENER_TAGS_REQUEST';
export const UPDATE_LISTENER_TAGS_SUCCESS = 'UPDATE_LISTENER_TAGS_SUCCESS';
export const UPDATE_LISTENER_TAGS_FAILURE = 'UPDATE_LISTENER_TAGS_FAILURE';

export const GET_LISTENER_TAGS_REQUEST = 'GET_LISTENER_TAGS_REQUEST';
export const GET_LISTENER_TAGS_SUCCESS = 'GET_LISTENER_TAGS_SUCCESS';
export const GET_LISTENER_TAGS_FAILURE = 'GET_LISTENER_TAGS_FAILURE';

export const REMOVE_LISTENER_TAGS_REQUEST = 'REMOVE_LISTENER_TAGS_REQUEST';
export const REMOVE_LISTENER_TAGS_SUCCESS = 'REMOVE_LISTENER_TAGS_SUCCESS';
export const REMOVE_LISTENER_TAGS_FAILURE = 'REMOVE_LISTENER_TAGS_FAILURE';

export const UPDATE_LISTENER_TAGS_LIST_REQUEST = 'UPDATE_LISTENER_TAGS_LIST_REQUEST';
export const UPDATE_LISTENER_TAGS_LIST_SUCCESS = 'UPDATE_LISTENER_TAGS_LIST_SUCCESS';
export const UPDATE_LISTENER_TAGS_LIST_FAILURE = 'UPDATE_LISTENER_TAGS_LIST_FAILURE';

export const POST_AUDIO_REQUEST = 'POST_AUDIO_REQUEST';
export const POST_AUDIO_SUCCESS = 'POST_AUDIO_SUCCESS';
export const POST_AUDIO_FAILURE = 'POST_AUDIO_FAILURE';

export const FETCH_AUDIO_LIST_REQUEST = 'FETCH_AUDIO_LIST_REQUEST';
export const FETCH_AUDIO_LIST_SUCCESS = 'FETCH_AUDIO_LIST_SUCCESS';
export const FETCH_AUDIO_LIST_FAILURE = 'FETCH_AUDIO_LIST_FAILURE';

export const UPDATE_AUDIO_REQUEST = 'UPDATE_AUDIO_REQUEST';
export const UPDATE_AUDIO_SUCCESS = 'UPDATE_AUDIO_SUCCESS';
export const UPDATE_AUDIO_FAILURE = 'UPDATE_AUDIO_FAILURE';

export const FETCH_MONEY_REPORT_REQUEST = 'FETCH_MONEY_REPORT_REQUEST';
export const FETCH_MONEY_REPORT_SUCCESS = 'FETCH_MONEY_REPORT_SUCCESS';
export const FETCH_MONEY_REPORT_FAILURE = 'FETCH_MONEY_REPORT_FAILURE';

export const UPDATE_PEER_STATUS_REQUEST = 'UPDATE_PEER_STATUS_REQUEST';
export const UPDATE_PEER_STATUS_SUCCESS = 'UPDATE_PEER_STATUS_SUCCESS';
export const UPDATE_PEER_STATUS_FAILURE = 'UPDATE_PEER_STATUS_FAILURE';
