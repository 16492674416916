import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from '../clients/clients-constants';
import HeadBack from '../clients/redirect';
import { useDispatch, connect } from 'react-redux';
import CalendarPaginator from '../../components/calender-paginator';
import { NumericLabel } from '../../components/common';
import { StackedBarChart } from '../../components/charts';
import { VerticalBarChart } from '../../components/charts';
import { fetchReportingRequest, getClientDetailsRequest } from '../clients/clients-redux/actions';
import Spinner from '../../components/common/spinner';
type props = {
  reporting: any;
  pending: boolean | null;
  client: any;
};
const options = {
  title: '',
  chartArea: { width: '35%', height: '90%' },
  colors: ['#1D1183'],
  legend: { position: 'none' },
  hAxis: {
    title: '',
    minValue: 0,
  },
  vAxis: {
    title: '',
  },
};

type moodChartprops = {
  data: any;
};
const MoodDistributions: React.FC<moodChartprops> = ({ data }): JSX.Element => {
  const { positive, neutral, negative } = data;
  const [Nodata, setNodata] = useState(false);

  const arrayData = [
    {
      bgColor: 'stacked-bar-red-primary',
      width: negative?.response_percentage,
    },
    {
      bgColor: 'stacked-bar-orange-primary',
      width: neutral?.response_percentage,
    },
    {
      bgColor: 'stacked-bar-green-primary',
      width: positive?.response_percentage,
    },
  ];

  useEffect(() => {
    if (
      parseInt(positive?.response_percentage) === 0 &&
      parseInt(neutral?.response_percentage) === 0 &&
      parseInt(negative?.response_percentage) === 0
    ) {
      setNodata(true);
    } else {
      setNodata(false);
    }
  }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full py-3">
      <div className=" flex justify-between w-full  h-2/3 items-end">
        {!Nodata ? (
          arrayData.map((item) => <StackedBarChart xwidth={`${item.width}%`} backGroundColor={item.bgColor} />)
        ) : (
          <div className="text-center h-10 flex items-center justify-center text-monsterrat border-white border text-white font-bold bg-gray-light w-full">
            No check-in responses
          </div>
        )}
      </div>
      <div className="text-sm text-gray-dark flex items-center py-1">Negative / Neutral / Positive</div>
    </div>
  );
};

const Reporting: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const clientId = localStorage.getItem('clientid');

  const members = props.reporting?.members || null;
  const checkins = props.reporting?.check_ins || null;
  const stroyListens = props.reporting?.story_listens || null;
  const calls = props.reporting?.calls || null;
  const engagementByTag = props.reporting?.engagement_by_tag || null;
  const engagementByTopic = props.reporting?.engagement_by_topic || null;
  const crisisElevations = props.reporting?.crisis_elevations || null;
  const TopicChart =
    engagementByTopic?.length > 0 ? engagementByTopic.map((item: any) => [item.label, item.value, '', item.value]) : [];
  const TagChart =
    engagementByTag?.length > 0 ? engagementByTag.map((item: any) => [item.label, item.value, '', item.value]) : [];
  const TopicChartData = [['Topic', 'Topics of intrests', { role: 'style' }, { role: 'annotation' }], ...TopicChart];
  const TagChartData = [['Tags', 'Tags of intrests', { role: 'style' }, { role: 'annotation' }], ...TagChart];

  // setTopicschartData(TopicChartData1);

  const changedDate = (startDate: string, endDate: string) => {
    const input = {
      linkInput: {
        clientId,
      },
      payload: {
        start_date: startDate,
        end_date: endDate,
      },
    };
    dispatch(fetchReportingRequest(input));
  };

  const calculateMemeberPercenetage = (data: number) => {
    if (members?.total > 0) return Math.floor((data / members?.total) * 100).toString() + ' % ';
    else return '0 %';
  };

  useEffect(() => {
    if (clientId) {
      dispatch(getClientDetailsRequest({ clientId }));
    }
  }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={<HeadBack clientName={props.client?.name || ''} />} />
      <SwitchBar heading={HeaderOptions} position={0} isDisabled={clientId ? false : true} />
      <CalendarPaginator changedDate={changedDate} />
      <div className="max-window-height-group overflow-y-auto px-7">
        <div className="w-full ">
          <div className="flex justify-between py-4">
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">
              Total Eligible Members
            </div>
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">Activated Members</div>
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">Engaged Members</div>
            <div className="w-1/4 text-gray-dark font-bold font-base  border-b border-gray-dark ">
              Connected Members
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-1/4 ">
              <NumericLabel numericValue={members?.total || 0} label={''} />
            </div>
            <div className="w-1/4  ">
              <NumericLabel
                numericValue={members?.active || 0}
                label={calculateMemeberPercenetage(members?.active) || '0'}
              />
            </div>
            <div className="w-1/4  ">
              <NumericLabel
                numericValue={members?.engaged || 0}
                label={calculateMemeberPercenetage(members?.engaged) || '0'}
              />
            </div>
            <div className="w-1/4  ">
              <NumericLabel
                numericValue={members?.engaged || 0}
                label={calculateMemeberPercenetage(members?.connected) || '0'}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between ">
          <div className="w-full flex-col">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              {' '}
              Crisis Elevations
            </div>

            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-4xl py-3 text-red">{crisisElevations?.total_crisis_elevations || 0}</div>
              <div className="flex-col">
                <div className="text-4xl py-3 text-red">{crisisElevations?.percentage_elevated_members || 0} %</div>
                <p className="text-gray-dark">of Members</p>
              </div>
            </div>
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Check-ins
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-4xl py-3 blue-primary">{checkins?.total_responses || 0}</div>
              <div className="flex-col">
                <div className="text-4xl py-3 blue-primary">{checkins?.response_percentage || 0} %</div>
                <span>{checkins?.total_sent || 0} Sent</span>
              </div>
            </div>
            <div className="relative  w-full  py-3 border-b text-base font-bold text-gray-dark">Mood Distribution</div>
            <MoodDistributions data={checkins?.mood_distribution || {}} />
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Story Listens
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-4xl py-3 blue-primary">{stroyListens?.total_listens || 0}</div>
              <div className="flex-col">
                <div className="text-4xl py-3 blue-primary">{stroyListens?.member_percentage || 0} %</div>
                <span>Of Members</span>
              </div>
            </div>
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Calls
            </div>
            <div className="w-3/4 flex justify-between">
              <div className="text-4xl py-3 blue-primary">{calls?.total || 0}</div>
              <div className="flex-col">
                <div className="text-4xl py-3 blue-primary">{calls?.member_percentage} %</div>
                <span>Of Members</span>
              </div>
            </div>
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark flex">
              Engagement by Tag
            </div>
            <div className="w-3/4">
              {/* <WeeklyChart error={false} /> */}
              {TagChartData?.length > 1 ? (
                <VerticalBarChart data={TagChartData} options={options} key={Math.random()} />
              ) : (
                'No Data Available'
              )}
            </div>
          </div>
          <div className="w-full px-7">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark flex">
              Topics of Interests
            </div>
            <div className="w-full">
              {/* <WeeklyChart error={false} /> */}
              {TopicChartData?.length > 1 ? (
                <VerticalBarChart data={TopicChartData} options={options} key={Math.random()} />
              ) : (
                'No Data Available'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    reporting: state.Client.Reporting?.data || null,
    client: state.Client.ClientDetials?.data || null,
    pending: state.Client.pending || null,
  };
};

export default connect(mapStateToProps)(Reporting);
