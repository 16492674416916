import {
  FETCH_CLIENT_ADMINSTRATORS_REQUEST,
  FETCH_CLIENT_ADMINSTRATORS_FAILURE,
  FETCH_CLIENT_ADMINSTRATORS_SUCCESS,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS,
  POST_INVITE_CLIENT_ADMIN_REQUEST,
  POST_INVITE_CLIENT_ADMIN_FAILURE,
  POST_INVITE_CLIENT_ADMIN_SUCCESS,
  DELETE_CLIENT_ADMIN_REQUEST,
  DELETE_CLIENT_ADMIN_SUCCESS,
  DELETE_CLIENT_ADMIN_FAILURE,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS,
  POST_INVITE_VIA_EMAIL_REQUEST,
  POST_INVITE_VIA_EMAIL_FAILURE,
  POST_INVITE_VIA_EMAIL_SUCCESS,
} from './actionTypes';
import {
  FetchClientAdminstratorsRequest,
  FetchClientAdminstratorsSuccess,
  FetchClientAdminstratorsSuccessPayload,
  FetchClientAdminstratorsFailure,
  FetchClientAdminstratorsFailurePayload,
  FetchClientAdminstratorsCountRequest,
  FetchClientAdminstratorsCountSuccess,
  FetchClientAdminstratorsCountSuccessPayload,
  FetchClientAdminstratorsCountFailure,
  FetchClientAdminstratorsCountFailurePayload,
  PostInviteClientAdminRequest,
  PostInviteClientAdminSuccess,
  PostInviteClientAdminSuccessPayload,
  PostInviteClientAdminFailure,
  PostInviteClientAdminFailurePayload,
  DeleteClientAdminAccessRequest,
  DeleteClientAdminAccessSuccess,
  DeleteClientAdminAccessFailure,
  DeleteClientAdminAccessSuccessPayload,
  DeleteClientAdminAccessFailurePayload,
  DeleteRevokeClientAdminAccessRequest,
  DeleteRevokeClientAdminAccessSuccess,
  DeleteRevokeClientAdminAccessSuccessPayload,
  DeleteRevokeClientAdminAccessFailure,
  DeleteRevokeClientAdminAccessFailurePayload,
  PostInviteViaEmailRequest,
  PostInviteViaEmailSuccess,
  PostInviteViaEmailSuccessPayload,
  PostInviteViaEmailFailure,
  PostInviteViaEmailFailurePayload,
} from './types';

export const fetchClientAdminstratorsRequest = (payload: any): FetchClientAdminstratorsRequest => ({
  type: FETCH_CLIENT_ADMINSTRATORS_REQUEST,
  payload,
});

export const fetchClientAdminstratorsSuccess = (
  payload: FetchClientAdminstratorsSuccessPayload,
): FetchClientAdminstratorsSuccess => ({
  type: FETCH_CLIENT_ADMINSTRATORS_SUCCESS,
  payload,
});

export const fetchClientAdminstratorsFailure = (
  payload: FetchClientAdminstratorsFailurePayload,
): FetchClientAdminstratorsFailure => ({
  type: FETCH_CLIENT_ADMINSTRATORS_FAILURE,
  payload,
});

export const fetchClientAdminstratorsCountRequest = (payload: any): FetchClientAdminstratorsCountRequest => ({
  type: FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST,
  payload,
});

export const fetchClientAdminstratorsCountSuccess = (
  payload: FetchClientAdminstratorsCountSuccessPayload,
): FetchClientAdminstratorsCountSuccess => ({
  type: FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS,
  payload,
});

export const fetchClientAdminstratorsCountFailure = (
  payload: FetchClientAdminstratorsCountFailurePayload,
): FetchClientAdminstratorsCountFailure => ({
  type: FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE,
  payload,
});

export const postInviteClientAdminRequest = (payload: any): PostInviteClientAdminRequest => ({
  type: POST_INVITE_CLIENT_ADMIN_REQUEST,
  payload,
});

export const postInviteClientAdminSuccess = (
  payload: PostInviteClientAdminSuccessPayload,
): PostInviteClientAdminSuccess => ({
  type: POST_INVITE_CLIENT_ADMIN_SUCCESS,
  payload,
});

export const postInviteClientAdminFailure = (
  payload: PostInviteClientAdminFailurePayload,
): PostInviteClientAdminFailure => ({
  type: POST_INVITE_CLIENT_ADMIN_FAILURE,
  payload,
});

export const deleteClientAdminAccessRequest = (payload: any): DeleteClientAdminAccessRequest => ({
  type: DELETE_CLIENT_ADMIN_REQUEST,
  payload,
});

export const deleteClientAdminAccessSuccess = (payload: any): DeleteClientAdminAccessSuccess => ({
  type: DELETE_CLIENT_ADMIN_SUCCESS,
  payload,
});

export const deleteClientAdminAccessFailure = (
  payload: DeleteClientAdminAccessFailurePayload,
): DeleteClientAdminAccessFailure => ({
  type: DELETE_CLIENT_ADMIN_FAILURE,
  payload,
});

export const deleteRevokeClientAdminAccessRequest = (payload: any): DeleteRevokeClientAdminAccessRequest => ({
  type: DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST,
  payload,
});

export const deleteRevokeClientAdminAccessSuccess = (payload: any): DeleteRevokeClientAdminAccessSuccess => ({
  type: DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS,
  payload,
});

export const deleteRevokeClientAdminAccessFailure = (
  payload: DeleteRevokeClientAdminAccessFailurePayload,
): DeleteRevokeClientAdminAccessFailure => ({
  type: DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE,
  payload,
});

export const postInviteViaEmailRequest = (payload: any): PostInviteViaEmailRequest => ({
  type: POST_INVITE_VIA_EMAIL_REQUEST,
  payload,
});

export const postInviteViaEmailSuccess = (payload: PostInviteViaEmailSuccessPayload): PostInviteViaEmailSuccess => ({
  type: POST_INVITE_VIA_EMAIL_SUCCESS,
  payload,
});

export const postInviteViaEmailFailure = (payload: PostInviteViaEmailFailurePayload): PostInviteViaEmailFailure => ({
  type: POST_INVITE_VIA_EMAIL_FAILURE,
  payload,
});
