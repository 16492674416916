import * as React from 'react';
import { Chart } from 'react-google-charts';

type props = {
  error: false;
  data: any[];
  options: any;
};

// To represent a Bar chart pass the data via props

const Histogram: React.FunctionComponent<props> = ({ error, data, options }): JSX.Element => {
  return (
    // Charts for react graphss
    <div className="mt-10">
      <Chart
        width={'35vw'}
        height={'200px'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ 'data-testid': '6' }}
      />
    </div>
  );
};

export default Histogram;
