import {
  FETCH_ALL_CONNECTIONS_REQUEST,
  FETCH_ALL_CONNECTIONS_FAILURE,
  FETCH_ALL_CONNECTIONS_SUCCESS,
  FETCH_ALL_CONNECTIONS_COUNT_REQUEST,
  FETCH_ALL_CONNECTIONS_COUNT_SUCCESS,
  FETCH_ALL_CONNECTIONS_COUNT_FAILURE,
} from './actionTypes';

import {
  FetchAllConnectionsRequest,
  FetchAllConnectionsSuccess,
  FetchAllConnectionsSuccessPayload,
  FetchAllConnectionsFailure,
  FetchAllConnectionsFailurePayload,
  FetchAllConnectionsCountRequest,
  FetchAllConnectionsCountSuccess,
  FetchAllConnectionsCountSuccessPayload,
  FetchAllConnectionsCountFailure,
  FetchAllConnectionsCountFailurePayload,
} from './types';

export const fetchAllConnectionsRequest = (payload: any): FetchAllConnectionsRequest => ({
  type: FETCH_ALL_CONNECTIONS_REQUEST,
  payload,
});

export const fetchAllConnectionsSuccess = (payload: FetchAllConnectionsSuccessPayload): FetchAllConnectionsSuccess => ({
  type: FETCH_ALL_CONNECTIONS_SUCCESS,
  payload,
});

export const fetchAllConnectionsFailure = (payload: FetchAllConnectionsFailurePayload): FetchAllConnectionsFailure => ({
  type: FETCH_ALL_CONNECTIONS_FAILURE,
  payload,
});

export const fetchAllConnectionsCountRequest = (payload: any): FetchAllConnectionsCountRequest => ({
  type: FETCH_ALL_CONNECTIONS_COUNT_REQUEST,
  payload,
});

export const fetchAllConnectionsCountSuccess = (
  payload: FetchAllConnectionsCountSuccessPayload,
): FetchAllConnectionsCountSuccess => ({
  type: FETCH_ALL_CONNECTIONS_COUNT_SUCCESS,
  payload,
});

export const fetchAllConnectionsCountFailure = (
  payload: FetchAllConnectionsCountFailurePayload,
): FetchAllConnectionsCountFailure => ({
  type: FETCH_ALL_CONNECTIONS_COUNT_FAILURE,
  payload,
});
