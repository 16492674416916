import React from 'react';
import Header from '../../components/common/global-top-bar';
import { NumericLabel } from '../../components/common';
import { LineChart, StackedBarChart, Histogram, WeeklyChart } from '../../components/charts';
import CalendarPaginator from '../../components/calender-paginator';

const CallsLength = () => {
  return (
    <div className=" flex justify-between w-1/2">
      <NumericLabel numericValue={'1,403'} label={'+21%'} />
      <LineChart error={false} />
      <NumericLabel numericValue={'75%'} label={'46,226 Sent'} />
    </div>
  );
};
const Connections = () => {
  return (
    <div className="w-1/2 ">
      <div className=" flex justify-between w-full  h-2/3 items-end">
        <StackedBarChart xwidth={'14%'} backGroundColor={'stacked-bar-red-primary'} />
        <StackedBarChart xwidth={'63%'} backGroundColor={'stacked-bar-orange-primary'} />
        <StackedBarChart xwidth={'23%'} backGroundColor={'stacked-bar-green-primary'} />
      </div>
      <div className="text-sm text-gray-dark flex items-center py-1"> Negative / Neutral / Positive</div>
    </div>
  );
};

const CheckIns: React.FunctionComponent = (): JSX.Element => {
  const data = [
    ['', ''],
    ['00:00', 22],
    ['00:30', 9.1],
    ['01:00', 12.2],
    ['01:30', 22.9],
    ['02:00', 0.9],
    ['02:30', 36.6],
    ['03:00', 9.1],
    ['03:30', 30.5],
    ['04:00', 6.1],
    ['04:30', 2.7],
    ['05:00', 0.9],
    ['05:30', 2.7],
    ['06:00', 3.4],
    ['06:30', 5.5],
    ['07:00', 21.0],
    ['07:30', 7.9],
    ['08:00', 1.2],
    ['08:30', 4.6],
    ['09:00', 1.5],
    ['09:30', 7.9],
    ['10:00 ', 2.0],
    ['10:30', 12.2],
    ['11:00', 30.5],
    ['11:30', 15.2],
    ['12:00', 30.5],
    ['12:30', 1.8],
    ['13:00', 22],
    ['13:30', 9.1],
    ['14:00', 12.2],
    ['14:30', 22.9],
    ['15:00', 0.9],
    ['15:30', 36.6],
    ['16:00', 9.1],
    ['16:30', 30.5],
    ['17:00', 6.1],
    ['17:30', 2.7],
    ['18:00', 0.9],
    ['18:30', 2.7],
    ['19:30', 3.4],
    ['19:00', 5.5],
    ['20:00', 21.0],
    ['20:30', 7.9],
    ['21:00', 1.2],
    ['21:30', 4.6],
    ['22:00', 1.5],
    ['22:30', 7.9],
    ['23:00 ', 2.0],
    ['23:30', 12.2],
  ];

  const options = {
    legend: { position: 'none' },
    colors: ['#1D1183'],
    axisFontSize: 0,
    hAxis: {
      textPosition: 'none',
      maxValue: 100,
      minValue: -100,
      gridlines: { count: 2 },
    },
    bar: { groupWidth: '50%' },
    histogram: { bucketSize: 1 },
  };
  return (
    <>
      <Header userName={'Jason'} heading={'Check-Ins'} />
      <CalendarPaginator />
      <div className="max-window-height pb-32 overflow-y-auto ">
        <div className="flex justify-between gap-7 px-7">
          <div className="w-full ">
            <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark">
              Check-Ins
            </div>
          </div>
          <div className="w-full ">
            <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark flex">
              Mood
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-7 px-7">
          <CallsLength />
          <Connections />
        </div>
        <div className="flex justify-between px-7 gap-7">
          <div className="w-full ">
            <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark">
              Response Times
            </div>
          </div>
          <div className="w-full ">
            <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark">
              Response Days
            </div>
          </div>
        </div>
        <div className="flex justify-between px-7 gap-7">
          <div className="w-1/2">
            <Histogram error={false} data={data} options={options} />
          </div>
          <div className="w-1/2">
            <WeeklyChart error={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckIns;
