export const Headeroptions = [
  { heading: 'User Management', path: '/user-safety/user-management' },
  { heading: 'Blacklist', path: '/user-safety/blacklist' },
  { heading: 'User Connection Blocks', path: '/user-safety/blocked' },
];

export const emailMapping = {
  banned: { subject: 'LOC_CALLER_MESSAGE_BANNED_SUBJECT', body: 'LOC_CALLER_MESSAGE_BANNED' },
  enabled: {
    subject: 'LOC_CALLER_MESSAGE_RESTORED_SUBJECT',
    body: 'LOC_CALLER_MESSAGE_RESTORED',
  },
  suspended: {
    subject: 'LOC_CALLER_MESSAGE_RESTORED_SUBJECT',
    body: 'LOC_CALLER_MESSAGE_RESTORED',
  },
  under_review: {
    subject: 'LOC_CALLER_MESSAGE_UNDER_REVIEW_SUBJECT',
    body: 'LOC_CALLER_MESSAGE_UNDER_REVIEW',
  },
};

export const defaultModal = {
  email: '',
  subject: '',
  toggle: true,
  status: '',
  enable: false,
};

export const UserTypes = {
  'Under Review': 'under_review',
  Suspended: 'suspended',
  Banned: 'banned',
};

export const userStatusOptions = [
  { action: 'enabled', name: 'Restore User' },
  { action: 'under_review', name: 'Place Under Review' },
  { action: 'suspended', name: 'Suspended' },
  { action: 'banned', name: 'Banned' },
];

export const userManagementFilter = {
  page: 1,
  limit: 25,
  search: '',
  status: 'under_review',
  include_ratings: true,
  include_topics: true,
  include_call_summary: true,
  include_user_safety: true,
  include_requests: true,
};
