import { toast } from 'react-toastify';
import urls from './urls';

const handleErrors = (error: any) => {
  if (error.status === 403) {
    localStorage.clear();
    window.location.pathname = '/';
    return;
  }
  throw JSON.stringify(error);
};
export const TopicsApi = (client: any) => ({
  getTopics: getTopics(client),
});

const getTopics = (http: any) => () => {
  return http
    .get(urls.topics)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const Topics = (client: any) => ({
  getTopics: getTopics(client),
});

export const ListenersApi = (client: any) => ({
  getListeners: (input: any) => getListeners(client, input),
  getListenersCount: (input: any) => getListenersCount(client, input), //getListenersCount
  gradeListener: (input: any) => gradeListener(client, input),
  getMessageQualityOptions: (input: any) => getMessageQualityOptions(client, input),
  getListenersReport: (input: any) => getListenersReport(client, input),
  getConnections: (input: any) => getConnections(client, input),
  getCallLog: (input: any) => getCallLog(client, input),
  getConnectionsCount: (input: any) => getConnectionsCount(client, input),
  getListenerDetails: (input: any) => getListenerDetails(client, input),
  UpdateListenerDetails: (input: any) => updateListenerDetails(client, input),
  UpdateListenersProfileRejected: (input: any) => UpdateListenersProfileRejected(client, input),
  getListenerAvailabilty: (input: any) => getListenerAvailabilty(client, input),
  getEngagement: (input: any) => getEngagement(client, input),
  getLogs: (input: any) => getLogs(client, input),
  getLogsCount: (input: any) => getLogsCount(client, input),
  getAudioList: (input: any) => getAudioList(client, input),
  postGradeAudio: (input: any) => postGradeAudio(client, input),
  updateAudio: (input: any) => updateAudio(client, input),
  getMoneyreport: (input: any) => getMoneyreport(client, input),
  UpdatePeerStatus: (input: any) => UpdatePeerStatus(client, input),
  fetchListenrsAudioList: (input: any) => fetchListenrsAudioList(client, input),
  fetchListenrsAudioListCount: (input: any) => fetchListenrsAudioListCount(client, input),
});

const fetchListenrsAudioListCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/audio/count`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchListenrsAudioList = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/audio`, {
      ...input,
      order_by: 'created_at',
      order_by_direction: 'desc',
    })
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMoneyreport = (http: any, input: any) => () => {
  const url = `${urls.user}/${input.id}/money_report`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const postGradeAudio = (http: any, input: any) => () => {
  return http
    .post(`${urls.listeners}/${input.id}/audio/${input.audioId}/grade`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateAudio = (http: any, input: any) => () => {
  return http
    .put(`${urls.listeners}/${input.id}/audio/${input.audioId}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAudioList = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/audio`, {
      order_by: 'created_at',
      order_by_direction: 'desc',
    })
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListenerDetails = (http: any, input: any) => () => {
  return http
    .get(`${urls.users}${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListenerAvailabilty = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/availabilities`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListenersCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/count`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateListenerDetails = (http: any, input: any) => () => {
  return http
    .put(`${urls.users}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getEngagement = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/engagement_report`, input.payload, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const UpdateListenersProfileRejected = (http: any, input: any) => () => {
  return http
    .post(`${urls.users}listener_profile/${input.id}/${input.type}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getLogsCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/logs/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getLogs = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/logs`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getConnections = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/connections`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getCallLog = (http: any, input: any) => () => {
  return http
    .get(urls.events, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getConnectionsCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/connections/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListeners = (http: any, input: any) => () => {
  const payload = { ...input, type: undefined };
  return http
    .get(`${urls.listeners}/`, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const gradeListener = (http: any, input: any) => () => {
  const url = `${urls.listenerTopics}/grade/${input.listenerId}/${input.topicId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMessageQualityOptions = (http: any, input: any) => () => {
  const url = `${urls.listenerTopics}/options`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListenersReport = (http: any, input: any) => () => {
  const url = `${urls.users}${input.listenerId}/listener_topic_report`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const UpdatePeerStatus = (http: any, input: any) => () => {
  const url = `${input.type === 'downgrade' ? urls.listeners : urls.peers}/${input.id}/${input.type}`;
  return http
    .post(url, {}, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const MembersApi = (client: any) => ({
  getMembers: (input: any) => getMembers(client, input), //
  fetchMembersConnections: (input: any) => fetchMembersConnections(client, input), //fetchMembersConnections
  fetchMembersConnectionsCount: (input: any) => fetchMembersConnectionsCount(client, input), //fetchMembersConnections
  getMemberProfile: (input: any) => getMemberProfile(client, input),
  getMembersCount: (input: any) => getMembersCount(client, input),
  getMembersLogs: (input: any) => getMembersLogs(client, input),
  getMembersLogsCount: (input: any) => getMembersLogsCount(client, input),
});

const getMembers = (http: any, input: any) => () => {
  const payload = { ...input };
  delete payload['type'];
  return http
    .get(`${urls.members}/`, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMembersCount = (http: any, input: any) => () => {
  const payload = { ...input };
  delete payload['type'];
  return http
    .get(`${urls.members}/count`, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMembersLogs = (http: any, input: any) => () => {
  return http
    .get(`${urls.members}/${input.id}/logs`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMembersLogsCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.members}/${input.id}/logs/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getMemberProfile = (http: any, input: any) => () => {
  const url = `${urls.members}/${input.memberId}/profile`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchMembersConnections = (http: any, input: any) => () => {
  return http
    .get(`${urls.members}/${input.id}/connections`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchMembersConnectionsCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.members}/${input.id}/connections/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const AdminApi = (client: any) => ({
  fetchAdmindetails: (input: any) => fetchAdminDetails(client, input),
  fetchAdminCount: (input: any) => fetchAdminCount(client, input),
  deleteAdminAccess: (input: any) => deleteAdminAccess(client, input),
  revokeAdminAccess: (input: any) => revokeAdminAccess(client, input),
  resendAdminInvite: (input: any) => resendAdminInvite(client, input),
  inviteViaEmail: (input: any) => inviteViaEmail(client, input),
});

const inviteViaEmail = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/invite_client_administrator`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchAdminDetails = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/client_administrators`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchAdminCount = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/client_administrators/count`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteAdminAccess = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/client_administrator/${input.id}`;
  const payload = input.payload;
  return http
    .delete(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const revokeAdminAccess = (http: any, input: any) => () => {
  const url = `${urls.users}${input.id}/client_administrator_role`;
  const payload = input.payload;
  return http
    .delete(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const resendAdminInvite = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/client_administrator/${input.id}/resend_invite`;
  return http
    .post(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const LoginApi = (client: any) => ({
  authenticate: (input: any) => authenticate(client, input),
  fetchUserdetails: (input: any) => fetchUserdetails(client, input),
  fetchUserDetailsById: (input: any) => fetchUserDetailsById(client, input),
  postRequestPassword: (input: any) => postRequestPassword(client, input),
  postRedeemPasswordReset: (input: any) => postRedeemPasswordReset(client, input),
  authenticateWithToken: (input: any) => authenticateWithToken(client, input),
  resendSwitchBoardOnBoardingLink: (input: any) => resendSwitchBoardOnBoardingLink(client, input),
  clientAdminOnboardingComplete: (input: any) => clientAdminOnboardingComplete(client, input),
  emailAvailable: (input: any) => emailAvailable(client, input),
  mobileValid: (input: any) => mobileValid(client, input),
});

const mobileValid = (http: any, input: any) => () => {
  const url = `${urls.user}/mobile_phone_valid?mobile_phone=${input.mobile_phone}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const postRedeemPasswordReset = (http: any, input: any) => () => {
  const url = `${urls.user}/redeem_password_reset`;
  return http
    .post(url, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const postRequestPassword = (http: any, input: any) => () => {
  const url = `${urls.user}/request_password_reset?email_address=${input.email_address.replace(
    '+',
    '%2B',
  )}&from_location=switchboard`;
  return http
    .post(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const authenticate = (http: any, input: any) => () => {
  const data = {
    email_address: input.email_address,
    password: input.password,
    trusted: input.trusted,
  };

  return http
    .post(urls.login, data)
    .then((results: any) => {
      localStorage.setItem('authorization_token', results.data.authorization_token);
      localStorage.setItem('role_type', results.data.access_role);
      return results;
    })
    .catch((error: any) => {
      if (error.response) {
        const { response } = error;
        toast.error(response.data.description);
      }
    });
};

const fetchUserdetails = (http: any, input: any) => () => {
  const url = `${urls.users}me`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchUserDetailsById = (http: any, input: any) => () => {
  const url = `${urls.users}${input.id}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

//Clients API
export const ClientsApi = (client: any) => ({
  getClientsList: (input: any) => getClientsList(client, input),
  getClientsCount: (input: any) => getClientsCount(client, input),
  getClient: (input: any) => getClient(client, input),
  deleteClient: (input: any) => deleteClient(client, input),
  getTags: (input: any) => getTags(client, input),
  updatePackageProduct: (input: any) => updatePackageProduct(client, input),
  updatePackaget: (input: any) => updatePackage(client, input),
  createPackage: (input: any) => createPackage(client, input),
  createPackageProduct: (input: any) => createPackageProduct(client, input),
  createClient: (input: any) => createClient(client, input),
  updateClient: (input: any) => updateClient(client, input),
  AddComms: (input: any) => AddComms(client, input),
  deleteComms: (input: any) => deleteComms(client, input),
  forceCheckIn: (input: any) => forceCheckIn(client, input),
  addCredits: (input: any) => addCredits(client, input),
  getProductTemplate: (input: any) => getProductTemplate(client, input),
  getCampaigns: (input: any) => getCampaigns(client, input),
  getIncentives: (input: any) => getIncentives(client, input),
  getSystemMessages: (input: any) => getSystemMessages(client, input),
  getEmailTemplates: (input: any) => getEmailTemplates(client, input),
  getReports: (input: any) => getReports(client, input),
  getPackageReports: (input: any) => getPackageReports(client, input),
  appSettings: (input: any) => appSettings(client, input), //
  fetchappSettings: (input: any) => fetchappSettings(client, input), //
  deletePackage: (input: any) => deletePackage(client, input),
  getTos: (input: any) => getTos(client, input),
  updateTos: (input: any) => updateTos(client, input),
  FetchExternalProviderPlans: (input: any) => FetchExternalProviderPlans(client, input), //

  createSubscriptions: (input: any) => createSubscriptions(client, input), //
  deleteSubscriptions: (input: any) => deleteSubscriptions(client, input), //
  updateSubscriptions: (input: any) => updateSubscriptions(client, input), //
  getDefaultSubscriptions: (input: any) => getDefaultSubscriptions(client, input), //
});

const createSubscriptions = (http: any, input: any) => () => {
  return http
    .post(`${urls.subscriptions}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateSubscriptions = (http: any, input: any) => () => {
  return http
    .put(`${urls.subscriptions}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteSubscriptions = (http: any, input: any) => () => {
  return http
    .delete(`${urls.subscriptions}${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getDefaultSubscriptions = (http: any, input: any) => () => {
  return http
    .get(`${urls.subscriptions}default_templates`, input, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const FetchExternalProviderPlans = (http: any, input: any) => () => {
  return http
    .get('/external_provider_plans/', input, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getClientsList = (http: any, input: any) => () => {
  return http
    .get(urls.clients, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getTos = (http: any, input: any) => () => {
  const url =
    input.type === 'app' ? urls.appSettings.replace('system/', input.type) : `${urls.appSettings}${input.type}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateTos = (http: any, input: any) => () => {
  return http
    .put(`${urls.appSettings}${input.type}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const appSettings = (http: any, input: any) => () => {
  return http
    .get(`${urls.appSettings}LOC_WEB_APP_URL`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchappSettings = (http: any, input: any) => () => {
  return http
    .get(`/settings${input?.type === 'email' ? '/' : '/app'}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updatePackageProduct = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/package_product/${input.linkInput.id}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getReports = (http: any, input: any) => () => {
  const url = input?.linkInput?.clientId
    ? `${urls.reporting}/${input.linkInput.clientId}/summary`
    : `${urls.reporting}/summary`;
  const payload = input.payload;
  return http
    .get(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getPackageReports = (http: any, input: any) => () => {
  const url = `${urls.reporting}/${input.linkInput.clientId}/package/${input.linkInput.packageId}/summary`;
  const payload = input.payload;
  return http
    .get(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updatePackage = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/${input.linkInput.packageId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createClient = (http: any, input: any) => () => {
  return http
    .post(`${urls.clients}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getEmailTemplates = (http: any, input: any) => () => {
  return http
    .get(`${urls.comms}/email_template_metadata`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getCampaigns = (http: any, input: any) => () => {
  return http
    .get(`${urls.comms}/campaign_templates`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getSystemMessages = (http: any, input: any) => () => {
  return http
    .get(`${urls.comms}/system_messages`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getIncentives = (http: any, input: any) => () => {
  return http
    .get(`${urls.clients}incentive_organizations`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};
const getProductTemplate = (http: any, input: any) => () => {
  return http
    .get(`${urls.clients}product_templates`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateClient = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createPackage = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deletePackage = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/packages/${input.packageId}`;
  return http
    .delete(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createPackageProduct = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/${input.linkInput.packageId}/products/${input.linkInput.id}`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const AddComms = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/${input.linkInput.id}/campaign_subscriptions`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const forceCheckIn = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/${input.linkInput.packageId}/products/${input.linkInput.productId}/force_check_in`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteComms = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/packages/${input.linkInput.packageId}/campaign_subscriptions/${input.linkInput.id}`;
  return http
    .delete(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getClient = (http: any, input: any) => () => {
  const url = input.clientId ? `${urls.clients}${input.clientId}` : `${urls.clients}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteClient = (http: any, input: any) => () => {
  return http
    .delete(`${urls.clients}${input.clientId}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getClientsCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.clients}count`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getTags = (http: any, input: any) => () => {
  return http
    .get(urls.tags, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const addCredits = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/package_product/${input.linkInput.id}/add_credit`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const ClientPortalApi = (client: any) => ({
  getSnapShot: (input: any) => getSnapShot(client, input),
});

const getSnapShot = (http: any, input: any) => () => {
  const url = input.linkInput.clientId
    ? `${urls.reporting}/${input.linkInput.clientId}/snapshot`
    : `${urls.reporting}/snapshot`;
  const payload = input.payload;
  return http
    .get(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const EligibiltyApi = (client: any) => ({
  getEligibilty: (input: any) => getEligibilty(client, input),
  getAllEligibilty: (input: any) => getAllEligibilty(client, input),
  updateEligibilty: (input: any) => updateEligibilty(client, input),
  deleteEligibilty: (input: any) => deleteEligibilty(client, input),
  createEligibilty: (input: any) => createEligibilty(client, input),
  validateEligibilty: (input: any) => validateEligibilty(client, input),
  processEligibility: (input: any) => processEligibility(client, input),
  downloadEligibilityMembers: (input: any) => downloadEligibilityMembers(client, input),
  processEligibilityReport: (input: any) => processEligibilityReport(client, input),
  transferEligibilityReport: (input: any) => transferEligibilityReport(client, input),
});

const getEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets/${input.eligibilityId}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets`;
  delete input['clientId'];
  return http
    .get(url, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/eligibility_datasets/${input.linkInput.eligibilityId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets/${input.eligibilityId}`;
  return http
    .delete(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/eligibility_datasets`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const validateEligibilty = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.linkInput.clientId}/eligibility_datasets/validate`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const processEligibility = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets/${input.eligibilityId}/process`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const downloadEligibilityMembers = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/download_members`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const processEligibilityReport = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets/${input.eligibilityId}/process_report`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const transferEligibilityReport = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}/eligibility_datasets/${input.eligibilityId}/transfer`;
  return http
    .post(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const CommsApi = (client: any) => ({
  getComms: (input: any) => getComms(client, input),
  getAllComms: (input: any) => getAllComms(client, input),
  updateComms: (input: any) => updateComms(client, input),
  deleteComms: (input: any) => deleteCommsTemplate(client, input),
  createComms: (input: any) => createComms(client, input),
  testComms: (input: any) => testComms(client, input),
  RepairTemplates: (input: any) => RepairTemplates(client, input),
  fetchSystemMessagesGroups: (input: any) => fetchSystemMessagesGroups(client, input),
  processEligibility: (input: any) => processEligibility(client, input),
  getCampaigns: (input: any) => getCampaigns(client, input),
  getAllCampaigns: (input: any) => getAllCampaigns(client, input),
  updateCampaigns: (input: any) => updateCampaigns(client, input),
  deleteCampaigns: (input: any) => deleteCampaignsTemplate(client, input),
  createCampaigns: (input: any) => createCampaigns(client, input),
  testCampaigns: (input: any) => testCampaigns(client, input),
});

const getComms = (http: any, input: any) => () => {
  const url = `${urls.clients}${input.clientId}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllComms = (http: any, input: any) => () => {
  return http
    .get(urls.commsTemplate, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateComms = (http: any, input: any) => () => {
  const url = `${urls.commsTemplate}/${input.commsId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteCommsTemplate = (http: any, input: any) => () => {
  const url = `${urls.commsTemplate}/${input.commsId}`;
  return http
    .delete(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createComms = (http: any, input: any) => () => {
  const url = urls.commsTemplate;
  const payload = input;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchSystemMessagesGroups = (http: any, input: any) => () => {
  const url = `${urls.commsTemplate}/groups`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const testComms = (http: any, input: any) => () => {
  const url = `${urls.commsTemplate}/${input.commsId}/trigger_test`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const RepairTemplates = (http: any, input: any) => () => {
  const url = `${urls.comms}/repair_templates`;
  const payload = input;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
}; // getAllCampaigns

const getAllCampaigns = (http: any, input: any) => () => {
  return http
    .get(urls.campaignTemplate, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateCampaigns = (http: any, input: any) => () => {
  const url = `${urls.campaignTemplate}/${input.campaignsId}`;
  const payload = input.payload;
  return http
    .put(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteCampaignsTemplate = (http: any, input: any) => () => {
  const url = `${urls.campaignTemplate}/${input.campaignsId}`;
  return http
    .delete(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createCampaigns = (http: any, input: any) => () => {
  const url = urls.campaignTemplate;
  const payload = input;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const testCampaigns = (http: any, input: any) => () => {
  const url = `${urls.campaignTemplate}/${input.campaignsId}/trigger_test`;
  const payload = input.payload;
  return http
    .post(url, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const authenticateWithToken = (http: any, input: any) => () => {
  const data = {
    token: input.token,
  };
  const url = `${urls.user}/${input.userId}/authenticate_with_token`;
  return http
    .post(url, data)
    .then((results: any) => {
      if (results.status === 200) {
        localStorage.setItem('authorization_token', results.data.authorization_token);
      }
      return results;
    })
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const resendSwitchBoardOnBoardingLink = (http: any, input: any) => () => {
  const data = {
    token: input.token,
  };
  const url = `${urls.user}/${input.userId}/resend_switchboard_onboarding_link`;
  return http
    .post(url, data)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const clientAdminOnboardingComplete = (http: any, input: any) => () => {
  const url = `${urls.user}/${input.userId}/client_administrator_role/complete`;
  delete input['userId'];
  return http
    .post(url, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const emailAvailable = (http: any, input: any) => () => {
  const email = input.email?.replace('+', '%2B');
  const url = `${urls.user}/email_address_available?email_address=${email}`;
  return http
    .get(url)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const TiersApi = (client: any) => ({
  getAllTiers: (input: any) => getAllTiers(client, input),
  updateTiers: (input: any) => updateTiers(client, input),
  deleteTiers: (input: any) => deleteTiers(client, input),
  createTiers: (input: any) => createTiers(client, input), //
  fetchTier: (input: any) => fetchTier(client, input), //fetchTier
});

const createTiers = (http: any, input: any) => () => {
  return http
    .post(`${urls.tiers}/`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllTiers = (http: any, input: any) => () => {
  return http
    .get(`${urls.tiers}/`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchTier = (http: any, input: any) => () => {
  return http
    .get(`${urls.tiers}/${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateTiers = (http: any, input: any) => () => {
  return http
    .put(`${urls.tiers}/${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteTiers = (http: any, input: any) => () => {
  return http
    .delete(`${urls.tiers}/${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};
// Partner API's
export const PartnerApi = (client: any) => ({
  postRedeemPartnerCode: (input: any) => postRedeemPartnerCode(client, input),
});

const postRedeemPartnerCode = (http: any, input: any) => () => {
  const url = `${urls.partners}/redeem_partner_code?user_id=${input.userId}&partner_code=${input.partnerCode}`;
  return http
    .post(url, {})
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const TagsApi = (client: any) => ({
  getAllTags: (input: any) => getAllTags(client, input),
  updateTags: (input: any) => updateTags(client, input),
  deleteTags: (input: any) => deleteTags(client, input),
  createTags: (input: any) => createTags(client, input), //
  fetchTag: (input: any) => fetchTag(client, input), //
  fetchTagsCount: (input: any) => fetchTagsCount(client, input), //
  updateTagsSort: (input: any) => updateTagsSort(client, input), //
  getListenerTags: (input: any) => getListenerTags(client, input), //
  updateListenersTags: (input: any) => updateListenersTags(client, input), //
  removeListenersTags: (input: any) => removeListenersTags(client, input), //
  updateListenerTagsList: (input: any) => updateListenerTagsList(client, input), //

  getAllTagsGroup: (input: any) => getAllTagsGroup(client, input), //
  createTagsGroup: (input: any) => createTagsGroup(client, input), //
  updateTagsGroup: (input: any) => updateTagsGroup(client, input), //
  deleteTagsGroup: (input: any) => deleteTagsGroup(client, input), //
  fetchTagsGroup: (input: any) => fetchTagsGroup(client, input), //
  fetchTagsGroupCount: (input: any) => fetchTagsGroupCount(client, input), //
});

const createTagsGroup = (http: any, input: any) => () => {
  return http
    .post(`${urls.tagGroups}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllTagsGroup = (http: any, input: any) => () => {
  const payload = { ...input };
  if (payload.tag_type === 'all') delete payload['tag_type'];
  if (!payload.keys) {
    payload['keys'] = 'all';
  }
  return http
    .get(`${urls.tagGroups}`, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchTagsGroupCount = (http: any, input: any) => () => {
  const payload = { ...input };
  if (payload.tag_type === 'all') delete payload['tag_type'];
  return http
    .get(`${urls.tagGroups}count/`, payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchTagsGroup = (http: any, input: any) => () => {
  return http
    .get(`${urls.tagGroups}${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateTagsGroup = (http: any, input: any) => () => {
  return http
    .put(`${urls.tagGroups}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteTagsGroup = (http: any, input: any) => () => {
  return http
    .delete(`${urls.tagGroups}${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createTags = (http: any, input: any) => () => {
  return http
    .post(`${urls.tags}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllTags = (http: any, input: any) => () => {
  const payload = { ...input };
  if (payload.tag_type === 'all') delete payload['tag_type'];
  return http
    .get(`${urls.tags}`, payload, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchTagsCount = (http: any, input: any) => () => {
  const payload = { ...input };
  if (payload.tag_type === 'all') delete payload['tag_type'];
  return http
    .get(`${urls.tags}count`, payload, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchTag = (http: any, input: any) => () => {
  return http
    .get(`${urls.tags}${input.id}`, input.payload, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateTags = (http: any, input: any) => () => {
  return http
    .put(`${urls.tags}${input.id}`, input.payload, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateTagsSort = (http: any, input: any) => () => {
  return http
    .put(`${urls.tags}${input.type}/sort_order`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteTags = (http: any, input: any) => () => {
  return http
    .delete(`${urls.tags}${input.id}`, 'v3')
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getListenerTags = (http: any, input: any) => () => {
  return http
    .get(`${urls.listeners}/${input.id}/listener_tags`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};
const updateListenersTags = (http: any, input: any) => () => {
  return http
    .put(`${urls.listeners}/${input.id}/listener_tags/${input.tag_id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};
const removeListenersTags = (http: any, input: any) => () => {
  return http
    .delete(`${urls.listeners}/${input.id}/listener_tags/${input.tag_id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateListenerTagsList = (http: any, input: any) => () => {
  return http
    .post(`${urls.listeners}/${input.id}/listener_tags`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const ResourcesApi = (client: any) => ({
  getAllResources: (input: any) => getAllResources(client, input),
  updateResources: (input: any) => updateResources(client, input),
  deleteResources: (input: any) => deleteResources(client, input),
  createResources: (input: any) => createResources(client, input), //
  fetchResource: (input: any) => fetchResource(client, input), //fetchResource
});

const createResources = (http: any, input: any) => () => {
  return http
    .post(`${urls.resources}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllResources = (http: any, input: any) => () => {
  return http
    .get(`${urls.resources}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchResource = (http: any, input: any) => () => {
  return http
    .get(`${urls.resources}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateResources = (http: any, input: any) => () => {
  return http
    .put(`${urls.resources}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteResources = (http: any, input: any) => () => {
  return http
    .delete(`${urls.resources}${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

export const SafetyApi = (client: any) => ({
  createBlackList: (input: any) => createBlackList(client, input),
  deleteBlackList: (input: any) => deleteBlackList(client, input),
  fetchBlackListCount: (input: any) => fetchBlackListCount(client, input),
  getAllBlackList: (input: any) => getAllBlackList(client, input),
  getAllBlocked: (input: any) => getAllBlocked(client, input),
  createBlocked: (input: any) => createBlocked(client, input),
  deleteBlocked: (input: any) => deleteBlocked(client, input),
  fetchBlockedCount: (input: any) => fetchBlockedCount(client, input),
  getAllUsers: (input: any) => getAllUsers(client, input), //
  updateUser: (input: any) => updateUser(client, input), //
  getAllConnections: (input: any) => getAllConnections(client, input),
  getAllConnectionCounts: (input: any) => getAllConnectionsCount(client, input),
  UpdateSettiings: (input: any) => UpdateSettiings(client, input),
});

const UpdateSettiings = (http: any, input: any) => () => {
  return http
    .put(`${urls.appSettings.replace('system/', '')}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createBlackList = (http: any, input: any) => () => {
  return http
    .post(`${urls.blackList}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllBlackList = (http: any, input: any) => () => {
  return http
    .get(`${urls.blackList}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchBlackListCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.blackList}/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteBlackList = (http: any, input: any) => () => {
  return http
    .delete(`${urls.blackList}/${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllBlocked = (http: any, input: any) => () => {
  return http
    .get(`${urls.blocked}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const createBlocked = (http: any, input: any) => () => {
  return http
    .post(`${urls.blackList}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const fetchBlockedCount = (http: any, input: any) => () => {
  return http
    .get(`${urls.blocked}/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const deleteBlocked = (http: any, input: any) => () => {
  return http
    .delete(`${urls.blocked}/${input.id}`)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllUsers = (http: any, input: any) => () => {
  return http
    .get(`${urls.users}`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const updateUser = (http: any, input: any) => () => {
  return http
    .put(`${urls.users}${input.id}`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllConnections = (http: any, input: any) => () => {
  return http
    .get(`/connections/all`, input)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};

const getAllConnectionsCount = (http: any, input: any) => () => {
  return http
    .get(`/connections/all/count`, input.payload)
    .then((results: any) => results)
    .catch((error: any) => {
      handleErrors(error.response);
    });
};
