import * as React from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { fetchUserDetailsRequest } from '../account-login/login-redux/actions';
import useAuth from '../../hooks/useAuth';

type props = {
  userName?: string;
  heading?: React.ReactNode;
  username: string;
  disableText?: boolean;
};

const GlobalDashboard: React.FunctionComponent<props> = ({ heading, userName, username, disableText }): JSX.Element => {
  const { logout } = useAuth()
  const todayDate = moment().format('MMM Do YYYY');
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (!username) {
      dispatch(fetchUserDetailsRequest({}));
    }
  }, [username]); //   eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className=" bg-gray-background-light px-2  border-b gray-border-line h-20">
      <div className="flex justify-between items-center py-2 px-4 gray-background-light h-full">
        <div className={disableText ? ' w-1/2 ' : 'text-xl font-bold blue-primary flex '}>{heading}</div>
        <div className="flex font-medium text-sm justify-between items-center text-gray-dark ">
          <div className="px-3">{todayDate} </div>
          <div className="px-3 font-bold">{`Hello, ${username ? username : ''}`}</div>
          <button className="rounded-full text-xs bg-white px-5 py-1" onClick={logout}>
            LOG OUT
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    username: state.login.loginDetails?.data?.first_name || state.login.UserDetails?.data?.first_name || null,
  };
};

export default connect(mapStateToProps)(GlobalDashboard);
