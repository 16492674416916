import React from 'react';
type props = {
  handleToggleModel: () => void;
};
const TemplateModel: React.FunctionComponent<props> = (props): JSX.Element => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-3xl max-h-162.5 overflow-y-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between">
              <div className="text-2xl px-10 pt-10 blue-primary">Replacement Variables</div>
            </div>
            <div className="text-sm leading-relaxed h-full text-gray-dark text-base font-normal px-10">
              Replacement variables can be used wihin the subject or message body. These are also passed to Mailchimp
              for use within those email templates. The following are supported:
            </div>
            <div className="px-10 flex w-full mt-4">
              <div className="w-1/2">
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{first_name}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{last_name}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{display_name}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{email_address}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{mobile_phone}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{signup_url}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{web_link}}'}
                </p>
              </div>
              <div className="w-1/2">
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{client_name}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{client_logo}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{group_logo}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{client_package}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{client_package_code}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{message_body}}'}
                </p>
                <p className="flex items-center">
                  <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                  {'{{reflection_link}}'}
                </p>
              </div>
            </div>
            <div className="text-sm leading-relaxed h-full text-gray-dark text-base font-normal px-10 mt-4">
              Caveats and notes:
            </div>
            <div className="px-10">
              <div className="flex">
                <div className="w-6">
                  <div className="w-3 h-3 mt-1 mr-2 rounded-full bg-gray-dark"></div>
                </div>
                <p>{'{{signup_url}} is only provided when the message is triggered during a conversion campaign.'}</p>
              </div>
              <div className="flex w-full">
                <div className="w-8">
                  <div className="w-3 h-3 mt-1 mr-2 rounded-full bg-gray-dark"></div>
                </div>
                <p>
                  {
                    '{{client_logo}} is intended for use within email templates and is replaced as the url, not an image tag, so this will not display as an image in a non-template email or notification/in-app message.'
                  }
                </p>
              </div>
              <div className="flex w-full">
                <div className="w-8">
                  <div className="w-3 h-3 mt-1 mr-2 rounded-full bg-gray-dark"></div>
                </div>
                <p>
                  {
                    '{{message_body}} provides the content of this message as a replacement variable to the Mailchimp email template, after performing our own replacement.'
                  }
                </p>
              </div>
              <div className="flex w-full">
                <div className="w-5">
                  <div className="w-3 h-3 mt-1 mr-2 rounded-full bg-gray-dark"></div>
                </div>
                <p>{'{{web_link}} provides the url for the web app for the target user if configured.'}</p>
              </div>
              <div className="flex w-full">
                <div className="w-5">
                  <div className="w-3 h-3 mt-1 mr-2 rounded-full bg-gray-dark"></div>
                </div>
                <p>{'{{reflection_link}} provides a link to the current active reflection for that user.'}</p>
              </div>
            </div>
            <div className="flex items-center justify-center pb-6 mt-4">
              <button
                className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                type="button"
                onClick={props.handleToggleModel}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default TemplateModel;
