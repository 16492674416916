import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Header from '../../components/common/global-top-bar';
import { filterIcon } from '../../assets/images';
import Spinner from '../../components/common/spinner';
import moment from 'moment';
import { ROUTE_PATH } from '../../routes/paths';
import { convertObjectToParams } from '../../components/common/create-params';
import { ListenerRole, PeerOptions, usePeers } from '../../hooks/usePeers';
import { Tier, useTiers } from '../../hooks/useTiers';

import { useProfileTagGroups } from '../../hooks/useProfileTagGroups';
import FilterBar from './components/filter-bar';
import FilterModal from './components/filter-modal';
import SwitchBar from '../../components/switch-bar';
import { set } from 'react-hook-form';

const PeersList: React.FC = (): JSX.Element => {
  const location = useLocation();
  const [peerType, setPeerType] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<PeerOptions>({
    limit: 25,
    page: 1,
    userType: peerType === 'peer' ? ['onboardingpeer', 'peer'] : ['onboardinglistener', 'peerlistener'],
    isPeerActive: ['true'],
  });
  const { limit, page, userType, isPeerActive, ...rest } = filters;
  const { data: peers, isLoading } = usePeers({
    limit,
    page,
    userType,
    isPeerActive,
    ...rest,
  });
  const { data: tiers } = useTiers();

  // We always want to separate results for Peers and Peer Listeners, so we set the default filter to the respective onboarding/active states depending on which tab we're in.
  useEffect(() => {
    if (filters.userType?.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        userType: peerType === 'peer' ? ['onboardingpeer', 'peer'] : ['onboardinglistener', 'peerlistener'],
      }));
    }
  }, [filters]);

  useEffect(() => {
    const storedFilters = localStorage.getItem('peerListingFilters');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      setFilters(parsedFilters);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/peers')) {
      setPeerType('peer');
      setFilters((prevFilters) => ({ ...prevFilters, page: 1, userType: ['onboardingpeer', 'peer'] }));
    } else if (location.pathname.startsWith('/peer-listeners')) {
      setPeerType('listener');
      setFilters((prevFilters) => ({ ...prevFilters, page: 1, userType: ['onboardinglistener', 'peerlistener'] }));
    }
    console.log('location.pathname', location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <Header heading="Peers" />
      <SwitchBar
        heading={[
          { heading: 'Peers', path: '/peers' },
          { heading: 'Peer Listeners', path: '/peer-listeners' },
        ]}
        position={peerType === 'peer' ? 0 : 1}
      />
      <FilterBar count={peers?.count} setFilters={setFilters} filters={filters} />
      {isLoading && <Spinner alignmentClass={'left-64 top-30 fixed z-100 inset-0 overflow-y-auto'} />}
      <div className="max-window-height-connections overflow-y-auto px-7 font-['Montserrat'">
        <PeerListingHeader filters={filters} setFilters={setFilters} setIsOpen={setIsOpen} count={peers?.count} />
        <PeerListingBody peers={peers?.data} tiers={tiers} filters={filters} peerType={peerType} />
        <FilterModal
          filters={filters}
          setFilters={setFilters}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          peerType={peerType}
        />
      </div>
    </div>
  );
};

const PeerListingHeader: React.FC<{
  filters?: PeerOptions;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  count?: number;
}> = ({ filters, setFilters, setIsOpen, count }) => {
  const getActiveFilters = (filters: PeerOptions) => {
    const activeFilters: { key: string; value: string | number }[] = [];
    // if the key is 'page, 'limit', or  'userType' and includes both default filters, don't show in the active filters
    Object.entries(filters).forEach(([key, value]) => {
      if (['page', 'limit'].includes(key)) {
        return;
      }
      if (key === 'userType' && value.length === 2) {
        return;
      }
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((filterValue) => {
          activeFilters.push({ key, value: filterValue });
        });
      } else if (value && !Array.isArray(value)) {
        activeFilters.push({ key, value });
      }
    });
    return activeFilters;
  };

  const activeFilters = getActiveFilters(filters ? filters : {});

  const clearFilter = (filterKey: string, filterValue: string | number | boolean) => {
    setFilters((prevFilters) => {
      const updatedFilters: PeerOptions = { ...prevFilters };
      // if the filter is an array, we just filter out the value of the filter. If the filter is not an array, we delete the entire filter key.
      if (Array.isArray(updatedFilters[filterKey as keyof PeerOptions])) {
        // @ts-ignore
        updatedFilters[filterKey as keyof PeerOptions] = (
          updatedFilters[filterKey as keyof PeerOptions] as Array<string | number>
        ).filter((item) => item !== filterValue);
        localStorage.setItem('peerListingFilters', JSON.stringify(updatedFilters));
      } else {
        delete updatedFilters[filterKey as keyof PeerOptions];
        localStorage.setItem('peerListingFilters', JSON.stringify(updatedFilters));
      }
      return updatedFilters;
    });
  };

  return (
    <div className="h-full border-b-4 border-black mb-4">
      <div className="w-full text-left w-full flex pt-8 pb-2 justify-between">
        <div className="flex items-center">
          <div className="pr-12 text-violet-950 text-3xl font-bold">Peers</div>
          <button onClick={() => setIsOpen(true)}>
            <img src={filterIcon} className="w-6 h-6" alt="filter" />
          </button>
          <div className="w-20 h-4 text-right text-neutral-600 text-xs font-normal whitespace-nowrap">Filtered by</div>
          <ActiveFilters activeFilters={activeFilters} clearFilter={clearFilter} />
        </div>
        <div className="flex items-end space-x-4">
          <div className="pb-1 text-neutral-600 text-xs font-normal">Matches</div>
          <div className="text-violet-950 text-4xl font-bold font-['Montse">{count}</div>
        </div>
      </div>
    </div>
  );
};

const ActiveFilters: React.FC<{
  activeFilters: {
    key: string;
    value: string | number;
  }[];
  clearFilter: (filterKey: string, filterValue: string | number) => void;
}> = ({ activeFilters, clearFilter }) => {
  const { data: filterOptions } = useProfileTagGroups();
  const { data: tiers } = useTiers();

  return (
    <div className="mx-2 flex space-x-3 overflow-x-auto overflow-y-hidden scrollbar-hide max-w-2xl">
      {activeFilters.map(({ key, value }) => {
        let valueString = value as string;
        if (value && typeof value === 'number' && key !== 'tierId') {
          valueString =
            filterOptions
              ?.find((item) => item.tags.some((tag) => tag.id === value))
              ?.tags.find((tag) => tag.id === value)?.name || '';
        } else if (value && key === 'tierId') {
          valueString = tiers?.find((tier) => tier.id === value)?.name || '';
        } else if (key === 'userType') {
          valueString = value === 'onboardingpeer' ? 'Peer Applicant' : 'Peer';
        } else if (key === 'isPeerActive') {
          valueString = value === 'true' ? 'Visible' : 'Hidden';
        }
        return (
          <div key={`${key}-${value}`} className="px-2 h-7 bg-gray-200 rounded-full flex items-center justify-between">
            <div className="p-2 text-zinc-600 text-sm font-bold font-['Montserrat'] truncate">{valueString}</div>
            <button
              className="w-4 h-4 bg-white rounded-full text-xs items-center"
              onClick={() => clearFilter(key, value)}
            >
              X
            </button>
          </div>
        );
      })}
    </div>
  );
};

const PeerListingBody: React.FC<{
  peers: ListenerRole[] | undefined;
  tiers: Tier[] | undefined;
  filters: PeerOptions;
  peerType: string;
}> = ({ peers, tiers, filters, peerType }) => {
  const history = useHistory();
  return (
    <div>
      {peers &&
        peers.length > 0 &&
        peers.map((peer: ListenerRole) => {
          return (
            <div
              role="button"
              className="w-full bg-gray-200 rounded text-gray-dark text-left h-36 border-b-8 border-white space-y-6 space-x-2 flex"
              onClick={() => {
                localStorage.setItem('peerListingFilters', JSON.stringify(filters));
                history.push(
                  (peerType === 'peer' ? ROUTE_PATH.PEERS_PROFILE : ROUTE_PATH.LISTENERS_PROFILE) +
                    convertObjectToParams({
                      listenerRoleId: peer.id,
                      listenerId: peer.user.id,
                      listenerType: peerType === 'peer' ? 'peer' : 'listener',
                      listenerName: peer.user.first_name,
                    }),
                );
              }}
            >
              <div className="w-4/12">
                <div className="pl-4 flex w-full h-full items-center">
                  {peer.current_profile_photo !== null ? (
                    <img src={peer.current_profile_photo?.file_url} className="w-24 h-24" alt="profile"></img>
                  ) : (
                    <div className="flex w-28 h-24 bg-gray-400 rounded-full">
                      <p className="flex items-center pl-2">No Avatar</p>
                    </div>
                  )}
                  <div className="w-full h-full flex flex-col space-y-3 pl-6 pt-6 font-normal truncate font-['Montserrat']">
                    <div className="text-neutral-600 text-xs">
                      <p>#{peer.user.id}</p>
                    </div>
                    <p
                      className={`h-6 text-black  ${peer.user.display_name ? 'text-xl underline font-bold' : 'text-sm italic'}`}
                    >
                      {peer.user.display_name ? peer.user.display_name : 'No display name'}
                    </p>
                    <p className="text-base text-black ">
                      {peer.user.first_name || peer.user.last_name
                        ? `${peer.user.first_name} ${peer.user.last_name}`
                        : 'No name'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-2/12">
                <div className="w-full flex flex-col space-y-3 font-normal truncate font-['Montserrat']">
                  <div>
                    <div className="text-neutral-600 text-xs flex justify-between w-3/4 space-x-2">
                      <p>{tiers?.find((tier) => tier.id === peer.tier_id)?.name}</p>
                      <p>{moment(peer?.user?.created_at).format('MMMM D, YYYY')}</p>
                    </div>
                    <p className="text-black text-base font-bold">
                      {peerType === 'peer'
                        ? peer.state === 'active_peer'
                          ? 'Peer'
                          : 'Peer Applicant'
                        : peer.state === 'active_listener'
                          ? 'Listener'
                          : 'Listener Applicant'}
                    </p>
                  </div>
                  <div>
                    <p className="text-neutral-600 text-xs uppercase">Visibility</p>
                    <p className="text-black text-base font-bold">{peer.is_peer_active ? 'Visible' : 'Hidden'}</p>
                  </div>
                </div>
              </div>
              <div className="w-2/12">
                <div className="w-full font-normal truncate font-['Montserrat']">
                  <div>
                    <p className="text-neutral-600 text-xs uppercase">Experiences</p>
                    <p className="text-black text-base font-bold">{peer.num_experiences}</p>
                  </div>
                  <div>
                    <p className="pt-3 text-neutral-600 text-xs uppercase">Experience Plays</p>
                    <p className="text-black text-base font-bold">{peer.num_playbacks}</p>
                  </div>
                </div>
              </div>
              <div className="w-2/12">
                <div className="w-full font-normal truncate font-['Montserrat']">
                  {peerType === 'peer' ? (
                    <div>
                      <p className="text-neutral-600 text-xs uppercase">Earnings</p>
                      <p className="text-black text-base font-bold">
                        {/* convert peer.earnings to $00.00 format from cents */}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format((peer?.earnings || 0) / 100)}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div>
                        <p className="text-neutral-600 text-xs uppercase">Successful Calls</p>
                        <p className="text-black text-base font-bold">{peer.calls_succeeded}</p>
                      </div>
                      <div>
                        <p className="pt-3 text-neutral-600 text-xs uppercase">Call Requests</p>
                        <p className="text-black text-base font-bold">{peer.connection_requests}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="w-2/12">
                <div className="w-full font-normal truncate font-['Montserrat']">
                  <p className="text-neutral-600 text-xs uppercase">Followers</p>
                  <p className="text-black text-base font-bold">{peer.num_followers}</p>
                </div>
                <div>
                  <p className="pt-3 text-neutral-600 text-xs uppercase">Org or Community</p>
                  <p className="text-black text-base font-bold">{peer.group_name ? peer.group_name : 'N/A'}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PeersList;
