import * as React from 'react';

type props = {
  xwidth: string;
  backGroundColor: string;
};

const StackedBarChart: React.FunctionComponent<props> = ({ xwidth, backGroundColor }): JSX.Element => {
  const background = ' ' + backGroundColor.toString() + ' ';

  return (
    <div
      style={{ width: `${xwidth}` }}
      className={
        'text-center h-10 flex items-center justify-center text-monsterrat border-white border text-white font-bold' +
        background
      }
    >
      {parseInt(xwidth.replace('%', '')) > 10 ? xwidth : null}
    </div>
  );
};

export default StackedBarChart;
