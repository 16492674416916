import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';
import { ROUTE_PATH } from '../../routes/paths';
import { Client, Package, useListClients } from '../../hooks/useClients';

type ClientAdminGroupDropdownProps = {
  type?: string;
  handleChange?: (data: any) => void;
};

type EngagementDropDownType = {
  label: string;
  value:
    | {
        packageId: number;
        clientId: number;
      }
    | string;
};

type EngagementListType = {
  label?: string;
  options?: EngagementDropDownType[];
  value?: string;
};

type EligibilityDropDownType = {
  label: string;
  value?: number;
};

const ClientAdminGroupDropDown: React.FunctionComponent<ClientAdminGroupDropdownProps> = ({
  type,
  handleChange,
}): JSX.Element => {
  const history = useHistory();
  const search = useLocation().search;
  const packageId = new URLSearchParams(search).get('packageId');
  const clientId = new URLSearchParams(search).get('clientId');

  const { data: clients } = useListClients();

  const [engagementValue, setEngagementValue] = useState<EngagementDropDownType>();
  const [eligibilityValue, setEligibilityValue] = useState<EligibilityDropDownType>();
  const [engagementClientList, setEngagementClientList] = useState<EngagementListType[]>([]);
  const [eligibilityClientList, setEligibilityClientList] = useState<EligibilityDropDownType[]>([]);

  const handleEngagementChange = (engagementValue: EngagementDropDownType) => {
    let url = ROUTE_PATH.ENAGEMENT;
    if (typeof engagementValue.value !== 'string') {
      url = `${ROUTE_PATH.ENAGEMENT}/?packageId=${engagementValue.value.packageId}&clientId=${engagementValue.value.clientId}`;
    }
    history.push(url);
  };

  const handleEligibilityChange = (eligibilityValue: EligibilityDropDownType) => {
    setEligibilityValue(eligibilityValue);
    handleChange &&
      handleChange({
        clientId: eligibilityValue.value,
      });
  };

  // This is necessary to work with the react-select component onChange event, which did not want to wor with our types.
  const handleOnChange = (value: any) => {
    if (type === 'engagement') {
      handleEngagementChange(value as EngagementDropDownType);
    } else if (type === 'eligibility') {
      handleEligibilityChange(value as EligibilityDropDownType);
    }
  };

  const findEngagementValue = (packageId: string, clientId: string, clients: Client[]) => {
    const foundPackage = clients
      .find((client: Client) => client.id === parseInt(clientId))
      ?.packages.find((pkg: Package) => pkg.id === parseInt(packageId));
    if (foundPackage && foundPackage.id && foundPackage.client_id) {
      setEngagementValue({
        label: foundPackage.name,
        value: { packageId: foundPackage.id, clientId: foundPackage.client_id },
      });
    }
  };

  const buildEngagementList = (clients: Client[]) => {
    const clientPackageList =
      clients && clients.length > 0
        ? clients?.map((client: Client) => {
            return {
              label: client.name,
              options: client?.packages.map((pkg: Package) => {
                return {
                  label: pkg.name!,
                  value: {
                    packageId: pkg.id!,
                    clientId: pkg.client_id!,
                  },
                };
              }),
            };
          })
        : [];
    setEngagementClientList([
      {
        label: 'All Groups',
        value: 'All Groups',
      },
      ...clientPackageList,
    ]);
  };

  const buildEligibilityList = (clients: Client[]) => {
    const clientList =
      clients?.length > 0 &&
      clients?.filter((item: Client) => !!!item.eligibility_config?.hide_client_admin_eligibility).length > 0
        ? clients
            ?.filter((item: Client) => !!!item.eligibility_config?.hide_client_admin_eligibility)
            .map((item: Client) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
        : [];
    if (clientList?.length > 0) {
      setEligibilityClientList(clientList);
      setEligibilityValue(clientList[0]);
    }
    handleChange &&
      handleChange({
        clientId: clientList?.length > 0 ? clientList[0].value : null,
      });
  };

  useEffect(() => {
    if (type === 'engagement' && packageId && clientId && clients) {
      findEngagementValue(packageId, clientId, clients);
    } else if (type === 'engagement') {
      setEngagementValue({
        label: 'All Groups',
        value: 'All Groups',
      });
    }
  }, [packageId, clientId, clients, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clients && type === 'engagement') {
      buildEngagementList(clients);
    } else if (clients && type === 'eligibility') {
      buildEligibilityList(clients);
    }
  }, [clients, type]);

  return (
    <div className="w-full">
      <Select
        styles={clientAdminGroupDropdownStyles}
        onChange={handleOnChange}
        options={
          (type === 'engagement' ? engagementClientList : eligibilityClientList) as readonly (
            | EngagementDropDownType
            | EligibilityDropDownType
          )[]
        }
        value={type === 'engagement' ? engagementValue : eligibilityValue}
      />
    </div>
  );
};

const clientAdminGroupDropdownStyles = {
  menuList: (styles: any) => ({
    ...styles,
    background: 'white',
  }),
  //@ts-ignore
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      background: isFocused && !isSelected ? 'hsl(204, 67%, 94%)' : isSelected ? 'hsl(218, 100%, 50%)' : undefined,
      paddingLeft: 30,
      zIndex: 1,
    };
  },
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  //@ts-ignore
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: 'none',
      border: state.isFocused && 'none',
    };
  },
};

export default ClientAdminGroupDropDown;
