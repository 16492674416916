export const HeaderOptions = [
  { heading: 'Reporting', path: '/groups/reporting' },
  // { heading: 'Billing', path: '/groups/billing' },
  { heading: 'Configure', path: '/groups/configure' },
  { heading: 'Plan', path: '/groups/packages' },
  { heading: 'Eligibility', path: '/groups/eligibility' },
  { heading: 'Resources', path: '/groups/resources' },
  { heading: 'Admins', path: '/groups/admins' },
  { heading: 'SSO Configuration', path: '/groups/sso-configuration' },
];

export const ResponsibleParty = [
  {
    text: 'Test New Campaign',
    value: 'SGL87MO5NXER',
  },
  {
    text: 'Listeners Celebrations',
    value: 'WACXG7O2YDE2',
  },
  {
    text: 'Default Campaign',
    value: '39LHT33ZVDGH',
  },
];
