import {
  POST_TIERS_REQUEST,
  POST_TIERS_SUCCESS,
  POST_TIERS_FAILURE,
  FETCH_TIERS_LIST_REQUEST,
  FETCH_TIERS_LIST_SUCCESS,
  FETCH_TIERS_LIST_FAILURE,
  UPDATE_TIERS_REQUEST,
  UPDATE_TIERS_SUCCESS,
  UPDATE_TIERS_FAILURE,
  REMOVE_TIERS_REQUEST,
  REMOVE_TIERS_SUCCESS,
  REMOVE_TIERS_FAILURE,
  POST_TEST_TIERS_REQUEST,
  POST_TEST_TIERS_SUCCESS,
  POST_TEST_TIERS_FAILURE,
  FETCH_TIER_REQUEST,
  FETCH_TIER_SUCCESS,
  FETCH_TIER_FAILURE,
} from './action-types';

import { HomeActions, TemplateInfoState } from './types';

const initialState: TemplateInfoState = {
  pending: false,
  Tiers: [],
  TiersList: [],
  Tier: null,
  UpdateTiers: null,
  RemoveTiers: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_TIERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TIERS_SUCCESS:
      return {
        ...state,
        pending: false,
        newTier: action.payload,
        error: null,
      };
    case POST_TIERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_TIERS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TIERS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        TiersList: action.payload.TiersList,
        error: null,
      };
    case FETCH_TIERS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_TIERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_TIERS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateTiers: action.payload,
        error: null,
      };
    case UPDATE_TIERS_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateTiers: null,
        error: action.payload.error,
      };
    case REMOVE_TIERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_TIERS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveTiers: action.payload,
        error: null,
      };
    case REMOVE_TIERS_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveTiers: null,
        error: action.payload.error,
      };

    case POST_TEST_TIERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TEST_TIERS_SUCCESS:
      return {
        ...state,
        pending: false,
        testTiers: action.payload,
        error: null,
      };
    case POST_TEST_TIERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_TIER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TIER_SUCCESS:
      return {
        ...state,
        pending: false,
        TIER: action.payload,
        error: null,
      };
    case FETCH_TIER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
