import ToggleButton from '../../../components/common/toggle-button';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import { CustomInput } from '../../../components/common';
import { postUpdatePackageRequest } from '../clients-redux/actions';

type props = {
  data: any;
  UpdateSaveOption: (data: boolean) => void;
};

const Dependents: React.FunctionComponent<props> = (props): JSX.Element => {
  const [packageId, setPackageId] = useState<any>();
  const clientId = localStorage.getItem('clientid');
  const [isDependentsEnable, setIsDependentsEnable] = useState<boolean>(false);
  const [dependentCount, setDependentCount] = useState<number>(3);
  const [memberCanAddDependents, setMemberCanAddDependents] = useState<boolean>(false);
  const [enableSaveOption, setEnableSaveOption] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  const handleSave = () => {
    const linkInput = {
      clientId,
      packageId,
    };

    const payload = {
      dependents_configuration: {
        enabled: isDependentsEnable,
        dependent_count: +dependentCount,
        members_can_add_dependents: memberCanAddDependents,
      },
    };
    if (clientId) {
      dispatch(postUpdatePackageRequest({ payload, linkInput }));
    }
  };

  useEffect(() => {
    if (props.data) {
      const data = props.data;
      setEnableSaveOption(false);
      setPackageId(data.id);
      if (data?.dependents_configuration) {
        setIsDependentsEnable(data.dependents_configuration?.enabled);
        setDependentCount(data.dependents_configuration?.dependent_count);
        setMemberCanAddDependents(data.dependents_configuration?.members_can_add_dependents);
      }
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AccordionTile
        setStatus={(data: boolean) => {
          !enableSaveOption && setEnableSaveOption(true);
          setIsDependentsEnable(data);
        }}
        Label={'DEPENDENTS'}
        buttonText={'SAVE'}
        status={isDependentsEnable}
        id={'Dependents'}
        initialstatus={true}
        onButtonClick={handleSave}
        disableButton={enableSaveOption}
        InnerComponent={
          <div className={'grid grid-cols-2 gap-4 py-2.5'}>
            <div className="col-span-1">
              <CustomInput
                inputType="text"
                Label="Dependent Count"
                value={dependentCount}
                setValue={(data) => {
                  setDependentCount(data);
                  if (parseInt(data, 10) < 1) {
                    setErrors({ dependent_count: { message: 'Enter 1 (one) or more' } });
                    setEnableSaveOption(false);
                    return;
                  }
                  setErrors({});
                  setEnableSaveOption(true);
                }}
                Error={!!errors?.dependent_count?.message?.length}
                ErrorMessage={errors.dependent_count?.message}
              />
            </div>
            <div className="col-span-1 flex">
              <div className="leading-4 text-sm font-medium text-dark-blue mr-2 flex items-center">
                Members Can Add Dependents
              </div>
              <div className="px-3 flex items-center">
                <ToggleButton
                  status={memberCanAddDependents}
                  setStatus={(data) => {
                    setEnableSaveOption(true);
                    setMemberCanAddDependents(data);
                  }}
                  initialstatus={true}
                  id={''}
                />
              </div>
            </div>
          </div>
        }
      />
      <div className="w-full border-b gray-border-line"></div>
    </>
  );
};

export default Dependents;
