import * as React from 'react';
import { SBLogo } from '../../assets/images';

const Logo: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center border-b gray-border-line h-20 ">
      <img className="w-4/5" src={SBLogo} alt="Logo" />
    </div>
  );
};

export default Logo;
