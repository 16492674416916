import {
  POST_RESOURCES_REQUEST,
  POST_RESOURCES_FAILURE,
  POST_RESOURCES_SUCCESS,
  FETCH_RESOURCES_LIST_REQUEST,
  FETCH_RESOURCES_LIST_FAILURE,
  FETCH_RESOURCES_LIST_SUCCESS,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_RESOURCES_FAILURE,
  UPDATE_RESOURCES_SUCCESS,
  REMOVE_RESOURCES_REQUEST,
  REMOVE_RESOURCES_FAILURE,
  REMOVE_RESOURCES_SUCCESS,
  POST_TEST_RESOURCES_REQUEST,
  POST_TEST_RESOURCES_FAILURE,
  POST_TEST_RESOURCES_SUCCESS,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_SUCCESS,
} from './action-types';

import {
  PostTestResourcesRequest,
  PostTestResourcesSuccess,
  PostTestResourcesSuccessPayload,
  PostTestResourcesFailure,
  PostTestResourcesFailurePayload,
  PostResourcesRequest,
  PostResourcesSuccess,
  PostResourcesSuccessPayload,
  PostResourcesFailure,
  PostResourcesFailurePayload,
  FetchResourcesListRequest,
  FetchResourcesListSuccess,
  FetchResourcesListSuccessPayload,
  FetchResourcesListFailure,
  FetchResourcesListFailurePayload,
  UpdateResourcesRequest,
  UpdateResourcesSuccess,
  UpdateResourcesSuccessPayload,
  UpdateResourcesFailure,
  UpdateResourcesFailurePayload,
  RemoveResourcesRequest,
  RemoveResourcesSuccess,
  RemoveResourcesSuccessPayload,
  RemoveResourcesFailure,
  RemoveResourcesFailurePayload,
  FetchResourceRequest,
  FetchResourceSuccess,
  FetchResourceSuccessPayload,
  FetchResourceFailure,
  FetchResourceFailurePayload,
} from './types';

export const postResourcesRequest = (payload: any): PostResourcesRequest => ({
  type: POST_RESOURCES_REQUEST,
  payload,
});

export const postResourcesSuccess = (payload: PostResourcesSuccessPayload): PostResourcesSuccess => ({
  type: POST_RESOURCES_SUCCESS,
  payload,
});

export const postResourcesFailure = (payload: PostResourcesFailurePayload): PostResourcesFailure => ({
  type: POST_RESOURCES_FAILURE,
  payload,
});

export const fetchResourcesListRequest = (payload: any): FetchResourcesListRequest => ({
  type: FETCH_RESOURCES_LIST_REQUEST,
  payload,
});

export const fetchResourcesListSuccess = (payload: FetchResourcesListSuccessPayload): FetchResourcesListSuccess => ({
  type: FETCH_RESOURCES_LIST_SUCCESS,
  payload,
});

export const fetchResourcesListFailure = (payload: FetchResourcesListFailurePayload): FetchResourcesListFailure => ({
  type: FETCH_RESOURCES_LIST_FAILURE,
  payload,
});

export const updateResourcesRequest = (payload: any): UpdateResourcesRequest => ({
  type: UPDATE_RESOURCES_REQUEST,
  payload,
});

export const updateResourcesSuccess = (payload: UpdateResourcesSuccessPayload): UpdateResourcesSuccess => ({
  type: UPDATE_RESOURCES_SUCCESS,
  payload,
});

export const updateResourcesFailure = (payload: UpdateResourcesFailurePayload): UpdateResourcesFailure => ({
  type: UPDATE_RESOURCES_FAILURE,
  payload,
});

export const removeResourcesRequest = (payload: any): RemoveResourcesRequest => ({
  type: REMOVE_RESOURCES_REQUEST,
  payload,
});

export const removeResourcesSuccess = (payload: RemoveResourcesSuccessPayload): RemoveResourcesSuccess => ({
  type: REMOVE_RESOURCES_SUCCESS,
  payload,
});

export const removeResourcesFailure = (payload: RemoveResourcesFailurePayload): RemoveResourcesFailure => ({
  type: REMOVE_RESOURCES_FAILURE,
  payload,
});

export const postTestResourcesRequest = (payload: any): PostTestResourcesRequest => ({
  type: POST_TEST_RESOURCES_REQUEST,
  payload,
});

export const postTestResourcesSuccess = (payload: PostTestResourcesSuccessPayload): PostTestResourcesSuccess => ({
  type: POST_TEST_RESOURCES_SUCCESS,
  payload,
});

export const postTestResourcesFailure = (payload: PostTestResourcesFailurePayload): PostTestResourcesFailure => ({
  type: POST_TEST_RESOURCES_FAILURE,
  payload,
});

export const fetchResourceRequest = (payload: any): FetchResourceRequest => ({
  type: FETCH_RESOURCE_REQUEST,
  payload,
});

export const fetchResourceSuccess = (payload: FetchResourceSuccessPayload): FetchResourceSuccess => ({
  type: FETCH_RESOURCE_SUCCESS,
  payload,
});

export const fetchResourceFailure = (payload: FetchResourceFailurePayload): FetchResourceFailure => ({
  type: FETCH_RESOURCE_FAILURE,
  payload,
});
