import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { CustomInput } from '../../../components/common';
import { decimalOnChange, numberonBlur, numberOnChange, onBlur } from '../../../components/common/useForm';
import AccordionTile from '../Accordion';
import {
  getDefaultSubscriptionsSuccess,
  postSubscriptionsRequest,
  removeSubscriptionsRequest,
  updateSubscriptionsRequest,
} from '../clients-redux/actions';

type props = {
  data: any;
  Campaigns: any;
  addComms: any;
  defaultSubscriptions: any;
};

type subscription = {
  data: any;
  id: number;
  type: string;
};

type switchbar = {
  type: 'details' | 'description';
  data: any;
  suffix: string;
};

const SwitchBar: React.FunctionComponent<switchbar> = ({ type, data, suffix }): JSX.Element => {
  const [state, setstate] = useState({ position: 0, text: '', id: 0, dataModified: false });
  const dispatch = useDispatch();

  const handleTab = (position: number, text: string, id: number) => {
    setstate({ position, text, dataModified: false, id });
  };

  const handleState = (evt: any) => {
    setstate((prev) => ({ ...prev, text: evt.target.value, dataModified: true }));
  };

  useEffect(() => {
    if (data && type) {
      const sub = data[0];
      setstate({ position: 0, text: sub[type], id: sub?.id, dataModified: false });
    }
  }, [data, type]);

  const update = () => {
    dispatch(updateSubscriptionsRequest({ id: state?.id, payload: { [type]: state.text } }));
  };

  return (
    <>
      <div className="flex justify-start items-center    h-full">
        {data.map((item: any, index: number) => (
          <button
            className={
              ' text-sm  px-5 py-2 ' +
              (index === state.position
                ? ' text-white font-bold bg-blue-primary h-full'
                : ' text-gray-dark  bg-gray-background-light h-full')
            }
            key={item.id}
            //@ts-ignore
            onClick={() => {
              handleTab(index, item[type], item.id);
            }}
          >
            {item.name}-{suffix}
          </button>
        ))}
      </div>
      <textarea
        value={state.text || ''}
        className="py-2 px-3 w-full rounded  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
        onChange={handleState}
      />
      <button
        className={` rounded-full   text-center  text-sm text-white  py-1 px-3 h-10 ${
          state.dataModified ? 'bg-blue-primary' : 'bg-gray-background-light '
        }`}
        onClick={update}
      >
        Update
      </button>
    </>
  );
};

const Subscriptions: React.FunctionComponent<subscription> = ({ data, id, type = 'existing' }): JSX.Element => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (data) {
        return {
          ...data,
          price: data.price / 100,
          cents_per_minute: data.cents_per_minute / 100,
        };
      }
    }, [data]),
  });

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        price: data.price / 100,
        cents_per_minute: data.cents_per_minute / 100,
      });
    }
  }, [data]);

  const remove = (e: any) => {
    e.preventDefault();
    dispatch(removeSubscriptionsRequest({ id: data.id }));
  };

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      cents_per_minute: data.cents_per_minute * 100,
      price: data.price * 100,
      package_id: id,
    };
    if (type === 'new') {
      dispatch(postSubscriptionsRequest({ payload }));
    } else {
      dispatch(updateSubscriptionsRequest({ id: data?.id, payload }));
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-8 gap-4 my-4 w-full ">
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Name"
              register={{
                ...register('name', {
                  required: 'Please provide Subscription name',
                  onBlur: (e: any) => onBlur(e, 'name', setValue),
                }),
              }}
              //@ts-ignore
              Error={errors?.name?.message?.length ? true : false}
              ErrorMessage={errors.name?.message}
            />
          </div>
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Code"
              register={{
                ...register('code', {
                  required: 'Please provide Subscription code',
                  onBlur: (e: any) => onBlur(e, 'code', setValue),
                }),
              }}
              //@ts-ignore
              Error={errors?.code?.message?.length ? true : false}
              ErrorMessage={errors.code?.message}
            />
          </div>

          <div className="w-full">
            <CustomInput
              inputType="text"
              Label="Minutes"
              register={{
                ...register('minutes', {
                  required: 'Please provide Subscription minutes',
                  onChange: (e) => numberOnChange(e, 'minutes', setValue),
                  onBlur: (e) => numberonBlur(e, 'minutes', setValue),
                  valueAsNumber: true,

                  min: {
                    value: 0,
                    message: 'Enter 0 (zero) or more',
                  },
                }),
              }}
              //@ts-ignore
              Error={errors?.minutes?.message?.length ? true : false}
              ErrorMessage={errors.minutes?.message}
            />
          </div>
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Price ($)"
              register={{
                ...register('price', {
                  required: 'Please provide Subscription price',
                  onChange: (e) => decimalOnChange(e, 'price', setValue, 2),
                  onBlur: (e) => numberonBlur(e, 'price', setValue),
                  valueAsNumber: true,
                  min: {
                    value: 0.0,
                    message: 'Enter 0 (zero) or more',
                  },
                }),
              }}
              //@ts-ignore
              Error={!!errors?.price?.message?.length}
              ErrorMessage={errors.price?.message}
              enableAdornment
            />
          </div>
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Overage ($/min)"
              register={{
                ...register('cents_per_minute', {
                  required: 'Please provide Subscription Overage ($/min)',
                  onChange: (e) => decimalOnChange(e, 'cents_per_minute', setValue, 2),
                  onBlur: (e) => numberonBlur(e, 'cents_per_minute', setValue),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: 'Enter 1 (one) or more',
                  },
                }),
              }}
              //@ts-ignore
              Error={!!errors?.cents_per_minute?.message?.length}
              ErrorMessage={errors.cents_per_minute?.message}
              enableAdornment
            />
          </div>
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Discount (%)"
              register={{
                ...register('discount', {
                  required: 'Please provide Subscription discount',
                  onChange: (e) => decimalOnChange(e, 'discount', setValue, 2),
                  onBlur: (e) => numberonBlur(e, 'discount', setValue),
                  valueAsNumber: true,
                  min: {
                    value: 0.0,
                    message: 'Enter 0 (zero) or more',
                  },
                }),
              }}
              //@ts-ignore
              Error={!!errors?.discount?.message?.length}
              ErrorMessage={errors.discount?.message}
            />
          </div>
          <div className=" w-full">
            <CustomInput
              inputType="text"
              Label="Corner Banner"
              register={{
                ...register('banner_text', {
                  required: 'Please provide Subscription Banner Text',
                  onBlur: (e: any) => onBlur(e, 'banner_text', setValue),
                }),
              }}
              //@ts-ignore
              Error={errors?.banner_text?.message?.length ? true : false}
              ErrorMessage={errors.banner_text?.message}
            />
          </div>

          <div className="flex gap-2 min-w-44 h-14 items-end">
            <button
              className={` rounded-full  text-center  text-sm text-white  py-1 px-3 h-10 ${
                isDirty || type === 'new' ? 'bg-blue-primary' : 'bg-gray-background-light '
              }`}
              type="submit"
            >
              {type === 'new' ? 'Add' : 'Update'}
            </button>
            {type === 'existing' && (
              <button
                className="bg-blue-primary rounded-full  text-center h-10 text-sm text-white  py-1 px-3"
                onClick={remove}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const MemberPay: React.FunctionComponent<props> = ({ data, defaultSubscriptions }): JSX.Element => {
  const [memberPay, setmemberPay] = useState(false);
  const [isOnloadCallAPi, setIsOnloadCallAPi] = useState(true);
  const dispatch = useDispatch();

  const generateHash = () => {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var hash = '';

    for (var i = 0; i < 6; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length);
      hash += characters.charAt(randomIndex);
    }

    return hash;
  };

  const handleDefaultTemplate = (isStatus: boolean) => {
    setmemberPay(isStatus);
    if (isStatus && !data?.subscriptions?.length) {
      defaultSubscriptions?.map((item: any, index: number) => {
        dispatch(
          postSubscriptionsRequest({
            payload: {
              ...item,
              discount: item?.discount ? item?.discount : 0,
              package_id: data?.id,
              code: generateHash(),
            },
            isDefault: true,
            isGetClient: defaultSubscriptions?.length === index + 1 ? false : true,
          }),
        );
        return true;
      });
    }
  };

  useEffect(() => {
    if (data?.subscriptions?.length > 0) {
      setmemberPay(true);
    }
  }, [data?.subscriptions]); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(getDefaultSubscriptionsSuccess({}));
    };
  }, []); // eslint-disable-line

  return (
    <>
      <AccordionTile
        setStatus={(isStatus) => {
          handleDefaultTemplate(isStatus);
        }}
        Label={'MEMBER PAY'}
        status={memberPay}
        disableButton={true}
        id={'MemeberPay'}
        buttonText={''}
        initialstatus={true}
        InnerComponent={
          <div>
            <h6 className="text-md font-bold text-gray-dark uppercase mt-4">Options</h6>
            <div className="justify-between gap-7 my-5 w-full">
              {data?.subscriptions.length > 0 &&
                data?.subscriptions
                  .sort((a: any, b: any) => a.id - b.id)
                  .map((item: any) => <Subscriptions data={item} key={item.id} id={data?.id} type="existing" />)}
            </div>
            <Subscriptions
              data={{
                minutes: 0,
                code: '',
                discount: 0,
                price: 0,
                cents_per_minute: 1,
                banner_text: '',
                name: '',
              }}
              key={'new'}
              id={data?.id}
              type="new"
            />

            <div>
              {data?.subscriptions.length > 0 && (
                <div className="mt-8">
                  <SwitchBar
                    data={data?.subscriptions.sort((a: any, b: any) => a.id - b.id)}
                    type={'description'}
                    suffix={'title'}
                  />
                </div>
              )}
            </div>
            <div>
              {data?.subscriptions.length > 0 && (
                <div className="mt-8">
                  <SwitchBar
                    data={data?.subscriptions.sort((a: any, b: any) => a.id - b.id)}
                    type={'details'}
                    suffix={'More Details'}
                  />
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    addComms: state.Client.AddComms || null,
    defaultSubscriptions: state.Client.defaultSubscriptions?.data || null,
  };
};

export default connect(mapStateToProps)(MemberPay);
