import * as React from 'react';
import { Chart } from 'react-google-charts';

type props = {
  error: false;
};

// To represent a Bar chart pass the data via props

const WeeklyChart: React.FunctionComponent<props> = ({ error }): JSX.Element => {
  return (
    // Charts for react graphss
    <div className="mr-7">
      <div className="h-10 transparent"></div>
      <Chart
        width={'40vw'}
        height={'200px'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={[
          ['', ''],
          ['Monday', 1170],
          ['Tuesday', 660],
          ['Wednesday', 1030],
          ['Thursday', 500],
          ['Friday', 200],
          ['Saturday', 900],
          ['Sunday', 800],
        ]}
        options={{
          // Material design options
          colors: ['#1D1183'],
          chart: {
            title: '',
          },
          legend: { position: 'none' },
        }}
        // For tests
        rootProps={{ 'data-testid': '2' }}
      />
    </div>
  );
};

export default WeeklyChart;
