import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
import { CustomInput, DropDown, Spinner } from '../../components/common';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '../../routes/paths';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchCampaignsListRequest,
  fetchCampaignsListSuccess,
  fetchCommsListRequest,
  fetchCommsListSuccess,
  postCampaignsFailure,
  postCampaignsRequest,
  postRepairTemplateRequest,
  postRepairTemplateSuccess,
  postTestCampaignsRequest,
  removeCampaignsFailure,
  removeCampaignsRequest,
  updateCampaignsFailure,
  updateCampaignsRequest,
} from './redux-templates/actions';
import 'rc-time-picker/assets/index.css';
import { fetchEmailTemplateRequest, fetchEmailTemplateSuccess } from '../clients/clients-redux/actions';
import Modal from '../../components/common/modal';
import moment from 'moment';
import Searchbar from '../../components/common/searchbar';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { Location } from 'history';
import MediaInput from '../../components/common/media-input';

type props = {
  campaigns: any;
  comms: any;
  EmailTemplates: any;
  RemoveCampaigns: any;
  UpdateCampaigns: any;
  CreateCampaigns: any;
  RepairTemplate: any;
  pending: boolean;
};

type ActionProps = {
  comms: any;
  details: any;
  index: any;
  updateActionsList: (data: any, index: number) => void;
  delete: (index: number) => void;
  addItem: (index: number) => void;
  handleDrag: (ev: any) => void;
  handleDrop: (ev: any) => void;
  saveOption: (data: any) => void;
};

type detailsProps = {
  comms?: any;
  details?: any;
  EmailTemplates?: any;
  RepairTemplate?: any;
  fetchLatest: () => void;
  handleContinueSave: (path: string) => void;
};

type ActionListProps = {
  actions: any;
  comms: any;
  fetchLatest: (data: any) => void;
  saveOption: (data: any) => void;
};

const Details: React.FunctionComponent<detailsProps> = (props): JSX.Element => {
  const [campaignName, setcampaignName] = useState('');
  const [campaigndescription, setcampaigndescription] = useState('');
  const [messageType, setmessageType] = useState('Conversion');
  const [email, setemail] = useState(localStorage.getItem('commsTestEmail'));
  const [timeOftheday, settimeOftheday] = useState<any>('00:00');
  const [dayofTheWeek, setdayofTheWeek] = useState('Monday');
  const [EmailCommsLabels, setEmailCommsLabels] = useState(['No Label']);
  const [selectedEmailTemplate, setselectedEmailTemplate] = useState('No Label');
  const [Messages, setMessages] = useState<any>(new Array(53).fill({ sms_media: undefined }));
  const [error, setError] = useState(false);
  const [enableRepairTemplate, setenableRepairTemplate] = useState(false);
  const [WeekOftheYear, setWeekOftheYear] = useState<any>();
  const [weeklyTextPopUp, settestweeklyPopUp] = useState(false);
  const [, setweeks] = useState<any>([]);
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const [Weeks] = React.useState([
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ]);

  const [actionsList, setactionsList] = useState<any>([
    {
      system_message_id: props.comms?.length > 0 ? props.comms[0].id : null,
      type: 'send_message',
    },
  ]);

  useEffect(() => {
    if (props.EmailTemplates) {
      const results: any = [];
      props.EmailTemplates?.map((item: any) => {
        return item.labels.length > 1
          ? item.labels.forEach((item: string) => {
              results.push(item);
            })
          : item.labels.length > 0 && results.push(item.labels[0]);
      });
      const setvalue: any = new Set(results);
      const uniquieLabelsresults = ['No Label', ...setvalue];
      setEmailCommsLabels(uniquieLabelsresults);
    }
  }, [props.EmailTemplates]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWeeks = () => {
    // output is a list of week numbers, one week behind current week + 6 weeks into the future
    let items = [];
    let start_week = Number(moment().format('W'));

    // if the current week is the first week of the year, add the last week of last year to the list
    if (start_week === 1) {
      let last_year: any = moment().startOf('year');
      while (last_year.format('W') === '1') {
        last_year.subtract(1, 'day');
      }
      last_year = last_year.format('W');
      items.push({
        text: 'Week ' + String(last_year).padStart(2, '0'),
        value: Number(last_year),
      });
    }

    // if the last week of the year is > 6 weeks from this week, set the last week to current week + 6
    let last_week: any = moment().set('month', 11).endOf('month');
    while (last_week.format('W') === '1') {
      last_week.subtract(1, 'day');
    }
    last_week = Number(last_week.format('W'));
    last_week = Math.min(start_week + 6, last_week);

    // this adds previous week if not in the first week of the year
    if (start_week > 1) {
      start_week = start_week - 1;
    }

    while (start_week <= last_week) {
      items.push({
        text: 'Week ' + String(start_week).padStart(2, '0'),
        value: start_week,
      });
      start_week++;
    }

    // if we're in the last weeks of the previous year, populate weeks 01 + n to fill the list out
    if (items.length <= 7) {
      var new_year = 1,
        num_items = items.length;
      while (num_items <= 7) {
        items.push({
          text: 'Week ' + String(new_year).padStart(2, '0'),
          value: new_year,
        });
        new_year++;
        num_items++;
      }
    }

    return items;
  };

  useEffect(() => {
    if (props.details && props.comms) {
      const details = props.details;

      setcampaignName(details.name);
      setcampaigndescription(details.description);
      setmessageType(details.type);

      if (details.type === 'weekly') {
        setdayofTheWeek(details?.script.day_of_week);
        settimeOftheday(details?.script.time_of_day);
        setselectedEmailTemplate(details.script.template_label);
      } else if (details.type === 'weekly_text') {
        setdayofTheWeek(details?.script?.day_of_week);
        settimeOftheday(details?.script.time_of_day);
        setMessages(details?.script?.messages);
      } else {
        setactionsList(details.script);
      }
    } else {
      setactionsList([
        {
          system_message_id: props.comms?.length > 0 ? props.comms[0].id : null,
          type: 'send_message',
        },
      ]);
      setcampaignName('');
      setcampaigndescription('');
      setmessageType('conversion');
    }
    setenableSaveOption(false);
  }, [props.details, props.comms]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.RepairTemplate?.status === 200) {
      setenableRepairTemplate(true);
    }
  }, [props.RepairTemplate]); //eslint-disable-line react-hooks/exhaustive-deps

  const deleteRepairTemplateData = () => {
    dispatch(postRepairTemplateSuccess({ LISTENERS: null }));
  };

  function handleMessages(value: any, idx: number, type: string) {
    const newMessages = Messages.map((item: any, index: number) =>
      index === idx ? { ...item, [type]: value, sms_media_error: null } : { ...item, sms_media_error: null },
    );
    setMessages(newMessages);
  }

  const handleDelete = () => {
    dispatch(removeCampaignsRequest({ campaignsId: props.details.id, name: props.details.name }));
    setIsDelete(false);
  };

  const validate = () => {
    if (
      !campaignName ||
      !campaignName.trim().length ||
      (campaigndescription?.length && !campaigndescription.trim().length)
    ) {
      setError(true);
      return true;
    }

    setError(false);
    return false;
  };

  useEffect(() => {
    if (messageType === 'weekly') {
      const weeks = getWeeks();
      setweeks(weeks);
      setWeekOftheYear(weeks[1].value);
    } else if (messageType === 'weekly') {
    }
  }, [messageType]);

  const convertTimetoSeconds = (time: any, type: any) => {
    const seconds = Number(time);
    const hours = Math.floor(seconds * 3600);
    const minutes = Math.floor(seconds * 60);
    const days = Math.floor(seconds * (3600 * 24));

    if (type === 'days') {
      return days;
    } else if (type === 'hours') {
      return hours;
    } else {
      return minutes;
    }
  };

  const convertSecondsToMinutes = (time: any) => {
    const seconds = Number(time);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60);
    const days = Math.floor(seconds / (3600 * 24));

    if (days > 0) {
      return { type: 'days', time: days };
    } else if (hours > 0) {
      return { type: 'hours', time: hours };
    } else {
      return { type: 'minutes', time: minutes };
    }
  };

  const convertArrayTimeToSeconds = (data: any) => {
    const result = data.map((item: any) => {
      if (item.type === 'pause_for') {
        return {
          pause_for_seconds: convertTimetoSeconds(item.pause_for_seconds.time, item.pause_for_seconds.type),
          type: 'pause_for',
        };
      }
      return item;
    });
    return result;
  };

  const convertArraySecondsToMinutes = (data: []) => {
    const result = data.map((item: any) => {
      if (item.type === 'pause_for') {
        return {
          pause_for_seconds: convertSecondsToMinutes(item.pause_for_seconds),
          type: 'pause_for',
        };
      }
      return item;
    });
    return result;
  };

  const handleSave = () => {
    let payload = {};
    if (messageType === 'conversion' || messageType === 'welcome' || messageType === 'post_call') {
      payload = {
        name: campaignName.trim(),
        description: campaigndescription.trim(),
        type: messageType,
        script: convertArrayTimeToSeconds(actionsList),
      };
    } else if (messageType === 'weekly_text') {
      payload = {
        name: campaignName.trim(),
        description: campaigndescription.trim(),
        type: messageType,
        script: {
          messages: Messages.map((item: any) => {
            if (item?.message) return { ...item, message: item?.message?.trim() };
            else return item;
          }),
          day_of_week: dayofTheWeek,
          time_of_day: timeOftheday,
        },
      };
    } else {
      payload = {
        name: campaignName.trim(),
        description: campaigndescription.trim(),
        type: messageType,
        script: {
          template_label: selectedEmailTemplate,
          day_of_week: dayofTheWeek,
          time_of_day: timeOftheday,
        },
      };
    }
    if (!validate()) {
      if (props.details?.id) {
        dispatch(updateCampaignsRequest({ campaignsId: props.details?.id, payload }));
      } else {
        dispatch(postCampaignsRequest(payload));
      }
    } else {
      props.handleContinueSave('');
    }
  };

  const handleTestCampaign = () => {
    const re = /\S+@\S+\.\S+/;
    if (email?.length && re.test(email)) {
      localStorage.setItem('commsTestEmail', email ? email : '');
      let input = {};
      if (messageType === 'weekly_text' || messageType === 'weekly') {
        const weekss = getWeeks();
        input = {
          email_address: email,
          week_of_year: WeekOftheYear ? WeekOftheYear : weekss[1].value,
        };
      } else {
        input = {
          email_address: email,
        };
      }
      dispatch(
        postTestCampaignsRequest({
          campaignsId: props.details.id,
          payload: input,
        }),
      );
      settestweeklyPopUp(false);
    } else {
      if (!email) {
        toast.error('Please enter mail address');
      } else if (!re.test(email)) {
        toast.error('Please enter a valid mail address');
      }
    }
  };

  const handleRepairtemplate = () => {
    dispatch(postRepairTemplateRequest({ label: selectedEmailTemplate }));
  };

  return (
    <>
      <RouteLeavingGuard
        when={enableSaveOption}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: Location) => {
          if (location.pathname !== ROUTE_PATH.TEMPLATES_CAMPAIGNS) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          handleSave();
          props.handleContinueSave(path);
          setenableSaveOption(false);
        }}
      />
      <div className="w-3/4 ">
        <div className="flex justify-end items-center   gray-background-dark  px-10 pt-5">
          {props.details?.id && (
            <button
              className="bg-gray-background-dark rounded-full text-center text-sm text-white py-1 px-5 mr-5"
              onClick={() => setIsDelete(true)}
            >
              REMOVE
            </button>
          )}
          <button
            className={
              'rounded-full text-center text-sm text-white px-5 py-1 ' +
              (enableSaveOption ? ' bg-blue-primary ' : ' bg-gray-background-dark cursor-not-allowed ')
            }
            onClick={handleSave}
            disabled={!enableSaveOption}
          >
            SAVE
          </button>
        </div>
        <div className="w-full  my-5">
          <CustomInput
            inputType={'text'}
            value={campaignName}
            setValue={(data) => {
              setenableSaveOption(true);
              setcampaignName(data);
            }}
            Label={'Campaign Name'}
            Error={error && (!campaignName || !campaignName.trim().length)}
            ErrorMessage={'Field is Required'}
          />
        </div>
        <div className="w-full  my-5">
          <CustomInput
            inputType={'text'}
            value={campaigndescription}
            setValue={(data) => {
              setenableSaveOption(true);
              setcampaigndescription(data);
            }}
            Label={'Campaign Description'}
            Error={error && (!campaigndescription || !campaigndescription.trim().length)}
            ErrorMessage={'Field is Required'}
          />
        </div>
        <div className="flex justify-between  pb-5 border-b gray-border-line">
          <div className=" w-1/2 pr-4">
            <DropDown
              Label={'Message Type'}
              value={messageType}
              setValue={(data) => {
                setenableSaveOption(true);
                setmessageType(data);
              }}
            >
              <option value={'conversion'}>Conversion</option>
              <option value={'welcome'}>Welcome</option>
              <option value={'post_call'}>Post-Call</option>
              <option value={'weekly'}>Weekly Email</option>
              <option value={'weekly_text'}>Weekly Text</option>
            </DropDown>
          </div>
        </div>
        {messageType === 'conversion' || messageType === 'welcome' || messageType === 'post_call' ? (
          <ActionList
            actions={convertArraySecondsToMinutes(actionsList)}
            comms={props.comms}
            fetchLatest={setactionsList}
            key={props.details}
            saveOption={setenableSaveOption}
          />
        ) : messageType === 'weekly' ? (
          <>
            <div className="py-2">
              <p className="text-gray-dark text-sm font-extrabold py-2">Campaign Script:</p>
              <p className="py-2">
                The weekly email system will send the current week's email using the templates contained within the
                template label selected below.
              </p>
              <p className="py-2">
                It will choose the correct template based on the last two characters of the template name corresponding
                to the week of the year. E.g., a template named 'M-TCP-03' will be sent the third week of the year and
                'M-TA-50' will be sent on the 50th week of the year. All dates and times will be relative to the user's
                selected timezone.
              </p>
              <div className="flex justify-between w-full">
                <div className="w-1/2 flex">
                  <div className=" w-3/4 pr-4">
                    <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Email Template</label>
                    <div className="relative inline-flex w-full mb-2">
                      <svg
                        className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 412 232"
                      >
                        <path
                          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                          fill="#648299"
                          fill-rule="nonzero"
                        />
                      </svg>
                      <select
                        className=" py-2 px-3 w-full h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                        onChange={(e: any) => {
                          setselectedEmailTemplate(e.target.value);
                          setenableSaveOption(true);
                        }}
                        value={selectedEmailTemplate}
                      >
                        {EmailCommsLabels.length > 0 &&
                          EmailCommsLabels.map((item) => <option value={item}>{item}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/4 h-full flex items-end py-2">
                    {selectedEmailTemplate && selectedEmailTemplate !== 'No Template' && (
                      <button
                        className="bg-blue-primary rounded-full text-center text-sm text-white px-5 py-1"
                        onClick={handleRepairtemplate}
                      >
                        REPAIR
                      </button>
                    )}
                  </div>
                </div>
                <div className="w-1/2 flex">
                  <div className="w-full">
                    <div className=" w-full">
                      <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Day of Week</label>
                      <div className="relative inline-flex w-full mb-2">
                        <svg
                          className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 412 232"
                        >
                          <path
                            d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                            fill="#648299"
                            fill-rule="nonzero"
                          />
                        </svg>
                        <select
                          className="w-full py-2 px-3  h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                          value={dayofTheWeek}
                          onChange={(e) => {
                            setdayofTheWeek(e.target.value);
                          }}
                        >
                          {Weeks.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="w-full  pl-4">
                    <CustomInput
                      inputType={'time'}
                      value={timeOftheday}
                      setValue={(data) => {
                        setenableSaveOption(true);
                        settimeOftheday(data);
                      }}
                      Label={'Time of the day'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          messageType === 'weekly_text' && (
            <>
              <div className="w-full flex py-2">
                <div className="w-full">
                  <div className=" w-full">
                    <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Day of Week</label>
                    <div className="relative inline-flex w-full mb-2">
                      <svg
                        className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 412 232"
                      >
                        <path
                          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                          fill="#648299"
                          fill-rule="nonzero"
                        />
                      </svg>
                      <select
                        className=" py-2 px-3 w-full h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                        value={dayofTheWeek}
                        onChange={(e) => {
                          setenableSaveOption(true);
                          setdayofTheWeek(e.target.value);
                        }}
                      >
                        {Weeks.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-full  pl-4">
                  <CustomInput
                    inputType={'time'}
                    value={timeOftheday}
                    setValue={(data) => {
                      setenableSaveOption(true);
                      settimeOftheday(data);
                    }}
                    Label={'Time of the day'}
                  />
                </div>
              </div>
              <div className="pb-10">
                {Messages?.length > 0 &&
                  Messages.map((item: any, index: number) => (
                    <>
                      <div className=" flex ">
                        <div className=" py-2 w-10/12">
                          <CustomInput
                            inputType={'text'}
                            value={item.message}
                            setValue={(value) => {
                              handleMessages(value, index, 'message');
                              setenableSaveOption(true);
                            }}
                            Label={' Week ' + (index + 1).toString()}
                          />
                        </div>
                        <div className=" flex justify-end items-end mb-2">
                          <button
                            className={
                              ' rounded-full text-center text-sm text-white px-5 py-1 ml-5  ' +
                              (props.details?.script?.messages?.length > 0 &&
                              props.details?.script?.messages[index]?.message?.length > 0
                                ? ' bg-blue-primary '
                                : ' bg-gray-light ')
                            }
                            onClick={() => {
                              if (
                                props.details?.script?.messages?.length > 0 &&
                                props.details?.script?.messages?.length > 0 &&
                                props.details?.script?.messages[index].message?.length > 0
                              ) {
                                settestweeklyPopUp(true);
                                setWeekOftheYear(index + 1);
                              }
                            }}
                          >
                            {' '}
                            TEST
                          </button>
                        </div>
                      </div>
                      <div className="py-1">
                        <MediaInput
                          fileSrc={item.sms_media}
                          setFileSrc={(data) => {
                            handleMessages(data, index, 'sms_media');
                          }}
                          enableSaveOption={setenableSaveOption}
                        />
                        <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                          {' '}
                          {item?.sms_media_error}
                        </label>
                      </div>
                    </>
                  ))}
                <Modal
                  isModel={weeklyTextPopUp}
                  InnerComponent={
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl modal-border-shadow  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="w-full">
                          <CustomInput inputType={'text'} value={email} setValue={setemail} Label={'Email Address'} />
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          onClick={handleTestCampaign}
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          TEST MESSAGE
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setWeekOftheYear(0);
                            settestweeklyPopUp(false);
                          }}
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  }
                />
              </div>
            </>
          )
        )}
        {props.details && messageType !== 'weekly_text' && (
          <>
            <div className={'text-gray-dark text-sm font-extrabold pt-5'}>Test Campaign</div>
            <div className="flex justify-between my-5">
              <div className={'pr-4' + (messageType === 'weekly' ? ' w-1/2 ' : ' w-full  ')}>
                <CustomInput
                  inputType={'text'}
                  value={email}
                  setValue={(data) => {
                    setenableSaveOption(true);
                    setemail(data);
                  }}
                  Label={'Email Address'}
                />
              </div>
              {messageType === 'weekly' && (
                <div className={'pr-4' + (messageType === 'weekly' ? ' w-1/2 ' : ' w-full  ')}>
                  <DropDown
                    Label={'Choose Week'}
                    setValue={(data) => {
                      setenableSaveOption(true);
                      setWeekOftheYear(data);
                    }}
                    value={WeekOftheYear}
                  >
                    {getWeeks().map((item) => (
                      <option value={item.value}>{item.text} </option>
                    ))}
                  </DropDown>
                </div>
              )}
            </div>
            <button
              className={
                'h-8 px-5 py-2 my-5 mx-1  text-white text-center flex items-center   font-normal rounded-full' +
                (email && email?.trim()?.length > 0 ? ' bg-blue-primary  ' : ' bg-gray-light ')
              }
              onClick={handleTestCampaign}
            >
              TEST CAMPAIGN{' '}
            </button>
          </>
        )}
      </div>
      {enableRepairTemplate && (
        <Modal
          isModel={enableRepairTemplate}
          InnerComponent={
            <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-5xl min-w-3xl max-h-162.5 overflow-y-auto modal-border-shadow rounded-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Email Template Report</div>
                  </div>
                  <div className="relative px-10 pt-2 flex-auto">
                    <p className=" leading-relaxed h-full text-gray-dark text-base font-normal">Report Summary:</p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Total Records:{' '}
                      {props.RepairTemplate?.data?.processed.length + props.RepairTemplate?.data?.errors.length}
                    </p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Processed Templates : {props.RepairTemplate?.data?.processed.length}
                    </p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Errored Templates: {props.RepairTemplate?.data?.errors.length}
                    </p>
                  </div>

                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Process Details:</div>
                  </div>
                  <table className="table-fixed table-row-format relative border-collapse mx-10 my-2">
                    <thead className="bg-white  h-full ">
                      <tr className=" text-left  w-full px-7">
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Template name
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.RepairTemplate?.data?.processed.length > 0 ? (
                        props.RepairTemplate?.data?.processed.map((item: any) => (
                          <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.name}</p>
                              </div>
                            </td>
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.message}</p>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                          <td className="py-1 w-1/2 border-bottom">
                            <div className="flex">
                              <p className="text-gray-dark text-bold font-bold pr-2 text-base">No Data Available</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Error Details:</div>
                  </div>
                  <table className="table-fixed table-row-format relative border-collapse mx-10 my-2">
                    <thead className="bg-white  h-full ">
                      <tr className=" text-left  w-full px-7">
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Template name
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.RepairTemplate?.data?.errors.length > 0 ? (
                        props.RepairTemplate?.data?.errors.map((item: any) => (
                          <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                            <td className="py-1 w-2/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.name}</p>
                              </div>
                            </td>
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.message}</p>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                          <td className="py-1 w-1/2 border-bottom">
                            <div className="flex">
                              <p className="text-gray-dark text-bold font-bold pr-2 text-base">No Data Available</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex items-center justify-center pb-6">
                    <button
                      className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                      type="button"
                      onClick={() => {
                        setenableRepairTemplate(false);
                        deleteRepairTemplateData();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
      <Modal
        isModel={isDelete}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Alert
                  </h3>
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500 flex items-center">
                      Are you sure you want to remove this {campaignName}?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleDelete}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Remove
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      ></Modal>
    </>
  );
};

const ActionList: React.FunctionComponent<ActionListProps> = (props): JSX.Element => {
  const [ActionList, setActionList] = useState(props.actions);
  const [dragId, setDragId] = useState();

  const deleteActionList = (idx: number) => {
    const newActionList = ActionList.length > 0 && ActionList.filter((item: any, index: number) => idx !== index);
    setActionList([...newActionList]);
    props.fetchLatest([...newActionList]);
  };

  const updateActionsList = (obj: any, seletedindex: number) => {
    const newActionList =
      ActionList.length > 0 &&
      ActionList.map((item: any, index: number) => {
        if (index === seletedindex) {
          return obj;
        } else {
          return item;
        }
      });

    setActionList([...newActionList]);
    props.fetchLatest([...newActionList]);
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    const dragBoxOrder = ActionList.findIndex((box: any, index: number) => index === Number(dragId));
    const dropBoxOrder = ActionList.findIndex((box: any, index: number) => index === Number(ev.currentTarget.id));

    const newBoxState = ActionList.map((box: any, index: number) => {
      if (index === Number(dragId)) {
        return ActionList[dropBoxOrder];
      }
      if (index === Number(ev.currentTarget.id)) {
        return ActionList[dragBoxOrder];
      }
      return box;
    });
    setActionList(newBoxState);
  };

  const addItem = (seletedindex: number) => {
    const newActionList = [...ActionList];
    newActionList.splice(seletedindex + 1, 0, {
      system_message_id: props.comms.length > 0 ? props.comms[0].id : null,
      type: 'send_message',
    });
    setActionList([...newActionList]);
    props.fetchLatest([...newActionList]);
  };

  return (
    <>
      {ActionList.length > 0 &&
        ActionList.map((item: any, index: number) => (
          <Actions
            comms={props.comms}
            index={index}
            updateActionsList={updateActionsList}
            details={item}
            key={JSON.stringify(item) + index.toString()}
            delete={deleteActionList}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            addItem={addItem}
            saveOption={props.saveOption}
          />
        ))}

      <div className="border-b gray-border-line">
        <button
          className="h-8 px-5 py-2 my-5 mx-1  text-white text-center flex items-center  bg-blue-primary font-normal rounded-full"
          onClick={() => {
            setActionList((prev: any) => {
              const input = {
                system_message_id: props.comms.length > 0 ? props.comms[0].id : null,
                type: 'send_message',
              };
              if (prev.length > 0) {
                return [...prev, input];
              } else {
                return [input];
              }
            });
            props.saveOption(true);
          }}
        >
          + ADD{' '}
        </button>
      </div>
    </>
  );
};

const Actions: React.FunctionComponent<ActionProps> = (props): JSX.Element => {
  const [actionType, setactionType] = useState<any>(props.details?.type);
  const [commMessage, setcommMessage] = useState<any>(props.details?.system_message_id);
  const [WaitUntilTime, setWaitUntilTime] = useState<any>(props.details?.wait_until_time);
  const [timeType, settimeType] = useState(props.details?.pause_for_seconds?.type);
  const [pauseTime, setpauseTime] = useState<any>(props.details?.pause_for_seconds?.time);
  const [savepauseTime, setsavepauseTime] = useState<any>(props.details?.pause_for_seconds?.time);
  const ref = React.useRef(null);
  const updateActionList = () => {
    let input = {};
    if (actionType === 'send_message') {
      input = {
        system_message_id: commMessage,
        type: 'send_message',
      };
    } else if (actionType === 'pause_for') {
      input = {
        pause_for_seconds: {
          time: pauseTime,
          type: timeType,
        },
        type: 'pause_for',
      };
    } else if (actionType === 'wait_until') {
      input = {
        wait_until_time: WaitUntilTime,
        type: 'wait_until',
      };
    }

    props.updateActionsList(input, props.index);
  };

  useEffect(() => {
    if (props.details && props.comms) {
      updateActionList();
    }
  }, [commMessage, pauseTime, WaitUntilTime, actionType, timeType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={ref}
      onDragStart={(ev) => {
        //@ts-ignore
        const child = ref.current;
        //@ts-ignore
        ev.dataTransfer.setDragImage(child, ev.clientX + 160, 60);
        props.handleDrag(ev);
      }}
      onDrop={props.handleDrop}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
      id={props.index}
      className="w-full  flex relative  py-5 border-b gray-border-line mr-7"
    >
      <div className="flex-col justify-between items-center w-2/6 mr-2">
        <DropDown
          Label={'Action'}
          value={actionType}
          setValue={(data) => {
            setactionType(data);
            props.saveOption(true);
          }}
        >
          <option value={'send_message'}>Send Message</option>
          <option value={'pause_for'}>Pause</option>
          <option value={'wait_until'}>Wait until</option>
        </DropDown>
      </div>
      <div className="w-1/2 ">
        {actionType === 'send_message' ? (
          <>
            <DropDown
              Label={'Comm Message'}
              value={commMessage}
              setValue={(data) => {
                setcommMessage(data);
                props.saveOption(true);
              }}
            >
              {' '}
              {props.comms?.length > 0 && props.comms.map((item: any) => <option value={item.id}>{item.name}</option>)}
            </DropDown>
          </>
        ) : actionType === 'pause_for' ? (
          <div className="flex">
            <div className="w-1/2 mr-5">
              <div>
                <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">{'Duration'}</label>

                <input
                  type={'text'}
                  onWheel={(e: any) => e.target.blur()}
                  value={savepauseTime}
                  className="py-2 px-3 w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setsavepauseTime(inputValue.replace(/\D/g, ''));
                    props.saveOption(true);
                  }}
                  onBlur={(e) => {
                    const inputValue = e.target.value;
                    setpauseTime(inputValue.replace(/\D/g, ''));
                  }}
                />
              </div>
            </div>
            <div className="w-1/2">
              <DropDown
                Label={'Units'}
                value={timeType}
                setValue={(data) => {
                  settimeType(data);
                  props.saveOption(true);
                }}
              >
                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </DropDown>
            </div>
          </div>
        ) : (
          actionType === 'wait_until' && (
            <>
              <div className="w-full  pl-2 ">
                <CustomInput
                  inputType={'time'}
                  value={WaitUntilTime}
                  setValue={(data) => {
                    setWaitUntilTime(data);
                    props.saveOption(true);
                  }}
                  Label={'Time of the day'}
                  key={props.details?.id || Math.random()}
                />
              </div>
            </>
          )
        )}
      </div>
      <div className="flex items-center   w-1/6">
        <div className="mt-2 flex items-center">
          <button
            type="button"
            className="ml-2  text-2xl bg-gray-200 rounded-full w-8 h-8 "
            onClick={() => {
              props.addItem(props.index);
              props.saveOption(true);
            }}
          >
            +
          </button>
          <button
            type="button"
            className="ml-2  text-xl bg-red font-medium rounded-full text-white   w-8 h-8 "
            onClick={() => {
              props.delete(props.index);
              props.saveOption(true);
            }}
          >
            {/* <img src={XCricle} alt="x-circle" className="-mb-1.5" /> */}
            <p className="-mt-1">x</p>
          </button>
          <button
            className={'ml-2  text-xl  font-medium  text-white   w-8 h-8 cursor-dragging'}
            draggable={true}
            id={props.index}
          >
            <div className="grid grid-cols-2  gap-y-1">
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
              <div className="bg-gray-600 w-1.5 h-1.5 rounded-full"></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

const Campaigns: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedCampaignsData, setselectedCampaignsData] = useState<any>();
  const [search, setsearch] = useState('');
  const [saveLocationPath, setSaveLocationPath] = useState<string>('');
  const history = useHistory();

  const fetchLatestCamapignsLists = () => {
    dispatch(fetchCampaignsListRequest({}));
  };

  useEffect(() => {
    if (
      props.RemoveCampaigns?.RemovePayment?.status === 200 ||
      props.UpdateCampaigns?.status === 200 ||
      props.CreateCampaigns?.status === 200
    ) {
      if (props.RemoveCampaigns?.RemovePayment?.status === 200) {
        setTimeout(() => {
          setselectedCampaignsData(undefined);
        }, 1000);
        dispatch(removeCampaignsFailure({ error: '' }));
      }
      if (props.CreateCampaigns?.status === 200) {
        setselectedCampaignsData(props.CreateCampaigns.data);
        dispatch(postCampaignsFailure({ error: '' }));
        saveLocationPath !== '' && history.push(saveLocationPath);
      }
      if (props.UpdateCampaigns?.status === 200) {
        setselectedCampaignsData(props.UpdateCampaigns.data);
        dispatch(updateCampaignsFailure({ error: '' }));
        saveLocationPath !== '' && history.push(saveLocationPath);
      }
      fetchLatestCamapignsLists();
    }
  }, [props.RemoveCampaigns, props.UpdateCampaigns, props.CreateCampaigns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchCampaignsListRequest({ search_term: search }));
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchCampaignsListRequest({}));
    dispatch(fetchCommsListRequest({}));
    dispatch(fetchEmailTemplateRequest({}));
    return () => {
      // setselectedCampaignsData(null);
      dispatch(fetchCampaignsListSuccess({ CampaignsList: null }));
      dispatch(fetchCommsListSuccess({ CommsList: null }));
      dispatch(fetchEmailTemplateSuccess({ experiencesFavorites: [] }));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.campaigns) {
      const selectedId = selectedCampaignsData?.id || null;
      if (selectedId) {
        const selectedCapiagn = props.campaigns.find((item: any) => item.id === selectedId);
        setselectedCampaignsData(selectedCapiagn ? selectedCapiagn : null);
      }
    }
  }, [props.campaigns]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={'Templates'} />

      <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
        <div className="flex justify-center items-center   gray-background-dark h-full">
          <button
            className={' text-sm  px-5 py-2   '}
            onClick={() => {
              history.push(ROUTE_PATH.TEMPLATES_COMMS);
            }}
          >
            Comms
          </button>
          <button
            className={' text-sm  px-5 py-2 text-white font-bold bg-blue-primary h-full'}
            onClick={() => {
              history.push(ROUTE_PATH.TEMPLATES_CAMPAIGNS);
            }}
          >
            Campaigns
          </button>
          <button
            className={' text-sm  px-5 py-2 '}
            onClick={() => {
              history.push(`${ROUTE_PATH.TEMPLATES_COMMS}?comms-type=system-comms`);
            }}
          >
            System Comms
          </button>
        </div>
        <div className="flex justify-center items-center   gray-background-dark h-full">
          {selectedCampaignsData && (
            <button
              className="bg-blue-primary rounded-full text-center text-sm text-white px-5 py-1 mr-5"
              onClick={() => {
                setselectedCampaignsData(null);
              }}
            >
              <span>+NEW</span>
            </button>
          )}

          <div className="w-full h-full py-1">
            <Searchbar search={setsearch} />
          </div>
        </div>
      </div>

      <div className="max-window-height overflow-y-auto px-7 flex">
        <div className="flex flex-col w-1/4 gray-background-dark h-full mr-10 mt-10">
          {props.campaigns?.length > 0 &&
            props.campaigns.map((item: any) => (
              <button
                key={item.id}
                className={
                  ' text-sm  px-5 py-3 my-1 rounded-md ' +
                  (selectedCampaignsData?.id === item.id
                    ? ' bg-blue-primary text-white font-bold'
                    : ' bg-gray-background-light ')
                }
                onClick={() => {
                  setselectedCampaignsData({ ...item });
                }}
              >
                {item.name}
              </button>
            ))}
        </div>
        <Details
          details={selectedCampaignsData}
          comms={props.comms}
          EmailTemplates={props.EmailTemplates}
          key={selectedCampaignsData?.id || null}
          fetchLatest={fetchLatestCamapignsLists}
          RepairTemplate={props.RepairTemplate}
          handleContinueSave={setSaveLocationPath}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    campaigns: state.Comms.CampaignsList?.data || null,
    comms: state.Comms.CommsList?.data || null,
    EmailTemplates: state.Client.emailTemplate?.data || null,
    SystemMessageGroups: state.Comms.SystemMessageGroups?.data || null,
    RemoveCampaigns: state.Comms.RemoveCampaigns || null,
    UpdateCampaigns: state.Comms.UpdateCampaigns || null,
    CreateCampaigns: state.Comms.CreateCampaigns || null,
    pending: state.Comms.pending || state.Client.pending || null,
    RepairTemplate: state.Comms.RepairTemplate || null,
  };
};

export default connect(mapStateToProps)(Campaigns);
