import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface Tier {
  call_bonus: number;
  call_earnings: number;
  experience_bonus: number;
  experience_listen_rate: number;
  id: number;
  is_default: boolean;
  max_daily_office_hours: number;
  max_experience_payouts: number;
  minimum_office_hours: number;
  name: string;
  office_hour_rate: number;
  paid_office_hours_cap: number;
  pay_office_hours: boolean;
  sort_weight: number;
  status: string;
}

const getTiers = async () => {
  return await axiosGet('/tiers/', {}, 'v2').then((tiersResponse) => tiersResponse.data);
};

export const useTiers = () => {
  const { data, isLoading, error } = useQuery<Tier[]>(['tiers'], () => getTiers());
  return {
    data,
    isLoading,
    error,
  };
};
