import React, { createContext, useEffect, useState, useRef } from 'react';
import Keycloak from 'keycloak-js';
import { SwitchboardFeatures, useFeatureFlag } from '../hooks/useFeatureFlags';
import { UserRole } from '../containers/navigation-bar/roles';
import { toast } from 'react-toastify';
import urls from '../api/urls';
import { axiosPost } from '../api/axios';
import { DESTROY_SESSION } from '../components/account-login/login-redux/actionTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStickyState from '../hooks/useStickyState';
import { ROUTE_PATH } from '../routes/paths';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  setAuthToken: React.Dispatch<string | null>;
  setRefreshToken: React.Dispatch<string | null>;
  roleType: string | null;
  setRoleType: React.Dispatch<string | null>;
  loginClassic: (emailAddress: string, password: string, trusted: boolean) => Promise<any>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const keycloakFeatureFlag = useFeatureFlag(SwitchboardFeatures.SB_KEYCLOAK_LOGIN);
  const [authToken, setAuthToken] = useStickyState('authorization_token', null);
  const [refreshToken, setRefreshToken] = useStickyState('refresh_token', null);

  // TODO pull role_type out of users/me and don't pass it around in localStorage
  // we need it here for now b/c alpine has used this localstorage slot for various workflows that aren't directly tied to user role
  const [roleType, setRoleType] = useStickyState('role_type', null);
  const dispatch = useDispatch();
  const history = useHistory();

  // "Classic" login means non-keycloak legacy auth
  const loginClassic = async (emailAddress: string, password: string, trusted: boolean) => {
    const http = {
      post: axiosPost,
    };
    const data = {
      email_address: emailAddress,
      password,
      trusted,
    };

    return http
      .post(urls.login, data)
      .then((results: any) => {
        onUserAuthenticated(results.data.authorization_token, results.data.access_role);
        return results;
      })
      .catch((error: any) => {
        if (error.response) {
          const { response } = error;
          toast.error(response.data.description);
        }
      });
  };

  const onUserAuthenticated = (authToken: string | null, roleType: string | null) => {
    setAuthToken(authToken);
    setRoleType(roleType);
    if (roleType === UserRole.admin && authToken) {
      history.push(ROUTE_PATH.DASHBOARD);
    } else if (roleType === UserRole.client_admin && authToken) {
      history.push(ROUTE_PATH.SNAPSHOT);
    } else if (roleType === 'invited' && authToken) {
      history.push(ROUTE_PATH.ONBOARDING);
    } else if ((!roleType || roleType === 'null') && authToken) {
      history.push('/notify-message');
    }
  };

  const logout = () => {
    if (keycloakFeatureFlag.enabled && keycloak) {
      keycloak.logout();
    } else {
      dispatch({ type: DESTROY_SESSION });
    }
    toast.success('User Logged Out Successfully');
    setAuthToken(null);
    setRefreshToken(null);
    setRoleType(null);
    localStorage.clear();
    history.push('/');
  };

  useEffect(() => {
    if (isFirstRun.current) return;

    isFirstRun.current = true;
    const initKeycloak = async () => {
      const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

      keycloakInstance
        .init({
          onLoad: 'check-sso',
        })
        .then((authenticated: boolean) => {
          if (keycloakInstance.token) {
            const isAuthenticating = authToken === null;

            let roleType: string | null = null;
            if (keycloakInstance.hasRealmRole(UserRole.admin)) {
              roleType = UserRole.admin;
            } else if (keycloakInstance.hasRealmRole(UserRole.client_admin)) {
              roleType = UserRole.client_admin;
            } else {
              roleType = null;
            }
            isAuthenticating && onUserAuthenticated(keycloakInstance.token, roleType);
          }
        })
        .catch((error) => {
          console.error('Keycloak initialization failed:', error);
        })
        .finally(() => {
          setKeycloak(keycloakInstance);
        });
    };

    initKeycloak();
  }, []);

  return (
    <AuthContext.Provider
      value={{ keycloak, authToken, setAuthToken, setRefreshToken, roleType, setRoleType, loginClassic, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
