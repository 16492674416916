import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { toast } from 'react-toastify';

const getEligibilityFile = async (clientId: number, datasetId: number): Promise<{ blob: Blob; fileName: string }> => {
  const response = await axiosGet(`/clients/${clientId}/eligibility_datasets/${datasetId}/download`, {}, 'v3');
  const contentDisposition = response.headers['content-disposition'];
  const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'unknown.csv';
  return { blob: response.data, fileName: fileName };
};

export const useEligibilityFile = (clientId: number, datasetId: number, enabled: boolean) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<{ blob: Blob; fileName: string }>(
    ['eligibilityFile', clientId, datasetId],
    () => getEligibilityFile(clientId, datasetId),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success(`File ${data.fileName} downloaded successfully.`);
      },
      onError: (error: any) => {
        let errorMessage = 'Error downloading file';
        if (error?.response?.data?.error) {
          errorMessage = error.response.data.error;
        }
        toast.error(errorMessage);
      },
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useCancelEligibilityFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ clientId, datasetId }: { clientId: number; datasetId: number }) =>
      axiosGet(`/clients/${clientId}/eligibility_datasets/${datasetId}/cancel`, {}, 'v3'),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['eligibilityFile']);
      },
      onError: () => {
        queryClient.invalidateQueries(['eligibilityFile']);
      },
    },
  );
};
