import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_BY_ID_REQUEST,
  FETCH_USER_DETAILS_BY_ID_SUCCESS,
  FETCH_USER_DETAILS_BY_ID_FAILURE,
  POST_REQUEST_PASSWORD_REQUEST,
  POST_REQUEST_PASSWORD_SUCCESS,
  POST_REQUEST_PASSWORD_FAILURE,
  POST_REDEEM_PASSWORD_RESET_REQUEST,
  POST_REDEEM_PASSWORD_RESET_SUCCESS,
  POST_REDEEM_PASSWORD_RESET_FAILURE,
  FETCH_SAVE_DEEP_LINK_DATA_REQUEST,
  FETCH_SAVE_DEEP_LINK_DATA_SUCCESS,
  FETCH_SAVE_DEEP_LINK_DATA_FAILURE,
  FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST,
  FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS,
  FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE,
  FETCH_EMAIL_AVAILABLE_REQUEST,
  FETCH_EMAIL_AVAILABLE_SUCCESS,
  FETCH_EMAIL_AVAILABLE_FAILURE,
  FETCH_MOBILE_VALID_REQUEST,
  FETCH_MOBILE_VALID_SUCCESS,
  FETCH_MOBILE_VALID_FAILURE,
} from './actionTypes';

import { LoginActions, LoginState } from './types';

const initialState: LoginState = {
  pending: false,
  loginDetails: [],
  userDetailsById: null,
  loginError: null,
  error: null,
  deepLinkData: [],
  authenticateWithToken: [],
  authenticateWithTokenError: null,
  resendSwitchBoardOnBoardingLink: [],
  resendSwitchBoardOnBoardingLinkError: null,
  clientAdminOnborading: [],
  clientAdminOnboradingError: null,
  emailAvailablePendding: false,
  emailAvailable: [],
  emailAvailableError: null,
  mobileValid: [],
};

const Reducer = (state = initialState, action: LoginActions) => {
  switch (action.type) {
    case FETCH_MOBILE_VALID_REQUEST:
      return {
        ...state,
        mobileValidPending: true,
      };
    case FETCH_MOBILE_VALID_SUCCESS:
      return {
        ...state,
        mobileValidPending: false,
        mobileValid: action.payload,
        mobileError: null,
      };
    case FETCH_MOBILE_VALID_FAILURE:
      return {
        ...state,
        mobileValidPending: false,
        mobileValid: false,
        mobileError: action.payload,
      };
    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        loginDetails: action.payload,
        loginError: null,
      };
    case FETCH_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        loginError: action.payload,
      };
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        UserDetails: action.payload,
        error: null,
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_USER_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_USER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        userDetailsById: action.payload,
        error: null,
      };
    case FETCH_USER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_REQUEST_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        requestPasswordReset: action.payload,
        requestPasswordResetError: null,
      };
    case POST_REQUEST_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        requestPasswordResetError: action.payload,
      };
    case POST_REDEEM_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_REDEEM_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        pending: false,
        redeemPasswordReset: action.payload,
        redeemPasswordResetError: null,
      };
    case POST_REDEEM_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        pending: false,
        redeemPasswordResetError: action.payload,
      };
    case FETCH_SAVE_DEEP_LINK_DATA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SAVE_DEEP_LINK_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        deepLinkData: action.payload,
        error: null,
      };
    case FETCH_SAVE_DEEP_LINK_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    // Authenticate with token
    case FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        authenticateWithToken: action.payload,
        error: null,
      };
    case FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE:
      return {
        ...state,
        pending: false,
        authenticateWithTokenError: action.payload,
      };
    // Resend onbording link
    case POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS:
      return {
        ...state,
        pending: false,
        resendSwitchBoardOnBoardingLink: action.payload,
        resendSwitchBoardOnBoardingLinkError: null,
      };
    case POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE:
      return {
        ...state,
        pending: false,
        resendSwitchBoardOnBoardingLinkError: action.payload,
      };
    // Client admin onboarding complete
    case POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS:
      return {
        ...state,
        pending: false,
        clientAdminOnborading: action.payload,
        clientAdminOnboradingError: null,
      };
    case POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE:
      return {
        ...state,
        pending: false,
        clientAdminOnboradingError: action.payload,
      };
    // Email Available
    case FETCH_EMAIL_AVAILABLE_REQUEST:
      return {
        ...state,
        emailAvailablePendding: true,
      };
    case FETCH_EMAIL_AVAILABLE_SUCCESS:
      return {
        ...state,
        emailAvailablePendding: false,
        emailAvailable: action.payload,
        emailError: null,
      };
    case FETCH_EMAIL_AVAILABLE_FAILURE:
      return {
        ...state,
        emailAvailablePendding: false,
        emailAvailable: [],
        emailAvailableError: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
