import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { useHistory } from 'react-router-dom';

type props = {
  memberName?: string | null;
};

const HeadBack: React.FunctionComponent<props> = ({ memberName }) => {
  const history = useHistory();
  return (
    <div className="w-full flex">
      <div
        className="flex justify-end  bg-white     rounded-full mr-4"
        role="button"
        onClick={() => {
          history.goBack();
        }}
      >
        <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
      </div>
      <p className="flex items-center">{memberName}</p>
    </div>
  );
};

export default HeadBack;
