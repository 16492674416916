export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

export const FETCH_CLIENT_COUNT_REQUEST = 'FETCH_CLIENT_COUNT_REQUEST';
export const FETCH_CLIENT_COUNT_SUCCESS = 'FETCH_CLIENT_COUNT_SUCCESS';
export const FETCH_CLIENT_COUNT_FAILURE = 'FETCH_CLIENT_COUNT_FAILURE';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const POST_AVAILABLE_CREDIT_REQUEST = 'POST_AVAILABLE_CREDIT_REQUEST';
export const POST_AVAILABLE_CREDIT_SUCCESS = 'POST_AVAILABLE_CREDIT_SUCCESS';
export const POST_AVAILABLE_CREDIT_FAILURE = 'POST_AVAILABLE_CREDIT_FAILURE';

export const GET_ADMIN_DETAILS_REQUEST = 'GET_ADMIN_DETAILS_REQUEST';
export const GET_ADMIN_DETAILS_SUCCESS = 'GET_ADMIN_DETAILS_SUCCESS';
export const GET_ADMIN_DETAILS_FAILURE = 'GET_ADMIN_DETAILS_FAILURE';

export const GET_CLIENT_DETAILS_REQUEST = 'GET_CLIENT_DETAILS_REQUEST';
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_FAILURE = 'GET_CLIENT_DETAILS_FAILURE';

export const DELETE_CLIENT_DETAILS_REQUEST = 'DELETE_CLIENT_DETAILS_REQUEST';
export const DELETE_CLIENT_DETAILS_SUCCESS = 'DELETE_CLIENT_DETAILS_SUCCESS';
export const DELETE_CLIENT_DETAILS_FAILURE = 'DELETE_CLIENT_DETAILS_FAILURE';

export const POST_CREATE_PACKAGE_REQUEST = 'POST_CREATE_PACKAGE_REQUEST';
export const POST_CREATE_PACKAGE_SUCCESS = 'POST_CREATE_PACKAGE_SUCCESS';
export const POST_CREATE_PACKAGE_FAILURE = 'POST_CREATE_PACKAGE_FAILURE';

export const POST_UPDATE_PACKAGE_REQUEST = 'POST_UPDATE_PACKAGE_REQUEST';
export const POST_UPDATE_PACKAGE_SUCCESS = 'POST_UPDATE_PACKAGE_SUCCESS';
export const POST_UPDATE_PACKAGE_FAILURE = 'POST_UPDATE_PACKAGE_FAILURE';

export const POST_UPDATE_PACKAGE_PRODUCT_REQUEST = 'POST_UPDATE_PACKAGE_PRODUCT_REQUEST';
export const POST_UPDATE_PACKAGE_PRODUCT_SUCCESS = 'POST_UPDATE_PACKAGE_PRODUCT_SUCCESS';
export const POST_UPDATE_PACKAGE_PRODUCT_FAILURE = 'POST_UPDATE_PACKAGE_PRODUCT_FAILURE';

export const POST_CREATE_PACKAGE_PRODUCT_REQUEST = 'POST_CREATE_PACKAGE_PRODUCT_REQUEST';
export const POST_CREATE_PACKAGE_PRODUCT_SUCCESS = 'POST_CREATE_PACKAGE_PRODUCT_SUCCESS';
export const POST_CREATE_PACKAGE_PRODUCT_FAILURE = 'POST_CREATE_PACKAGE_PRODUCT_FAILURE';

export const POST_ADD_COMMS_REQUEST = 'POST_ADD_COMMS_REQUEST';
export const POST_ADD_COMMS_SUCCESS = 'POST_ADD_COMMS_SUCCESS';
export const POST_ADD_COMMS_FAILURE = 'POST_ADD_COMMS_FAILURE';

export const FETCH_WEB_APP_URL_SETTING_REQUEST = 'FETCH_WEB_APP_URL_SETTING_REQUEST';
export const FETCH_WEB_APP_URL_SETTING_SUCCESS = 'FETCH_WEB_APP_URL_SETTING_SUCCESS';
export const FETCH_WEB_APP_URL_SETTING_FAILURE = 'FETCH_WEB_APP_URL_SETTING_FAILURE';

export const POST_FORCE_CHECK_IN_REQUEST = 'POST_FORCE_CHECK_IN_REQUEST';
export const POST_FORCE_CHECK_IN_SUCCESS = 'POST_FORCE_CHECK_IN_SUCCESS';
export const POST_FORCE_CHECK_IN_FAILURE = 'POST_FORCE_CHECK_IN_FAILURE';

export const FETCH_CAMPAIGN_REQUEST = 'FETCH_CAMPAIGN_REQUEST';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_FAILURE = 'FETCH_CAMPAIGN_FAILURE';

export const FETCH_PRODUCT_TEMPLATE_REQUEST = 'FETCH_PRODUCT_TEMPLATE_REQUEST';
export const FETCH_PRODUCT_TEMPLATE_SUCCESS = 'FETCH_PRODUCT_TEMPLATE_SUCCESS';
export const FETCH_PRODUCT_TEMPLATE_FAILURE = 'FETCH_PRODUCT_TEMPLATE_FAILURE';

// remove comms
export const REMOVED_COMMS_REQUEST = 'REMOVED_COMMS_REQUEST';
export const REMOVED_COMMS_SUCCESS = 'REMOVED_COMMS_SUCCESS';
export const REMOVED_COMMS_FAILURE = 'REMOVED_COMMS_FAILURE';

export const POST_CREATE_CLIENT_REQUEST = 'POST_CREATE_CLIENT_REQUEST';
export const POST_CREATE_CLIENT_SUCCESS = 'POST_CREATE_CLIENT_SUCCESS';
export const POST_CREATE_CLIENT_FAILURE = 'POST_CREATE_CLIENT_FAILURE';

//Update Client
export const POST_UPDATE_CLIENT_REQUEST = 'POST_UPDATE_CLIENT_REQUEST';
export const POST_UPDATE_CLIENT_SUCCESS = 'POST_UPDATE_CLIENT_SUCCESS';
export const POST_UPDATE_CLIENT_FAILURE = 'POST_UPDATE_CLIENT_FAILURE';

//Fetch Email Template
export const FETCH_EMAIL_TEMPLATE_REQUEST = 'FETCH_EMAIL_TEMPLATE_REQUEST';
export const FETCH_EMAIL_TEMPLATE_SUCCESS = 'FETCH_EMAIL_TEMPLATE_SUCCESS';
export const FETCH_EMAIL_TEMPLATE_FAILURE = 'FETCH_EMAIL_TEMPLATE_FAILURE';

export const FETCH_CAMPAIGNS_REQUEST = 'FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';

//fetch incentives
export const FETCH_INCENTIVES_REQUEST = 'FETCH_INCENTIVES_REQUEST';
export const FETCH_INCENTIVES_SUCCESS = 'FETCH_INCENTIVES_SUCCESS';
export const FETCH_INCENTIVES_FAILURE = 'FETCH_INCENTIVES_FAILURE';

// fetch system messages
export const FETCH_SYSTEM_MESSAGES_REQUEST = 'FETCH_SYSTEM_MESSAGES_REQUEST';
export const FETCH_SYSTEM_MESSAGES_SUCCESS = 'FETCH_SYSTEM_MESSAGES_SUCCESS';
export const FETCH_SYSTEM_MESSAGES_FAILURE = 'FETCH_SYSTEM_MESSAGES_FAILURE';

export const FETCH_REPORTING_REQUEST = 'FETCH_REPORTING_REQUEST';
export const FETCH_REPORTING_SUCCESS = 'FETCH_REPORTING_SUCCESS';
export const FETCH_REPORTING_FAILURE = 'FETCH_REPORTING_FAILURE';

// delete client package
export const DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILURE = 'DELETE_PACKAGE_FAILURE';

export const FETCH_APP_SETTINGS_REQUEST = 'FETCH_APP_SETTINGS_REQUEST';
export const FETCH_APP_SETTINGS_SUCCESS = 'FETCH_APP_SETTINGS_SUCCESS';
export const FETCH_APP_SETTINGS_FAILURE = 'FETCH_APP_SETTINGS_FAILURE';

//partner Code Redemption
export const FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST = 'FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST';
export const FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS = 'FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS';
export const FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE = 'FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE';

export const POST_SUBSCRIPTIONS_REQUEST = 'POST_SUBSCRIPTIONS_REQUEST';
export const POST_SUBSCRIPTIONS_SUCCESS = 'POST_SUBSCRIPTIONS_SUCCESS';
export const POST_SUBSCRIPTIONS_FAILURE = 'POST_SUBSCRIPTIONS_FAILURE';

// activate Subscription
export const UPDATE_SUBSCRIPTIONS_REQUEST = 'UPDATE_SUBSCRIPTIONS_REQUEST';
export const UPDATE_SUBSCRIPTIONS_SUCCESS = 'UPDATE_SUBSCRIPTIONS_SUCCESS';
export const UPDATE_SUBSCRIPTIONS_FAILURE = 'UPDATE_SUBSCRIPTIONS_FAILURE';

// remove payment
export const REMOVE_SUBSCRIPTIONS_REQUEST = 'REMOVE_SUBSCRIPTIONS_REQUEST';
export const REMOVE_SUBSCRIPTIONS_SUCCESS = 'REMOVE_SUBSCRIPTIONS_SUCCESS';
export const REMOVE_SUBSCRIPTIONS_FAILURE = 'REMOVE_SUBSCRIPTIONS_FAILURE';

// default subscription template
export const GET_DEFAULT_SUBSCRIPTIONS_REQUEST = 'GET_DEFAULT_SUBSCRIPTIONS_REQUEST';
export const GET_DEFAULT_SUBSCRIPTIONS_SUCCESS = 'GET_DEFAULT_SUBSCRIPTIONS_SUCCESS';
export const GET_DEFAULT_SUBSCRIPTIONS_FAILURE = 'GET_DEFAULT_SUBSCRIPTIONS_FAILURE';
