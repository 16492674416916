import {
  POST_RESOURCES_REQUEST,
  POST_RESOURCES_SUCCESS,
  POST_RESOURCES_FAILURE,
  FETCH_RESOURCES_LIST_REQUEST,
  FETCH_RESOURCES_LIST_SUCCESS,
  FETCH_RESOURCES_LIST_FAILURE,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_RESOURCES_SUCCESS,
  UPDATE_RESOURCES_FAILURE,
  REMOVE_RESOURCES_REQUEST,
  REMOVE_RESOURCES_SUCCESS,
  REMOVE_RESOURCES_FAILURE,
  POST_TEST_RESOURCES_REQUEST,
  POST_TEST_RESOURCES_SUCCESS,
  POST_TEST_RESOURCES_FAILURE,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_SUCCESS,
  FETCH_RESOURCE_FAILURE,
} from './action-types';

import { HomeActions, TemplateInfoState } from './types';

const initialState: TemplateInfoState = {
  pending: false,
  Resources: [],
  ResourcesList: [],
  Resource: null,
  UpdateResources: null,
  RemoveResources: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_RESOURCES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_RESOURCES_SUCCESS:
      return {
        ...state,
        pending: false,
        newResource: action.payload,
        error: null,
      };
    case POST_RESOURCES_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_RESOURCES_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RESOURCES_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        ResourcesList: action.payload.ResourcesList,
        error: null,
      };
    case FETCH_RESOURCES_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_RESOURCES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_RESOURCES_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateResources: action.payload,
        error: null,
      };
    case UPDATE_RESOURCES_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateResources: null,
        error: action.payload.error,
      };
    case REMOVE_RESOURCES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_RESOURCES_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveResources: action.payload,
        error: null,
      };
    case REMOVE_RESOURCES_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveResources: null,
        error: action.payload.error,
      };

    case POST_TEST_RESOURCES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TEST_RESOURCES_SUCCESS:
      return {
        ...state,
        pending: false,
        testResources: action.payload,
        error: null,
      };
    case POST_TEST_RESOURCES_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_RESOURCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RESOURCE_SUCCESS:
      return {
        ...state,
        pending: false,
        resource: action.payload,
        error: null,
      };
    case FETCH_RESOURCE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
