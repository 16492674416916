import {
  FETCH_CLIENT_ADMINSTRATORS_REQUEST,
  FETCH_CLIENT_ADMINSTRATORS_SUCCESS,
  FETCH_CLIENT_ADMINSTRATORS_FAILURE,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE,
  POST_INVITE_CLIENT_ADMIN_REQUEST,
  POST_INVITE_CLIENT_ADMIN_SUCCESS,
  POST_INVITE_CLIENT_ADMIN_FAILURE,
  DELETE_CLIENT_ADMIN_REQUEST,
  DELETE_CLIENT_ADMIN_SUCCESS,
  DELETE_CLIENT_ADMIN_FAILURE,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE,
  POST_INVITE_VIA_EMAIL_REQUEST,
  POST_INVITE_VIA_EMAIL_SUCCESS,
  POST_INVITE_VIA_EMAIL_FAILURE,
} from './actionTypes';

import { GroupAdminsActions, GroupAdminsState } from './types';

const initialState: GroupAdminsState = {
  pending: false,
  adminstrators: null,
  clientAdminCount: null,
  resendClientAdmin: null,
  deleteClientAdmin: null,
  error: null,
};

const Reducer = (state = initialState, action: GroupAdminsActions) => {
  switch (action.type) {
    case FETCH_CLIENT_ADMINSTRATORS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLIENT_ADMINSTRATORS_SUCCESS:
      return {
        ...state,
        pending: false,
        adminstrators: action.payload,
        error: null,
      };
    case FETCH_CLIENT_ADMINSTRATORS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        clientAdminCount: action.payload,
        error: null,
      };
    case FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        clientAdminCount: null,
        error: action.payload.error,
      };
    case POST_INVITE_CLIENT_ADMIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_INVITE_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        resendClientAdmin: action.payload,
        error: null,
      };
    case POST_INVITE_CLIENT_ADMIN_FAILURE:
      return {
        ...state,
        pending: false,
        resendClientAdmin: {},
        error: action.payload.error,
      };
    case DELETE_CLIENT_ADMIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteClientAdmin: action.payload,
      };
    case DELETE_CLIENT_ADMIN_FAILURE:
      return {
        ...state,
        pending: false,
        deleteClientAdmin: null,
      };
    case DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS:
      return {
        ...state,
        pending: false,
        revokeClient: action.payload,
      };
    case DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE:
      return {
        ...state,
        pending: false,
        revokeClient: null,
      };
    case POST_INVITE_VIA_EMAIL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_INVITE_VIA_EMAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        inviteEmail: action.payload,
        error: null,
      };
    case POST_INVITE_VIA_EMAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
