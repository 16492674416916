import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, DropDown, SectionLabel } from '../../../components/common';
import AccordionTile from '../Accordion';
import { postCreatePackageProductRequest, postUpdatePackageProductRequest } from '../clients-redux/actions';
import { ResponsibleParty } from '../clients-constants';

type props = {
  data: any;
  default: any;
  Incentives: any;
  UpdateSaveOption: (data: boolean) => void;
};

const RewardGiftCard: React.FunctionComponent<props> = (props): JSX.Element => {
  const [reward, setreward] = useState(0);
  const [rewardmilestonevalue, setrewardmilestonevalue] = useState<any>();
  const [image, setimgPreview] = useState<any>();
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [preloadedImage, setpreloadedImage] = useState(false);
  const [id, setid] = useState();
  const dispatch = useDispatch();
  const [rewardGiftCardTab, setrewardGiftCardTab] = useState(false);
  const fileInput: any = React.createRef();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<any>({});
  const [campaign, setcampaign] = useState();
  const [incentives, setIncentives] = useState();

  const onFileChange = async (event: any) => {
    try {
      //

      if (event.target.files && event.target.files[0]) {
        // setimage(event.target.files[0]);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setpreloadedImage(false);
          setimgPreview(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }

      // Update the state
    } catch (error) {
      console.error('err--', error);
    }
  };

  const hadleImageinpuy = (e: any) => {
    fileInput.current.click();
  };

  const Validation = () => {
    if (rewardGiftCardTab) {
      if (!reward) {
        setErrorMessage({ reward: 'Field is required' });
        return false;
      } else if (reward.toString().indexOf('.') !== -1) {
        setErrorMessage({
          reward: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (reward <= 0 || reward > 50) {
        setErrorMessage({ reward: 'Must be a number between 1 and 50' });
        return false;
      } else if (!rewardmilestonevalue) {
        setErrorMessage({ rewardmilestonevalue: 'Field is required' });
        return false;
      } else if (rewardmilestonevalue.toString().indexOf('.') !== -1) {
        setErrorMessage({
          rewardmilestonevalue: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (rewardmilestonevalue <= 0 || rewardmilestonevalue > 50) {
        setErrorMessage({ rewardmilestonevalue: 'Must be a number between 1 and 50' });
        return false;
      }
    }
    setErrorMessage({});
    return true;
  };

  const onSave = () => {
    const payload = {
      status: rewardGiftCardTab ? 'enabled' : 'disabled',
      configuration: {
        required_points: +reward,
        reward_value: +rewardmilestonevalue * 100,
        tremendous_campaign_id: campaign,
        tremendous_organization_id: incentives,
        image_url: !preloadedImage ? image : image === null ? null : undefined,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };

    if (Validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;

      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'REWARD_GIFT_CARD') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setrewardGiftCardTab(selectedItem?.status === 'enabled' ? true : false);

        const configuration = selectedItem.configuration;
        setreward(configuration.required_points);
        setrewardmilestonevalue(configuration.reward_value / 100);
        if (configuration.image_url) {
          setimgPreview(configuration.image_url);
          setpreloadedImage(true);
        }
        setpackageId(selectedItem && selectedItem.package_id);
        setproductId(selectedItem && selectedItem.product_id);
        setid(selectedItem && selectedItem.id);
      } else {
        setrewardGiftCardTab(false);
        const configuration = props.default.configuration;
        setreward(configuration.required_points);
        setrewardmilestonevalue(configuration.reward_value / 100);
        if (configuration.image_url) {
          setimgPreview(configuration.image_url);
          setpreloadedImage(true);
        }
        setid(props.default.id);
        setpackageId(props.data.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionTile
      setStatus={(data: boolean) => {
        if ((!data && Validation()) || data) {
          !enableSaveOption && setenableSaveOption(true);
          setrewardGiftCardTab(data);
        }
      }}
      Label={'REWARD GIFT CARD'}
      buttonText={'SAVE'}
      status={rewardGiftCardTab}
      id={'rewardGiftCardTab'}
      initialstatus={true}
      disableButton={enableSaveOption}
      onButtonClick={() => {
        if (enableSaveOption) onSave();
      }}
      InnerComponent={
        <div className="flex justify-evenly gap-7 my-10">
          <div className="w-full ">
            <div className="py-4">
              <CustomInput
                inputType={'number'}
                value={reward}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setreward(data);
                }}
                Label={'Reward Milestone'}
                Error={ErrorMessage?.reward?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.reward}
              />
            </div>
            <div className="py-4">
              <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">Reward Milestone Value</label>
              <input
                type={'text'}
                value={rewardmilestonevalue}
                className="py-2 px-3 w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, '');
                  if (val !== rewardmilestonevalue.toString()) {
                    !enableSaveOption && setenableSaveOption(true);
                  }
                  setrewardmilestonevalue(val);
                }}
              />
              {ErrorMessage?.rewardmilestonevalue?.length > 0 && (
                <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                  {' '}
                  {ErrorMessage?.rewardmilestonevalue}
                </label>
              )}
            </div>
            <div className="py-2">
              <DropDown
                Label={'Responsible Party'}
                value={campaign}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setcampaign(data);
                }}
              >
                {props.Incentives?.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </DropDown>
            </div>
            <div className="py-2">
              <DropDown
                Label={' Incentive Campaign'}
                value={incentives}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setIncentives(data);
                }}
              >
                {ResponsibleParty.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </DropDown>
            </div>
          </div>
          <div className="w-full">
            <div className="py-4">
              <SectionLabel Label="Reward Graphic" />
            </div>
            <div className="w-full flex">
              <div
                className="bg-gray-200 w-full aspect-ratio-container rounded-sm flex justify-center items-center cursor-pointer"
                onClick={hadleImageinpuy}
              >
                {image ? (
                  <img className="w-full h-full" src={image} alt="clinetImage" />
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-6 w-6 text-3xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInput}
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onClick={() => {
                        fileInput.current.value = null;
                      }}
                      onChange={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        onFileChange(data);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-6">
                {image && (
                  <button
                    className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                    onClick={() => {
                      setimgPreview(null);
                      !enableSaveOption && setenableSaveOption(true);
                    }}
                  >
                    REMOVE
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default RewardGiftCard;
