export const convertToHoursMinuteSeconds = (data: number) => {
  const totalMinutes = Math.floor(data / 60);
  const seconds = Math.floor(data % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formatHours = hours < 10 ? `0${hours}` : hours;
  const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;

  if (Math.floor(data) >= 120) {
    return `${formatHours.toString()}:${formatMinutes.toString()}:${formatSeconds.toString()}`;
  }
  return '--';
};
