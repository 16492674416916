import React, { useState } from 'react';

type props = {
  value: string;
};

const CustomSlider: React.FunctionComponent<props> = ({ value }): JSX.Element => {
  const [range] = useState(value);

  return (
    <div>
      <div className="relative  h-10 flex flex-wrap items-center w-full">
        <div className="absolute z-10 flex justify-between items-center h-6 w-full">
          <div className="bg-gray-200   w-0.5 h-full"></div>
          <div className="bg-gray-200  w-0.5 h-full"></div>
          <div className="bg-gray-200 w-0.5 h-full"></div>
        </div>
        <div className="absolute z-20 flex justify-evenly items-center h-1 w-full px-2">
          <div className="stacked-bar-red-primary m-0.5  w-full h-full"></div>
          <div className="stacked-bar-orange-primary m-0.5 w-full h-full"></div>
          <div className="stacked-bar-green-primary m-0.5 w-full h-full"></div>
        </div>
        <div className="w-full absolute z-30 h-1 flex items-center px-2">
          <input
            type="range"
            min="1"
            max="100"
            value={range}
            className="w-full bg-transparent appearance-none"
            id="myRange"
          ></input>
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
