import {
  POST_ELIGIBILTY_REQUEST,
  POST_ELIGIBILTY_FAILURE,
  POST_ELIGIBILTY_SUCCESS,
  FETCH_ELIGILITY_REQUEST,
  FETCH_ELIGILITY_FAILURE,
  FETCH_ELIGILITY_SUCCESS,
  POST_VALIDATE_ELIGIBILTY_REQUEST,
  POST_VALIDATE_ELIGIBILTY_FAILURE,
  POST_VALIDATE_ELIGIBILTY_SUCCESS,
  FETCH_ELIGIBILTY_LIST_REQUEST,
  FETCH_ELIGIBILTY_LIST_FAILURE,
  FETCH_ELIGIBILTY_LIST_SUCCESS,
  FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST,
  FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS,
  FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE,
  UPDATE_ELIGIBILTY_REQUEST,
  UPDATE_ELIGIBILTY_FAILURE,
  UPDATE_ELIGIBILTY_SUCCESS,
  REMOVE_ELIGIBILTY_REQUEST,
  REMOVE_ELIGIBILTY_FAILURE,
  REMOVE_ELIGIBILTY_SUCCESS,
  PROCESS_ELIGIBILTY_REQUEST,
  PROCESS_ELIGIBILTY_SUCCESS,
  PROCESS_ELIGIBILTY_FAILURE,
  DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST,
  DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS,
  DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE,
  PROCESS_ELIGIBILITY_REPORT_REQUEST,
  PROCESS_ELIGIBILITY_REPORT_SUCCESS,
  PROCESS_ELIGIBILITY_REPORT_FAILURE,
  TRANSFER_ELIGIBILITY_REPORT_REQUEST,
  TRANSFER_ELIGIBILITY_REPORT_SUCCESS,
  TRANSFER_ELIGIBILITY_REPORT_FAILURE,
} from './action-types';

import {
  PostEligibilityRequest,
  PostEligibilitySuccess,
  PostEligibilitySuccessPayload,
  PostEligibilityFailure,
  PostEligibilityFailurePayload,
  FetchEligibiltyRequest,
  FetchEligibiltySuccess,
  FetchEligibiltyFailure,
  FetchEligibiltyFailurePayload,
  PostValidateEligibiltyRequest,
  PostValidateEligibiltySuccess,
  PostValidateEligibiltyFailure,
  PostValidateEligibiltyFailurePayload,
  FetchEligibiltyListRequest,
  FetchEligibiltyListSuccess,
  FetchEligibiltyListSuccessPayload,
  FetchEligibiltyListFailure,
  FetchEligibiltyListFailurePayload,
  FetchEligibiltyActiveListRequest,
  FetchEligibiltyActiveListSuccess,
  FetchEligibiltyActiveListSuccessPayload,
  FetchEligibiltyActiveListFailure,
  FetchEligibiltyActiveListFailurePayload,
  FetchEligibiltyScheduledListRequest,
  FetchEligibiltyScheduledListSuccess,
  FetchEligibiltyScheduledListSuccessPayload,
  FetchEligibiltyScheduledListFailure,
  FetchEligibiltyScheduledListFailurePayload,
  UpdateEligibiltyRequest,
  UpdateEligibiltySuccess,
  UpdateEligibiltySuccessPayload,
  UpdateEligibiltyFailure,
  UpdateEligibiltyFailurePayload,
  RemoveEligibiltyRequest,
  RemoveEligibiltySuccess,
  RemoveEligibiltySuccessPayload,
  RemoveEligibiltyFailure,
  RemoveEligibiltyFailurePayload,
  ProcessEligibilityRequest,
  ProcessEligibilitySuccess,
  ProcessEligibilitySuccessPayload,
  ProcessEligibilityFailure,
  ProcessEligibilityFailurePayload,
  DownloadEligibilityMemebersRequest,
  DownloadEligibilityMemebersSuccess,
  DownloadEligibilityMemebersFailure,
  DownloadEligibilityMemebersFailurePayload,
  ProcessEligibilityReportRequest,
  ProcessEligibilityReportSuccess,
  ProcessEligibilityReportFailure,
  ProcessEligibilityReportFailurePayload,
  TransferEligibilityReportRequest,
  TransferEligibilityReportSuccess,
  TransferEligibilityReportFailure,
  TransferEligibilityReportSuccessPayload,
  TransferEligibilityReportFailurePayload,
} from './types';

export const postEligibilityRequest = (payload: any): PostEligibilityRequest => ({
  type: POST_ELIGIBILTY_REQUEST,
  payload,
});

export const postEligibilitySuccess = (payload: PostEligibilitySuccessPayload): PostEligibilitySuccess => ({
  type: POST_ELIGIBILTY_SUCCESS,
  payload,
});

export const postEligibilityFailure = (payload: PostEligibilityFailurePayload): PostEligibilityFailure => ({
  type: POST_ELIGIBILTY_FAILURE,
  payload,
});

export const fetchEligibiltyRequest = (payload: any): FetchEligibiltyRequest => ({
  type: FETCH_ELIGILITY_REQUEST,
  payload,
});

export const fetchEligibiltySuccess = (payload: any): FetchEligibiltySuccess => ({
  type: FETCH_ELIGILITY_SUCCESS,
  payload,
});

export const fetchEligibiltyFailure = (payload: FetchEligibiltyFailurePayload): FetchEligibiltyFailure => ({
  type: FETCH_ELIGILITY_FAILURE,
  payload,
});

export const postValidateEligibiltyRequest = (payload: any): PostValidateEligibiltyRequest => ({
  type: POST_VALIDATE_ELIGIBILTY_REQUEST,
  payload,
});

export const postValidateEligibiltySuccess = (payload: any): PostValidateEligibiltySuccess => ({
  type: POST_VALIDATE_ELIGIBILTY_SUCCESS,
  payload,
});

export const postValidateEligibiltyFailure = (
  payload: PostValidateEligibiltyFailurePayload,
): PostValidateEligibiltyFailure => ({
  type: POST_VALIDATE_ELIGIBILTY_FAILURE,
  payload,
});

export const fetchEligibiltyListRequest = (payload: any): FetchEligibiltyListRequest => ({
  type: FETCH_ELIGIBILTY_LIST_REQUEST,
  payload,
});

export const fetchEligibiltyListSuccess = (payload: FetchEligibiltyListSuccessPayload): FetchEligibiltyListSuccess => ({
  type: FETCH_ELIGIBILTY_LIST_SUCCESS,
  payload,
});

export const fetchEligibiltyListFailure = (payload: FetchEligibiltyListFailurePayload): FetchEligibiltyListFailure => ({
  type: FETCH_ELIGIBILTY_LIST_FAILURE,
  payload,
});

export const fetchEligibiltyActiveListRequest = (payload: any): FetchEligibiltyActiveListRequest => ({
  type: FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST,
  payload,
});

export const fetchEligibiltyActiveListSuccess = (
  payload: FetchEligibiltyActiveListSuccessPayload,
): FetchEligibiltyActiveListSuccess => ({
  type: FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS,
  payload,
});

export const fetchEligibiltyActiveListFailure = (
  payload: FetchEligibiltyActiveListFailurePayload,
): FetchEligibiltyActiveListFailure => ({
  type: FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE,
  payload,
});

export const fetchEligibiltyScheduledListRequest = (payload: any): FetchEligibiltyScheduledListRequest => ({
  type: FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST,
  payload,
});

export const fetchEligibiltyScheduledListSuccess = (
  payload: FetchEligibiltyScheduledListSuccessPayload,
): FetchEligibiltyScheduledListSuccess => ({
  type: FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS,
  payload,
});

export const fetchEligibiltyScheduledListFailure = (
  payload: FetchEligibiltyScheduledListFailurePayload,
): FetchEligibiltyScheduledListFailure => ({
  type: FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE,
  payload,
});

export const updateEligibiltyRequest = (payload: any): UpdateEligibiltyRequest => ({
  type: UPDATE_ELIGIBILTY_REQUEST,
  payload,
});

export const updateEligibiltySuccess = (payload: UpdateEligibiltySuccessPayload): UpdateEligibiltySuccess => ({
  type: UPDATE_ELIGIBILTY_SUCCESS,
  payload,
});

export const updateEligibiltyFailure = (payload: UpdateEligibiltyFailurePayload): UpdateEligibiltyFailure => ({
  type: UPDATE_ELIGIBILTY_FAILURE,
  payload,
});

export const removeEligibiltyRequest = (payload: any): RemoveEligibiltyRequest => ({
  type: REMOVE_ELIGIBILTY_REQUEST,
  payload,
});

export const removeEligibiltySuccess = (payload: RemoveEligibiltySuccessPayload): RemoveEligibiltySuccess => ({
  type: REMOVE_ELIGIBILTY_SUCCESS,
  payload,
});

export const removeEligibiltyFailure = (payload: RemoveEligibiltyFailurePayload): RemoveEligibiltyFailure => ({
  type: REMOVE_ELIGIBILTY_FAILURE,
  payload,
});

export const processEligibilityRequest = (payload: any): ProcessEligibilityRequest => ({
  type: PROCESS_ELIGIBILTY_REQUEST,
  payload,
});

export const processEligibilitySuccess = (payload: ProcessEligibilitySuccessPayload): ProcessEligibilitySuccess => ({
  type: PROCESS_ELIGIBILTY_SUCCESS,
  payload,
});

export const processEligibilityFailure = (payload: ProcessEligibilityFailurePayload): ProcessEligibilityFailure => ({
  type: PROCESS_ELIGIBILTY_FAILURE,
  payload,
});

export const downloadEligibilityMemebersRequest = (payload: any): DownloadEligibilityMemebersRequest => ({
  type: DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST,
  payload,
});

export const downloadEligibilityMemebersSuccess = (payload: any): DownloadEligibilityMemebersSuccess => ({
  type: DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS,
  payload,
});

export const downloadEligibilityMemebersFailure = (
  payload: DownloadEligibilityMemebersFailurePayload,
): DownloadEligibilityMemebersFailure => ({
  type: DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE,
  payload,
});

export const processEligibilityReportRequest = (payload: any): ProcessEligibilityReportRequest => ({
  type: PROCESS_ELIGIBILITY_REPORT_REQUEST,
  payload,
});

export const processEligibilityReportSuccess = (payload: any): ProcessEligibilityReportSuccess => ({
  type: PROCESS_ELIGIBILITY_REPORT_SUCCESS,
  payload,
});

export const processEligibilityReportFailure = (
  payload: ProcessEligibilityReportFailurePayload,
): ProcessEligibilityReportFailure => ({
  type: PROCESS_ELIGIBILITY_REPORT_FAILURE,
  payload,
});

export const transferEligibilityReportRequest = (payload: any): TransferEligibilityReportRequest => ({
  type: TRANSFER_ELIGIBILITY_REPORT_REQUEST,
  payload,
});

export const transferEligibilityReportSuccess = (
  payload: TransferEligibilityReportSuccessPayload,
): TransferEligibilityReportSuccess => ({
  type: TRANSFER_ELIGIBILITY_REPORT_SUCCESS,
  payload,
});

export const transferEligibilityReportFailure = (
  payload: TransferEligibilityReportFailurePayload,
): TransferEligibilityReportFailure => ({
  type: TRANSFER_ELIGIBILITY_REPORT_FAILURE,
  payload,
});
