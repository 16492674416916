import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface AvailabilityActualsResponse {
  count: number;
  data: AvailabilityActual[];
}

export interface AvailabilityActual {
  adjusted_ended_at: string;
  adjusted_started_at: string;
  duration_during_schedule: number;
  ended_at: string;
  listener_role_id: number;
  schedule_snapshot: ScheduleSnapshot[];
  started_at: string;
}

export interface ScheduleSnapshot {
  end_utc: string;
  start_utc: string;
}

const getAvailabilityActualsByListenerId = async (
  listener_role_id: number,
  startDate?: string | null,
  endDate?: string | null,
) => {
  const params: { listener_role_id: number; start_date?: string; end_date?: string } = {
    listener_role_id,
  };

  if (startDate) {
    params.start_date = startDate;
  }

  if (endDate) {
    params.end_date = endDate;
  }

  return await axiosGet(`/availability_actuals/`, params, 'v3').then(
    (availabilityActualResponse) => availabilityActualResponse.data,
  );
};

export const useAvailabilityActuals = (listener_role_id: number, start_date?: string, end_date?: string) => {
  const queryKey = ['availabilityActuals', listener_role_id];
  if (start_date) {
    queryKey.push(start_date);
  }
  if (end_date) {
    queryKey.push(end_date);
  }

  const { data, isLoading, error } = useQuery<AvailabilityActualsResponse>(queryKey, () =>
    getAvailabilityActualsByListenerId(listener_role_id, start_date, end_date),
  );

  return { data, isLoading, error };
};
