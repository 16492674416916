import React from 'react';

type props = {
  Label: string;
  labelClass?: string;
  name?: string;
  value?: any;
  setValue?: (data: any) => void;
  inputType: string;
  Error?: any;
  ErrorMessage?: any;
  disabled?: boolean;
  allowDecimals?: boolean;
  secondLable?: string;
  register?: any;
  enableAdornment?: boolean;
  placeholder?: string;
};

const CustomInput: React.FunctionComponent<props> = ({
  Label,
  labelClass,
  value,
  setValue,
  inputType,
  Error,
  ErrorMessage,
  disabled,
  name,
  allowDecimals,
  secondLable,
  register,
  enableAdornment,
  placeholder,
}): JSX.Element => {
  return (
    <div>
      <div className="flex justify-between">
        {Label.length > 0 && <label className={`${labelClass} block leading-4 text-gray-dark mb-2`}> {Label}</label>}
        {secondLable && secondLable?.length > 0 ? (
          <label className={`text-xs block leading-4 text-gray-dark mb-2`}> {secondLable}</label>
        ) : null}
      </div>
      <div className=" flex  w-full rounded-md  bg-gray-background-light  ">
        {enableAdornment && (
          <p className="my-2 ml-2 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none ">
            $
          </p>
        )}{' '}
        <input
          placeholder={placeholder}
          type={inputType === 'number' || inputType === 'decimal' ? 'text' : inputType}
          min={0}
          name={name}
          onWheel={(e: any) => e.target.blur()}
          value={value}
          disabled={disabled}
          className={`py-2  w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none ${
            !enableAdornment ? 'px-3' : 'px'
          } ${disabled ? 'cursor-not-allowed' : null}`}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputType === 'number') {
              const convertedValue = inputValue.replace(/\D/g, '');
              if (convertedValue !== value.toString()) {
                setValue && setValue(convertedValue);
              }
            } else if (inputType === 'decimal') {
              const validNumber = new RegExp(/^\d*\.?\d{0,3}$/);

              if (!validNumber.test(inputValue)) {
                e.preventDefault();
              } else {
                setValue && setValue(inputValue);
              }
            } else {
              setValue && setValue(inputValue);
            }
          }}
          {...register}
        />
      </div>
      {Error && <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2"> {ErrorMessage}</label>}
    </div>
  );
};

export default CustomInput;
