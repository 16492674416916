import {
  POST_ELIGIBILTY_REQUEST,
  POST_ELIGIBILTY_SUCCESS,
  POST_ELIGIBILTY_FAILURE,
  FETCH_ELIGILITY_REQUEST,
  FETCH_ELIGILITY_SUCCESS,
  FETCH_ELIGILITY_FAILURE,
  POST_VALIDATE_ELIGIBILTY_REQUEST,
  POST_VALIDATE_ELIGIBILTY_SUCCESS,
  POST_VALIDATE_ELIGIBILTY_FAILURE,
  FETCH_ELIGIBILTY_LIST_REQUEST,
  FETCH_ELIGIBILTY_LIST_SUCCESS,
  FETCH_ELIGIBILTY_LIST_FAILURE,
  FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST,
  FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS,
  FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS,
  FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE,
  UPDATE_ELIGIBILTY_REQUEST,
  UPDATE_ELIGIBILTY_SUCCESS,
  UPDATE_ELIGIBILTY_FAILURE,
  REMOVE_ELIGIBILTY_REQUEST,
  REMOVE_ELIGIBILTY_SUCCESS,
  REMOVE_ELIGIBILTY_FAILURE,
  PROCESS_ELIGIBILTY_REQUEST,
  PROCESS_ELIGIBILTY_SUCCESS,
  PROCESS_ELIGIBILTY_FAILURE,
  DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST,
  DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS,
  DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE,
  PROCESS_ELIGIBILITY_REPORT_REQUEST,
  PROCESS_ELIGIBILITY_REPORT_SUCCESS,
  PROCESS_ELIGIBILITY_REPORT_FAILURE,
  TRANSFER_ELIGIBILITY_REPORT_REQUEST,
  TRANSFER_ELIGIBILITY_REPORT_SUCCESS,
  TRANSFER_ELIGIBILITY_REPORT_FAILURE,
} from './action-types';

import { HomeActions, ClientInfoState } from './types';

const initialState: ClientInfoState = {
  pending: false,
  eligibility: [],
  eligibilityError: null,
  eligibiltyList: [],
  eligibiltyValidation: [],
  eligibiltyActiveList: [],
  eligibiltyScheduledList: [],
  eligibilitySingle: null,
  updateEligibility: [],
  updateEligibilityError: null,
  removeEligibility: [],
  removeEligibilityError: null,
  processEligibility: [],
  processEligibilityError: null,
  downloadEligibilityMembers: [],
  downloadEligibilityMembersError: null,
  processEligibilityReport: [],
  processEligibilityReportError: null,
  transferEligibilityReport: [],
  transferEligibilityReportError: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_ELIGIBILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibility: action.payload,
        error: null,
      };
    case POST_ELIGIBILTY_FAILURE:
      return {
        ...state,
        pending: false,
        eligibilityError: action.payload,
      };
    case FETCH_ELIGILITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ELIGILITY_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibilitySingle: action.payload,
        error: null,
      };
    case FETCH_ELIGILITY_FAILURE:
      return {
        ...state,
        pending: false,
        eligibilitySingle: null,
        error: action.payload.error,
      };
    case POST_VALIDATE_ELIGIBILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_VALIDATE_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibiltyValidation: action.payload,
        error: null,
      };
    case POST_VALIDATE_ELIGIBILTY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ELIGIBILTY_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ELIGIBILTY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibiltyList: action.payload.eligibiltyList,
        error: null,
      };
    case FETCH_ELIGIBILTY_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ELIGIBILTY_ACTIVE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ELIGIBILTY_ACTIVE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibiltyActiveList: action.payload.eligibiltyActiveList,
        error: null,
      };
    case FETCH_ELIGIBILTY_ACTIVE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ELIGIBILTY_SCHEDULED_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ELIGIBILTY_SCHEDULED_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        eligibiltyScheduledList: action.payload.eligibiltyScheduledList,
        error: null,
      };
    case FETCH_ELIGIBILTY_SCHEDULED_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_ELIGIBILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        updateEligibility: action.payload,
        updateEligibilityError: null,
      };
    case UPDATE_ELIGIBILTY_FAILURE:
      return {
        ...state,
        pending: false,
        updateEligibilityError: action.payload,
      };
    case REMOVE_ELIGIBILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        removeEligibility: action.payload,
        removeEligibilityError: null,
      };
    case REMOVE_ELIGIBILTY_FAILURE:
      return {
        ...state,
        pending: false,
        removeEligibility: null,
        removeEligibilityError: action.payload,
      };
    case PROCESS_ELIGIBILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PROCESS_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        processEligibility: action.payload,
        processEligibilityError: null,
      };
    case PROCESS_ELIGIBILTY_FAILURE:
      return {
        ...state,
        pending: false,
        processEligibility: null,
        processEligibilityError: action.payload,
      };
    case DOWNLOAD_ELIGIBILTY_MEMBERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_ELIGIBILTY_MEMBERS_SUCCESS:
      return {
        ...state,
        pending: false,
        downloadEligibilityMembers: action.payload,
        downloadEligibilityMembersError: null,
      };
    case DOWNLOAD_ELIGIBILTY_MEMBERS_FAILURE:
      return {
        ...state,
        pending: false,
        downloadEligibilityMembers: null,
        downloadEligibilityMembersError: action.payload,
      };
    case PROCESS_ELIGIBILITY_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PROCESS_ELIGIBILITY_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        processEligibilityReport: action.payload,
        processEligibilityReportError: null,
      };
    case PROCESS_ELIGIBILITY_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        processEligibilityReport: null,
        processEligibilityReportError: action.payload,
      };
    case TRANSFER_ELIGIBILITY_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case TRANSFER_ELIGIBILITY_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        transferEligibilityReport: action.payload,
        transferEligibilityReportError: null,
      };
    case TRANSFER_ELIGIBILITY_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        transferEligibilityReport: null,
        transferEligibilityReportError: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
