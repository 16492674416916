import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';

import {
  fetchMembersSuccess,
  fetchMembersFailure,
  fetchMembersConnectionsSuccess,
  fetchMembersConnectionsFailure,
  fetchMembersConnectionsCountFailure,
  fetchMembersConnectionsCountSuccess,
  fetchMemberLogsCountFailure,
  fetchMemberLogsCountSuccess,
} from './actions';
import {
  FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST,
  FETCH_MEMBERS_CONNECTIONS_REQUEST,
  FETCH_MEMBERS_LOGS_COUNT_REQUEST,
  FETCH_MEMBERS_REQUEST,
  GET_MEMBER_LOGS_REQUEST,
} from './actionTypes';
import {
  getMemberProfileSuccess,
  getMemberProfileFailure,
  fetchTopicsSuccess,
  fetchTopicsFailure,
  postRedeemPartnerCodeSuccess,
  postRedeemPartnerCodeFailure,
  fetchMembersCountSuccess,
  fetchMembersCountFailure,
  getMemberLogsSuccess,
  getMemberLogsFailure,
} from './actions';
import {
  FETCH_TOPICS_REQUEST,
  GET_MEMBER_PROFILE_REQUEST,
  POST_REDEEM_PARTNER_CODE_REQUEST,
  FETCH_MEMBERS_COUNT_REQUEST,
} from './actionTypes';

function* fetchMembersSaga(action: any): any {
  try {
    yield delay(300);
    const res = Api.fetchMembers(action.payload);
    const response = yield call(res);

    yield put(fetchMembersSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMembersFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchMembersConnectionsSaga(action: any): any {
  try {
    yield delay(50);
    const res = Api.fetchMembersConnections(action.payload);
    const response = yield call(res);
    yield put(fetchMembersConnectionsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMembersConnectionsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchMembersConnectionsCountSaga(action: any): any {
  try {
    yield delay(50);
    const res = Api.fetchMembersConnectionsCount(action.payload);
    const response = yield call(res);

    yield put(fetchMembersConnectionsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMembersConnectionsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}
function* getMemberProfileSaga(action: any): any {
  try {
    const response = yield call(Api.getMemberProfile(action.payload));
    yield put(getMemberProfileSuccess(response));
  } catch (e: any) {
    yield put(getMemberProfileFailure(JSON.parse(e)));
  }
}

function* fetchTopicsSaga(): any {
  try {
    const response = yield call(Api.fetchTopics);
    yield put(fetchTopicsSuccess(response));
  } catch (e: any) {
    console.error('onBoardingSaga:fetchTopics::error--', e);
    yield put(fetchTopicsFailure(JSON.parse(e)));
  }
}

function* postRedeemPartnerCode(action: any): any {
  try {
    const response = yield call(Api.postRedeemPartnerCode(action.payload));
    yield put(postRedeemPartnerCodeSuccess(response));
  } catch (e: unknown) {
    console.error('home:postRedeemPartnerCode::error--', e);
    yield put(
      postRedeemPartnerCodeFailure({
        redeemPartnerCodeError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* fetchMembersCountSaga(action: any): any {
  try {
    yield delay(50);
    const res = Api.getMembersCount(action.payload);
    const response = yield call(res);

    yield put(fetchMembersCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMembersCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* getMemberLogsSaga(action: any): any {
  try {
    const response = yield call(Api.getMembersLogs(action.payload));
    yield put(getMemberLogsSuccess(response));
  } catch (e: any) {
    yield put(getMemberLogsFailure(JSON.parse(e)));
  }
}

function* fetchMemberLogsCountSaga(action: any): any {
  try {
    const res = Api.getMembersLogsCount(action.payload);
    const response = yield call(res);
    yield put(fetchMemberLogsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchMemberLogsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* MembersSaga() {
  yield all([
    takeLatest(FETCH_MEMBERS_REQUEST, fetchMembersSaga),
    takeLatest(FETCH_MEMBERS_CONNECTIONS_REQUEST, fetchMembersConnectionsSaga),
    takeLatest(FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST, fetchMembersConnectionsCountSaga),
    takeLatest(GET_MEMBER_PROFILE_REQUEST, getMemberProfileSaga),
    takeLatest(FETCH_TOPICS_REQUEST, fetchTopicsSaga),
    takeLatest(POST_REDEEM_PARTNER_CODE_REQUEST, postRedeemPartnerCode),
    takeLatest(FETCH_MEMBERS_COUNT_REQUEST, fetchMembersCountSaga),
    takeLatest(GET_MEMBER_LOGS_REQUEST, getMemberLogsSaga),
    takeLatest(FETCH_MEMBERS_LOGS_COUNT_REQUEST, fetchMemberLogsCountSaga),
  ]);
}
export default MembersSaga;
