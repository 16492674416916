import {
  FETCH_TOS_REQUEST,
  FETCH_TERMS_OF_SERVICE_TOS_SUCCESS,
  FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS,
  FETCH_ABOUT_LOC_TOS_SUCCESS,
  FETCH_PRIVACY_POLICY_TOS_SUCCESS,
  FETCH_TOS_FAILURE,
  UPDATE_TOS_REQUEST,
  UPDATE_TOS_SUCCESS,
  UPDATE_TOS_FAILURE,
  FETCH_LISTENER_FAQ_SUCCESS,
  FETCH_PEER_FAQ_SUCCESS,
  FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS,
} from './action-types';

import { TosActions, TosState } from './types';

const initialState: TosState = {
  pending: false,
  aboutTos: null,
  clientAdmin: null,
  Policy: null,
  termsOfService: null,
  listenerFaq: null,
  systemWelcomeVideo: null,
  error: null,
};

const Reducer = (state = initialState, action: TosActions) => {
  switch (action.type) {
    case FETCH_TOS_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FETCH_ABOUT_LOC_TOS_SUCCESS:
      return {
        ...state,
        pending: false,
        aboutTos: action.payload,
        error: null,
      };
    case FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS:
      return {
        ...state,
        pending: false,
        clientAdmin: action.payload,
        error: null,
      };
    case FETCH_PRIVACY_POLICY_TOS_SUCCESS:
      return {
        ...state,
        pending: false,
        Policy: action.payload,
        error: null,
      };
    case FETCH_TERMS_OF_SERVICE_TOS_SUCCESS:
      return {
        ...state,
        pending: false,
        termsOfService: action.payload,
        error: null,
      };
    case FETCH_LISTENER_FAQ_SUCCESS:
      return {
        ...state,
        pending: false,
        listenerFaq: action.payload,
        error: null,
      };
    case FETCH_PEER_FAQ_SUCCESS:
      return {
        ...state,
        pending: false,
        peersFaq: action.payload,
        error: null,
      };
    case FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS:
      return {
        ...state,
        pending: false,
        systemWelcomeVideo: action.payload,
        error: null,
      };
    case FETCH_TOS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_TOS_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case UPDATE_TOS_SUCCESS:
      return {
        ...state,
        pending: false,
        TOSs: action.payload.TOSs,
        error: null,
      };
    case UPDATE_TOS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
