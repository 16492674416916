import { useSearchQueries } from '../../hooks/useSearch';
import moment from 'moment';
import Header from '../../components/common/global-top-bar';
import { Pagination } from '../../components/common';
import { useState } from 'react';

export const SearchQueriesPage = () => {
  const pageSize = 100;
  const { data: searchQueries } = useSearchQueries();
  const [currentPage, setCurrentPage] = useState(0);

  if (!searchQueries) {
    return <div>Loading...</div>;
  }
  const pageCount = Math.ceil(searchQueries.length / pageSize);

  return (
    <>
      <Header heading="Search Queries" />
      <h3 className="text-2xl font-semibold mx-8 mt-4">Total Queries: {searchQueries.length}</h3>
      <Pagination pageCount={pageCount} updatePageNumber={(page) => setCurrentPage(page)} />
      <table className="table mx-8 mt-4">
        <thead>
          <tr>
            <th>Id</th>
            <th>Created At</th>
            <th>Query</th>
            <th>Scores</th>
          </tr>
        </thead>
        <tbody>
          {searchQueries
            .sort((search1, search2) => {
              return moment(search2.created_at).diff(search1.created_at);
            })
            .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
            .map((query, index) => {
              return (
                <tr key={index}>
                  <td className="min-w-20">{query.id}</td>
                  <td className="min-w-20">{moment(query.created_at).format('MM/DD/YYYY')}</td>
                  <td className="px-10 font-semibold">{query.query}</td>
                  <td className="min-w-52">
                    {query.score_results?.map((result, idx) => (
                      <span key={result}>
                        {result}
                        {query.score_results?.length === idx + 1 ? '' : ', '}{' '}
                      </span>
                    ))}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
