import {
  POST_COMMS_REQUEST,
  POST_COMMS_SUCCESS,
  POST_COMMS_FAILURE,
  FETCH_ELIGILITY_REQUEST,
  FETCH_ELIGILITY_SUCCESS,
  FETCH_ELIGILITY_FAILURE,
  FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST,
  FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS,
  FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE,
  FETCH_COMMS_LIST_REQUEST,
  FETCH_COMMS_LIST_SUCCESS,
  FETCH_COMMS_LIST_FAILURE,
  UPDATE_COMMS_REQUEST,
  UPDATE_COMMS_SUCCESS,
  UPDATE_COMMS_FAILURE,
  REMOVE_COMMS_REQUEST,
  REMOVE_COMMS_SUCCESS,
  REMOVE_COMMS_FAILURE,
  POST_REPAIR_TEMPLATE_REQUEST,
  POST_REPAIR_TEMPLATE_SUCCESS,
  POST_REPAIR_TEMPLATE_FAILURE,
  POST_TEST_COMMS_REQUEST,
  POST_TEST_COMMS_SUCCESS,
  POST_TEST_COMMS_FAILURE,
  POST_CAMPAIGNS_REQUEST,
  POST_CAMPAIGNS_SUCCESS,
  POST_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  FETCH_CAMPAIGNS_LIST_SUCCESS,
  FETCH_CAMPAIGNS_LIST_FAILURE,
  UPDATE_CAMPAIGNS_REQUEST,
  UPDATE_CAMPAIGNS_SUCCESS,
  UPDATE_CAMPAIGNS_FAILURE,
  REMOVE_CAMPAIGNS_REQUEST,
  REMOVE_CAMPAIGNS_SUCCESS,
  REMOVE_CAMPAIGNS_FAILURE,
  POST_TEST_CAMPAIGNS_REQUEST,
  POST_TEST_CAMPAIGNS_SUCCESS,
  POST_TEST_CAMPAIGNS_FAILURE,
} from './action-types';

import { HomeActions, TemplateInfoState } from './types';

const initialState: TemplateInfoState = {
  pending: false,
  Comms: [],
  CommsList: [],
  UpdateComms: null,
  RemoveComms: null,
  Campaigns: [],
  CampaignsList: [],
  UpdateCampaigns: null,
  RemoveCampaigns: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        Comms: action.payload,
        error: null,
      };
    case POST_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ELIGILITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ELIGILITY_SUCCESS:
      return {
        ...state,
        pending: false,
        Comms: action.payload,
        error: null,
      };
    case FETCH_ELIGILITY_FAILURE:
      return {
        ...state,
        pending: false,
        Comms: null,
        error: action.payload.error,
      };
    case FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS:
      return {
        ...state,
        pending: false,
        SystemMessageGroups: action.payload,
        error: null,
      };
    case FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_COMMS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_COMMS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        CommsList: action.payload.CommsList,
        error: null,
      };
    case FETCH_COMMS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateComms: action.payload,
        error: null,
      };
    case UPDATE_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateComms: null,
        error: action.payload.error,
      };
    case REMOVE_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveComms: action.payload,
        error: null,
      };
    case REMOVE_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveComms: null,
        error: action.payload.error,
      };
    case POST_REPAIR_TEMPLATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_REPAIR_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        RepairTemplate: action.payload,
        error: null,
      };
    case POST_REPAIR_TEMPLATE_FAILURE:
      return {
        ...state,
        pending: false,
        RepairTemplate: null,
        error: action.payload.error,
      };

    case POST_TEST_COMMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TEST_COMMS_SUCCESS:
      return {
        ...state,
        pending: false,
        testComms: action.payload,
        error: null,
      };
    case POST_TEST_COMMS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        pending: false,
        CreateCampaigns: action.payload,
        error: null,
      };
    case POST_CAMPAIGNS_FAILURE:
      return {
        ...state,
        pending: false,

        error: action.payload.error,
      };

    case FETCH_CAMPAIGNS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        CampaignsList: action.payload.CampaignsList,
        error: null,
      };
    case FETCH_CAMPAIGNS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateCampaigns: action.payload,
        error: null,
      };
    case UPDATE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case REMOVE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveCampaigns: action.payload,
        error: null,
      };
    case REMOVE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveCampaigns: null,
        error: action.payload.error,
      };

    case POST_TEST_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TEST_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        pending: false,
        testCampaigns: action.payload,
        error: null,
      };
    case POST_TEST_CAMPAIGNS_FAILURE:
      return {
        ...state,
        pending: false,
        testCampaigns: null,
        testCampaignsError: action.payload,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
