import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import Spinner from '../../components/common/spinner';
import Searchbar from '../../components/common/searchbar';
import { ToggleButton } from '../../components/common';
import EditResourceDetails from './resource-edit';
import { Resource, useResources } from '../../hooks/useResources';
import { normalizeMobilePhone } from '../../components/common/phone-validation';
import { useTagGroups } from '../../hooks/useTagGroups';
import { AreaLabel } from '@kindlyhuman/component-library';

const ResourceListing: React.FC = () => {
  const [includeHidden, setIncludeHidden] = useState(false);
  const [search, setSearch] = useState<string>('');
  const { data: resources, isLoading, refetch } = useResources(includeHidden, search);
  const { getById } = useTagGroups();

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <div>
      <Header heading={'Resources'} />
      <FiltersNew
        resources={resources}
        setSearch={setSearch}
        includeHidden={includeHidden}
        setIncludeHidden={setIncludeHidden}
      />
      {isLoading && <Spinner />}
      <div className="max-window-height-table overflow-y-auto px-7 border-b">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white sticky top-0">
            <tr className="text-left px-7">
              <th className="w-2/12" />
              <th className="w-2/12" />
              <th className="w-4/12">Name</th>
              <th className="w-4/12">Areas</th>
              <th className="w-3/12">Phone</th>
              <th className="w-3/12">URL</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {resources?.length ? (
              resources?.map((resource: Resource) => {
                return (
                  <>
                    <tr className="bg-gray-200 text-gray-dark text-left border-t-8 border-white">
                      <td className="px-5 pt-5 w-2/12">
                        <EditResourceDetails resource={resource} />
                      </td>
                      <td className="pt-5 w-2/12 pb-2">
                        {resource?.image_url ? (
                          <img width="60" src={resource?.image_url} alt="Resource Image" />
                        ) : (
                          <div className="w-24" />
                        )}
                      </td>
                      <td className="pt-5 w-4/12">
                        <div className="text-gray-dark font-extrabold truncate">{resource?.name}</div>
                      </td>
                      <td className="pt-5 w-5/12 flex flex-col gap-y-2">
                        {resource?.challenge_areas?.map((id) => {
                          // @ts-ignore
                          return <AreaLabel className="flex-nowrap" key={id} variant={getById(id)?.name} />;
                        })}
                      </td>
                      <td className="pt-5 w-3/12">{normalizeMobilePhone(resource?.phone_number?.replace('+1', ''))}</td>
                      <td className="pt-5 pl-1 w-3/12">
                        <div className="truncate">{resource?.url}</div>
                      </td>
                    </tr>
                    <tr className="bg-gray-200 text-gray-dark text-left h-6 group">
                      <td />
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate">{resource?.description}</div>
                      </td>
                      <td colSpan={1} />
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate">{resource?.long_description}</div>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="flex justify-center items-center">
                    <p className="text-gray-dark text-2xl">No Resources Found</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const FiltersNew: React.FC<{
  resources?: Resource[];
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  includeHidden: boolean;
  setIncludeHidden: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ resources, includeHidden, setIncludeHidden, setSearch }) => {
  const newResource = {
    name: '',
    description: '',
    phone_number: '',
    url: '',
    url_description: '',
    image_url: '',
    sort_weight: 0,
    status: 'live',
    text_number: '',
    notes: '',
    long_description: '',
    meta_keywords: '',
    challenge_areas: [],
    subject_areas: [],
  };

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7">
      <div className="flex items-center w-1/4 h-8">
        <Searchbar search={(data) => setSearch(data)} />
      </div>
      {resources?.length && (
        <div className="flex items-center text-center py-4 text-sm w-1/5">
          <p>
            Displaying 1-{resources.length} of {resources.length} matching
          </p>
        </div>
      )}
      <div className="flex items-center py-4 w-1/4">
        <span className="px-3 text-sm">Only Hidden</span>
        <ToggleButton
          id={'includeHidden'}
          status={includeHidden}
          initialstatus={false}
          setStatus={(data: boolean) => {
            data ? setIncludeHidden(true) : setIncludeHidden(false);
          }}
        />
      </div>
      <div className="my-auto">
        <EditResourceDetails resource={newResource} />
      </div>
    </div>
  );
};

export default ResourceListing;
