import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import CalendarPaginator from '../../components/calender-paginator';
import Header from '../../components/common/global-top-bar';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import { fetchClientsRequest, getClientDetailsRequest } from '../clients/clients-redux/actions';
import { fetchSnapshotRequest } from './client-portal-redux/actions';

type props = {
  snapShot: any;
  pending: boolean;
};
const SnapShot: React.FunctionComponent<props> = ({ snapShot, pending }): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const changedDate = (startdate: string, enddate: string) => {
    const input = {
      linkInput: {
        clientId: null,
      },
      payload: {
        start_date: startdate,
        end_date: enddate,
      },
    };

    dispatch(fetchSnapshotRequest(input));
  };

  const handleDownload = (date: any) => {
    const data =
      snapShot?.package_metrics.length > 0 &&
      snapShot.package_metrics.map((item: any) => {
        return [
          item.code,
          `${item.eligible} (${((item.eligible / (snapShot?.total_eligible_members || 100)) * 100).toFixed(2)}) %`,
          `${item.activated} (${item.activated_percentage} %)`,
          `${item.engaged} (${item.engaged_percentage} %)`,
          `${item.connected} (${item.connected_percentage} %)`,
        ];
      });
    const headings = [
      ' Group ID ',
      '  Eligible Members ',
      ' Activated Members ',
      ' Engaged Members ',
      '  Connected Members ',
    ];

    const csvData = [headings, ...data];

    let lineArray = csvData.map((infoArray) => {
      const line = infoArray.join(',');
      return line;
    });

    const csvContent = lineArray.join('\n');

    const downloadLink = document.createElement('a');
    const blob = new Blob(['\ufeff', csvContent]);
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `Snapshot-${date.startdate.format('YYYY-MM-DD')}-${date.enddate.format('YYYY-MM-DD')}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    dispatch(fetchClientsRequest({ include_packages: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {pending && <Spinner />}
      <Header heading="Snapshot" />
      <CalendarPaginator changedDate={changedDate} handleDownload={handleDownload} />
      <div className="max-window-height-group  overflow-y-auto  px-7 ">
        <div className=" w-1/4 py-7">
          <div className="text-gray-dark text-bold font-bold pr-2 border-bottom">Total Eligible Members</div>
          <div className="text-2xl font-bold py-7 blue-primary">{snapShot?.total_eligible_members || 0}</div>
        </div>
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 font-normal">Group ID</th>
              <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 font-normal">Group Name</th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                Eligible Members
              </th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                Activated Members
              </th>
              <th className="px-2 py-1  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                Engaged Members
              </th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                Connected Members
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {snapShot?.package_metrics.length > 0 &&
              snapShot.package_metrics.map((item: any) => {
                return (
                  <tr
                    role="button"
                    className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                  >
                    <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom">
                      <div className="truncate">{item.code}</div>
                    </td>
                    <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom">
                      <div className="truncate">{item.client_name}</div>
                    </td>
                    <td className="py-1 w-2/12 blue-primary border-bottom">
                      <div className="flex">
                        {/* <p className="text-gray-dark text-base">(20%)</p> */}
                        <p className="text-gray-dark text-bold font-bold pr-2">{item.eligible}</p>
                        <p className="text-gray-dark text-base">
                          ({((item.eligible / (snapShot?.total_eligible_members || 100)) * 100).toFixed(2)} %)
                        </p>
                      </div>
                    </td>
                    <td className="py-1 w-2/12 border-bottom">
                      <div className="flex">
                        {' '}
                        <p className="text-gray-dark text-bold font-bold pr-2">{item.activated}</p>
                        <p className="text-gray-dark text-base">({item.activated_percentage} %)</p>
                      </div>
                    </td>
                    <td className="py-1 w-2/12 border-bottom">
                      <div className="flex">
                        <p className="text-gray-dark text-bold font-bold pr-2">{item.engaged}</p>
                        <p className="text-gray-dark text-base">({item.engaged_percentage} %)</p>
                      </div>
                    </td>
                    <td className="py-1 w-2/12 border-bottom">
                      <div className="flex">
                        <p className="text-gray-dark text-bold font-bold pr-2">{item.connected}</p>
                        <p className="text-gray-dark text-base">({item.connected_percentage} %)</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    snapShot: state.ClientPortal.snapShot?.data || null,
    pending: state.ClientPortal.pending || null,
  };
};

export default connect(mapStateToProps)(SnapShot);
