import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';

import {
  fetchClientAdminstratorsFailure,
  postInviteClientAdminFailure,
  postInviteClientAdminSuccess,
  fetchClientAdminstratorsCountSuccess,
  fetchClientAdminstratorsCountFailure,
  deleteClientAdminAccessSuccess,
  deleteClientAdminAccessFailure,
  deleteRevokeClientAdminAccessSuccess,
  deleteRevokeClientAdminAccessFailure,
  fetchClientAdminstratorsSuccess,
  postInviteViaEmailSuccess,
  postInviteViaEmailFailure,
} from './actions';
import {
  FETCH_CLIENT_ADMINSTRATORS_REQUEST,
  FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST,
  POST_INVITE_CLIENT_ADMIN_REQUEST,
  DELETE_CLIENT_ADMIN_REQUEST,
  DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST,
  POST_INVITE_VIA_EMAIL_REQUEST,
} from './actionTypes';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* FetchClientAdminstratorsSaga(action: any): any {
  try {
    const response = yield call(Api.fetchAdmindetails(action.payload));

    yield put(fetchClientAdminstratorsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchClientAdminstratorsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* FetchClientAdminstratorsCountSaga(action: any): any {
  try {
    const response = yield call(Api.fetchAdminCount(action.payload));
    yield put(fetchClientAdminstratorsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchClientAdminstratorsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* PostInviteClientAdminSaga(action: any): any {
  try {
    const response = yield call(Api.resendAdminInvite(action.payload));
    yield put(postInviteClientAdminSuccess(response));
    toast.success('Email Sent Successfully');
  } catch (e: any) {
    const error = JSON.parse(e);
    error?.data?.description && toast.error(error.data.description);
    yield put(
      postInviteClientAdminFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* DeleteClientAdminAccess(action: any): any {
  try {
    const response = yield call(Api.deleteAdminAccess(action.payload));
    const { clientId } = action.payload;
    yield put(deleteClientAdminAccessSuccess(response));
    if (response.status === 200) {
      const response = yield call(Api.fetchAdmindetails({ clientId }));
      yield put(fetchClientAdminstratorsSuccess(response));
      toast.success('Admin deleted successfully');
    }
  } catch (e: unknown) {
    console.error('experience:DeleteClientAdminAccess::error--', e);
    toast.error('Admin deletion failed');
    yield put(
      deleteClientAdminAccessFailure({
        experiencesFavoritesError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* DeleteRevokeClientAdminAccessSaga(action: any): any {
  try {
    const { client_id } = action.payload.payload;
    const response = yield call(Api.revokeAdminAccess(action.payload));
    yield put(deleteRevokeClientAdminAccessSuccess(response));
    if (response.status === 200) {
      const response = yield call(Api.fetchAdmindetails({ clientId: client_id }));
      yield put(fetchClientAdminstratorsSuccess(response));
      toast.success('Admin access revoked successfully');
    }
  } catch (e: any) {
    const alertMessage = JSON.parse(e);
    toast.error(alertMessage.data?.description);
    yield put(
      deleteRevokeClientAdminAccessFailure({
        loginError: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* PostInviteViaEmailSaga(action: any): any {
  try {
    const response = yield call(Api.inviteViaEmail(action.payload));
    const { clientId } = action.payload;
    yield put(postInviteViaEmailSuccess(response));
    if (response.status === 200) {
      const response = yield call(Api.fetchAdmindetails({ clientId }));
      yield put(fetchClientAdminstratorsSuccess(response));
      toast.success('Invite Sent Successfully');
    }
  } catch (e: any) {
    const alertMessage = JSON.parse(e);
    yield put(postInviteViaEmailFailure(alertMessage));
    toast.error(alertMessage.data.description);
  }
}

function* ListenersSaga() {
  yield all([
    takeLatest(FETCH_CLIENT_ADMINSTRATORS_REQUEST, FetchClientAdminstratorsSaga),
    takeLatest(FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST, FetchClientAdminstratorsCountSaga),
    takeLatest(POST_INVITE_CLIENT_ADMIN_REQUEST, PostInviteClientAdminSaga),
    takeLatest(DELETE_CLIENT_ADMIN_REQUEST, DeleteClientAdminAccess),
    takeLatest(DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST, DeleteRevokeClientAdminAccessSaga),
    takeLatest(POST_INVITE_VIA_EMAIL_REQUEST, PostInviteViaEmailSaga),
  ]);
}

export default ListenersSaga;
