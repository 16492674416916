import {
  FETCH_SNAPSHOT_REQUEST,
  FETCH_SNAPSHOT_SUCCESS,
  FETCH_SNAPSHOT_FAILURE,
  FETCH_ENGAGEMENT_DETAILS_REQUEST,
  FETCH_ENGAGEMENT_DETAILS_SUCCESS,
  FETCH_ENGAGEMENT_DETAILS_FAILURE,
} from './actionTypes';

import { SnapshotActions, ClientPortalState } from './types';

const initialState: ClientPortalState = {
  pending: false,
  snapShot: [],
  enagagementDetails: [],
  error: null,
};

const Reducer = (state = initialState, action: SnapshotActions) => {
  switch (action.type) {
    case FETCH_SNAPSHOT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SNAPSHOT_SUCCESS:
      return {
        ...state,
        pending: false,
        snapShot: action.payload,
        error: null,
      };
    case FETCH_SNAPSHOT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ENGAGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ENGAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        enagagementDetails: action.payload,
        error: null,
      };
    case FETCH_ENGAGEMENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
