import NumericDisplayLarge from '../../components/common/numeric-header-label';
import { GlobalDashboardResponse } from '../../hooks/useReporting';
import { safeDiv, formatToMinutesSeconds } from './helpers';

type props = {
  reportingDataExtras?: GlobalDashboardResponse;
  reportingOverviewData?: GlobalDashboardResponse;
};

const GlobalDashboardEngagement = ({ reportingDataExtras, reportingOverviewData }: props) => {
  return (
    <div>
      <div className="w-full blue-primary text-3xl font-bold font-['Montserrat'] border-b-2 border-gray-dark-1 py-2">
        Peer Experience Engagement
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
        <NumericDisplayLarge
          bigNumber={reportingDataExtras?.experience_listen_count || 0}
          header={'Total # of Plays'}
          subHeader={'Plays are 10 seconds or longer'}
        />
        <NumericDisplayLarge
          bigNumber={safeDiv(
            reportingDataExtras?.experience_listen_count,
            reportingOverviewData?.engaged_members,
            false,
          )}
          header={'Avg. Plays per Member'}
          subHeader={'Total Plays / Engaged Members'}
        />
        <NumericDisplayLarge
          bigNumber={reportingDataExtras?.experience_favorite_count || 0}
          header={'Total # of Favorites'}
          subHeader={'Experiences Favorited by Members'}
        />
      </div>
      <div className="w-full blue-primary text-3xl font-bold font-['Montserrat'] border-b-2 border-gray-dark-1 pt-8 pb-2">
        Peer Listener Calls
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
        <NumericDisplayLarge
          bigNumber={reportingDataExtras?.calls_count || 0}
          header={'Completed Calls'}
          subHeader={'Calls that are at least 2 minutes long'}
          otherNumber={safeDiv(reportingDataExtras?.calls_count, reportingDataExtras?.calls_scheduled) + '%'}
          otherText={'scheduled calls'}
        />
        <NumericDisplayLarge
          bigNumber={reportingDataExtras?.callers_count || 0}
          header={'By Unique Members'}
          subHeader={'Unique Members attempting calls'}
        />
        <NumericDisplayLarge
          bigNumber={safeDiv(reportingDataExtras?.calls_count, reportingDataExtras?.callers_count, false)}
          header={'Avg. Calls per Member'}
          subHeader={'Average number of Calls per Unique Member'}
        />
        <NumericDisplayLarge
          bigNumber={formatToMinutesSeconds(
            parseInt(safeDiv(reportingDataExtras?.all_calls_length, reportingDataExtras?.calls_count, false)),
          )}
          header={'Avg Call Length'}
          subHeader={'Average # of Minutes per call'}
          otherNumber={safeDiv(reportingDataExtras?.all_calls_length, 3600, false) + ' hours'}
          otherText={'of total call time'}
        />
      </div>
      <div className="flex flex-row space-x-4">
        <span>
          <div className="w-full text-xl font-bold font-['Montserrat'] border-b border-gray-dark-1 pt-8 pb-2">
            Call Failures
          </div>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
            <NumericDisplayLarge
              bigNumber={reportingDataExtras?.calls_scheduled || 0}
              header={'Call Schedule Attempts'}
              subHeader={'Total number of attempted calls, including Connect Now'}
            />
            <NumericDisplayLarge
              bigNumber={reportingDataExtras?.failed_calls || 0}
              header={'Failed Calls'}
              subHeader={'Calls did not connect'}
              otherNumber={safeDiv(reportingDataExtras?.failed_calls, reportingDataExtras?.calls_scheduled) + '%'}
              otherText={'of scheduled calls'}
              color={'text-red'}
            />
            <NumericDisplayLarge
              bigNumber={reportingDataExtras?.passed_calls || 0}
              header={'Passed Calls'}
              subHeader={'Listener passed on the call'}
              otherNumber={safeDiv(reportingDataExtras?.passed_calls, reportingDataExtras?.calls_scheduled) + '%'}
              otherText={'of scheduled calls'}
              color={'text-red'}
            />
          </div>
        </span>
        <span>
          <div className="w-full text-xl font-bold font-['Montserrat'] border-b border-gray-dark-1 pt-8 pb-2">
            Call Escalations
          </div>
          <div className="w-full grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
            <NumericDisplayLarge
              bigNumber={reportingDataExtras?.escalated_calls || 0}
              header={'Calls Escalated to 911'}
              subHeader={'Total number of calls initiating a 911 escalation'}
              color={'text-red'}
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export default GlobalDashboardEngagement;
