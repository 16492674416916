import React, { useEffect, useState, useCallback } from 'react';
import { DropDown, Spinner } from '../../components/common';
import { SectionLabel, ToggleButton } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import HeadBack from './redirect';
import { connect, useDispatch } from 'react-redux';
import {
  getListenerDetailsRequest,
  getListenerDetailsSuccess,
  getListenerTagsRequest,
  removeListenerTagsRequest,
  updateListenerDetailsRequest,
  updateListenerTagsListRequest,
  updateListenerTagsRequest,
} from './listeners-redux/actions';
import SaveButton from '../clients/switch-bar-button';
import { fetchCommsListRequest } from '../templates/redux-templates/actions';
import Modal from '../../components/common/modal';
import { fetchTosRequest } from '../clients/text-blocks/text-blocks-redux/actions';
import { fetchTiersListRequest } from '../listener-tiers/redux-templates/actions';
import axios from 'axios';
import { fetchTagsGroupListRequest } from '../tags-management/redux-tags/actions';
import { paramsToObjects } from '../../components/common/create-params';
import TierModal from './components/tier-modal';
import EditDetails from './components/profile-edit';
import { ListenerDataType } from '../listener-reviewers/common/types';
import ProfileGradeCard from './common/profile-grade-card';
import { axiosGet } from '../../api/axios';
import { TagKey, useProfileTagGroups } from '../../hooks/useProfileTagGroups';
import { BackgroundTag } from '../../hooks/usePeers';
import { useListClients } from '../../hooks/useClients';
import { Resource, useResources } from '../../hooks/useResources';

const mime = require('mime');

type props = {
  Label: string;
  value: string;
};

type TagDisplay = {
  id: number;
  name: string;
  selected?: boolean;
};

const userStatus = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  under_review: 'Under Review',
  suspended: 'Suspended',
  banned: 'Banned',
};

const Label: React.FunctionComponent<props> = ({ Label, value }): JSX.Element => {
  return (
    <>
      <label className={` block leading-4 text-gray-dark mb-2`}>{Label}</label>
      <p className="text-gray-dark  text-sm font-extrabold ">{value}</p>
    </>
  );
};

export enum OnboardingStatus {
  PENDING = 'Pending',
  REVISIT = 'Revisit',
  DONE = 'Done',
  ERROR = 'Error',
}

type ListenerDetailProps = {
  userDataProp: any;
  comms: any;
  pending: boolean;
  tiersList: any;
  tags: any;
  listenersTags: any;
};

export const getListenerType = (state: string) => {
  if (['onboarding_peer', 'active_peer'].includes(state)) {
    return 'peer';
  } else if (state === 'active_listener') {
    return 'listener';
  } else if (state === 'upgrading_peer') {
    return 'listener';
  } else {
    return 'rejected';
  }
};

const PeerType = {
  onboarding_peer: 'Onboarding Peer',
  rejected_peer: 'Rejected Peer',
  active_peer: 'Peer',
  upgrading_peer: 'Peer Listener Onboarding',
  active_listener: 'Peer Listener',
};

type UserTraitTagsTypes = {
  [K in 'pronouns' | 'races' | 'genders' | 'family' | 'spirituality' | 'relationship']: BackgroundTag[];
};

const Profile: React.FunctionComponent<ListenerDetailProps> = ({
  userDataProp,
  pending,
  comms,
  tiersList,
  tags,
  listenersTags,
}): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const userId = params['listenerId'];
  const listenerType = params['listenerType'];
  const [userDetails, setUserDetails] = useState<any>();
  const [listenerDetails, setListenerDetails] = useState<any>();
  const [enableSaveOption, setEnableSaveOption] = useState(false);

  // Calculate the status of items that need approval. Relates to https://listenersoncall.atlassian.net/browse/LOC-2960
  // we can't calculate profile image status using the calculateApprovalStatus because it's a different logic
  let profileApprovedStatus = calculateProfileImageStatus(userDetails);
  let displayNameStatus = calculateApprovalStatus(
    userDetails?.display_name,
    userDetails?.proposed_display_name,
    userDetails?.display_name_rejected,
  );
  let aboutMeStatus = calculateApprovalStatus(
    userDetails?.listener_role?.about_me,
    userDetails?.listener_role?.proposed_about_me,
    userDetails?.listener_role?.about_me_rejected,
  );

  const [addTagsPopup, setAddTagsPopup] = useState<'PROFILE' | 'SORTING' | ''>('');

  const { getTagsByKey } = useProfileTagGroups();
  const [selectedProfileTags, setSelectedProfileTags] = useState<any>([]);
  const [selectedSortingTags, setSelectedSortingTags] = useState<any>([]);
  const [selectedBackgroundTags, setSelectedBackgroundTags] = useState<any>([]);

  const dispatch = useDispatch();
  const [userTraitTags, setUserTraitTags] = useState<UserTraitTagsTypes>({
    pronouns: [],
    races: [],
    genders: [],
    family: [],
    spirituality: [],
    relationship: [],
  });

  // TODO this should be view=summary to improve performance
  const { data: clients, isFetching: isClientsFetching } = useListClients();

  const { data: resources, isFetching: isResourcesFetching } = useResources(false);
  const [clientResources, setClientResources] = useState<Resource[] | null>(null);
  const clientResourceData = useResources(false, undefined, listenerDetails?.client_id || undefined, true);

  useEffect(() => {
    if (clientResourceData.data) {
      setClientResources(clientResourceData.data);
    } else {
      setClientResources(null);
    }
  }, [clientResourceData.data]);

  const allResources = [...(resources || []), ...(clientResources || [])];

  const initStates = useCallback((initalData) => {
    if (initalData) {
      setEnableSaveOption(false);
      setUserDetails(initalData);
      setListenerDetails(initalData.listener_role);
    }
  }, []);

  useEffect(() => {
    initStates(userDataProp);
  }, [initStates, userDataProp]);

  const normalizeInput = (value: string): string => {
    if (value) {
      // only allows 0-9 inputs
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;
      if (cvLength > 0) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      }
    }
    return '';
  };

  const setUserValue = (name: string, value: string | number) => {
    setEnableSaveOption(true);
    setUserDetails((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch(getListenerDetailsRequest({ id: userId }));
    dispatch(fetchCommsListRequest({ only_group_key: 'listener_profile_rejected' }));
    dispatch(fetchTosRequest({ type: 'app' }));
    dispatch(fetchTiersListRequest({ all_tier_versions: true }));
    dispatch(
      fetchTagsGroupListRequest({
        keys: 'BACKGROUND,SORTING,PROFILE,PRONOUN,RACE/ETHNICITY,GENDER,RELATIONSHIP,SPIRITUALITY,FAMILY',
      }),
    );
    dispatch(getListenerTagsRequest({ id: listenerId, payload: { include_hidden: true } }));
    return () => {
      dispatch(getListenerDetailsSuccess({ listened: null }));
    };
  }, [userId, dispatch, listenerId]);

  const formatPhone = useCallback(
    (mobilePhone: string) => {
      if (!mobilePhone) {
        return;
      }
      const phone = mobilePhone.replace('+1', '');
      setUserDetails((prev: any) => ({ ...prev, mobile_phone: normalizeInput(phone) }));
    },
    [setUserDetails],
  );

  useEffect(() => {
    formatPhone(userDetails?.mobile_phone);
  }, [userDetails?.mobile_phone, userDetails?.approvedState, userDetails?.profileUrl, formatPhone]);

  // HACKHACKHACK: the existing Redux store doesn't user the listeners /profile API endpoint
  // so we're unable to get the `notes` key from the generic users endpoint, so this is a
  // one-off fix to get the `notes` upon a page load (updating was working fine previously)
  useEffect(() => {
    axiosGet(`/listeners/${listenerId}/profile`, {})
      .then((res) => {
        setListenerDetails((prev: any) => ({
          ...prev,
          // I dunno why it's an array either
          notes: res.data[0].notes,
        }));
      })
      .catch(() => {});
  }, [listenerId]);

  // process user trait tags
  useEffect(() => {
    if (tags && tags.length > 0) {
      const getTags = (type: string) => {
        const filteredTags = tags?.find((tag: any) => tag.name === type);
        return filteredTags ? filteredTags.tags.map((tag: any) => tag.name) : [];
      };

      setUserTraitTags({
        pronouns: getTags('Pronoun'),
        races: getTags('Race/Ethnicity'),
        genders: getTags('Gender'),
        family: getTags('Family'),
        spirituality: getTags('Spirituality'),
        relationship: getTags('Relationship'),
      });
    }
  }, [tags, setUserTraitTags]);

  const updateTags = useCallback(
    (key: TagKey, selectedTags: BackgroundTag[], setSelectedTags: (tags: BackgroundTag[]) => void) => {
      const tags: TagDisplay[] = getTagsByKey(key) || [];
      const tagIds = tags.map((tag) => tag.id);
      const newTags = listenersTags.filter((tte: BackgroundTag) => tagIds?.includes(tte.tag.id));

      if (key === 'BACKGROUND') {
        const newApprovedTags = newTags.filter((tte: BackgroundTag) => tte.status === 'APPROVED');
        const oldApprovedTags = selectedTags.filter((tte: BackgroundTag) => tte.status === 'APPROVED');
        if (newTags.length !== selectedTags.length || newApprovedTags.length !== oldApprovedTags.length) {
          setSelectedTags(newTags);
        }
      } else if (newTags.length !== selectedTags.length) {
        setSelectedTags(newTags);
      }
    },
    [getTagsByKey, listenersTags],
  );

  useEffect(() => {
    if (listenersTags && listenersTags.length > 0) {
      updateTags('PROFILE', selectedProfileTags, setSelectedProfileTags);
      updateTags('SORTING', selectedSortingTags, setSelectedSortingTags);
      updateTags('BACKGROUND', selectedBackgroundTags, setSelectedBackgroundTags);
    }
  }, [getTagsByKey, listenersTags, selectedBackgroundTags, selectedProfileTags, selectedSortingTags, updateTags]);

  const setToggle = (status: any, id: any) => {
    status
      ? dispatch(
          updateListenerTagsListRequest({
            id: listenerId,
            payload: { id: +userId, tag_id: id },
          }),
        )
      : dispatch(removeListenerTagsRequest({ id: +listenerId, tag_id: id }));
  };

  const handleSave = () => {
    const listenerDetailsPayload: ListenerDataType = {
      ...listenerDetails,
    };
    const omitListenerFields: string[] = [
      'about_me_rejected',
      'proposed_about_me',
      'about_me',
      'is_active',
      'is_peer_active',
    ];
    omitListenerFields.forEach((field: string) => {
      delete listenerDetailsPayload[field as keyof ListenerDataType];
    });

    const userDetailsPayload = {
      status: userDetails.status,
      is_text_compatible_phone: userDetails.is_text_compatible_phone,
      is_mms_compatible_phone: userDetails.is_mms_compatible_phone,
    };

    // We need to check if the resource_id is valid for the client_id
    // If the resource belongs to a client and that client is not affiliated with the listener, we need to nullify the resource_id
    let { resource_id, client_id } = listenerDetailsPayload;
    const matchingResource = allResources?.find((resource) => Number(resource.id) === Number(resource_id));
    if (!matchingResource || (matchingResource.client_id && matchingResource.client_id !== client_id)) {
      resource_id = null;
    }

    const payload = {
      id: userId,
      listenerId,
      payload: {
        ...userDetailsPayload,
        listener_role: {
          ...listenerDetailsPayload,
          client_id: listenerDetailsPayload.client_id || null,
          resource_id: resource_id || null,
        },
      },
    };

    dispatch(updateListenerDetailsRequest(payload));
  };

  const handleBackGroundToggle = (id: number, tag_id: number, is_approved: boolean) => {
    const payload = {
      id: listenerId,
      tag_id: id,
      payload: {
        tag_id,
        status: is_approved ? 'APPROVED' : 'UNGRADED',
      },
    };
    dispatch(updateListenerTagsRequest(payload));
  };

  const handleDelete = (id: number) => {
    dispatch(removeListenerTagsRequest({ id: listenerId, tag_id: id }));
  };

  const getSectionLabel = (): string => {
    switch (listenerDetails?.state) {
      case 'active_peer':
        return 'Peer';
      case 'rejected_peer':
        return 'Rejected Peer';
      case 'onboarding_peer':
        return 'Onboarding Peer';
      case 'upgrading_peer':
        return 'Upgrading Peer';
      case 'active_listener':
        return 'Peer Listener';
      default:
        return 'Unknown';
    }
  };

  const isPeerListener = (): boolean => {
    return ['upgrading_peer', 'active_listener'].includes(listenerDetails?.state);
  };

  const getSelectedTagOptions = (key: 'PROFILE' | 'SORTING') => {
    const tagList: TagDisplay[] | undefined = getTagsByKey(key);

    let selectedTags: BackgroundTag[] = [];
    if (key === 'PROFILE') {
      selectedTags = selectedProfileTags || [];
    } else if (key === 'SORTING') {
      selectedTags = selectedSortingTags || [];
    }

    if (tagList && selectedTags) {
      const selectedTagIds = selectedTags.map((selectedTag: BackgroundTag) => selectedTag.tag.id);
      tagList.forEach((item: TagDisplay) => {
        if (item) {
          item.selected = selectedTagIds.includes(item?.id);
        }
      });
    }
    return tagList;
  };

  return (
    <div>
      {pending && <Spinner />}
      <Header heading={<HeadBack listenerName={userDetails?.first_name || ''} />} />
      <SwitchBar
        heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions}
        position={0}
        forwardParams
        optionalButton={
          <div className={`w-1/12 ${!enableSaveOption ? 'cursor-not-allowed   ' : ''} `}>
            <SaveButton onSave={handleSave} disable={!enableSaveOption} />
          </div>
        }
      />

      <div className="max-window-height overflow-y-auto flex justify-between ">
        <div className="w-1/2 ">
          <div className="px-7">
            <SectionLabel Label={getSectionLabel()} />
          </div>
          <ProfileGradeCard
            currentPhotoUrl={listenerDetails?.current_profile_photo?.file_url}
            proposedPhotoUrl={
              listenerDetails?.proposed_profile_photo?.status != 'rejected'
                ? listenerDetails?.proposed_profile_photo?.file_url
                : null
            }
            profilePicVerified={!!listenerDetails?.current_profile_photo}
            lastName={userDetails?.last_name || ''}
            firstName={userDetails?.first_name || ''}
            displayName={userDetails?.display_name || ''}
            comms={comms}
            peerState={listenerDetails?.state}
            userId={userDetails?.id}
            listenerId={listenerDetails?.id}
            switchboardArea="profile"
          />
          <Modal
            isModel={addTagsPopup?.length > 0}
            InnerComponent={
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                        Add Listener Tags
                      </h3>

                      <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                        {addTagsPopup &&
                          (getSelectedTagOptions(addTagsPopup) || []).map((item: any, index: number) => {
                            return (
                              <button
                                key={`addtag_${item.id}`}
                                className={`${
                                  item.selected ? ' bg-citrus ' : ' bg-gray-background-light '
                                } text-white rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                                onClick={() => {
                                  setToggle(!item.selected, item.id);
                                }}
                              >
                                <div className="flex px-3 py-2">
                                  <div className="grow  pr-2">{item.name}</div>
                                </div>
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
                  <button
                    type="button"
                    className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
                    onClick={() => {
                      setAddTagsPopup('');
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <Label value={userDetails?.id} Label={'User ID#'} />
            </div>
            <EditDetails tags={userTraitTags} />
          </div>
          <div className="w-1/2 px-2"></div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <Label value={userDetails?.first_name} Label={'First Name'} />
            </div>

            <div className="w-1/2 px-2">
              <Label value={userDetails?.last_name} Label={'Last Name'} />
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-full px-2">
              <Label value={userDetails?.display_name} Label={'Display Name'} />
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-full px-2">
              <Label value={userDetails?.email_address} Label={'Email'} />
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <Label value={userDetails?.mobile_phone} Label={'Phone'} />
            </div>
            <div className="w-1/2 px-2">
              <Label Label="Time Zone" value={userDetails?.timezone} />
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <Label value={userDetails?.date_of_birth} Label={'DOB'} />
            </div>

            <div className="w-1/2 px-2">
              {/* <select className="w-full py-2 px-3  h-10 rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"> */}

              <Label Label={'Gender'} value={userDetails?.gender} />
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Primary Language'} value={listenerDetails?.primary_language} />
              </div>
            </div>

            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Pronouns'} value={userDetails?.pronoun} />
              </div>
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Race'} value={userDetails?.race} />
              </div>
            </div>

            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Spirituality'} value={userDetails?.spirituality} />
              </div>
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Relationship'} value={userDetails?.relationship} />
              </div>
            </div>

            <div className="w-1/2 px-2">
              <div>
                <Label Label={'Family'} value={userDetails?.family} />
              </div>
            </div>
          </div>

          <div className="flex justify-between px-7 py-4">
            <div className="w-1/2 px-2">
              <div>
                <Label Label={'How Did You Hear?'} value={userDetails?.how_did_you_hear} />
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 ">
          <div>
            <div className="px-7">
              <div className="w-full py-2 pr-2 flex justify-between">
                <TierModal />
              </div>
              <div className="w-full border-b gray-border-line">
                <div className="py-2 flow-root">
                  <h3 className="text-md text-gray-dark float-left">
                    Member Visibility ({listenerDetails?.peer_status === 'enabled' ? 'Auto' : 'Admin'}):
                  </h3>
                  <div className="float-right">
                    <div
                      className={`${
                        listenerDetails?.is_peer_active ? 'bg-citrus' : 'bg-redSalsa'
                      } px-4 py-1 text-white font-bold rounded-md text-base`}
                    >
                      {listenerDetails?.is_peer_active ? 'Visible' : 'Hidden'}
                      {listenerDetails?.can_take_calls
                        ? '/' + (listenerDetails?.is_active ? 'Callable' : 'Not Callable')
                        : null}
                    </div>
                  </div>
                </div>
                <div className="py-2 flow-root">
                  <div className="text-md text-gray-dark float-left">Peer Visible to Members:</div>
                  <div className="float-right">
                    <ToggleButton
                      id={'peerStatus'}
                      status={listenerDetails?.peer_status === 'enabled' ? true : false}
                      setStatus={(data) => {
                        setListenerDetails((prev: any) => ({
                          ...prev,

                          peer_status: data ? 'enabled' : 'disabled',
                        }));

                        setEnableSaveOption(true);
                      }}
                    ></ToggleButton>
                  </div>
                </div>
                <div className="py-2 flow-root">
                  <div className="text-md text-gray-dark float-left">Listener Can Take Calls</div>
                  <div className="float-right">
                    <ToggleButton
                      id={'listenerStatus'}
                      status={
                        listenerDetails?.status === 'enabled' &&
                        ['upgrading_peer', 'active_listener'].includes(listenerDetails?.state)
                          ? true
                          : false
                      }
                      setStatus={(data) => {
                        setListenerDetails((prev: any) => ({
                          ...prev,

                          status: data ? 'enabled' : 'disabled',
                        }));

                        setEnableSaveOption(true);
                      }}
                      disabled={['onboarding_peer', 'rejected_peer', 'active_peer'].includes(listenerDetails?.state)}
                    ></ToggleButton>
                  </div>
                </div>
                <div className="py-2 flow-root">
                  <div className="text-md text-gray-dark float-left">SMS Enabled</div>
                  <div className="float-right">
                    <ToggleButton
                      id={'smsEnabled'}
                      status={userDetails?.is_text_compatible_phone}
                      setStatus={(data) => {
                        setUserValue('is_text_compatible_phone', data);
                      }}
                    ></ToggleButton>
                  </div>
                </div>
                <div className="py-2 flow-root">
                  <div className="text-md text-gray-dark float-left">MMS Enabled</div>
                  <div className="float-right">
                    <ToggleButton
                      id={'mmsEnabled'}
                      status={userDetails?.is_mms_compatible_phone}
                      setStatus={(data) => {
                        setUserValue('is_mms_compatible_phone', data);
                      }}
                    ></ToggleButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <div>
                  <DropDown
                    Label={'Peer State'}
                    value={listenerDetails?.state}
                    setValue={(data) => {
                      setListenerDetails((prev: any) => ({
                        ...prev,
                        state: data,
                      }));
                      setEnableSaveOption(true);
                    }}
                  >
                    {Object.keys(PeerType).map((item: string) => (
                      <option key={item} value={item}>
                        {/* @ts-ignore */}
                        {PeerType[item]}
                      </option>
                    ))}
                  </DropDown>
                </div>
              </div>
              <div className="w-1/2 px-2">
                <DropDown
                  Label={'User Status'}
                  value={userDetails?.status}
                  setValue={(data) => {
                    setUserValue('status', data);
                  }}
                >
                  {Object.keys(userStatus).map((item: string) => (
                    <option key={item} value={item}>
                      {/* @ts-ignore */}

                      {userStatus[item]}
                    </option>
                  ))}
                </DropDown>
              </div>
            </div>

            <div className="flex justify-between px-7 py-2"></div>
            {listenerDetails?.state !== 'peer_rejected' ? (
              <div className="w-1/2 pl-9">
                <div className="grid grid-cols-3 gap-4">
                  {isPeerListener() && (
                    <>
                      <label className="leading-4 text-gray-dark flex items-center col-span-2">Listener Guide</label>

                      <div
                        className={`${
                          listenerDetails?.has_completed_listener_guide
                            ? 'stacked-bar-green-primary'
                            : 'stacked-bar-orange-primary'
                        } w-22 h-7 rounded-md text-white font-medium flex items-center justify-center`}
                      >
                        {listenerDetails?.has_completed_listener_guide ? 'Done' : 'Pending'}
                      </div>
                    </>
                  )}
                  {isPeerListener() && (
                    <>
                      <label className="leading-4 text-gray-dark flex items-center col-span-2">About Me</label>

                      <div
                        className={`${
                          aboutMeStatus === OnboardingStatus.DONE
                            ? 'stacked-bar-green-primary'
                            : 'stacked-bar-orange-primary'
                        } w-22 h-7 rounded-md text-white font-medium flex items-center justify-center`}
                      >
                        {aboutMeStatus}
                      </div>
                    </>
                  )}

                  <label className="leading-4 text-gray-dark flex items-center col-span-2">Profile Photo</label>
                  <div
                    className={`${
                      profileApprovedStatus === OnboardingStatus.DONE
                        ? 'stacked-bar-green-primary'
                        : 'stacked-bar-orange-primary'
                    } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                  >
                    {profileApprovedStatus}
                  </div>
                  <label className="leading-4 text-gray-dark flex items-center col-span-2">Display Name</label>
                  <div
                    className={`${
                      displayNameStatus === OnboardingStatus.DONE
                        ? 'stacked-bar-green-primary'
                        : 'stacked-bar-orange-primary'
                    } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                  >
                    {displayNameStatus}
                  </div>
                  {isPeerListener() && (
                    <>
                      {' '}
                      <label className="leading-4 text-gray-dark flex items-center col-span-2 ">
                        Listening Academy
                      </label>
                      <div
                        className={`${
                          listenerDetails?.required_training_complete
                            ? 'stacked-bar-green-primary'
                            : 'stacked-bar-orange-primary'
                        } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                      >
                        {listenerDetails?.required_training_complete ? 'Done' : 'Pending'}
                      </div>{' '}
                    </>
                  )}

                  <label className="leading-4 text-gray-dark flex items-center col-span-2">Experiences</label>

                  <div
                    className={`${
                      listenerDetails?.has_required_experiences
                        ? 'stacked-bar-green-primary'
                        : 'stacked-bar-orange-primary'
                    } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                  >
                    {listenerDetails?.has_required_experiences ? 'Done' : 'Pending'}
                  </div>

                  {isPeerListener() && (
                    <>
                      {' '}
                      <label className="leading-4 text-gray-dark flex items-center col-span-2">Office Hours</label>
                      <div
                        className={`${
                          listenerDetails?.has_required_office_hours
                            ? 'stacked-bar-green-primary'
                            : 'stacked-bar-orange-primary'
                        } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                      >
                        {listenerDetails?.has_required_office_hours ? 'Done' : 'Pending'}
                      </div>
                      <label className="leading-4 text-gray-dark flex items-center col-span-2">Background Check</label>
                      <div
                        className={`${
                          listenerDetails?.background_check_verified
                            ? 'stacked-bar-green-primary'
                            : 'stacked-bar-orange-primary'
                        } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                      >
                        {listenerDetails?.background_check_verified ? 'Done' : 'Pending'}
                      </div>
                    </>
                  )}

                  <label className="leading-4 text-gray-dark flex items-center col-span-2">Payment Method</label>

                  <div
                    className={`${
                      listenerDetails?.stripe_user_id ? 'stacked-bar-green-primary' : 'stacked-bar-orange-primary'
                    } w-22 h-7 rounded-md stacked-bar-orange-primary text-white font-medium flex items-center justify-center`}
                  >
                    {listenerDetails?.stripe_user_id ? 'Done' : 'Pending'}
                  </div>

                  {isPeerListener() && (
                    <>
                      {' '}
                      <label className="leading-4 text-gray-dark flex items-center col-span-2">
                        Lobby Subscription
                      </label>
                      <ToggleButton
                        id={'lobbySubscription'}
                        status={listenerDetails?.has_lobby_subscription}
                        setStatus={(data) => {
                          setListenerDetails((prev: any) => ({
                            ...prev,

                            has_lobby_subscription: data,
                          }));

                          setEnableSaveOption(true);
                        }}
                      ></ToggleButton>{' '}
                    </>
                  )}

                  <label className="leading-4 text-gray-dark flex items-center col-span-2">Volunteer</label>

                  <ToggleButton
                    id={'volunteer'}
                    status={listenerDetails?.is_volunteer}
                    setStatus={(data) => {
                      setListenerDetails((prev: any) => ({
                        ...prev,

                        is_volunteer: data,
                      }));

                      setEnableSaveOption(true);
                    }}
                  ></ToggleButton>
                </div>
              </div>
            ) : null}

            <div className="w-full px-7 ">
              <p className="py-2 font-bold text-gray-dark text-left">Relationships</p>
              <hr className="bg-gray-dark"></hr>
              <div className="flex justify-between mt-2">
                <div className="w-1/2 px-2">
                  <div>
                    <DropDown
                      Label={'Group Affiliation'}
                      value={listenerDetails?.client_id ?? ''}
                      setValue={(data) => {
                        setListenerDetails((prev: any) => ({
                          ...prev,
                          client_id: data,
                        }));
                        setEnableSaveOption(true);
                      }}
                    >
                      <>
                        {isClientsFetching && <option>Loading...</option>}
                        {clients &&
                          clients.map((client: any) => (
                            <option key={`Client ${client.id}`} value={client.id}>
                              {client.name} (#{client.id})
                            </option>
                          ))}
                        <option value="">None</option>
                      </>
                    </DropDown>
                  </div>
                </div>
                <div className="w-1/2 px-2">
                  <DropDown
                    Label={'Resource Affiliation'}
                    value={
                      allResources?.find((resource) => Number(resource.id) === Number(listenerDetails?.resource_id))
                        ? listenerDetails?.resource_id
                        : ''
                    }
                    setValue={(data) => {
                      setListenerDetails((prev: any) => ({
                        ...prev,
                        resource_id: data,
                      }));
                      setEnableSaveOption(true);
                    }}
                  >
                    <>
                      {isResourcesFetching && <option>Loading...</option>}
                      {resources &&
                        resources.map((resource: any) => (
                          <option key={`Resource ${resource.id}`} value={resource.id}>
                            {resource.name} (#{resource.id})
                          </option>
                        ))}
                      {clientResources && (
                        <>
                          <option disabled>CLIENT RESOURCES:</option>
                          {clientResources.map((resource: any) => (
                            <option key={`Resource ${resource.id}`} value={resource.id}>
                              {resource.name} (#{resource.id})
                            </option>
                          ))}
                        </>
                      )}
                      <option value="">None</option>
                    </>
                  </DropDown>
                </div>
              </div>
            </div>

            <div className="w-full px-7 ">
              <div className="flex justify-between">
                <p className="py-2 font-bold text-gray-dark text-left">Profile Tags</p>
                <button
                  className="py-2 font-bold text-right text-blue-primary "
                  onClick={() => {
                    setAddTagsPopup('PROFILE');
                  }}
                >
                  + Add
                </button>
              </div>
              <hr className="bg-gray-dark"></hr>
              <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                {selectedProfileTags.length > 0 ? (
                  selectedProfileTags.map((item: any, index: number) => {
                    return (
                      <button
                        key={`profile_${item.id}`}
                        className={`bg-citrus text-white rounded-md text-sm font-bold ${
                          index === 0 ? 'ml-2 mt-2' : ''
                        }`}
                      >
                        <div className="flex px-3 py-2">
                          <div className="grow  pr-2">{item.tag.name}</div>
                          <div
                            className="grow-0 "
                            onClick={() => {
                              setToggle(false, item.id);
                            }}
                          >
                            x
                          </div>
                        </div>
                      </button>
                    );
                  })
                ) : (
                  <p className="m-2"> No Profile Tags </p>
                )}
              </div>
            </div>
            <div className="w-full px-7 ">
              <div className="flex justify-between">
                <p className="py-2 font-bold text-gray-dark text-left">Background Tags</p>
              </div>
              <hr className="bg-gray-dark"></hr>
              <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                {selectedBackgroundTags.length > 0 ? (
                  selectedBackgroundTags.map((item: any, index: number) => {
                    return (
                      <div className="grid grid-cols-7 gap-0.5  py-2" key={`background_${item.id}`}>
                        <div className="col-span-3">
                          <button
                            className={`${
                              item?.status === 'APPROVED' ? ' bg-citrus ' : ' stacked-bar-orange-primary '
                            } text-white rounded-md text-sm font-bold ${index === 0 ? 'ml-2 mt-2' : ''} w-fit`}
                          >
                            <div className="flex px-3 py-2">
                              <div className="grow  pr-2">{item.tag.name}</div>
                              <div
                                className="grow-0 "
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                x
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="flex justify-center col-span-3 w-full">
                          {item?.media?.length > 0 ? (
                            <button
                              className="whitespace-nowrap w-full"
                              onClick={() => handleImage(item.media[0]?.file_url, item.media[0]?.file_key)}
                            >
                              <p className="underline cursor-pointer w-full truncate">
                                {item.media[0]?.file_name ? item.media[0]?.file_name : item.media[0]?.file_url}
                              </p>
                            </button>
                          ) : (
                            <p>No File Found </p>
                          )}
                        </div>
                        <div className="flex justify-end">
                          <ToggleButton
                            status={item?.status === 'APPROVED' ? true : false}
                            id={item.name}
                            setStatus={(data) => {
                              handleBackGroundToggle(item.id, item.tag.id, data);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="m-2"> No Background Tags </p>
                )}
              </div>
            </div>

            <div className="w-full px-7 ">
              <div className="flex justify-between">
                <p className="py-2 font-bold text-gray-dark text-left">Sorting Tags</p>
                <button
                  className="py-2 font-bold text-right text-blue-primary "
                  onClick={() => {
                    setAddTagsPopup('SORTING');
                  }}
                >
                  + Add
                </button>
              </div>
              <hr className="bg-gray-dark"></hr>
              <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                {selectedSortingTags.length > 0 ? (
                  selectedSortingTags.map((item: any, index: number) => {
                    return (
                      <button
                        key={`sorting_${item.id}`}
                        className={`bg-blue-primary text-white rounded-md text-sm font-bold ${
                          index === 0 ? 'ml-2 mt-2' : ''
                        }`}
                      >
                        <div className="flex px-3 py-2">
                          <div className="grow  pr-2">{item.tag.name}</div>
                          <div
                            className="grow-0 "
                            onClick={() => {
                              setToggle(false, item.id);
                            }}
                          >
                            x
                          </div>
                        </div>
                      </button>
                    );
                  })
                ) : (
                  <p className="m-2"> No Sorting Tags </p>
                )}
              </div>
              <div>
                <SectionLabel Label={'Internal Notes'} />
              </div>
              <div className=" py-2">
                <textarea
                  value={listenerDetails?.notes}
                  className="py-2 px-3  rounded-md  h-28 w-full text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                  onChange={(evt: any) => {
                    setListenerDetails((prev: any) => ({
                      ...prev,
                      notes: evt.target.value,
                    }));
                    setEnableSaveOption(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDataProp: state.Listeners?.listenersDetails?.data || null,
    pending: state.Listeners?.pending || false,
    comms: state.Comms.CommsList?.data || null,
    tiersList: state.tiers.TiersList?.data || null,
    listenersTags: state.Listeners.listenersTags?.data || null,
    tags: state.tags.TagsGroupList?.data || null,
  };
};

export default connect(mapStateToProps)(Profile);

const getUrlFileType = (url: string) => {
  const u = new URL(url);
  const ext: any = u.pathname.split('.').pop();
  return ext === '/' ? undefined : mime?.getType(ext.toLowerCase());
};

const handleImage = async (file_url: string, key: string) => {
  if (key === 'VERIFICATION') {
    const authenticateToken = localStorage.getItem('authorization_token');
    const response: any = await axios.get(file_url, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + authenticateToken,
        'X-Requested-With': 'XMLHTTPRequest',
        Accept: 'application/json',
      },
    });
    const blob: any = new Blob([response.data], {
      type: getUrlFileType(file_url),
    });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } else {
    window.open(file_url, '_blank');
  }
};
const calculateApprovalStatus = (field: boolean, proposed_field: boolean, field_rejected: boolean) => {
  let status = OnboardingStatus.ERROR;
  if (field_rejected && !field) {
    status = OnboardingStatus.REVISIT;
  } else if (!field || (proposed_field && !field_rejected)) {
    status = OnboardingStatus.PENDING;
  } else if (field) {
    status = OnboardingStatus.DONE;
  }
  return status;
};

const calculateProfileImageStatus = (userDetails: any) => {
  let profileApprovedStatus = OnboardingStatus.ERROR;
  if (userDetails?.listener_role?.proposed_profile_photo?.status === 'rejected') {
    profileApprovedStatus = OnboardingStatus.REVISIT;
  } else if (
    (userDetails?.listener_role?.current_profile_photo?.status !== 'approved' &&
      !userDetails?.listener_role?.proposed_profile_photo) ||
    userDetails?.listener_role?.proposed_profile_photo?.status === 'pending'
  ) {
    profileApprovedStatus = OnboardingStatus.PENDING;
  } else if (
    userDetails?.listener_role?.current_profile_photo?.status === 'approved' &&
    userDetails?.listener_role?.proposed_profile_photo?.status !== 'pending'
  ) {
    profileApprovedStatus = OnboardingStatus.DONE;
  }
  return profileApprovedStatus;
};
