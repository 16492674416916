import React from 'react';
import Spinner from '../../components/common/spinner';
import Header from '../../components/common/global-top-bar';
import { ExternalProviderPlan, useExternalProviderPlans } from '../../hooks/useExternalProviderPlans';
import ExternalProviderPlanModal from './plus-care-edit';

const PlusCarePage: React.FC = () => {
  const { data, isLoading } = useExternalProviderPlans();
  const newPlan: ExternalProviderPlan = {
    code: '',
    display_name: '',
    name: '',
    provider: '',
    provider_types: [],
    recuro_metatag3: '',
  };

  return (
    <div>
      <Header heading={'+Care'} />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <ExternalProviderPlanModal plan={newPlan} />
      </div>
      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-3">
          {isLoading ? (
            <Spinner />
          ) : (
            <table className="table-fixed table-row-format w-full relative border-collapse ">
              <thead className="bg-white  h-full sticky top-0">
                <tr className=" text-left  w-full px-7 pt-5">
                  <th className="px-2 py-2 w-1/12"></th>
                  <th className="px-2 py-2 w-1/12 sticky top-0  bg-white  h-full mb-4   pl-2">
                    <p className="px-2">Id</p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-4 px-2">Plan Name</p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Plan Code</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Display Name</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-1">Provider</p>
                  </th>
                  <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-4">Plan Type</p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Metatag 3</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.sort((a, b) => a.id! - b.id!)
                  .map((externalProviderPlan) => (
                    <tr key={externalProviderPlan.id} className="cursor-pointer">
                      <td className="px-2 py-2 w-1/12">
                        <ExternalProviderPlanModal plan={externalProviderPlan} />
                      </td>
                      <td className="px-2 py-2 w-1/12">{externalProviderPlan.id}</td>
                      <td className="px-2 py-2 w-2/12">{externalProviderPlan.name}</td>
                      <td className="px-2 py-2 w-2/12">{externalProviderPlan.code}</td>
                      <td className="px-2 py-2 w-2/12">{externalProviderPlan.display_name}</td>
                      <td className="px-2 py-2 w-2/12">{externalProviderPlan.provider}</td>
                      <td className="px-2 py-2 w-3/12">{externalProviderPlan.provider_types.join(', ')}</td>
                      <td className="px-2 py-2 w-2/12">{externalProviderPlan.recuro_metatag3}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlusCarePage;
