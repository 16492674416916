import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, ToggleButton } from '../../../components/common';
import { postUpdateClientRequest, postUpdatePackageRequest } from '../clients-redux/actions';

type props = {
  data: any;
  eligibilityConfig: any;
  UpdateSaveOption: any;
};

const EligibilityConfiguration: React.FunctionComponent<props> = (props): JSX.Element => {
  const [packageId, setpackageId] = useState();
  const clientId = localStorage.getItem('clientid');
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [domainList, setdomainList] = useState('');
  const [defaultpackage, setdefaultpackage] = useState(false);
  const dispatch = useDispatch();

  const handleSave = () => {
    const linkInput = {
      clientId,
      packageId,
    };

    const payload = {
      domain_whitelist: domainList,
    };
    const clientPayload = {
      default_package: packageId,
    };
    if (clientId) {
      dispatch(postUpdatePackageRequest({ payload, linkInput }));
    }
    if (clientId && defaultpackage) {
      dispatch(postUpdateClientRequest({ payload: clientPayload, linkInput }));
    }
  };

  useEffect(() => {
    if (props.data) {
      const data = props.data;
      setdomainList(data?.domain_whitelist);
      setdefaultpackage(!!(props.eligibilityConfig === packageId));
      setpackageId(data.id);
      setenableSaveOption(false);
    }
  }, [props.data, props.eligibilityConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="flex  w-full justify-between  py-3 border-b gray-border-line">
        <div className="text-md font-bold text-gray-dark uppercase">Eligibility Configuration</div>
        <button
          className={
            ' rounded-full  text-center h-6 text-sm text-white px-2 ' +
            (enableSaveOption ? ' stacked-bar-blue-400  ' : '  bg-gray-background-light cursor-not-allowed ')
          }
          onClick={handleSave}
        >
          {' '}
          SAVE{' '}
        </button>
      </div>
      <div className={` grid grid-cols-2 gap-4 py-2.5 mb-5`}>
        <div className="col-span-1">
          <CustomInput
            inputType={'text'}
            value={domainList}
            setValue={(data) => {
              setenableSaveOption(true);
              setdomainList(data);
            }}
            Label={'Allowed Email Domains'}
          />
        </div>
        <div className="col-span-1 flex">
          <div className="leading-4 text-sm font-medium text-dark-blue mr-2 flex items-center">Default Package</div>
          <div className="px-3 flex items-center">
            <ToggleButton
              status={defaultpackage}
              setStatus={(data) => {
                setenableSaveOption(true);
                setdefaultpackage(data);
              }}
              disabled={!!(props.eligibilityConfig === packageId)}
              id={''}
            />
          </div>
        </div>
      </div>
      <div className="w-full border-b gray-border-line"></div>
    </>
  );
};

export default EligibilityConfiguration;
