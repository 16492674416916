import React, { useEffect, useState } from 'react';
import { LOCLogo } from '../../assets/images';
import { CustomInput } from '../../components/common';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/paths';
import {
  fetchEmailAvailableRequest,
  fetchUserDetailsRequest,
  postClinetAdminOnboardCompleteRequest,
} from '../../components/account-login/login-redux/actions';
import useDebounce from '../../components/common/debounce';
import { toast } from 'react-toastify';
import { fetchTosRequest } from '../clients/text-blocks/text-blocks-redux/actions';
import { SwitchboardFeatures, useFeatureFlag } from '../../hooks/useFeatureFlags';
import useAuth from '../../hooks/useAuth';
import useStickyState from '../../hooks/useStickyState';
import { axiosPost } from '../../api/axios';

type props = {
  UserDetails: any;
  authenticateWithToken: any;
  authenticateWithTokenError: any;
  pending: boolean;
  emailAvailable: any;
  emailAvailableError: any;
  emailAvailablePendding: boolean;
  clientAdminOnborading: any;
  clientAdminOnboradingError: any;
  tos: any;
};

interface RedeemAuthCodeForTokenResponse {
  access_token: string;
  refresh_token: string;
}

const redeemAuthCodeForToken = async (code: string): Promise<RedeemAuthCodeForTokenResponse> => {
  return await axiosPost(`/users/redeem_code/switchboard`, { code: code }, 'v3').then((response) => {
    return response.data;
  });
};

const OnBoarding: React.FunctionComponent<props> = (props): JSX.Element => {
  const { UserDetails, authenticateWithTokenError, pending, emailAvailable, clientAdminOnborading } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<any>();
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<any>();
  const [isAvailableEmail, setIsAvailableEmail] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const [isShowForm, setIsShowForm] = useState<boolean>(true);
  const [isTerms, setIsTerms] = useState<boolean>(false);

  const debouncedEmail = useDebounce(email, 500);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const keycloakLogin = useFeatureFlag(SwitchboardFeatures.SB_KEYCLOAK_LOGIN).enabled;
  const [keycloakCode] = useStickyState('code', params.get('code'));

  const { authToken, setRefreshToken, setAuthToken, keycloak } = useAuth();

  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);

  const handleSubmit = () => {
    const trimEmail = email?.trim();
    const trimFirstName = firstName?.trim();
    const trimLastName = lastName?.trim();
    const trimPassword = password?.trim();

    setEmail(trimEmail);
    setFirstName(trimFirstName);
    setLastName(trimLastName);
    setPassword(trimPassword);

    const emailAddress = UserDetails?.data?.email_address;

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(trimEmail) || trimEmail === '') {
      return setError({ email: 'Enter valid email address' });
    } else if (isAvailableEmail === false && emailAddress !== trimEmail) {
      return setError({ email: 'Email already exists' });
    } else if (trimFirstName === undefined || trimFirstName?.length < 2) {
      if (trimFirstName === '' || trimFirstName === undefined) {
        return setError({ firstName: 'Enter first name' });
      } else {
        return setError({ firstName: 'Enter min. 2 characters' });
      }
    } else if (trimLastName === undefined || trimLastName?.length < 2) {
      if (trimLastName === '' || trimLastName === undefined) {
        return setError({ lastName: 'Enter last name' });
      } else {
        return setError({ lastName: 'Enter min. 2 characters' });
      }
    }
    if (trimPassword === undefined || trimPassword === '') {
      return setError({ password: 'Enter password' });
    } else if (passwordConfirmation !== password) {
      return setError({ passwordConfirmation: 'password does not match' });
    } else {
      setError({});
    }
    setIsShowForm(false);
  };

  const handleTosSubmit = () => {
    const input = {
      email_address: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      userId: UserDetails?.data?.id,
    };
    dispatch(postClinetAdminOnboardCompleteRequest(input));
    // Unset these values so we can log in to keycloak when the above dispatch forwards us to index
    setAuthToken(null);
    setRefreshToken(null);
    localStorage.clear(); // TODO: not sure if this is redundant?
  };

  useEffect(() => {
    if (!isFirstRun) {
      return;
    }

    // We need to check if they came with a code, which we will exchange for a pair of tokens
    if (keycloakLogin && keycloakCode) {
      setIsFirstRun(false);
      redeemAuthCodeForToken(keycloakCode)
        .then((response: RedeemAuthCodeForTokenResponse) => {
          setAuthToken(response.access_token);
          setRefreshToken(response.refresh_token);
        })
        .catch((reason) => {
          console.error('Problem trading auth code for token', reason);
          history.push(ROUTE_PATH.REQUESTNEWINVITE);
        });
      return;
    }
  }, [keycloakLogin, keycloakCode, keycloak, authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.clientAdminOnboradingError?.data) {
      if (props.clientAdminOnboradingError?.data.description === 'There is no valid role on your account.') {
        history.push(ROUTE_PATH.INVITEREVOKE);
      } else {
        toast.error(props.clientAdminOnboradingError?.data.description);
      }
    }
  }, [props.clientAdminOnboradingError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (UserDetails?.data) {
      const userData = UserDetails?.data;
      localStorage.setItem('role_type', userData?.client_administrator_role?.status);
      if (
        userData.client_administrator_role?.status === 'enabled' &&
        userData.client_administrator_role?.type === 'client_administrator'
      ) {
        history.push(ROUTE_PATH.SNAPSHOT);
      }
      setEmail(userData.email_address);
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      dispatch(fetchEmailAvailableRequest({ email_address: userData.email_address }));
    }
  }, [UserDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailAvailable) {
      setIsAvailableEmail(emailAvailable.data);
    }
  }, [emailAvailable]);

  useEffect(() => {
    if (debouncedEmail) {
      dispatch(fetchEmailAvailableRequest({ email }));
    }
  }, [debouncedEmail, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authToken) {
      dispatch(fetchUserDetailsRequest({}));
      dispatch(fetchTosRequest({ type: 'app' }));
    }
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientAdminOnborading?.data) {
      if (clientAdminOnborading?.data.access_role === 'administrator') {
        history.push(ROUTE_PATH.DASHBOARD);
      } else if (clientAdminOnborading?.data.access_role === 'client_administrator') {
        history.push(ROUTE_PATH.SNAPSHOT);
      }
    }
  }, [clientAdminOnborading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="w-full h-screen bg-gray-background-light flex justify-center items-center">
        <div className="w-96 bg-white pt-8">
          <div className="text-primary w-full">
            {!isShowForm && (
              <button
                onClick={() => setIsShowForm(true)}
                className="my-2 mx-8 font-bold text-bright-blue pt-10 text-xl"
              >
                Back
              </button>
            )}
            <div className="flex justify-center pt-4 px-8">
              <img src={LOCLogo} alt="kindly-logo" className="w-56" />
            </div>
            {isShowForm ? (
              <div className="px-8 pb-12">
                <h3 className="text-bright-blue pt-10 text-xl">Create Your Account</h3>
                <div className="mt-8">
                  <CustomInput
                    inputType={'text'}
                    value={email}
                    setValue={setEmail}
                    Label={'Email'}
                    Error={error?.email}
                    ErrorMessage={error?.email}
                    labelClass="font-normal"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <CustomInput
                    inputType={'text'}
                    value={firstName}
                    setValue={setFirstName}
                    Label={'First Name'}
                    Error={error?.firstName}
                    ErrorMessage={error?.firstName}
                    labelClass="font-normal"
                  />
                  <CustomInput
                    inputType={'text'}
                    value={lastName}
                    setValue={setLastName}
                    Label={'Last Name'}
                    Error={error?.lastName}
                    ErrorMessage={error?.lastName}
                    labelClass="font-normal"
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={password}
                    setValue={setPassword}
                    Label={'Password'}
                    Error={error?.password}
                    ErrorMessage={error?.password}
                    labelClass="font-normal"
                    secondLable="Minimum 15 characters"
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'password'}
                    value={passwordConfirmation}
                    setValue={setPasswordConfirmation}
                    Label={'Confirm Password'}
                    Error={error?.passwordConfirmation}
                    ErrorMessage={error?.passwordConfirmation}
                    labelClass="font-normal"
                  />
                </div>
                <div className="flex justify-center mt-8">
                  <button
                    type="button"
                    className="bg-bright-blue rounded-full py-1.5 px-12 text-white"
                    onClick={handleSubmit}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h3 className="text-bright-blue pt-10 text-xl px-8">Terms of Service</h3>
                <div
                  className="bg-primary bg-opacity-2 overflow-y-auto h-96 px-8 pb-4 mt-4"
                  dangerouslySetInnerHTML={{
                    __html: props.tos?.data?.app ? props.tos?.data?.app?.CLIENT_ADMIN_TERMS_OF_SERVICE : null,
                  }}
                ></div>
                <div className="bg-bright-blue-10 pb-12">
                  <div className="px-8">
                    <label className="inline-flex items-center mt-3">
                      <input
                        type="radio"
                        className="form-radio h-5 w-5 text-gray-600 w-1/4 -mt-8"
                        checked={isTerms}
                        onClick={(e: any) => setIsTerms(!isTerms)}
                      />
                      <span className="ml-2 text-gray-700 text-sm leading-5">
                        I accept the Terms of Service. I have read and understood how my data as described in the{' '}
                        <a href="https://www.listenersoncall.com/privacy" target="_blank" rel="noopener noreferrer">
                          <u>Privacy Statement</u>
                        </a>{' '}
                        will be processed.
                      </span>
                    </label>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      type="button"
                      className={`rounded-full py-1.5 px-12 text-white ${
                        !isTerms ? 'bg-gray-background-dark cursor-not-allowed' : 'bg-bright-blue'
                      }`}
                      onClick={handleTosSubmit}
                      disabled={!isTerms}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserDetails: state.login.UserDetails,
    authenticateWithToken: state.login?.authenticateWithToken,
    authenticateWithTokenError: state.login.authenticateWithTokenError,
    emailAvailable: state.login.emailAvailable,
    emailAvailableError: state.login.emailAvailableError,
    emailAvailablePendding: state.login.emailAvailablePendding,
    clientAdminOnborading: state.login.clientAdminOnborading,
    clientAdminOnboradingError: state.login.clientAdminOnboradingError,
    tos: state.Tos.clientAdmin?.data || null,
    pending: state.login.pending,
  };
};

export default connect(mapStateToProps)(OnBoarding);
