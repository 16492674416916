import {
  FETCH_LISTENERS_REQUEST,
  FETCH_LISTENERS_SUCCESS,
  FETCH_LISTENERS_FAILURE,
  GET_LISTEN_TOPIC_REPORT_REQUEST,
  GET_LISTEN_TOPIC_REPORT_SUCCESS,
  GET_LISTEN_TOPIC_REPORT_FAILURE,
  POST_LISTENER_GRADE_REQUEST,
  POST_LISTENER_GRADE_SUCCESS,
  POST_LISTENER_GRADE_FAILURE,
  FETCH_MESSAGE_QUALITY_REQUEST,
  FETCH_MESSAGE_QUALITY_SUCCESS,
  FETCH_MESSAGE_QUALITY_FAILURE,
  FETCH_LIST_CONNECTIONS_REQUEST,
  FETCH_LIST_CONNECTIONS_SUCCESS,
  FETCH_LIST_CONNECTIONS_FAILURE,
  FETCH_CALL_LOG_REQUEST,
  FETCH_CALL_LOG_SUCCESS,
  FETCH_CALL_LOG_FAILURE,
  FETCH_CONNECTIONS_COUNT_REQUEST,
  FETCH_CONNECTIONS_COUNT_SUCCESS,
  FETCH_CONNECTIONS_COUNT_FAILURE,
  UPDATE_LISTENER_DETAILS_REQUEST,
  UPDATE_LISTENER_DETAILS_SUCCESS,
  UPDATE_LISTENER_DETAILS_FAILURE,
  GET_LISTENER_DETAILS_REQUEST,
  GET_LISTENER_DETAILS_SUCCESS,
  GET_LISTENER_DETAILS_FAILURE,
  POST_LISTENER_PROFILE_REJECTED_REQUEST,
  POST_LISTENER_PROFILE_REJECTED_SUCCESS,
  POST_LISTENER_PROFILE_REJECTED_FAILURE,
  FETCH_LISTENERS_COUNT_REQUEST,
  FETCH_LISTENERS_COUNT_SUCCESS,
  FETCH_LISTENERS_COUNT_FAILURE,
  FETCH_LISTENER_AVAILABILTY_REQUEST,
  FETCH_LISTENER_AVAILABILTY_SUCCESS,
  FETCH_LISTENER_AVAILABILTY_FAILURE,
  FETCH_ENGAGEMENT_SUCCESS,
  FETCH_ENGAGEMENT_FAILURE,
  FETCH_ENGAGEMENT_REQUEST,
  FETCH_LOGS_REQUEST,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE,
  FETCH_LOGSCOUNT_REQUEST,
  FETCH_LOGSCOUNT_SUCCESS,
  FETCH_LOGSCOUNT_FAILURE,
  UPDATE_LISTENER_TAGS_REQUEST,
  UPDATE_LISTENER_TAGS_SUCCESS,
  UPDATE_LISTENER_TAGS_FAILURE,
  GET_LISTENER_TAGS_REQUEST,
  GET_LISTENER_TAGS_SUCCESS,
  GET_LISTENER_TAGS_FAILURE,
  REMOVE_LISTENER_TAGS_REQUEST,
  REMOVE_LISTENER_TAGS_SUCCESS,
  REMOVE_LISTENER_TAGS_FAILURE,
  UPDATE_LISTENER_TAGS_LIST_REQUEST,
  UPDATE_LISTENER_TAGS_LIST_SUCCESS,
  UPDATE_LISTENER_TAGS_LIST_FAILURE,
  FETCH_AUDIO_LIST_FAILURE,
  FETCH_AUDIO_LIST_REQUEST,
  FETCH_AUDIO_LIST_SUCCESS,
  POST_AUDIO_FAILURE,
  POST_AUDIO_REQUEST,
  POST_AUDIO_SUCCESS,
  UPDATE_AUDIO_FAILURE,
  UPDATE_AUDIO_REQUEST,
  UPDATE_AUDIO_SUCCESS,
  FETCH_MONEY_REPORT_FAILURE,
  FETCH_MONEY_REPORT_REQUEST,
  FETCH_MONEY_REPORT_SUCCESS,
  UPDATE_PEER_STATUS_FAILURE,
  UPDATE_PEER_STATUS_REQUEST,
  UPDATE_PEER_STATUS_SUCCESS,
  FETCH_LISTENERS_AUDIO_LIST_FAILURE,
  FETCH_LISTENERS_AUDIO_LIST_REQUEST,
  FETCH_LISTENERS_AUDIO_LIST_SUCCESS,
  FETCH_LISTENERS_AUDIO_LIST_COUNT_FAILURE,
  FETCH_LISTENERS_AUDIO_LIST_COUNT_REQUEST,
  FETCH_LISTENERS_AUDIO_LIST_COUNT_SUCCESS,
} from './actionTypes';

import { ListenersActions, ListenersState } from './types';

const initialState: ListenersState = {
  pending: false,
  listeners: [],
  listenersTopic: null,
  listenerGrade: null,
  messageQuality: null,
  listenersDetails: [],
  listenerAvailabilty: null,
  listenerEngagement: null,
  logsCount: null,
  error: null,
};

const Reducer = (state = initialState, action: ListenersActions) => {
  switch (action.type) {
    case FETCH_LISTENERS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LISTENERS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        listenersCount: action.payload,
        error: null,
      };
    case FETCH_LISTENERS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_LOGSCOUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LOGSCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        logsCount: action.payload.LOGSCOUNT,
        error: null,
      };
    case FETCH_LOGSCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case POST_LISTENER_PROFILE_REJECTED_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_LISTENER_PROFILE_REJECTED_SUCCESS:
      return {
        ...state,
        pending: false,
        listenerGrade: action.payload,
        error: null,
      };
    case POST_LISTENER_PROFILE_REJECTED_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case UPDATE_LISTENER_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_LISTENER_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        listeners: action.payload,
        error: null,
      };
    case UPDATE_LISTENER_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_LISTENER_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_LISTENER_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        listenersDetails: action.payload,
        error: null,
      };
    case GET_LISTENER_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        listenersDetails: null,
        error: action.payload.error,
      };
    case FETCH_LOGS_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        pending: false,
        logs: action.payload.LOGS,
        error: null,
      };
    case FETCH_LOGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_LISTENERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LISTENERS_SUCCESS:
      return {
        ...state,
        pending: false,
        listeners: action.payload,
        error: null,
      };
    case FETCH_LISTENERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_LISTEN_TOPIC_REPORT_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case GET_LISTEN_TOPIC_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        listenersTopic: action.payload,
        error: null,
      };
    case GET_LISTEN_TOPIC_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        listenersTopic: null,
        error: action.payload.error,
      };
    case POST_LISTENER_GRADE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_LISTENER_GRADE_SUCCESS:
      return {
        ...state,
        pending: false,
        listenerGrade: action.payload,
        error: null,
      };
    case POST_LISTENER_GRADE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_MESSAGE_QUALITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MESSAGE_QUALITY_SUCCESS:
      return {
        ...state,
        pending: false,
        messageQuality: action.payload,
      };
    case FETCH_MESSAGE_QUALITY_FAILURE:
      return {
        ...state,
        pending: false,
        messageQuality: action.payload,
      };
    case FETCH_LIST_CONNECTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LIST_CONNECTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        ConnectionsList: action.payload,
      };
    case FETCH_LIST_CONNECTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        loginError: null,
      };
    case FETCH_CALL_LOG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CALL_LOG_SUCCESS:
      return {
        ...state,
        pending: false,
        CallLog: action.payload,
        error: null,
      };
    case FETCH_CALL_LOG_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_CONNECTIONS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CONNECTIONS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        ConnectionsCount: action.payload,
        error: null,
      };
    case FETCH_CONNECTIONS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_ENGAGEMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        listenerEngagement: action.payload,
        error: null,
      };
    case FETCH_ENGAGEMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_LISTENER_AVAILABILTY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LISTENER_AVAILABILTY_SUCCESS:
      return {
        ...state,
        pending: false,
        listenerAvailabilty: action.payload,
      };
    case FETCH_LISTENER_AVAILABILTY_FAILURE:
      return {
        ...state,
        pending: false,
        listenerAvailabilty: action.payload,
      };
    case UPDATE_LISTENER_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_LISTENER_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        listeners: action.payload,
        error: null,
      };
    case UPDATE_LISTENER_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_LISTENER_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case GET_LISTENER_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        listenersTags: action.payload,
        error: null,
      };
    case GET_LISTENER_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        listenersTags: null,
        error: action.payload.error,
      };
    case REMOVE_LISTENER_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_LISTENER_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        listeners: action.payload,
        error: null,
      };
    case REMOVE_LISTENER_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        listenersTags: null,
        error: action.payload.error,
      };
    case UPDATE_LISTENER_TAGS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_LISTENER_TAGS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        listenersTags: action.payload,
        error: null,
      };
    case UPDATE_LISTENER_TAGS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        listenersTags: null,
        error: action.payload.error,
      };
    case POST_AUDIO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_AUDIO_SUCCESS:
      return {
        ...state,
        pending: false,
        newTag: action.payload,
        error: null,
      };
    case POST_AUDIO_FAILURE:
      return {
        ...state,
        pending: false,
        listenersTags: null,
        error: action.payload.error,
      };
    case FETCH_AUDIO_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_AUDIO_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        AudioList: action.payload.AudioList,
        error: null,
      };
    case FETCH_AUDIO_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_AUDIO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_AUDIO_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateAUDIO: action.payload,
        error: null,
      };
    case UPDATE_AUDIO_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateAUDIO: null,
        error: action.payload.error,
      };
    case FETCH_MONEY_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MONEY_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        MoneyDetails: action.payload,
        error: null,
      };
    case FETCH_MONEY_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        MoneyDetails: null,
        error: action.payload.error,
      };
    case UPDATE_PEER_STATUS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_PEER_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemovePayment: action.payload,
        error: null,
      };
    case UPDATE_PEER_STATUS_FAILURE:
      return {
        ...state,
        pending: false,
        RemovePayment: null,
        error: action.payload.error,
      };
    case FETCH_LISTENERS_AUDIO_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        reviewAudioPending: true,
      };
    case FETCH_LISTENERS_AUDIO_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        reviewAudioPending: false,
        ListenersAudioList: action.payload,
        error: null,
      };
    case FETCH_LISTENERS_AUDIO_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        reviewAudioPending: false,
        ListenersAudioList: null,
        error: action.payload.error,
      };
    case FETCH_LISTENERS_AUDIO_LIST_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LISTENERS_AUDIO_LIST_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        ListenersAudioListCount: action.payload,
        error: null,
      };
    case FETCH_LISTENERS_AUDIO_LIST_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        ListenersAudioListCount: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
