import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CustomInput, DropDown } from '../../../components/common';
import { postUpdatePackageRequest } from '../clients-redux/actions';
import useOutsideAlerter from '../../../components/common/outside-check';
import RichTextEditor from '../../../components/common/rich-text-editor';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
//@ts-ignore
import htmlToDraft from 'html-to-draftjs';

type props = {
  data: any;
  webAppUrl: any;
  UpdateSaveOption: any;
  providerOptions: any;
};

const PackageDetails: React.FunctionComponent<props> = (props): JSX.Element => {
  const [packageName, setpackageName] = useState('');
  const [packageCode, setpackageCode] = useState('');
  const [internalNotes, setinternalNotes] = useState<any>();
  const [webAppUrl, setwebAppUrl] = useState<string>('');
  const [saveWebAppUrl, setsaveWebAppUrl] = useState<any>('');
  const [packageId, setpackageId] = useState();
  const [webappDropDown, setwebappDropDown] = useState(false);
  const [dropDownoption, setdropDownoption] = useState('');
  const [serviceProvider, setServiceProvider] = useState(null);
  const [costPerMinuteCents, setCostPerMinuteCents] = useState(0);
  const [clientPerMemberPerMonth, setClientPerMemberPerMonth] = useState(0);
  const [clientCostPerMinute, setClientCostPerMinute] = useState(0);
  const [clientCostPerCall, setClientCostPerCall] = useState(0);
  const [monthlyPlatformAccessFee, setMonthlyPlatformAccessFee] = useState(0);
  const [annualPlatformAccessFee, setAnnualPlatformAccessFee] = useState(0);
  const clientId = localStorage.getItem('clientid');
  const [myPlanImage, setMyPlanImage] = useState<any>();
  const [preLoadedMyPlanImage, setPreLoadedMyPlanImage] = useState(true);

  const fileInput: any = React.createRef();

  const onFileChange = async (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          //Set the Base64 string return from FileReader as source.
          const image = new Image();
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = () => {
            setMyPlanImage(image.src);
            setPreLoadedMyPlanImage(false);
          };
        };
        reader.readAsDataURL(event.target.files[0]);
        setenableSaveOption(true);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };

  const hadleImageinput = () => {
    fileInput.current.click();
  };

  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [error, seterror] = useState(false);
  const [webappTheme, setWebappTheme] = useState<string | null>(null);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setwebappDropDown);

  const isURL = (str: string) => {
    var urlRegex =
      '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str?.length < 2083 && url.test(str);
  };

  const Validation = () => {
    if (
      !packageName ||
      !packageCode ||
      (saveWebAppUrl !== null && saveWebAppUrl?.trim() !== '' && saveWebAppUrl && !isURL(saveWebAppUrl?.trim()))
    ) {
      seterror(true);
      return false;
    }
    seterror(false);
    return true;
  };

  const handleSave = () => {
    const linkInput = {
      clientId,
      packageId,
    };

    const payload = {
      benefits_description: internalNotes ? draftToHtml(convertToRaw(internalNotes.getCurrentContent())) : null,
      code: packageCode?.trim(),
      cost_per_minute_cents: costPerMinuteCents,
      client_fee_per_member_per_month: clientPerMemberPerMonth,
      client_cost_per_minute: clientCostPerMinute,
      client_cost_per_call_unit: clientCostPerCall,
      monthly_platform_access_fee: monthlyPlatformAccessFee,
      annual_platform_access_fee: annualPlatformAccessFee,
      name: packageName?.trim(),
      external_plan_id: serviceProvider === 'none' ? null : serviceProvider,
      web_app_url:
        saveWebAppUrl === null || saveWebAppUrl?.trim() === '' || !saveWebAppUrl ? null : saveWebAppUrl?.trim(),
      theme_name: webappTheme,
      my_plan_file_url: !preLoadedMyPlanImage ? myPlanImage : myPlanImage === null ? null : undefined,
    };
    if (clientId && Validation()) {
      dispatch(postUpdatePackageRequest({ payload, linkInput }));
    }
  };

  const setWebAppUrl = (data: string) => {
    if (data === 'https://listeners.app.link') {
      return 'Native App Only';
    }
    if (data === props.webAppUrl?.LOC_WEB_APP_URL || data === null || data === '') {
      return `Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`;
    }
    return data;
  };

  useEffect(() => {
    if (props.data && props.webAppUrl) {
      const data = props.data;
      setpackageName(data.name);
      setpackageCode(data.code);
      setwebAppUrl(setWebAppUrl(data.web_app_url));
      setsaveWebAppUrl(data.web_app_url);
      setpackageId(data.id);
      setenableSaveOption(false);
      setWebappTheme(data?.theme_name);
      setServiceProvider(data.external_plan_id === null ? 'none' : data.external_plan_id);
      setCostPerMinuteCents(data.cost_per_minute_cents);
      setClientPerMemberPerMonth(data.client_fee_per_member_per_month);
      setClientCostPerMinute(data.client_cost_per_minute);
      setClientCostPerCall(data.client_cost_per_call_unit);
      setMonthlyPlatformAccessFee(data.monthly_platform_access_fee);
      setAnnualPlatformAccessFee(data.annual_platform_access_fee);
      const contentBlock = htmlToDraft(data?.benefits_description || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setinternalNotes(editorState);
      }
      if (data?.my_plan_file_url) {
        setMyPlanImage(data?.my_plan_file_url);
        setPreLoadedMyPlanImage(false);
      } else {
        setMyPlanImage(null);
        setPreLoadedMyPlanImage(true);
      }
    }
  }, [props.data, props.webAppUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="h-10  w-full bg-transparent px-2 flex justify-between items-center border-b text-gray-dark gray-border-line text-sm font-bold mb-2">
        <div>PLAN CONFIGURATION</div>
        <div>
          <button
            className={
              ' rounded-full w-full text-center h-6 text-sm text-white px-2 ' +
              (enableSaveOption ? ' stacked-bar-blue-400  ' : '  bg-gray-background-light cursor-not-allowed ')
            }
            onClick={handleSave}
          >
            SAVE
          </button>
        </div>
      </div>
      <div className="flex justify-evenly gap-7 ">
        <div className="py-4  w-full">
          <CustomInput
            inputType={'text'}
            value={packageName}
            setValue={(data) => {
              setenableSaveOption(true);
              setpackageName(data);
            }}
            Label={'Plan Name'}
            Error={error && !packageName}
            ErrorMessage={'Field is Required'}
          />
        </div>
        <div className="py-4  w-full">
          <CustomInput
            inputType={'text'}
            value={packageCode}
            setValue={(data) => {
              setenableSaveOption(true);
              setpackageCode(data);
            }}
            Label={'Plan Code'}
            Error={error && !packageCode}
            ErrorMessage={'Field is Required'}
          />
        </div>
      </div>
      <div className="flex justify-evenly gap-7">
        <div className="py-4  w-full">
          <label className="block leading-4 text-gray-dark mb-2">Benefits Description</label>
          <RichTextEditor
            RichText={internalNotes}
            setRichText={(data) => {
              setinternalNotes(data);
              setenableSaveOption(true);
            }}
          />
          {/* <textarea
                        value={internalNotes}
                        className="py-2 px-3  rounded-md  h-28 w-full text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                        onChange={(e) => {
                            setenableSaveOption(true);
                            setinternalNotes(e.target.value);
                        }}
                    /> */}
          <div className="my-2">
            <p className="pt-1 font-bold text-gray-dark">My Plan Image</p>
            <p className="pt-1 py-2 font-normal text-sm text-gray-dark">
              Displays on the Member Web App Home page and My Plan page. Should be 9:16 and a minimum of 330px wide to
              look good
            </p>
            <div className="w-full flex">
              <div
                className={`${
                  !myPlanImage ? 'cursor-pointer' : ''
                } bg-gray-background-light rounded-sm  w-full aspect-ratio-container`}
                onClick={() => hadleImageinput()}
              >
                {myPlanImage ? (
                  <div className="aspect-ratio-div">
                    <img className=" aspect-ratio-container-img " src={myPlanImage} alt="clientsImage" />
                  </div>
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center ">
                      +
                    </button>
                    <input
                      ref={fileInput}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e)}
                      onClick={() => {
                        fileInput.current.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                <div className="w-full aspect-ratio-container pt-2">
                  {myPlanImage && (
                    <button
                      className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                      onClick={() => {
                        setMyPlanImage(null);
                        setenableSaveOption(true);
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4  w-full">
          <div className=" relative ">
            <label className="block leading-4 text-gray-dark mb-2">Web App URL</label>
            <input
              name="browser"
              type="url"
              onFocus={() => {
                setwebappDropDown(true);
              }}
              value={webAppUrl}
              onChange={(e) => {
                setenableSaveOption(true);
                setwebAppUrl(e.target.value);
              }}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  webAppUrl !== `Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})` &&
                  webAppUrl !== 'Native App Only'
                ) {
                  setdropDownoption(webAppUrl);
                  setsaveWebAppUrl(webAppUrl);
                  setwebappDropDown(true);
                }
              }}
              id="browser"
              className="w-full py-2 px-3  h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
            />
            {error &&
              saveWebAppUrl !== null &&
              saveWebAppUrl?.trim() !== '' &&
              saveWebAppUrl &&
              !isURL(saveWebAppUrl?.trim()) &&
              !webappDropDown && <p className="text-red -z-10 ">Please enter valid URL</p>}

            {webappDropDown && (
              <div
                className="z-10 overflow-visible absolute h-auto w-full text-white opacity-90 bg-gray-dark rounded-md py-2"
                ref={wrapperRef}
              >
                <div
                  className="  w-full h-8 z-10 px-2 py-2 rounded-md hover:bg-blue-primary flex items-center"
                  role="button"
                  onClick={() => {
                    setwebAppUrl(`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`);
                    setenableSaveOption(true);
                    setdropDownoption('');
                    setsaveWebAppUrl(null);
                    setwebappDropDown(false);
                  }}
                >
                  {' '}
                  <p>{`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`}</p>
                </div>
                <div
                  className=" w-full h-8 z-10 p-2 hover:bg-blue-primary rounded-md flex items-center"
                  role="button"
                  onClick={() => {
                    setenableSaveOption(true);
                    setwebAppUrl('Native App Only');
                    setsaveWebAppUrl('https://listeners.app.link');
                    setdropDownoption('');
                    setwebappDropDown(false);
                  }}
                >
                  <p>Native App Only</p>
                </div>
                {dropDownoption?.length > 0 && (
                  <div
                    className=" w-full h-8 z-10 p-2 hover:bg-blue-primary flex items-center rounded-md "
                    role="button"
                    onClick={() => {
                      setenableSaveOption(true);
                      setwebAppUrl(dropDownoption);
                      setsaveWebAppUrl(dropDownoption);
                      setwebappDropDown(false);
                    }}
                  >
                    <p>{dropDownoption}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={'mt-5'}>
            <DropDown
              Label={'Web App Theme'}
              value={webappTheme !== null ? webappTheme : ''}
              setValue={(data) => {
                setWebappTheme(data === '' ? null : data);
                setenableSaveOption(true);
              }}
            >
              <option value="">Default Theme</option>
              <option value="Clever">CleverRx</option>
            </DropDown>
          </div>
          <div className={'mt-5'}>
            <DropDown
              Label={'Professional Services Provider'}
              value={serviceProvider}
              setValue={(data) => {
                setenableSaveOption(true);
                setServiceProvider(data);
              }}
            >
              <option value={'none'}>None</option>
              {props.providerOptions?.length > 0 &&
                props.providerOptions?.map((item: any) => <option value={item.id}>{item.name}</option>)}
            </DropDown>
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Member Cost Per Minute'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                costPerMinuteCents / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setCostPerMinuteCents(valueInDollars * 100);
              }}
              Error={error && !costPerMinuteCents}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Client Cost Per Minute'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                clientCostPerMinute / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setClientCostPerMinute(valueInDollars * 100);
              }}
              Error={error && !clientCostPerMinute}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Client Cost Per Call Unit'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                clientCostPerCall / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setClientCostPerCall(valueInDollars * 100);
              }}
              Error={error && !clientCostPerCall}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Client Fee Per Member Per Month'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                clientPerMemberPerMonth / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setClientPerMemberPerMonth(valueInDollars * 100);
              }}
              Error={error && !clientPerMemberPerMonth}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Monthly Platform Access Fee'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                monthlyPlatformAccessFee / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setMonthlyPlatformAccessFee(valueInDollars * 100);
              }}
              Error={error && !monthlyPlatformAccessFee}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Annual Platform Access Fee'}
              inputType={'text'}
              value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                annualPlatformAccessFee / 100,
              )}
              setValue={(data) => {
                const valueInDollars = data.endsWith('.') ? data : parseFloat(data.replace(/[^0-9]/g, '')) / 100;
                setenableSaveOption(true);
                setAnnualPlatformAccessFee(valueInDollars * 100);
              }}
              Error={error && !annualPlatformAccessFee}
              ErrorMessage={'Field is Required'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    providerOptions: state.Client.ExternalProviderPlans?.data || null,
  };
};

export default connect(mapStateToProps)(PackageDetails);
