import {
  FETCH_ALL_CONNECTIONS_FAILURE,
  FETCH_ALL_CONNECTIONS_SUCCESS,
  FETCH_ALL_CONNECTIONS_REQUEST,
  FETCH_ALL_CONNECTIONS_COUNT_FAILURE,
  FETCH_ALL_CONNECTIONS_COUNT_SUCCESS,
  FETCH_ALL_CONNECTIONS_COUNT_REQUEST,
} from './actionTypes';

import { MembersActions, MembersState } from './types';

const initialState: MembersState = {
  pending: false,
  connectionPending: false,
  error: null,
  connections: null,
  connectionsCount: null,
};

const Reducer = (state = initialState, action: MembersActions) => {
  switch (action.type) {
    case FETCH_ALL_CONNECTIONS_REQUEST:
      return {
        ...state,
        connectionPending: true,
      };
    case FETCH_ALL_CONNECTIONS_SUCCESS:
      return {
        ...state,
        connectionPending: false,
        connections: action.payload,
        error: null,
      };
    case FETCH_ALL_CONNECTIONS_FAILURE:
      return {
        ...state,
        connectionPending: false,
        error: action.payload.error,
      };
    case FETCH_ALL_CONNECTIONS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ALL_CONNECTIONS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        connectionsCount: action.payload,
        error: null,
      };
    case FETCH_ALL_CONNECTIONS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
