import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import HeadBack from './redirect';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import CalendarPaginator from './availability-pagination';
import { fetchListenerAvailabilityRequest } from './listeners-redux/actions';
import { Spinner } from '../../components/common';
import { paramsToObjects } from '../../components/common/create-params';

type props = {
  listenersAvailability: any;
  pending: boolean;
};

const weeks = ['MON', 'TUE', 'WED', 'THR', 'FRI', 'SAT', 'SUN'];

type durationType = {
  availableHours: number;
  onCallHours: number;
  callHours: number;
  futureHours: number;
};

const Additional: React.FunctionComponent<props> = ({ listenersAvailability, pending }): JSX.Element => {
  const [displayData, setdisplayData] = useState<any>([]);
  const dispatch = useDispatch();
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const [enableThisWeek, setenableThisWeek] = useState(true);
  const [duration, setDuration] = useState<durationType>({
    availableHours: 0,
    onCallHours: 0,
    callHours: 0,
    futureHours: 0,
  });

  const getIndex = (start: any, end: any, type: string, item: string) => {
    const startTime =
      start <= moment(item).startOf('day')
        ? moment(item).startOf('day')
        : start >= moment(item).endOf('day')
          ? moment(item).endOf('day')
          : start;
    const endTime =
      end >= moment(item).endOf('day')
        ? moment(item).endOf('day')
        : end <= moment(item).startOf('day')
          ? moment(item).startOf('day')
          : end;

    const ActualDuration = Math.round(+moment.duration(endTime.diff(startTime)).asMinutes());

    setDuration((prevDuration: any) => {
      return type === 'futureHours'
        ? {
            ...prevDuration,
            futureHours: prevDuration.futureHours + ActualDuration,
            availableHours: prevDuration.availableHours - ActualDuration,
          }
        : {
            ...prevDuration,
            [type]: +prevDuration[type] + ActualDuration,
          };
    });

    if (+startTime.minutes() !== 30 && +startTime.minutes() !== 0)
      startTime.subtract(+startTime.minutes() % 30, 'minutes');
    if (+endTime.minutes() !== 30 && +endTime.minutes() !== 0) endTime.add(30 - (+endTime.minutes() % 30), 'minutes');

    const steps =
      start >= moment(item).endOf('day') ? 0 : Math.round(+moment.duration(endTime.diff(startTime)).asMinutes() / 30);
    const startIndex = startTime.hours() * 2 + (startTime.minutes() >= 30 ? 1 : 0);

    return { startIndex, steps };
  };

  const constructAvailability = () => {
    setDuration({
      availableHours: 0,
      onCallHours: 0,
      callHours: 0,
      futureHours: 0,
    });

    const displayData =
      Object.keys(listenersAvailability)?.length > 0
        ? Object.keys(listenersAvailability).reduce((displayData: any[], item: any, index: number) => {
            //@ts-ignore
            const { current_availability, calls, oncall } = listenersAvailability[item];
            const currentAvailabilityArr = enableThisWeek
              ? current_availability.reduce((CAData: any[], time: any) => {
                  const { startIndex, steps } = getIndex(
                    moment(time.start_utc),
                    moment(time.end_utc).format('hh:mm:ss') === '00:00:00'
                      ? moment(time.end_utc).subtract(1, 'minute')
                      : moment(time.end_utc),
                    'availableHours',
                    item,
                  );

                  if (steps > 0) {
                    CAData.splice(startIndex, steps, ...new Array(steps).fill('R'));
                  }
                  return CAData;
                }, new Array(48).fill('E'))
              : new Array(48).fill('E');
            const fututeArr = enableThisWeek
              ? current_availability.reduce((FAData: any[], time: any) => {
                  const startTime = moment(time.start_utc);
                  const endTime =
                    moment(time.end_utc).format('hh:mm:ss') === '00:00:00'
                      ? moment(time.end_utc).subtract(1, 'minute')
                      : moment(time.end_utc);
                  if (
                    moment().isBetween(startTime, endTime) ||
                    moment().endOf('day').diff(moment(startTime)) < 0 ||
                    moment() < startTime
                  ) {
                    if (+startTime.minutes() !== 30 && +startTime.minutes() !== 0)
                      startTime.add(30 - (+startTime.minutes() % 30), 'minutes');
                    const { startIndex, steps } = getIndex(
                      moment().isBetween(startTime, endTime) ? moment() : startTime,
                      endTime,
                      'futureHours',
                      item,
                    );
                    steps > 0 && FAData.splice(startIndex, steps, ...new Array(steps).fill('P'));
                  }
                  return FAData;
                }, currentAvailabilityArr)
              : currentAvailabilityArr;
            const oncallArr = oncall?.reduce((OCdata: any[], time: any) => {
              const start = moment(time.start);
              const enday =
                moment(time.end).diff(start.endOf('day'), 'minutes') > 0 ? start.endOf('day') : moment(time.end);
              const { startIndex, steps } = getIndex(moment(time.start), enday, 'onCallHours', item);
              steps > 0 && OCdata.splice(startIndex, steps, ...new Array(steps).fill('B'));
              return OCdata;
            }, fututeArr);
            const callsArr = calls?.reduce((Cdata: any[], time: any) => {
              if (time.end !== null) {
                const { startIndex, steps } = getIndex(moment(time.start), moment(time.end), 'callHours', item);
                Cdata.splice(startIndex, steps, ...new Array(steps).fill('G'));
              }
              return Cdata;
            }, oncallArr);

            return [...displayData, callsArr];
          }, [])
        : [new Array(48).fill('E'), new Array(48).fill('E')];
    setdisplayData(displayData);
  };

  useEffect(() => {
    if (listenersAvailability) {
      constructAvailability();
    }
  }, [listenersAvailability]);

  const fetchData = (startdate: string | null, enddate: string | null, thisWeek: boolean) => {
    setenableThisWeek(thisWeek);
    let input: any = {
      id: listenerId,
      payload: {
        start_date: startdate ? startdate : moment(new Date()).startOf('week').format('YYYY-MM-DD'),
        end_date: enddate ? enddate : moment(new Date()).endOf('week').format('YYYY-MM-DD'),
        //@ts-ignore
        local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    dispatch(fetchListenerAvailabilityRequest(input));
  };

  return (
    <div>
      {pending && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />
      <SwitchBar heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions} position={3} forwardParams />
      <CalendarPaginator changedDate={fetchData} />

      <div className="max-window-height  overflow-y-auto  px-7 ">
        <div className=" py-4 ">
          <div className="h-8 flex justify-start  border-b border-gray-dark ">
            <div className="w-1/6 text-gray-dark font-bold font-base ">On Call Hours</div>
            <div className="w-1/6 text-gray-dark font-bold font-base ">Call Hours</div>
            {enableThisWeek && (
              <>
                <div className="w-1/6 text-gray-dark font-bold font-base ">Cancelled Hours</div>
                <div className="w-1/6 text-gray-dark font-bold font-base ">Future Hours</div>
              </>
            )}
          </div>

          <div className=" flex justify-start  ">
            <div className="w-1/6 ">
              <div className="relative   py-3 ">
                <div className="text-4xl font-normal blue-primary font-montseerat">
                  {(+duration.onCallHours / 60).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="w-1/6 text-gray-dark  ">
              <div className="relative   py-3 ">
                <div className="text-4xl font-normal text-stacked-bar-green-primary font-montseerat">
                  {(+duration.callHours / 60).toFixed(2)}
                </div>
              </div>
            </div>
            {enableThisWeek && (
              <>
                <div className="w-1/6  ">
                  <div className="relativ py-3 ">
                    <div className="text-4xl font-normal text-stacked-bar-orange-primary font-montseerat">
                      {((duration.availableHours - duration.onCallHours) / 60).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="w-1/6  ">
                  <div className="relativ py-3 ">
                    <div className="text-4xl font-normal text-purple font-montseerat">
                      {(duration.futureHours / 60).toFixed(2)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-1  items-center border-b border-gray-dark">
          <div className="col-span-1 text-gray-dark font-bold font-base">Current Schedule</div>
          <div className="col-span-3">
            <div className="flex flex-evenly  text-left gap-1 py-1">
              {new Array(24).fill(0).map((item: any, index: any) => (
                <div className=" h-8 w-full flex items-center">{index}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-1">
          <div className="col-span-1">
            {listenersAvailability &&
              Object.keys(listenersAvailability)?.length > 0 &&
              Object.keys(listenersAvailability).map((item, index) => (
                <div className="text-left text-gray-dark h-8 gap-1 my-2 tracking-normal">{` ${
                  weeks[index]
                } (${moment(item).format('MMM Do YYYY')})`}</div>
              ))}
          </div>
          <div className="col-span-3 flex-evely">
            {displayData?.length > 0 &&
              displayData.map((item: any, idx: number) => (
                <div className="flex flex-evenly gap-1 py-1" key={`${item ? item : Math.random()}${idx}`}>
                  {item &&
                    item?.map((item: any, index: any) => (
                      <div
                        className={` h-8 w-full
                                    ${
                                      item === 'E'
                                        ? ` bg-gray-200 `
                                        : item === 'R'
                                          ? `stacked-bar-orange-primary`
                                          : item === 'B'
                                            ? ` bg-blue-primary `
                                            : item === 'G'
                                              ? ` stacked-bar-green-primary `
                                              : item === 'P'
                                                ? ` bg-purple `
                                                : ` bg-gray-200 `
                                    }`}
                      ></div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listenersAvailability: state.Listeners.listenerAvailabilty?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(Additional);
