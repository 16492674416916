import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export enum ChannelTypeEnum {
  marketing = 'Marketing',
  notification = 'Notification',
  mixed = 'Mixed',
}

export interface SMSChannel {
  id: number;
  name: string;
  channel_type: keyof ChannelTypeEnum;
  number: string;
  is_default: boolean;
}

const getSMSChannels = async (): Promise<SMSChannel[]> => {
  return await axiosGet('/comms/sms_channels', null, 'v3').then((smsChannelResponse) => smsChannelResponse.data);
};

export const useSMSChannels = () => {
  const { data, isLoading, error, refetch } = useQuery<SMSChannel[]>(['sms_channels'], getSMSChannels);

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
