import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import AlertBox from './alert-box';
interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  handleContinueSaveChange?: (path: string) => void;
  titleText: string;
  contentText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmSaveButtonText?: string;
  componentType?: any;
}
const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  confirmSaveButtonText,
  componentType,
  handleContinueSaveChange,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleContinueSave = () => {
    setModalVisible(false);
    handleContinueSaveChange && handleContinueSaveChange(`${lastLocation?.pathname}${lastLocation?.search}`);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(`${lastLocation.pathname}${lastLocation.search}`);
    }
  }, [confirmedNavigation, lastLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setConfirmedNavigation(false);
  }, [componentType]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}

      <AlertBox
        visible={modalVisible}
        handleContinueSave={handleContinueSave}
        handlePrompt={handleConfirmNavigationClick}
        closeModal={closeModal}
        titleText={titleText}
        contentText={contentText}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        confirmSaveButtonText={confirmSaveButtonText}
      />
    </>
  );
};
export default RouteLeavingGuard;
