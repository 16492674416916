import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPost, axiosPut } from '../api/axios';
import { toast } from 'react-toastify';

export const clientQueryKeys = {
  listClients: ['ListClients'] as const,
  listClientSummaries: ['ListClientSummaries'] as const,
  allClientSummaries: ['AllClientSummaries'] as const,
};

export interface Client {
  allowed_listener_tags?: number[];
  archived_at: any;
  client_code?: string;
  comms_configuration?: CommsConfiguration;
  comms_logo_file_url?: string;
  contract_date?: string;
  created_at: string;
  crm_sync: boolean;
  description: string;
  eligibility_config: EligibilityConfig;
  enabled_resource_ids: number[];
  go_live_date?: string;
  group_id: any;
  id?: number;
  invoice_terms: any;
  is_test: boolean;
  landing_page_content?: string;
  logo_background_file_url?: string;
  logo_file_url?: string;
  logo_overlay_file_url?: string;
  member_self_enrollment: boolean;
  monthly_cap: any;
  name: string;
  notes?: string;
  packages: Package[];
  per_member_rate: any;
  promo_configuration?: PromoConfiguration;
  quickbooks_customer_id: any;
  reporting_configuration: any;
  reseller?: string;
  retired_at: any;
  sso_configuration?: SsoConfiguration | null;
  status: string;
  use_global_resources?: boolean;
  video_configuration?: VideoConfiguration;
  schedule_frequency?: string;
  schedule_ordinal?: number;
  schedule_hour?: number;
  group_banner_file_url?: string | null;
  group_banner_enabled?: boolean;
}

export interface PackageSummary {
  id: number;
  name: string;
  code: string;
}

export interface ClientSummary {
  contract_date: Date;
  id: number;
  is_test_client: boolean;
  name: string;
  packages: PackageSummary[];
  reseller: string;
  status: string;
  total_eligible_members: number;
  total_active_members: number;
}

export interface EligibilityConfig {
  default_package?: any;
  hide_client_admin_eligibility: boolean;
  transfer_type?: string;
}

export interface PromoConfiguration {
  button_label?: string;
  enabled?: boolean;
  image_file?: any;
  image_url?: string;
  placements?: string[];
  type: string;
  url?: string;
  video_file?: any;
  video_url?: string;
}

export interface SsoConfiguration {
  allow_sign_out?: boolean;
  collect_password?: boolean;
  sso_error_action?: string;
  allow_signup_exit?: boolean;
  authentication_url?: any;
  allow_invitation_expired_recovery?: boolean;
}

export interface Package {
  archived_at: any;
  benefits_description?: string;
  branding_configuration?: BrandingConfiguration;
  campaign_subscriptions: CampaignSubscription[];
  can_manage_dependents: boolean;
  client_id?: number;
  code: string;
  collect_user_address: boolean;
  created_at: string;
  dependents_configuration: DependentsConfiguration;
  domain_whitelist?: string;
  external_plan_id?: number;
  force_web_app?: boolean;
  id?: number;
  member_count: number;
  name: string;
  package_products: PackageProduct[];
  subscriptions: Subscription[];
  theme_name?: string;
  web_app_url?: string;
}

export interface PackageProduct {
  configuration: Configuration;
  created_at: string;
  id: number;
  minute_balance: number;
  package_id: number;
  product_id: number;
  product: Product;
  status: string;
  unused_minute_balance: number;
}

export interface Configuration {
  additional_discount: any;
  auto_renew_frequency?: string;
  auto_renew_maximum_uses?: number;
  auto_renew?: boolean;
  cadence_time?: string;
  cadence?: Cadence;
  discount_enabled?: boolean;
  discount_percentage: any;
  email_template?: string;
  engagement_points: any;
  expires_after?: number;
  hide_payment_tiles?: boolean;
  image_file?: string;
  image_url?: string;
  interim_question_delay?: number;
  interim_question?: string;
  maximum_minutes_per_user?: number;
  maximum_redemptions_allowed?: number;
  minutes: any;
  onboarding?: Onboarding;
  primary_question_timeout: any;
  primary_question?: string;
  pto_reward_value: any;
  rate?: number;
  required_points: any;
  reward_value: any;
  tremendous_campaign_id?: string;
  tremendous_organization_id?: string;
}

export interface Cadence {
  on: string;
  period: string;
}

export interface Onboarding {
  delay: number;
  enabled?: boolean;
  system_message_id: any;
}

export interface Product {
  configuration: ProductConfiguration;
  description: string;
  id: number;
  key: string;
  name: string;
  product_type: string;
}

export interface ProductConfiguration {
  additional_discount?: number;
  auto_renew_frequency?: string;
  auto_renew_maximum_uses?: number;
  auto_renew?: boolean;
  cadence_time?: string;
  cadence?: Cadence;
  component: string;
  discount_enabled?: boolean;
  discount_percentage?: number;
  email_template: any;
  engagement_points?: number;
  expires_after: any;
  hide_payment_tiles?: boolean;
  image_file?: string;
  image_url?: string;
  interim_question_delay?: number;
  interim_question?: string;
  maximum_minutes_per_user?: number;
  maximum_redemptions_allowed?: number;
  minutes?: number;
  onboarding?: Onboarding;
  primary_question_timeout?: boolean;
  primary_question?: string;
  pto_reward_value?: number;
  rate?: number;
  required_points: any;
  reward_value?: string;
  tremendous_campaign_id?: string;
  tremendous_organization_id?: string;
}

export interface CampaignSubscription {
  campaign_template_id: number;
  campaign_template: CampaignTemplate;
  created_at: string;
  id: number;
  package_id: number;
}

export interface CampaignTemplate {
  archived_at: any;
  created_at: string;
  description: string;
  id: number;
  name: string;
  script: any;
  sms_channel_id: any;
  type: string;
}

export interface BrandingConfiguration {
  button_label: string;
  enabled: boolean;
  image_url?: string;
  placements: string[];
  type: string;
  url: string;
  video_url?: string;
}

export interface DependentsConfiguration {
  dependent_count: number;
  enabled: boolean;
  members_can_add_dependents: boolean;
}

export interface Subscription {
  banner_text: string;
  cents_per_minute: number;
  code: string;
  description?: string;
  details?: string;
  discount: number;
  id: number;
  minutes: number;
  name: string;
  price: number;
}

export interface VideoConfiguration {
  author_image_file?: any;
  author_image_url?: string;
  author_name?: string;
  enabled?: boolean;
  image_file?: any;
  image_url?: string;
  title?: string;
  video_file?: string;
  video_url?: string;
}

export interface CommsConfiguration {
  clever_webhook_process: boolean;
}

const getClient = async (clientId: number): Promise<Client> => {
  return await axiosGet(`/clients/${clientId}/`, null, 'v2').then((clientResponse) => clientResponse.data);
};

export const useClient = (clientId: number) => {
  const { data, isLoading, error, refetch } = useQuery<Client>(['clients', clientId], () => getClient(clientId));
  return { data, isLoading, error, refetch };
};

const updateClientRequest = async (clientId: number, data: any): Promise<Client> => {
  return await axiosPut(`/clients/${clientId}`, data, 'v2').then((clientResponse) => clientResponse.data);
};

export const useUpdateClient = (clientId: number) => {
  const queryClient = useQueryClient();
  const updateClient = useMutation((data: Partial<Client>) => updateClientRequest(clientId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['clients', clientId]);
      toast.success('Client updated successfully');
    },
    onError: (error) => {
      queryClient.invalidateQueries(['clients', clientId]);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(['clients', clientId], (oldData: any) => {
        if (oldData) {
          return { ...oldData, ...data };
        }
        return oldData;
      });
    },
  });

  return {
    updateClient,
  };
};

const getListClients = async (): Promise<Client[]> => {
  return await axiosGet(
    `/clients/?status=active&include_packages=true&order_by=name&order_by_direction=asc`,
    {},
    'v2',
  ).then((clientResponse) => clientResponse.data);
};

export const useListClients = () => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Client[]>(clientQueryKeys.listClients, () =>
    getListClients(),
  );
  return { data, isLoading, error, refetch, isFetching };
};

export interface ClientSummaryOptions {
  search?: string;
  type?: string;
  is_test_client?: boolean;
  show_all_clients?: boolean;
  order_by?: string;
  order_by_direction?: string;
  all_statuses?: boolean;
}

const getClientSummaryList = async (options?: ClientSummaryOptions): Promise<ClientSummary[]> => {
  const params: {
    is_test_client?: boolean;
    show_all_clients?: boolean;
    view: string;
    order_by?: string;
    order_by_direction?: string;
    search?: string;
    statuses?: string;
  } = {
    is_test_client: options?.is_test_client ?? false,
    show_all_clients: options?.show_all_clients ?? false,
    view: 'summary',
    order_by: options?.order_by ?? 'name',
    order_by_direction: options?.order_by_direction ?? 'asc',
    search: options?.search ?? '',
  };
  if (options?.type) {
    params.statuses = mapClientTypeFilterToStatuses(options.type).toString();
  } else if (options?.all_statuses) {
    params.statuses = 'active,inactive,setup';
  }

  // NOTE since summaries is not paginated we're pulling out the entire response.data array into return val here
  return await axiosGet(`/clients/`, params, 'v2').then((clientSummaryList) => clientSummaryList.data.data);
};

export const useClientSummaryList = (options?: ClientSummaryOptions) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ClientSummary[]>(
    clientQueryKeys.listClientSummaries,
    () => getClientSummaryList(options),
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useAllClientSummaries = (isTestClient: boolean = false) => {
  return useQuery<ClientSummary[]>(
    clientQueryKeys.allClientSummaries,
    () => getClientSummaryList({ all_statuses: true, is_test_client: isTestClient }),
    {
      staleTime: 1000 * 60 * 15,
      cacheTime: 1000 * 60 * 15,
    },
  );
};

export const useSyncHubspot = () =>
  useMutation((clientId: number) => axiosPost(`/clients/hubspot/`, { client_id: clientId }, 'v3'));

export const clientTypeFilterOptions = ['All', 'Setup', 'Live', 'Inactive'];

export const clientStatuses = ['active', 'setup', 'inactive', 'archived'];

const mapClientTypeFilterToStatuses = (type: string) => {
  if (type.toLowerCase() === 'all') {
    return ['active', 'setup'];
  } else if (type.toLowerCase() === 'live') {
    return ['active'];
  } else if (type.toLowerCase() === 'setup') {
    return ['setup'];
  } else if (type.toLowerCase() === 'inactive') {
    return ['inactive'];
  } else return [];
};
