import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';

import rootReducer from './rootReducer';
import { rootSaga } from './rootSaga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
let middleware: any = [];
if (process.env.REACT_APP_ENVTYPE === 'PRODUCTION') {
  middleware = [...middleware, sagaMiddleware];
} else {
  middleware = [...middleware, sagaMiddleware];
}

// Mount it on the Store
const store = createStore(rootReducer, applyMiddleware(...middleware));

// Run the saga
sagaMiddleware.run(rootSaga);

export default store;
