import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_CONNECTIONS_REQUEST,
  FETCH_MEMBERS_CONNECTIONS_FAILURE,
  FETCH_MEMBERS_CONNECTIONS_SUCCESS,
  FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST,
  FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS,
  FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE,
  GET_MEMBER_LOGS_REQUEST,
  GET_MEMBER_LOGS_SUCCESS,
  GET_MEMBER_LOGS_FAILURE,
  FETCH_MEMBERS_LOGS_COUNT_REQUEST,
  FETCH_MEMBERS_LOGS_COUNT_SUCCESS,
  FETCH_MEMBERS_LOGS_COUNT_FAILURE,
} from './actionTypes';
import { FETCH_MEMBERS_COUNT_REQUEST, FETCH_MEMBERS_COUNT_FAILURE, FETCH_MEMBERS_COUNT_SUCCESS } from './actionTypes';
import {
  GET_MEMBER_PROFILE_REQUEST,
  GET_MEMBER_PROFILE_SUCCESS,
  GET_MEMBER_PROFILE_FAILURE,
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILURE,
  POST_REDEEM_PARTNER_CODE_REQUEST,
  POST_REDEEM_PARTNER_CODE_SUCCESS,
  POST_REDEEM_PARTNER_CODE_FAILURE,
} from './actionTypes';
import {
  FetchMembersRequest,
  FetchMembersSuccess,
  FetchMembersSuccessPayload,
  FetchMembersFailure,
  FetchMembersFailurePayload,
  FetchMembersConnectionsRequest,
  FetchMembersConnectionsSuccess,
  FetchMembersConnectionsSuccessPayload,
  FetchMembersConnectionsFailure,
  FetchMembersConnectionsFailurePayload,
  FetchMembersConnectionsCountRequest,
  FetchMembersConnectionsCountSuccess,
  FetchMembersConnectionsCountSuccessPayload,
  FetchMembersConnectionsCountFailure,
  FetchMembersConnectionsCountFailurePayload,
  GetMemberProfileRequest,
  GetMemberProfileSuccess,
  GetMemberProfileFailure,
  GetMemberProfileSuccessPayload,
  GetMemberProfileFailurePayload,
  PostRedeemPartnerCodeSuccess,
  PostRedeemPartnerCodeFailure,
  PostRedeemPartnerCodeFailurePayload,
  PostRedeemPartnerCodeRequest,
  FetchMembersCountRequest,
  FetchMembersCountSuccess,
  FetchMembersCountSuccessPayload,
  FetchMembersCountFailure,
  FetchMembersCountFailurePayload,
  GetMemberLogsSuccessPayload,
  GetMemberLogsFailure,
  GetMemberLogsFailurePayload,
  GetMemberLogsRequest,
  GetMemberLogsSuccess,
  FetchMemberLogsCountFailure,
  FetchMemberLogsCountFailurePayload,
  FetchMemberLogsCountRequest,
  FetchMemberLogsCountSuccess,
  FetchMemberLogsCountSuccessPayload,
} from './types';

export const fetchMembersRequest = (payload: any): FetchMembersRequest => ({
  type: FETCH_MEMBERS_REQUEST,
  payload,
});

export const fetchMembersSuccess = (payload: FetchMembersSuccessPayload): FetchMembersSuccess => ({
  type: FETCH_MEMBERS_SUCCESS,
  payload,
});

export const fetchMembersFailure = (payload: FetchMembersFailurePayload): FetchMembersFailure => ({
  type: FETCH_MEMBERS_FAILURE,
  payload,
});

export const fetchMembersConnectionsRequest = (payload: any): FetchMembersConnectionsRequest => ({
  type: FETCH_MEMBERS_CONNECTIONS_REQUEST,
  payload,
});

export const fetchMembersConnectionsSuccess = (
  payload: FetchMembersConnectionsSuccessPayload,
): FetchMembersConnectionsSuccess => ({
  type: FETCH_MEMBERS_CONNECTIONS_SUCCESS,
  payload,
});

export const fetchMembersConnectionsFailure = (
  payload: FetchMembersConnectionsFailurePayload,
): FetchMembersConnectionsFailure => ({
  type: FETCH_MEMBERS_CONNECTIONS_FAILURE,
  payload,
});

export const fetchMembersConnectionsCountRequest = (payload: any): FetchMembersConnectionsCountRequest => ({
  type: FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST,
  payload,
});

export const fetchMembersConnectionsCountSuccess = (
  payload: FetchMembersConnectionsCountSuccessPayload,
): FetchMembersConnectionsCountSuccess => ({
  type: FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS,
  payload,
});

export const fetchMembersConnectionsCountFailure = (
  payload: FetchMembersConnectionsCountFailurePayload,
): FetchMembersConnectionsCountFailure => ({
  type: FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE,
  payload,
});

export const getMemberProfileRequest = (payload: any): GetMemberProfileRequest => ({
  type: GET_MEMBER_PROFILE_REQUEST,
  payload,
});

export const getMemberProfileSuccess = (payload: GetMemberProfileSuccessPayload): GetMemberProfileSuccess => ({
  type: GET_MEMBER_PROFILE_SUCCESS,
  payload,
});

export const getMemberProfileFailure = (payload: GetMemberProfileFailurePayload): GetMemberProfileFailure => ({
  type: GET_MEMBER_PROFILE_FAILURE,
  payload,
});

export const fetchTopicsRequest = () => ({
  type: FETCH_TOPICS_REQUEST,
});

export const fetchTopicsSuccess = (payload: any) => ({
  type: FETCH_TOPICS_SUCCESS,
  payload,
});

export const fetchTopicsFailure = (payload: any) => ({
  type: FETCH_TOPICS_FAILURE,
  payload,
});

export const postRedeemPartnerCodeRequest = (payload: any): PostRedeemPartnerCodeRequest => ({
  type: POST_REDEEM_PARTNER_CODE_REQUEST,
  payload,
});

export const postRedeemPartnerCodeSuccess = (payload: any): PostRedeemPartnerCodeSuccess => ({
  type: POST_REDEEM_PARTNER_CODE_SUCCESS,
  payload,
});

export const postRedeemPartnerCodeFailure = (
  payload: PostRedeemPartnerCodeFailurePayload,
): PostRedeemPartnerCodeFailure => ({
  type: POST_REDEEM_PARTNER_CODE_FAILURE,
  payload,
});

export const fetchMembersCountRequest = (payload: any): FetchMembersCountRequest => ({
  type: FETCH_MEMBERS_COUNT_REQUEST,
  payload,
});

export const fetchMembersCountSuccess = (payload: FetchMembersCountSuccessPayload): FetchMembersCountSuccess => ({
  type: FETCH_MEMBERS_COUNT_SUCCESS,
  payload,
});

export const fetchMembersCountFailure = (payload: FetchMembersCountFailurePayload): FetchMembersCountFailure => ({
  type: FETCH_MEMBERS_COUNT_FAILURE,
  payload,
});

export const getMemberLogsRequest = (payload: any): GetMemberLogsRequest => ({
  type: GET_MEMBER_LOGS_REQUEST,
  payload,
});

export const getMemberLogsSuccess = (payload: GetMemberLogsSuccessPayload): GetMemberLogsSuccess => ({
  type: GET_MEMBER_LOGS_SUCCESS,
  payload,
});

export const getMemberLogsFailure = (payload: GetMemberLogsFailurePayload): GetMemberLogsFailure => ({
  type: GET_MEMBER_LOGS_FAILURE,
  payload,
});

export const fetchMemberLogsCountRequest = (payload: any): FetchMemberLogsCountRequest => ({
  type: FETCH_MEMBERS_LOGS_COUNT_REQUEST,
  payload,
});

export const fetchMemberLogsCountSuccess = (
  payload: FetchMemberLogsCountSuccessPayload,
): FetchMemberLogsCountSuccess => ({
  type: FETCH_MEMBERS_LOGS_COUNT_SUCCESS,
  payload,
});

export const fetchMemberLogsCountFailure = (
  payload: FetchMemberLogsCountFailurePayload,
): FetchMemberLogsCountFailure => ({
  type: FETCH_MEMBERS_LOGS_COUNT_FAILURE,
  payload,
});
