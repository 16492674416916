import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CustomInput, DropDown } from '../../../components/common';
import Modal from '../../../components/common/modal';
import AccordionTile from '../Accordion';
import { postAddCommsRequest, RemoveCommRequest } from '../clients-redux/actions';

type props = {
  data: any;
  Campaigns: any;
  addComms: any;
};

type comms = {
  data: any;
};

const IndivualComms: React.FunctionComponent<comms> = (comms): JSX.Element => {
  const [name, setname] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [Description, setDescription] = useState('');
  const [productId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [id, setid] = useState();
  const [, setenableSaveOption] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (comms.data) {
      const template = comms.data.campaign_template;
      setname(template.name);
      setCampaignType(template.type);
      setDescription(template.description);
      setpackageId(comms.data.package_id);
      setid(comms.data.id);
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
    }
  }, [comms]);

  const removeComms = () => {
    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };

    if (id) {
      dispatch(RemoveCommRequest({ linkInput }));
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-between gap-7 my-2 w-full">
        <div className="py-4  w-full">
          <CustomInput
            inputType={'text'}
            value={name}
            disabled={true}
            setValue={(data) => {
              setenableSaveOption(true);
              setname(data);
            }}
            Label={'Name'}
          />
        </div>
        <div className="py-4 w-full">
          <CustomInput
            inputType={'text'}
            disabled={true}
            value={campaignType}
            setValue={(data) => {
              setenableSaveOption(true);
              setCampaignType(data);
            }}
            Label={'Campaign Type'}
          />
        </div>
        <div className="py-4 w-full">
          <CustomInput
            inputType={'text'}
            disabled={true}
            value={Description}
            setValue={(data) => {
              setenableSaveOption(true);
              setDescription(data);
            }}
            Label={'Description'}
          />
        </div>
      </div>
      <button className="stacked-bar-blue-400 rounded-sm  text-center  text-sm text-white  p-2" onClick={removeComms}>
        {' '}
        Remove
      </button>
    </div>
  );
};

const Comms: React.FunctionComponent<props> = (props): JSX.Element => {
  const [Campaignsdata, setCampaigns] = useState([]);
  const [comms1, setcomms1] = useState(false);
  const [clientId, setclientId] = useState<any>();
  const [enablePopUp, setenablePopUp] = useState(false);
  const [id, setid] = useState();
  const [selectedCampaign, setselectedCampaign] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data) {
      const campaignSubscriptions = props.data.campaign_subscriptions;

      if (campaignSubscriptions.length > 0) {
        setcomms1(true);
        setCampaigns(campaignSubscriptions);
      } else {
        setcomms1(false);
        setCampaigns([]);
      }
      const packageId = props?.data?.id || null;
      setid(packageId);
      const clientid = localStorage.getItem('clientid');
      setclientId(clientid);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.addComms?.status === 200) setenablePopUp(false);
  }, [props.addComms]);

  const addComms = () => {
    if (clientId && id) {
      const linkInput = {
        clientId,
        id,
      };
      const payload = {
        campaign_template_id: selectedCampaign ? selectedCampaign : props.Campaigns[0].id,
      };
      dispatch(postAddCommsRequest({ linkInput, payload }));
    }
  };

  const openComms = (data: boolean) => {
    if (Campaignsdata.length <= 0) {
      setenablePopUp(data);
    }
    setcomms1(data);
  };

  return (
    <>
      <AccordionTile
        setStatus={openComms}
        Label={'COMMS 1'}
        status={Campaignsdata?.length <= 0 ? comms1 : true}
        disableButton={true}
        id={'comms1'}
        buttonText={'ADD'}
        initialstatus={true}
        onButtonClick={() => {
          setenablePopUp(true);
        }}
        InnerComponent={
          <div>
            <div className="justify-between gap-7 my-10 w-full">
              {Campaignsdata.length > 0 &&
                Campaignsdata.map((item: any) => <IndivualComms data={item} key={Math.random()} />)}
            </div>
          </div>
        }
      />
      {
        <Modal
          isModel={enablePopUp}
          InnerComponent={
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl modal-border-shadow  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <DropDown
                    value={selectedCampaign}
                    Label={'Campaign Name'}
                    setValue={(data) => {
                      setselectedCampaign(data);
                    }}
                  >
                    {props.Campaigns?.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </DropDown>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={addComms}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  ADD COMMS
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (Campaignsdata?.length <= 0) setcomms1(false);

                    setenablePopUp(false);
                  }}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  CLOSE
                </button>
              </div>
            </div>
          }
        />
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    addComms: state.Client.AddComms || null,
  };
};

export default connect(mapStateToProps)(Comms);
