import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { TagStatus } from './usePeers';

export interface Count {
  count: number;
}

export interface Connection {
  created_at: string;
  crisis_elevation_id?: number;
  evaluation_ends_at: any;
  expired: boolean;
  id: number;
  listener_acknowledged: boolean;
  listener_role: ListenerRole;
  listener_role_id: number;
  offered_at: any;
  outcome: string;
  package_id: number;
  request: Request;
  request_id: number;
  result: string;
  scheduled_at: any;
  status: string;
  tier: any;
  tier_id: any;
  updated_at: any;
}

export interface ListenerRole {
  listener_audio: ListenerAudio[];
  listener_topics: any[];
  user: ListenerRoleUser;
}

export interface ListenerAudio {
  created_at: string;
  duration: number;
  excerpt: any;
  file_url: string;
  first_approved_at: string;
  id: number;
  is_favorite: boolean;
  last_updated_at: string;
  listener_role_id: number;
  message_quality: number;
  title: string;
  topic_tags: TopicTag[];
  transcription: any;
}

export interface TopicTag {
  created_at: string;
  id: number;
  status: TagStatus;
  listener_audio_id: number;
  media: any;
  postcall_metadata_id: number;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: any;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: Medum[];
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface Medum {
  file_key: string;
  file_url: string;
}

export interface ListenerRoleUser {
  administrator_role_id: number;
  api_key: string;
  caller_role_id: number;
  city: string;
  client_administrator_role_id: any;
  client_id: number;
  client_name: string;
  counseling_configuration: any;
  created_at: string;
  current_app_version: string;
  date_of_birth: string;
  dialcare_processing_date: any;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  family: any;
  first_name: string;
  gender: any;
  how_did_you_hear: any;
  id: number;
  is_mms_compatible_phone: boolean;
  is_partial: boolean;
  is_text_compatible_phone: boolean;
  is_text_unsubscribed: boolean;
  languages: any[];
  last_active_at: string;
  last_name: string;
  listener_role_id: number;
  military_branch: any;
  military_role: any;
  mobile_phone: string;
  mobile_phone_verification_started_at: any;
  mobile_phone_verification_token: any;
  mobile_phone_verified: boolean;
  needs_password: boolean;
  password_reset_token: any;
  password_reset_token_created_at: any;
  pronoun: any;
  proposed_display_name: any;
  race: any;
  relationship: any;
  relationship_type: string;
  spirituality: any;
  state: string;
  status: string;
  street_address_1: string;
  street_address_2: string;
  test_user: boolean;
  timezone: string;
  user_subscription: any;
  zip_code: string;
}

export interface Request {
  audio_file_url: any;
  call: Call;
  call_id: number;
  call_limit: any;
  caller_role: CallerRole;
  caller_role_id: number;
  connections_processed_at: any;
  created_at: string;
  id: number;
  is_backup_request: boolean;
  original_backup_request_id: any;
  original_request_id: any;
  package_id: number;
  partner_code: any;
  pending_morphii_data: PendingMorphiiData;
  perform_backup_requests: boolean;
  status: string;
  tag_group_ids: any[];
  text: string;
  topic: Topic;
  topic_id: number;
  updated_at: any;
}

export interface Call {
  call_rate: number;
  caller_role_id: number;
  caller_state_id: number;
  created_at: string;
  duration_timer_began_at: any;
  ended_at: any;
  id: number;
  impact_score: any;
  listener_earned_amount: any;
  listener_mobile_phone: string;
  listener_paid_amount: any;
  listener_role_id: number;
  listener_state_id: number;
  logged_duration: number;
  package_id: number;
  partner_code: any;
  pending_announcement: string;
  pending_announcement_all: any;
  phone_number: string;
  postcall_metadata: PostcallMetadata;
  recording_path: any;
  state: State;
  state_id: number;
  stripe_payment_intent_id: any;
  termination_disposition: any;
  tier_id: number;
}

export interface PostcallMetadata {
  begin_call_mood: number;
  call_id: number;
  call_reported: boolean;
  caller_completed_at?: any;
  caller_connect?: any;
  caller_description?: any;
  caller_feedback?: any;
  caller_morphii?: any;
  completed_at: string;
  counseling_recommended: boolean;
  end_call_mood: number;
  id: number;
  package_id?: number;
  resources: any[];
  telemed_recommended: boolean;
  topic_tags: TopicTag[];
}

export interface State {
  id: number;
  sub_state_id: number;
  type: string;
}

export interface CallerRole {
  created_at: string;
  id: number;
  listener_preferences: ListenerPreferences;
  notes: string;
  notifications_check_ins: boolean;
  notifications_incoming_call: boolean;
  notifications_listener_online: boolean;
  notifications_resources: boolean;
  status: string;
  stripe_customer_id: string;
  stripe_payment_failed: boolean;
  stripe_payment_failure_code: any;
  stripe_payment_method_id: string;
  tag_group_ids: any;
  tag_ids: any;
  type: string;
  user: CallerRoleUser;
}

export interface ListenerPreferences {
  age_range: AgeRange;
  all_topics: boolean;
  genders: Genders;
  languages: string[];
  preferred_language: string;
  topic_tags: number[];
  topics: number[];
}

export interface AgeRange {
  from: number;
  to: number;
}

export interface Genders {
  female: boolean;
  male: boolean;
  other: boolean;
}

export interface CallerRoleUser {
  administrator_role_id: any;
  api_key: string;
  caller_role_id: number;
  city: string;
  client_administrator_role_id: any;
  client_id: number;
  client_name: string;
  counseling_configuration: any;
  created_at: string;
  current_app_version: string;
  date_of_birth: string;
  dialcare_processing_date: string;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  family: any;
  first_name: string;
  gender: any;
  how_did_you_hear: any;
  id: number;
  is_mms_compatible_phone: boolean;
  is_partial: boolean;
  is_text_compatible_phone: boolean;
  is_text_unsubscribed: boolean;
  languages: any[];
  last_active_at: string;
  last_name: string;
  listener_role_id: number;
  military_branch: any;
  military_role: any;
  mobile_phone: string;
  mobile_phone_verification_started_at: string;
  mobile_phone_verification_token: string;
  mobile_phone_verified: boolean;
  needs_password: boolean;
  password_reset_token: string;
  password_reset_token_created_at: string;
  pronoun: any;
  proposed_display_name: any;
  race: any;
  relationship: any;
  relationship_type: string;
  spirituality: any;
  state: string;
  status: string;
  street_address_1: string;
  street_address_2: string;
  test_user: boolean;
  timezone: string;
  user_subscription: any;
  zip_code: string;
}

export interface PendingMorphiiData {
  mode: string;
  morphii_display_name: string;
  morphii_id: string;
  morphii_intensity: number;
  morphii_part_name: string;
}

export interface Topic {
  audio_file_url: string;
  button_image_selected_url: string;
  button_image_url: string;
  description: string;
  id: number;
  identifier: string;
  is_default: boolean;
  is_featured: boolean;
  is_primary: boolean;
  is_required: boolean;
  morphii: string[];
  name: string;
  sample_1_audio_file_url: string;
  sample_1_theme: string;
  sample_2_audio_file_url: any;
  sample_2_theme: any;
  sort_weight: number;
  status: string;
  tag_id: number;
  theme: string;
}

export interface Event {
  administrator_role_id: any;
  amount: any;
  call_id: number;
  caller_role_id: any;
  client_administrator_role_id: any;
  eligibility_dataset_id: any;
  engagement_id: any;
  event_category: string;
  event_name: string;
  id: number;
  listener_role_id: any;
  message: string;
  request_id: any;
  stripe_payment_intent_id: any;
  stripe_transfer_id: any;
  timestamp: string;
  topic_id: any;
}

export interface ConnectionOptions {
  page: number;
  limit: number;
  search?: string;
  testUserState?: 'live' | 'test';
  clientId?: number;
  callerRoleId?: number;
  listenerRoleId?: number;
  includeRequestEvents?: boolean;
}

export interface CountOptions {
  search?: string;
  clientId?: number;
  testUserState?: 'live' | 'test';
  callerRoleId?: number;
  listenerRoleId?: number;
}

const getConnections = async ({
  limit,
  search,
  page,
  testUserState: test_user_state,
  clientId: client_id,
  callerRoleId: caller_role_id,
  listenerRoleId: listener_role_id,
  includeRequestEvents: include_request_events,
}: ConnectionOptions): Promise<Connection[]> => {
  return await axiosGet(
    '/connections/all',
    {
      page,
      limit,
      search,
      client_id,
      test_user_state,
      caller_role_id,
      listener_role_id,
      include_request_events,
    },
    'v2',
  ).then((connectionsResponse) => connectionsResponse.data);
};

const getConnectionsCount = async ({
  search,
  clientId: client_id,
  callerRoleId: caller_role_id,
  listenerRoleId: listener_role_id,
  testUserState: test_user_state,
}: CountOptions): Promise<Count> => {
  return await axiosGet(
    '/connections/all/count',
    { search, client_id, caller_role_id, test_user_state, listener_role_id },
    'v2',
  ).then((connectionsResponse) => connectionsResponse.data);
};

const getEvents = async (callId?: number, requestId?: number): Promise<Event[]> => {
  return await axiosGet(
    '/events/',
    { call_id: callId, request_id: requestId, include_request_events: !!callId },
    'v2',
  ).then((eventsResponse) => eventsResponse.data);
};

export const useConnections = (connectionOptions: ConnectionOptions) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Connection[]>(
    ['connections', ...Object.values(connectionOptions)],
    () => getConnections(connectionOptions),
    { refetchOnWindowFocus: false },
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useConnectionsCount = (countOptions: CountOptions) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Count>(
    ['connectionsCount', ...Object.values(countOptions)],
    () => getConnectionsCount(countOptions),
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useEvents = (callId?: number, requestId?: number) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Event[]>(
    ['events', callId],
    () => getEvents(callId, requestId),
    { enabled: !!callId || !!requestId, refetchOnWindowFocus: false },
  );
  return { data, isLoading, error, refetch, isFetching };
};
