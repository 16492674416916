import { useEffect } from 'react';
import { toast } from 'react-toastify';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, setState: any) {
  useEffect(() => {
    /**
     * toast.error if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setState(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }); //   eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Component that alerts if you click outside of it
 */

export default useOutsideAlerter;
