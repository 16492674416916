import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  postTiersSuccess,
  postTiersFailure,
  fetchTiersListSuccess,
  fetchTiersListFailure,
  updateTiersFailure,
  updateTiersSuccess,
  removeTiersSuccess,
  removeTiersFailure,
  fetchTierSuccess,
  fetchTierFailure,
  fetchTierRequest,
} from './actions';
import {
  POST_TIERS_REQUEST,
  FETCH_TIERS_LIST_REQUEST,
  UPDATE_TIERS_REQUEST,
  REMOVE_TIERS_REQUEST,
  FETCH_TIER_REQUEST,
} from './action-types';
import { toast } from 'react-toastify';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* postTiersSaga(action: any): any {
  try {
    const response = yield call(Api.createTiers(action.payload));
    yield put(postTiersSuccess(response));
    toast.success('Tier Created Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postTiersFailure(JSON.parse(e)));
  }
}

function* fetchTiersListSaga(action: any): any {
  try {
    const response = yield call(Api.getAlltiers(action.payload));
    yield put(
      fetchTiersListSuccess({
        TiersList: response,
      }),
    );
  } catch (e: any) {
    yield put(fetchTiersListFailure(JSON.parse(e)));
  }
}

function* UpdateTiersSaga(action: any): any {
  try {
    yield call(Api.updateTiers(action.payload));
    toast.success('Tier Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(fetchTierRequest({ id, payload: { all_tier_versions: true, include_unpublished: true } }));
}
function* removeTiersSaga(action: any): any {
  try {
    const response = yield call(Api.deleteTiers(action.payload));
    yield put(removeTiersSuccess(response));
    toast.success(`tier removed sucessfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeTiersFailure(JSON.parse(e)));
  }
}

function* fetchTierSaga(action: any): any {
  try {
    const res = Api.fetchTier(action.payload);
    const response = yield call(res);
    yield put(fetchTierSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchTierFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      }),
    );
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(POST_TIERS_REQUEST, postTiersSaga),
    //  takeLatest(FETCH_ELIGILITY_REQUEST, fetchTiersSaga),
    takeLatest(FETCH_TIERS_LIST_REQUEST, fetchTiersListSaga),
    takeLatest(UPDATE_TIERS_REQUEST, UpdateTiersSaga),
    takeLatest(REMOVE_TIERS_REQUEST, removeTiersSaga),
    takeLatest(FETCH_TIER_REQUEST, fetchTierSaga),
  ]);
}

export default paymentSaga;
