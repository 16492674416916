import {
  POST_TAGS_REQUEST,
  POST_TAGS_SUCCESS,
  POST_TAGS_FAILURE,
  FETCH_TAGS_LIST_REQUEST,
  FETCH_TAGS_LIST_SUCCESS,
  FETCH_TAGS_LIST_FAILURE,
  UPDATE_TAGS_REQUEST,
  UPDATE_TAGS_SUCCESS,
  UPDATE_TAGS_FAILURE,
  REMOVE_TAGS_REQUEST,
  REMOVE_TAGS_SUCCESS,
  REMOVE_TAGS_FAILURE,
  FETCH_TAGS_COUNT_REQUEST,
  FETCH_TAGS_COUNT_SUCCESS,
  FETCH_TAGS_COUNT_FAILURE,
  FETCH_TAG_REQUEST,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_FAILURE,
  FETCH_TAGS_GROUP_COUNT_FAILURE,
  FETCH_TAGS_GROUP_COUNT_REQUEST,
  FETCH_TAGS_GROUP_COUNT_SUCCESS,
  FETCH_TAGS_GROUP_LIST_FAILURE,
  FETCH_TAGS_GROUP_LIST_REQUEST,
  FETCH_TAGS_GROUP_LIST_SUCCESS,
  POST_TAGS_GROUP_FAILURE,
  POST_TAGS_GROUP_REQUEST,
  POST_TAGS_GROUP_SUCCESS,
  REMOVE_TAGS_GROUP_FAILURE,
  REMOVE_TAGS_GROUP_REQUEST,
  REMOVE_TAGS_GROUP_SUCCESS,
  UPDATE_TAGS_GROUP_FAILURE,
  UPDATE_TAGS_GROUP_REQUEST,
  UPDATE_TAGS_GROUP_SUCCESS,
  FETCH_TAGS_GROUP_FAILURE,
  FETCH_TAGS_GROUP_REQUEST,
  FETCH_TAGS_GROUP_SUCCESS,
} from './action-types';

import { HomeActions, TemplateInfoState } from './types';

const initialState: TemplateInfoState = {
  pending: false,
  Tags: [],
  TagsList: [],
  Tag: null,
  UpdateTags: null,
  RemoveTags: null,
  newTag: null,
  error: null,
};

const reducer = (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case POST_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        newTag: action.payload,
        error: null,
      };
    case POST_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_TAGS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        TagsList: action.payload.TagsList,
        error: null,
      };
    case FETCH_TAGS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateTags: action.payload,
        error: null,
      };
    case UPDATE_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateTags: null,
        error: action.payload.error,
      };
    case REMOVE_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_TAGS_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveTags: action.payload,
        error: null,
      };
    case REMOVE_TAGS_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveTags: null,
        error: action.payload.error,
      };

    case FETCH_TAGS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        TagsCount: action.payload,
        error: null,
      };
    case FETCH_TAGS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_TAG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAG_SUCCESS:
      return {
        ...state,
        pending: false,
        TAG: action.payload,
        error: null,
      };
    case FETCH_TAG_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case POST_TAGS_GROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_TAGS_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        newTagGroup: action.payload,
        error: null,
      };
    case POST_TAGS_GROUP_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case FETCH_TAGS_GROUP_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_GROUP_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        TagsGroupList: action.payload.TagsGroupList,
        error: null,
      };
    case FETCH_TAGS_GROUP_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_TAGS_GROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_TAGS_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        UpdateTagsGroup: action.payload,
        error: null,
      };
    case UPDATE_TAGS_GROUP_FAILURE:
      return {
        ...state,
        pending: false,
        UpdateTagsGroup: null,
        error: action.payload.error,
      };
    case REMOVE_TAGS_GROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_TAGS_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        RemoveTagsGroup: action.payload,
        error: null,
      };
    case REMOVE_TAGS_GROUP_FAILURE:
      return {
        ...state,
        pending: false,
        RemoveTagsGroup: null,
        error: action.payload.error,
      };

    case FETCH_TAGS_GROUP_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_GROUP_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        TagsGroupCount: action.payload,
        error: null,
      };
    case FETCH_TAGS_GROUP_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_TAGS_GROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TAGS_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        TagsGroup: action.payload.TagsGroup,
        error: null,
      };
    case FETCH_TAGS_GROUP_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
