import { convertObjectToParams } from '../../components/common/create-params';

export const UserRole = {
  admin: 'administrator',
  // TODO I think managers role is unused and may be deleted (pdb 7/2024)
  MANAGERS: 'MANAGERS',
  client_admin: 'client_administrator',
};

export const defaultListenerParams = {
  page: 1,
  limit: 25,
  search: '',
  order_by_direction: 'desc',
  order_by: 'created_at',
};

export const Clients = [
  { path: '/groups', text: 'Groups', id: 'Groups' },
  // { path: '/clients', text: 'Clients', id: 'Clients' },
  // { path: '/billing', text: 'Billing', id: 'Billing' },
  { path: '/templates/comms', text: 'Templates', id: 'Templates' },
  { path: '/+care', text: '+Care', id: '+Care' },
];

export const Engagement = [
  { path: '/checkins', text: 'Check-Ins', id: 'Check-Ins' },
  { path: '/requests', text: 'Requests', id: 'Requests' },
  { path: '/calls', text: 'Calls', id: 'Calls' },
  { path: '/topics', text: 'Topics', id: 'Topics' },
  { path: '/stories', text: 'Stories', id: 'Stories' },
];

export const users = [
  { path: '/members', text: 'Members', id: 'Members' },
  {
    path:
      '/peers' +
      convertObjectToParams({
        ...defaultListenerParams,
        user_type: 'peer',
      }),
    text: 'Peers',
    id: 'Peers',
  },
  // { path: '/Demographics', text: 'Demographics', id: 'Demographics' },
];

export const textBlock = [
  { path: '/text-block/members', text: 'Text Blocks', id: 'TextBlocks' },
  { path: '/files', text: 'Files', id: 'Files' },
  { path: '/users', text: 'Users', id: 'Users' },
  // { path: 'text-block/client-admin', text: 'Demographics', id: 'Demographics' },
];

export const DashBoard = [{ path: '/dashboard', text: 'Dashboard', id: 'Dashboard' }];

export const admin = [
  { path: '/user-safety/user-management', text: 'User Safety', id: 'Safety' },
  { path: '/listeners-reviews/messages', text: 'Review', id: 'Review' },
  { path: '/calls', text: 'Calls', id: 'Calls' },
  { path: '/settings', text: 'Settings', id: 'Settings' },
  { path: '/resources', text: 'Resources', id: 'Resources' },
  { path: '/tiers', text: 'Tiers', id: 'Tiers' },
  { path: '/tag-groups', text: 'Tags', id: 'Tags' },
  { path: '/search-queries', text: 'Search Queries', id: 'SearchQueries' },
  { path: '/links', text: 'Links', id: 'Links' },
  // { path: '/legacy', text: 'Partners(Legacy)', id: 'Legacy' },
];

export const clientPortal = [
  { path: '/snapshot', text: 'Snapshot', id: 'Snapshot' },
  { path: '/eligibility', text: 'Eligibility', id: 'Eligibility' },
];
