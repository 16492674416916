import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import { CustomInput } from '../../../components/common';
import { postCreatePackageProductRequest, postUpdatePackageProductRequest } from '../clients-redux/actions';

type props = {
  client: any;
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

const Discount: React.FunctionComponent<props> = (props): JSX.Element => {
  const [discount, setdiscount] = useState(0);
  const [discounttab, setdiscounttab] = useState(false);
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [error, seterror] = useState('');
  const [enableSaveOption, setenableSaveOption] = useState(false);

  const [id, setid] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const Validation = () => {
    if (discounttab) {
      if (!discount) {
        seterror('Field is required');
        return false;
      } else if (discount.toString().indexOf('.') !== -1) {
        seterror('Number may not include decimals - whole numbers only');
        return false;
      } else if (discount < 1 || discount > 100) {
        seterror('Must be a number between 1 and 100');
        return false;
      }
    }
    seterror('');
    return true;
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;

      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'DISCOUNT') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setdiscounttab(selectedItem?.status === 'enabled' ? true : false);

        const configuration = selectedItem.configuration;
        setdiscount(configuration.discount_percentage * 100);
        setpackageId(selectedItem && selectedItem.package_id);
        setproductId(selectedItem && selectedItem.product_id);
        setid(selectedItem && selectedItem.id);
      } else {
        const configuration = props.default.configuration;
        setdiscount(configuration.discount_percentage * 100);
        setid(props.default.id);
        setpackageId(props.data.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    const payload = {
      status: discounttab ? 'enabled' : 'disabled',
      configuration: {
        discount_percentage: +(discount / 100).toFixed(2),
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (Validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  return (
    <AccordionTile
      setStatus={(data: boolean) => {
        if ((!data && Validation()) || data) {
          !enableSaveOption && setenableSaveOption(true);
          setdiscounttab(data);
        }
      }}
      Label={'DISCOUNT'}
      buttonText={'SAVE'}
      status={discounttab}
      id={'discount'}
      initialstatus={true}
      disableButton={enableSaveOption}
      onButtonClick={() => {
        if (enableSaveOption) onSave();
      }}
      InnerComponent={
        <div className="w-1/2 px-2 py-4">
          <CustomInput
            inputType={'number'}
            value={discount}
            setValue={(data) => {
              !enableSaveOption && setenableSaveOption(true);
              setdiscount(data);
            }}
            Label={'Discount Rate (%)'}
            Error={error?.length > 0 ? true : false}
            ErrorMessage={error}
          />
        </div>
      }
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(Discount);
