import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

// To represent a Bar chart pass the data via props

const BarChart = ({ data, options }) => {
    const [chartdata, setchartdata] = useState(data);
    const [selecetedIndex, setselecetedIndex] = useState(-1);
    const randomKey = Math.random();
    const updateIndex = (columnIndex) => {
        const newArray = data.map((item, index) => {
            if (columnIndex === index) {
                return [item[0], item[1], "#173564", item[1]];
            } else {
                return item;
            }
        });
        return newArray;
    };

    useEffect(() => {
        if (selecetedIndex > 0) {
            const res = updateIndex(selecetedIndex);
            setchartdata(res);
        }
    }, [selecetedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        // Charts for react graphss
        <div className=" -my-8" key={randomKey}>
            <div className="h-8 transparent"></div>
            <div className=" flex ">
                <Chart
                    width={'35vw'}
                    height={'85vh'}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={chartdata}
                    options={options}
                    // For tests
                    chartEvents={[
                        {
                            eventName: 'ready',
                            callback: ({ chartWrapper, google }) => {
                                var handler = function (e) {
                                    var parts = e.targetID.split('#');
                                    if (parts.indexOf('label') >= 0) {
                                        let idx = parts[parts.indexOf('label') + 1];
                                        idx = parseInt(idx);
                                        setselecetedIndex(idx + 1);
                                    }
                                };
                                google.visualization.events.addListener(
                                    chartWrapper.getChart(),
                                    'click',
                                    handler
                                );
                            },
                        },
                    ]}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        </div>
    );
};

export default BarChart;
