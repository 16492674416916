export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const FETCH_USER_DETAILS_REQUEST = 'FETCH_USER_DETAILS_REQUEST';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';

//Fetch user me
export const FETCH_USER_DETAILS_BY_ID_REQUEST = 'FETCH_USER_DETAILS_BY_ID_REQUEST';
export const FETCH_USER_DETAILS_BY_ID_SUCCESS = 'FETCH_USER_DETAILS_BY_ID_SUCCESS';
export const FETCH_USER_DETAILS_BY_ID_FAILURE = 'FETCH_USER_DETAILS_BY_ID_FAILURE';

//destroy session
export const DESTROY_SESSION = 'DESTROY_SESSION';

export const POST_REQUEST_PASSWORD_REQUEST = 'POST_REQUEST_PASSWORD_REQUEST';
export const POST_REQUEST_PASSWORD_SUCCESS = 'POST_REQUEST_PASSWORD_SUCCESS';
export const POST_REQUEST_PASSWORD_FAILURE = 'POST_REQUEST_PASSWORD_FAILURE';

export const POST_REDEEM_PASSWORD_RESET_REQUEST = 'POST_REDEEM_PASSWORD_RESET_REQUEST';
export const POST_REDEEM_PASSWORD_RESET_SUCCESS = 'POST_REDEEM_PASSWORD_RESET_SUCCESS';
export const POST_REDEEM_PASSWORD_RESET_FAILURE = 'POST_REDEEM_PASSWORD_RESET_FAILURE';

//Fetch deep link data
export const FETCH_SAVE_DEEP_LINK_DATA_REQUEST = 'FETCH_SAVE_DEEP_LINK_DATA_REQUEST';
export const FETCH_SAVE_DEEP_LINK_DATA_SUCCESS = 'FETCH_SAVE_DEEP_LINK_DATA_SUCCESS';
export const FETCH_SAVE_DEEP_LINK_DATA_FAILURE = 'FETCH_SAVE_DEEP_LINK_DATA_FAILURE';

// On-Boarding steps with token
export const FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST = 'FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST';
export const FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS = 'FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS';
export const FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE = 'FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE';

// On-Boarding resend link
export const POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST = 'POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST';
export const POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS = 'POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS';
export const POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE = 'POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE';

// Client admin On-Boarding resend link
export const POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST = 'POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST';
export const POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS = 'POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS';
export const POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE = 'POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE';

// Email address available
export const FETCH_EMAIL_AVAILABLE_REQUEST = 'FETCH_EMAIL_AVAILABLE_REQUEST';
export const FETCH_EMAIL_AVAILABLE_SUCCESS = 'FETCH_EMAIL_AVAILABLE_SUCCESS';
export const FETCH_EMAIL_AVAILABLE_FAILURE = 'FETCH_EMAIL_AVAILABLE_FAILURE';

//verify valid mobile phone number
export const FETCH_MOBILE_VALID_REQUEST = 'FETCH_MOBILE_VALID_REQUEST';
export const FETCH_MOBILE_VALID_SUCCESS = 'FETCH_MOBILE_VALID_SUCCESS';
export const FETCH_MOBILE_VALID_FAILURE = 'FETCH_MOBILE_VALID_FAILURE';
