import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Modal from '../../../components/common/modal';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { updateListenerDetailsRequest } from '../listeners-redux/actions';
import { paramsToObjects } from '../../../components/common/create-params';

type props = {
  tiersList: any;
  listenerDetails: any;
};

const TierModal: React.FunctionComponent<props> = ({ tiersList, listenerDetails }): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedId, setselectedId] = useState(0);
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const [modal, setmodal] = useState(false);
  const [alertModal, setalertModal] = useState(false);
  const userId = params['listenerId'];

  const [enableSaveOption, setenableSaveOption] = useState(false);

  const onSelected = (e: any) => {
    const val = e.currentTarget.value;
    setselectedId(val);
    setenableSaveOption(true);
  };

  useEffect(() => {
    if (listenerDetails) {
      setDefaultOptions();
    }
  }, [listenerDetails]);

  const setDefaultOptions = () => {
    setselectedId(listenerDetails?.id);
    setenableSaveOption(false);
  };

  const handleSave = () => {
    setmodal(false);
    setalertModal(false);
    const payload = {
      id: userId,
      listenerId,
      payload: {
        listener_role: {
          tier_id: +selectedId,
        },
      },
    };
    dispatch(updateListenerDetailsRequest(payload));
  };

  return (
    <>
      <div className="flex justify-between w-full h-20 items-center">
        <div>
          <p className="text-md font-bold text-gray-dark   "> Peer Details </p> <p> {listenerDetails?.name}</p>
        </div>
        <div>
          <button
            onClick={() => {
              setmodal(true);
            }}
            className=" text-center h-10 text-sm text-white px-2 bg-blue-primary w-28 rounded-sm "
          >
            Change Tier
          </button>
        </div>
      </div>
      <Modal
        isModel={modal}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="px-7 ">
                <p className="text-2xl font-normal blue-primary  py-1">Current Tier</p>

                <div className="flex justify-between ">
                  <div>
                    <p className="text-lg font-normal text-blue-primary  py-1">Display Name</p>
                    <p className="text-lg font-normal  text-gray-dark py-1">{listenerDetails?.name} </p>
                  </div>
                  <div>
                    <p className="text-lg font-normal text-blue-primary  py-1">Experience Max Payouts</p>
                    <p className="text-lg font-normal  text-gray-dark py-1">
                      {listenerDetails?.max_experience_payouts}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg font-normal text-blue-primary  py-1">Experience Approvals</p>
                    <p className="text-lg font-normal  text-gray-dark py-1">
                      ${listenerDetails?.experience_bonus / 100 || 0}
                    </p>
                  </div>
                </div>
                <div className=" flex  ">
                  <div className="w-1/3 -mr-4">
                    <p className="text-lg  font-normal text-blue-primary  py-1">Experience Listens</p>
                    <p className="text-lg font-normal  text-gray-dark py-1">
                      ${listenerDetails?.experience_listen_rate / 100 || 0}
                    </p>
                  </div>
                </div>
                <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
                  <div className=" font-bold col-span-3">Name</div>
                  <div className=" col-span-2 "> Experience Approvals </div>
                  <div className=" col-span-2 ">Experience Listens </div>
                  <div className=" col-span-2 "> Experience Max Payouts </div>
                </div>
                <div className="w-full  font-bold text-gray-dark text-left h-64 overflow-y-scroll gap-y-3   grid grid-cols-12  items-center sticky mb-2 ">
                  {tiersList?.length > 0 &&
                    tiersList?.map((tier: any) => (
                      <>
                        <div className=" font-bold col-span-3">{tier.name}</div>
                        <div className=" col-span-2 "> ${tier.experience_bonus / 100 || 0} </div>
                        <div className=" col-span-2 ">${tier.experience_listen_rate / 100 || 0} </div>
                        <div className=" col-span-2 "> {tier.max_experience_payouts} </div>
                        <div className=" col-span-2 ">
                          <input
                            id={tier.id}
                            type="radio"
                            name="radio"
                            value={tier.id}
                            className="hidden"
                            onChange={onSelected}
                            checked={tier.id === +selectedId}
                          />
                          <label htmlFor={tier.id} className="flex items-center cursor-pointer">
                            <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                          </label>
                        </div>
                      </>
                    ))}
                </div>
              </div>
              <div className="flex justify-end ">
                <div>
                  <button
                    className={
                      ' text-center h-8 text-sm text-white px-2  w-16 rounded-sm ' +
                      (enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                    }
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    className=" text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                    onClick={() => {
                      if (enableSaveOption) {
                        setalertModal(true);
                      } else {
                        setmodal(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <AlertBox
              visible={alertModal}
              handlePrompt={() => {
                setDefaultOptions();
                setmodal(false);
                setalertModal(false);
              }}
              handleContinueSave={handleSave}
              closeModal={() => {
                setalertModal(false);
              }}
              confirmButtonText={'Discard Changes'}
              titleText={'Alert'}
              contentText={
                'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'
              }
              confirmSaveButtonText={'Save Changes'}
              cancelButtonText={'Cancel'}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiersList: state.tiers.TiersList?.data || null,
    listenerDetails: state.Listeners?.listenersDetails?.data?.listener_role?.tier || null,
  };
};

export default connect(mapStateToProps)(TierModal);
