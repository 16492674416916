export const numberOnChange = (evt: any, key: string, setValue: any) => {
  const inputValue = evt.target.value;
  const convertedValue = inputValue.replace(/\D/g, '');
  setValue(key, convertedValue, { shouldDirty: true });
};

const setTrimValue = (evt: any) => {
  const val = evt.target.value.trim();
  return val === '0' ? 0 : val?.length > 0 ? +val : undefined;
};
export const numberonBlur = (evt: any, key: string, setValue: any) =>
  setValue(key, setTrimValue(evt), { shouldDirty: true });

export const onBlur = (evt: any, key: string, setValue: any) =>
  setValue(key, evt.target.value.trim(), { shouldDirty: true });

export const decimalOnChange = (evt: any, key: string, setValue: any, decimals: number) => {
  const data = evt.target.value;

  if (data.indexOf('.') > -1) {
    const [first, second] = data.split('.');
    first.replace(/\D/g, '');
    const decimal = second && second?.length > decimals ? second.substring(0, decimals) : second;
    decimal.replace(/\D/g, '');
    setValue(key, `${first}.${decimal}`, { shouldDirty: true });
  } else {
    const convertedValue = data.replace(/\D/g, '');
    setValue(key, convertedValue, { shouldDirty: true });
  }
};
