import React, { useState } from 'react';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import Searchbar from '../../components/common/searchbar';
import {
  fetchBlackListListRequest,
  fetchBlackListCountRequest,
  postBlackListRequest,
  removeBlackListRequest,
} from './redux-tags/actions';
import { CustomInput, DropDown, Pagination } from '../../components/common';
import { Headeroptions } from './user-safety-constants';
import SwitchBar from '../../components/switch-bar';
import Modal from '../../components/common/modal';
import AlertBox from '../../components/route-leaving-prompt/alert-box';

export const blackListType = {
  'Phone Number': 'mobile_phone',
  'Email Address': 'email_address',
  'IP Address': 'ip_address',
};

type props = {
  blackList: any;
  pending: any;
  blackListCount: any;
};

type state = {
  showModal: boolean;
  resourceId: number;
  name: string;
};

type FilterProps = {
  addFunctionality: () => void;
  fetchData: (data: any) => void;
  count: number | undefined;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData, addFunctionality }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    entity_type: blackListType['Phone Number'],
  });

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/3">
        <div className="w-full h-8 flex">
          <div className="w-3/4">
            <Searchbar
              search={(data) => {
                setfilters((prev) => {
                  return { ...prev, page: 1, search: data };
                });
                fetchData({ ...filters, page: 1, search: data });
              }}
            />
          </div>
          <button className="bg-white rounded-full w-1/4 h-full py-1 mx-2 text-center " onClick={addFunctionality}>
            ADD +
          </button>
        </div>
      </div>
      <div className="w-1/4 ">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) => {
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            });
            fetchData({ ...filters, page: pages + 1 });
          }}
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(blackListType).map((item) => (
              <button
                className={
                  ' text-sm  px-3 py-1 ' +
                  //@ts-ignore
                  (blackListType[item] === filters.entity_type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setfilters((filter) => {
                    //@ts-ignore
                    return {
                      ...filter,
                      //@ts-ignore
                      entity_type: blackListType[item],
                      page: 1,
                    };
                  });
                  fetchData({
                    ...filters,
                    //@ts-ignore
                    entity_type: blackListType[item],
                    page: 1,
                  });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultState = {
  showModal: false,
  resourceId: 0,
  name: '',
};

const BlackList: React.FunctionComponent<props> = (props): JSX.Element => {
  const [tagType, settagType] = useState(blackListType['Phone Number']);
  const [filters, setfilters] = useState();
  const [state, setState] = useState<state>(defaultState);
  const [enablePopUp, setenablePopUp] = useState(false);
  const [Values, setValues] = useState({
    entity_type: blackListType['Phone Number'],
    value: '',
  });

  const dispatch = useDispatch();

  const fetchData = (filter: any) => {
    const { page, limit, ...rest } = filter;
    settagType(filter.entity_type.replace('_', ' '));
    setfilters(filter);
    dispatch(fetchBlackListListRequest(filter));
    dispatch(fetchBlackListCountRequest(rest));
  };

  const deleteBlackList = () => {
    dispatch(removeBlackListRequest({ id: state.resourceId, filters }));
    setState(defaultState);
  };

  return (
    <div>
      <Header heading={'Safety'} />
      <SwitchBar heading={Headeroptions} position={1} />

      <Filters
        count={props.blackListCount?.count}
        fetchData={fetchData}
        addFunctionality={() => {
          setenablePopUp(true);
        }}
      />

      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-7">
          {props.pending && <Spinner />}
          <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
            <div className=" font-bold col-span-5">
              {/* @ts-ignore */}
              <p className="pl-10 capitalize">{tagType}</p>
            </div>
            <div className=" col-span-7 ">Type </div>
          </div>
          <div className="w-full  overflow-y-scroll">
            {props.blackList?.length > 0 ? (
              props.blackList?.map((tag: any) => (
                <div
                  className="w-full bg-gray-200 rounded-xl text-gray-dark text-left h-12 border-y my-2 grid grid-cols-12  items-center cursor-pointer"
                  onClick={() => {}}
                >
                  <div className=" font-bold col-span-5">
                    <p className="pl-10">{tag.value}</p>
                  </div>
                  <div className=" col-span-7 flex justify-between mr-2 items-center">
                    <div className=" col-span-3 capitalize"> {tag.entity_type.replace('_', ' ')} </div>
                    <button
                      className="rounded-md px-2 py-1 font-bold bg-blue-primary  text-white"
                      onClick={() => {
                        setState({
                          showModal: true,
                          name: tag.value,
                          resourceId: tag.id,
                        });
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-xl font-normal blue-primary  py-2 ml-3 capitalize">No {tagType} Found</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        isModel={enablePopUp}
        InnerComponent={
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none shadow-lg">
            <div className="relative  my-6 mx-auto max-w-5xl w-162.5 max-h-162.5 overflow-y-auto shadow-md">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between">
                  <div className="text-2xl px-10 py-5 blue-primary ">Add Blacklist Entry</div>
                </div>
                <div className="px-7 w-1/2 mb-5">
                  <p className="float-left my-2 font-normal block leading-4 text-gray-dark mb-2">Type</p>
                  <DropDown
                    Label={''}
                    value={Values.entity_type}
                    setValue={(data: string) => {
                      setValues((values: any) => ({
                        ...values,
                        entity_type: data,
                      }));
                    }}
                  >
                    {Object.entries(blackListType).map((blacklist) => (
                      <option value={blacklist[1]}>{blacklist[0]}</option>
                    ))}
                  </DropDown>
                  <CustomInput
                    inputType={'text'}
                    value={Values.value}
                    setValue={(data: boolean) => {
                      setValues((values: any) => ({
                        ...values,
                        value: data,
                      }));
                    }}
                    Label={'value'}
                    // Error={formError?.userName}
                    // ErrorMessage={formError?.userName}
                    labelClass="font-normal"
                  />
                </div>

                <div className="flex items-center justify-center pb-6">
                  <div>
                    <button
                      className="px-7 py-2 text-sm bg-blue-primary text-white text-cente rounded-full mr-3"
                      type="button"
                      onClick={() => {
                        dispatch(
                          postBlackListRequest({
                            payload: Values,
                            filters,
                          }),
                        );
                        setenablePopUp(false);
                      }}
                    >
                      Add
                    </button>

                    <button
                      className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                      type="button"
                      onClick={() => {
                        setenablePopUp(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <AlertBox
        visible={state.showModal}
        handlePrompt={deleteBlackList}
        closeModal={() => setState(defaultState)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove ${tagType} ${state.name}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    blackList: state.safety.BlackListList?.data || null,
    blackListCount: state.safety.BlackListCount?.data || null,
    pending: state.safety?.pending || null,
  };
};

export default connect(mapStateToProps)(BlackList);
