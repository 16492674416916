import {
  FETCH_TOS_REQUEST,
  FETCH_TOS_FAILURE,
  FETCH_TERMS_OF_SERVICE_TOS_SUCCESS,
  FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS,
  FETCH_ABOUT_LOC_TOS_SUCCESS,
  FETCH_PRIVACY_POLICY_TOS_SUCCESS,
  UPDATE_TOS_REQUEST,
  UPDATE_TOS_SUCCESS,
  UPDATE_TOS_FAILURE,
  FETCH_LISTENER_FAQ_SUCCESS,
  FETCH_PEER_FAQ_SUCCESS,
  FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS,
} from './action-types';
import {
  FetchTosRequest,
  FetchTosSuccess,
  FetchTosSuccessPayload,
  FetchTosFailure,
  FetchTosFailurePayload,
  UpdateTosRequest,
  UpdateTosSuccess,
  UpdateTosSuccessPayload,
  UpdateTosFailure,
  UpdateTosFailurePayload,
} from './types';

export const fetchTosRequest = (payload: any): FetchTosRequest => ({
  type: FETCH_TOS_REQUEST,
  payload,
});

export const fetchTermsOfServiceTosSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_TERMS_OF_SERVICE_TOS_SUCCESS,
  payload,
});

export const fetchAboutLocTosSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_ABOUT_LOC_TOS_SUCCESS,
  payload,
});

export const fetchSystemWelcomeVideoSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS,
  payload,
});

export const fetchPrivacyPolicyTosSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_PRIVACY_POLICY_TOS_SUCCESS,
  payload,
});

export const fetchClientAdminTermsOfServiceTosSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS,
  payload,
});

export const fetchListnerFAQSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_LISTENER_FAQ_SUCCESS,
  payload,
});

export const fetchPeersFAQSuccess = (payload: any): FetchTosSuccess => ({
  type: FETCH_PEER_FAQ_SUCCESS,
  payload,
});

export const fetchTosFailure = (payload: FetchTosFailurePayload): FetchTosFailure => ({
  type: FETCH_TOS_FAILURE,
  payload,
});

export const updateTosRequest = (payload: any): UpdateTosRequest => ({
  type: UPDATE_TOS_REQUEST,
  payload,
});

export const updateTosSuccess = (payload: UpdateTosSuccessPayload): UpdateTosSuccess => ({
  type: UPDATE_TOS_SUCCESS,
  payload,
});

export const updateTosFailure = (payload: UpdateTosFailurePayload): UpdateTosFailure => ({
  type: UPDATE_TOS_FAILURE,
  payload,
});
