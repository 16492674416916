import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DropDown, NumericLabel, SectionLabel, Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AudioPlayer from '../../components/common/audio-player-slider';
import Modal from '../../components/common/modal';
import { fetchCommsListRequest } from '../templates/redux-templates/actions';
import { fetchTagsRequest } from '../clients/clients-redux/actions';
import {
  fetchAudioListRequest,
  fetchAudioListSuccess,
  postAudioRequest,
  updateAudioRequest,
} from './listeners-redux/actions';
import SaveButton from '../clients/switch-bar-button';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import { toast } from 'react-toastify';
import { paramsToObjects } from '../../components/common/create-params';
import HeadBack from './redirect';
import { Dialog } from '@kindlyhuman/component-library';
import { Tag } from '../../hooks/useListenerTags';

const GradeDropDownOptions = [
  { value: 'rejected', name: 'Rejected' },
  { value: 'approved', name: 'Approved' },
];

const messageGroups = {
  rejected: 'listener_message_rejected',
  approved: 'approved',
  ungraded: 'ungraded',
};

type props = {
  AudioList: any;
  tags: any;
  comms: any;
  pending: boolean;
};

type gradeOptionsProps = {
  seletedId: any;
  comms: any;
  tagList: any;
  setIsDirty: (isDirty: boolean) => void;
  setTopicsDirty: (isDirty: boolean) => void;
  setRedirect: (path: string) => void;
};

interface refProps {
  submit(): void;
  handleSendRejectMessage(): void;
}

interface promptProps {
  modalVisible: boolean;
  isDirty: boolean;
  isTopicsDirty: boolean;
  promptId?: number;
}

const defaultErrorMessages = {
  message: '',
  description: '',
};

const GradeOptions = forwardRef<refProps, gradeOptionsProps>(
  ({ seletedId: audio, comms, tagList, setIsDirty, setTopicsDirty, setRedirect }, ref) => {
    const params = paramsToObjects();
    const listenerId = params['listenerRoleId'];
    const dispatch = useDispatch();
    const history = useHistory();
    const [errors, setErrors] = useState(defaultErrorMessages);
    const [gradeSelection, setgradeSelection] = useState<any>({
      grade: 'rejected',
      sytemMessageGroup: 'listener_message_rejected',
      messageId: 'none',
      message: '',
      excerpt: '',
      topicsUpdated: false,
      gradeUpdated: false,
    });
    const [groupDownOptions] = useState({
      listener_message_rejected: [],
      listener_message_poor: [],
      approved: [],
    });
    const [addTagspopUp, setaddTagspopUp] = useState(false);
    const [tagsList, setTags] = useState<any>([]);

    useEffect(() => {
      const groupKey = ['listener_message_rejected', 'listener_message_poor'];
      comms.map((item: any) => {
        if (groupKey.includes(item.group?.key)) {
          //@ts-ignore
          groupDownOptions[item.group.key].push(item);
        }
      });
    }, []);

    const submit = () => {
      if (gradeSelection.topicsUpdated && verifyMessageErrors(false, 'submit')) {
        setErrors(defaultErrorMessages);
        const selectedIds = tagsList.filter((item: any) => item.status).map((item: any) => item.id);
        const MyStory = tagsList.find((item: any) => item.name === 'My Story' && item.is_required);

        const input = {
          id: listenerId,
          audioId: audio.id,
          payload: {
            topic_tag_ids: audio.title === 'My Story' ? [...selectedIds, MyStory.id] : selectedIds,
            excerpt: gradeSelection.excerpt.trim(),
            admin_summary: gradeSelection.admin_summary.trim(),
          },
        };
        dispatch(updateAudioRequest(input));
      }
    };

    useImperativeHandle(ref, () => ({
      submit,
      handleSendRejectMessage,
    }));

    useEffect(() => {
      if (tagList?.length > 0 && audio.topic_tags !== undefined) {
        const selectedTags: any[] =
          audio.topic_tags?.length > 0 ? audio.topic_tags.map((item: any) => item.tag_id) : [];

        const tagsArr: any[] = tagList.map((item: any) => ({
          ...item,
          status: !!selectedTags?.includes(item.id),
        }));
        setTags(tagsArr);
      }
    }, [tagList, audio]);

    useEffect(() => {
      audio &&
        setgradeSelection({
          grade: audio?.message_quality === 3 ? 'approved' : audio?.message_quality === 7 ? 'ungraded' : 'rejected',
          sytemMessageGroup: 'listener_message_rejected',
          messageId: 'none',
          message: '',
          excerpt: audio?.excerpt || '',
          admin_summary: audio?.admin_summary || '',
          ai_summary: audio?.ai_summary || '',
          topicsUpdated: false,
          gradeUpdated: false,
        });
    }, [audio]);

    const setToggle = (status: any, id: any) => {
      if (!gradeSelection.topicsUpdated) {
        setgradeSelection((prev: any) => ({ ...prev, topicsUpdated: true }));
      }
      const updatedTags = tagsList.map((item: any) => {
        if (item.id === id) {
          return { ...item, status };
        } else {
          return item;
        }
      });
      setTags(updatedTags);
      handleIsDirty(true);
    };

    const verifyMessageErrors = (sendMessage: boolean, type: string) => {
      if (type === 'message') {
        if ((!sendMessage && gradeSelection.message?.trim()?.length > 0) || sendMessage) {
          setErrors((prev: any) => ({
            ...prev,
            message: '',
          }));
          return true;
        } else {
          setErrors((prev: any) => ({
            ...prev,
            message: 'Please Enter a message to proceed',
          }));
          return false;
        }
      } else {
        if (tagsList?.filter((item: any) => item.status && item.name !== 'My Story').length <= 0) {
          toast.error('Experiences must have at least 1 topic tag');
          return false;
        } else if (gradeSelection.excerpt?.trim()?.length > 0) {
          setErrors((prev: any) => ({
            ...prev,
            description: '',
          }));
          return true;
        } else {
          setErrors((prev: any) => ({
            ...prev,
            description: 'Please Enter a description to proceed',
          }));
          return false;
        }
      }
    };

    const handleSendRejectMessage = () => {
      const sendMessage = !!(gradeSelection.messageId === 'none' || gradeSelection.grade === 'approved');
      if (gradeSelection.gradeUpdated && verifyMessageErrors(sendMessage, 'message')) {
        const input = {
          id: listenerId,
          audioId: audio.id,
          payload: {
            system_message_id: sendMessage ? undefined : +gradeSelection.messageId,
            message: sendMessage ? undefined : gradeSelection.message.trim(),
            message_quality: gradeSelection.grade,
          },
        };
        dispatch(postAudioRequest(input));
      }
    };

    const handleIsDirty = (isTopics = false) => {
      const type = isTopics ? 'topicsUpdated' : 'gradeUpdated';
      if (!gradeSelection[type]) {
        setgradeSelection((prev: any) => ({
          ...prev,
          [type]: true,
        }));
        if (isTopics) {
          setTopicsDirty(true);
          !gradeSelection.topicsUpdated && setgradeSelection((prev: any) => ({ ...prev, topicsUpdated: true }));
        }
      }
      setIsDirty(true);
    };

    return (
      <>
        <RouteLeavingGuard
          when={gradeSelection.gradeUpdated || gradeSelection.topicsUpdated}
          navigate={(path: string) => {
            history.push(path);
          }}
          shouldBlockNavigation={(location: any) => {
            if (location.pathname !== ROUTE_PATH.LISTENER_AUDIO_APPROVAL) {
              return true;
            }
            return false;
          }}
          titleText={'Alert'}
          contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
          cancelButtonText="Cancel"
          confirmSaveButtonText={'Save Changes'}
          confirmButtonText={'Disregard Changes'}
          handleContinueSaveChange={(path: string) => {
            const sendMessage = !!(gradeSelection.messageId === 'none' || gradeSelection.grade === 'approved');
            if (verifyMessageErrors(false, 'submit') && verifyMessageErrors(sendMessage, 'message')) {
              setRedirect(path);
              setgradeSelection((prev: any) => ({
                ...prev,
                topicsUpdated: false,
                gradeUpdated: false,
              }));
              submit();
              handleSendRejectMessage();
            }
          }}
        />
        <Dialog open={addTagspopUp} onClose={() => setaddTagspopUp(false)}>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                    Add Listener Tags
                  </h3>

                  <div className="w-full pb-4 flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                    {tagsList?.length > 0 &&
                      tagsList
                        .filter((item: any) => !item.is_required)
                        .sort((tag1: Tag, tag2: Tag) => tag1.name.localeCompare(tag2.name))
                        .map((item: any, index: number) => {
                          return (
                            <button
                              className={` ${
                                item.status ? ' bg-blue-primary  text-white' : ' bg-gray-background-light '
                              }  rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                              onClick={() => {
                                setToggle(!item.status, item.id);
                              }}
                            >
                              <div className="flex px-3 py-2">
                                <div className="grow  pr-2">{item.name}</div>
                              </div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
              <button
                type="button"
                className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
                onClick={() => {
                  setaddTagspopUp(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog>
        <div className="border-2 border-gray-200 rounded-3xl px-5 h-auto">
          <h6 className="font-bold  text-left text-gray-dark py-5">{audio.title}</h6>
          <AudioPlayer audioLength={audio.duration} audioUrl={audio.file_url} />
          <div className="flex justify-start mt-5">
            <label className="mr-2">Grade</label>
            <div className="w-36">
              {/* @ts-ignore */}
              <DropDown
                Label={''}
                value={gradeSelection.grade}
                setValue={(value) => {
                  value !== 'ungraded' && handleIsDirty();
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    grade: value,
                    //@ts-ignore
                    sytemMessageGroup: messageGroups[value],
                    gradeUpdated: value === 'ungraded' ? false : true,
                  }));
                }}
              >
                {audio?.message_quality === 7 && <option value={'ungraded'}>Ungraded</option>}
                {GradeDropDownOptions.length > 0 &&
                  GradeDropDownOptions.map((item: any) => <option value={item.value}>{item.name}</option>)}
              </DropDown>
            </div>
          </div>
          {gradeSelection.grade === 'rejected' && (
            <div className="flex justify-start w-full">
              <label className="mr-2">Message to Listener</label>
              <DropDown
                Label={''}
                value={gradeSelection.messageId}
                setValue={(value) => {
                  handleIsDirty();
                  //@ts-ignore
                  const comm = groupDownOptions[gradeSelection.sytemMessageGroup].find(
                    (item: any) => item.id === +value,
                  );
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    messageId: value,
                    message: comm ? comm.message : prev.message,
                  }));
                }}
              >
                <option value={'none'}>Do NOT Message Listener</option>
                {/* @ts-ignore */}
                {groupDownOptions[gradeSelection.sytemMessageGroup]?.length > 0 &&
                  // @ts-ignore
                  groupDownOptions[gradeSelection.sytemMessageGroup]?.map((item: any) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </DropDown>
            </div>
          )}
          {gradeSelection.messageId !== 'none' && gradeSelection.grade !== 'approved' && (
            <div className="w-full  my-2">
              <p className="mb-2"> Message </p>
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.message}
                onChange={(evt) => {
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    message: evt.target.value,
                  }));
                }}
              />
              {errors.message?.length > 0 && <p className="text-red">{errors.message}</p>}
            </div>
          )}
          <button
            className={
              ' py-2 px-3 rounded-full mt-4 ' +
              (gradeSelection.gradeUpdated
                ? 'bg-blue-primary  text-white'
                : 'bg-gray-background-light text-gray-dark cursor-not-allowed')
            }
            disabled={!gradeSelection.gradeUpdated || gradeSelection.grade === '7'}
            onClick={handleSendRejectMessage}
          >
            Submit Grade
          </button>
          <div>
            <SectionLabel Label={'Description'} />
            <div className="pt-4">
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.excerpt}
                onChange={(evt) => {
                  handleIsDirty(true);
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    excerpt: evt.target.value,
                  }));
                }}
              />
              {errors.description?.length > 0 && <p className="text-red">{errors.description}</p>}
            </div>
          </div>
          <div>
            <SectionLabel Label="Transcription" />
            <div className="pt-2 pb-6">{audio.transcription}</div>
          </div>
          <div>
            <SectionLabel Label={'Admin Summary'} />
            <div className="pt-4">
              <textarea
                className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                value={gradeSelection?.admin_summary}
                onChange={(evt) => {
                  handleIsDirty(true);
                  setgradeSelection((prev: any) => ({
                    ...prev,
                    admin_summary: evt.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <SectionLabel Label="AI Summary" />
            <div className="pt-2 pb-6">
              {audio.ai_summary ? audio.ai_summary : 'No AI summary generated at this time.'}
            </div>
          </div>
          <div>
            <SectionLabel Label="Transcription" />
            <div className="pt-2 pb-6">{audio.transcription}</div>
          </div>
          <div className="flex justify-between">
            <p className="py-2 font-bold text-gray-dark text-left">Topic Tags</p>
            <button
              className="py-2 font-bold text-right text-blue-primary "
              onClick={() => {
                setaddTagspopUp(true);
              }}
            >
              + Add
            </button>
          </div>
          <hr className="bg-gray-dark mb-2"></hr>
          <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
            {tagsList?.length > 0 &&
              tagsList

                .filter((item: any) => item.status && !item.is_required)
                .map((item: any, index: number) => {
                  return (
                    <button
                      className={`bg-blue-primary text-white rounded-md text-sm font-bold ${
                        index === 0 ? 'ml-2 mt-2' : ''
                      }`}
                    >
                      <div className="flex px-3 py-2">
                        <div className="grow  pr-2">{item.name}</div>
                        <div
                          className="grow-0 "
                          onClick={() => {
                            setToggle(false, item.id);
                          }}
                        >
                          x
                        </div>
                      </div>
                    </button>
                  );
                })}
          </div>
          <hr className="mt-4" />
          {audio?.opensearch_tag_ids && (
            <div className="flex flex-col">
              <p className="py-2 font-bold text-gray-dark text-left">AI Recommended Tags</p>
              <div className="flex gap-4 py-4">
                {audio?.opensearch_tag_ids?.map((id: number) => {
                  return (
                    <div className="bg-gray-600 text-white rounded-md text-sm font-bold px-3 py-2">
                      {tagList.find((tag: Tag) => tag.id === id)?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex justify-start mt-2">
            <div className="h-36 w-1/4">
              <SectionLabel Label={'Plays'} />
              <NumericLabel numericValue={audio?.total_playbacks?.toString() || '0'} label={''} />
            </div>
            <div className="h-36 w-1/4">
              <SectionLabel Label={'Favorites'} />
              <NumericLabel numericValue={audio?.total_favorites?.toString() || '0'} label={''} />
            </div>
          </div>
        </div>
      </>
    );
  },
);

const Audio: React.FunctionComponent<props> = (props): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const audioRef: any = useRef();
  const dispatch = useDispatch();
  const [seletedId, setseletedId] = useState<any>();
  const [AccordionStatus, setAccordionStatus] = useState({
    ungraded: false,
    graded: false,
  });
  const [savedPath, setSavedPath] = useState('');
  const [prompt, setPrompt] = useState<promptProps>({
    modalVisible: false,
    isDirty: false,
    isTopicsDirty: false,
  });
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCommsListRequest({}));
    dispatch(fetchTagsRequest({ tag_type: 'topic', include_hidden: true }));
    dispatch(fetchAudioListRequest({ id: listenerId }));

    return () => {
      dispatch(fetchAudioListSuccess({ AudioList: undefined }));
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (props.AudioList) {
      if (!seletedId && props.AudioList?.length > 0) {
        const ungraded = props.AudioList.find((item: any) => item.message_quality === 7);
        setseletedId(ungraded ? ungraded : props.AudioList[0]);
      } else {
        const audio = props.AudioList.find((item: any) => item.id === seletedId.id);
        setseletedId(audio);
      }
      if (savedPath?.length > 0) {
        history.push(savedPath);
        setSavedPath('');
      }
      setPrompt({
        modalVisible: false,
        isDirty: false,
        isTopicsDirty: false,
      });
    }
  }, [props.AudioList]); // eslint-disable-line

  const handleContinueSave = () => {
    audioRef.current.handleSendRejectMessage();
    audioRef.current.submit();
    setPrompt((prev: promptProps) => ({ ...prev, modalVisible: false }));
  };
  return (
    <div className="px-">
      {props.pending && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />
      <SwitchBar
        heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions}
        position={listenerType === 'peer' ? 2 : 4}
        forwardParams
        optionalButton={
          <div className={`w-1/12  `}>
            <SaveButton
              disable={!prompt.isTopicsDirty}
              onSave={() => {
                audioRef.current.submit();
              }}
            />
          </div>
        }
      />
      <AlertBox
        visible={prompt.modalVisible}
        handlePrompt={() => {
          setseletedId(prompt.promptId);
          setPrompt({
            isDirty: false,
            isTopicsDirty: false,
            modalVisible: false,
            promptId: undefined,
          });
        }}
        handleContinueSave={handleContinueSave}
        closeModal={() => {
          setPrompt((prev: promptProps) => ({
            ...prev,
            modalVisible: false,
          }));
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
        confirmButtonText={'Discard Changes'}
        confirmSaveButtonText={'Save Changes'}
        cancelButtonText={'Cancel'}
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="px-7 flex space-x-4 py-5">
          <div className="w-1/2 ">
            <div
              className="flex justify-between border-b border-gray-500 pb-2 cursor-pointer"
              onClick={() => {
                setAccordionStatus((prev) => ({
                  ...prev,
                  ungraded: !prev.ungraded,
                }));
              }}
            >
              <p className="font-bold text-gray-dark text-left">
                Ungraded (
                {props.AudioList?.length > 0
                  ? props.AudioList?.filter((item: any) => item.message_quality === 7).length
                  : 0}
                )
              </p>
              <p className="pr-2 text-xl cursor-pointer">
                <div
                  className={
                    'flex justify-end  bg-white  transform   rounded-full ' +
                    (AccordionStatus.ungraded ? ' rotate-90 rotate-down' : ' rotate-270 rotate-up')
                  }
                >
                  <img className="h-6 w-6" src={RightArrow} alt="Right Arrow"></img>
                </div>
              </p>
            </div>
            <div
              className={
                ' accordion-panel-close' + (AccordionStatus.ungraded ? ' max-h-xlscreen accordion-panel' : ' max-h-0 ')
              }
            >
              {props.AudioList?.length > 0 &&
                props.AudioList.filter((item: any) => item.message_quality === 7).map((item: any) => (
                  <div
                    className={` ${
                      seletedId?.id === item.id
                        ? 'bg-blue-primary  text-white'
                        : 'bg-gray-background-light text-gray-dark'
                    }   font-medium text-xl flex justify-between px-7 h-24 items-center rounded-2xl my-3 cursor-pointer`}
                    onClick={() => {
                      prompt.isDirty
                        ? setPrompt((prev: promptProps) => ({
                            ...prev,
                            modalVisible: true,
                            promptId: item,
                          }))
                        : setseletedId(item);
                    }}
                  >
                    <p className={`font-bold   text-left`}>{item.title}</p>
                    <button
                      className={
                        'border  bg-transparent rounded-md px-4 text-base py-1 font-bold' +
                        (seletedId?.id === item.id ? ' border-white ' : ' border-gray-dark ')
                      }
                    >
                      Ungraded
                    </button>
                  </div>
                ))}
            </div>
            <div
              className="flex justify-between border-b border-gray-500 pt-3 pb-2 text-gray-dark"
              onClick={() => {
                setAccordionStatus((prev) => ({
                  ...prev,
                  graded: !prev.graded,
                }));
              }}
            >
              <p className="font-bold  text-left">
                Graded (
                {props.AudioList?.length > 0
                  ? props.AudioList?.filter((item: any) => item.message_quality !== 7).length
                  : 0}
                )
              </p>
              <p className="pr-2 text-xl cursor-pointer">
                <div
                  className={
                    'flex justify-end  bg-white  transform   rounded-full ' +
                    (AccordionStatus.graded ? ' rotate-90 rotate-down' : ' rotate-270 rotate-up')
                  }
                >
                  <img className="h-6 w-6" src={RightArrow} alt="Right Arrow"></img>
                </div>
              </p>
            </div>
            <div
              className={
                ' accordion-panel-close' + (AccordionStatus.graded ? ' max-h-xlscreen accordion-panel' : ' max-h-0 ')
              }
            >
              {props.AudioList?.length > 0 &&
                props.AudioList.filter((item: any) => item.message_quality !== 7).map((item: any) => (
                  <div
                    className={` ${
                      seletedId?.id === item.id
                        ? 'bg-blue-primary  text-white'
                        : 'bg-gray-background-light text-gray-dark'
                    }   font-medium text-xl flex justify-between px-7 h-24 items-center rounded-2xl my-3 cursor-pointer`}
                    onClick={() => {
                      prompt.isDirty
                        ? setPrompt((prev: promptProps) => ({
                            ...prev,
                            modalVisible: true,
                            promptId: item,
                          }))
                        : setseletedId(item);
                    }}
                  >
                    <p className={`font-bold   text-left`}>{item.title}</p>
                    <button
                      className={` ${
                        item?.message_quality === 3 ? 'bg-green ' : 'bg-red'
                      }  rounded-md px-4 text-base py-1 text-white font-bold `}
                    >
                      {item?.message_quality === 3 ? 'Approved' : 'Rejected'}
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-1/2 px-3">
            {props.AudioList?.length > 0 && seletedId && props.comms?.length > 0 && props.tags?.length > 0 && (
              <GradeOptions
                ref={audioRef}
                seletedId={seletedId}
                comms={props.comms}
                key={seletedId || 0}
                tagList={props.tags}
                setIsDirty={(isDirty: boolean) => setPrompt((prev: promptProps) => ({ ...prev, isDirty }))}
                setTopicsDirty={(isTopicsDirty: boolean) =>
                  setPrompt((prev: promptProps) => ({
                    ...prev,
                    isTopicsDirty,
                  }))
                }
                setRedirect={setSavedPath}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    AudioList: state.Listeners?.AudioList?.data || null,
    tags: state.Client.tags?.data || null,
    comms: state.Comms.CommsList?.data || null,
    pending: state.Comms.pending || state.Listeners.pending || state.Client.pending || false,
  };
};

export default connect(mapStateToProps)(Audio);
