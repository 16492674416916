import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { useDispatch, connect } from 'react-redux';
// import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import { fetchTiersListRequest } from './redux-templates/actions';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '../../routes/paths';

type props = {
  tiersList: any;
  pending: any;
};

const tierStatus = {
  unpublished: 'Unpublished',
  published: 'Published',
};

const TierListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchTiersListRequest({ all_tier_versions: true, include_unpublished: true }));
  }, []);

  return (
    <div>
      <Header heading={'Tiers'} />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <button
          className="bg-white rounded-full w-20 text-center h-6 text-sm text-black px-2"
          onClick={() => {
            history.push(`${ROUTE_PATH.TIER_DETAILS}/?tier-id=new`);
          }}
        >
          ADD +
        </button>
      </div>

      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-3">
          {props.pending ? (
            <Spinner />
          ) : (
            <table className="table-fixed table-row-format w-full relative border-collapse ">
              <thead className="bg-white  h-full ">
                <tr className=" text-left  w-full px-7 pt-5">
                  <th className="px-2 py-2 w-1/12 sticky top-0  bg-white  h-full mb-4   pl-2">
                    <p className="px-2"> Name</p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-4 px-2"> Status </p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Call Earnings</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Office Hours Cap</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-1">Office Hour Rate</p>
                  </th>
                  <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                    <p className="ml-4">Minimum Office Hours</p>
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Call Bonus</th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 z-100">Is Default?</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-2"></tr>
                {props.tiersList?.length > 0 &&
                  props.tiersList.map((item: any, index: any) => {
                    return (
                      <tr
                        className="bg-gray-200 rounded text-gray-dark text-left h-12 border-y border-white border-4 space-y-6"
                        onClick={() => {
                          history.push(`${ROUTE_PATH.TIER_DETAILS}/?tier-id=${item.id}`);
                        }}
                        role="button"
                      >
                        <td className="px-2 py-1 w-2/12 text-gray-dark font-bold pl-2">{item.name}</td>
                        <td className="px-2 py-1 w-1/12">
                          <p className="ml-4">
                            {/* @ts-ignore */}
                            {tierStatus[item.status]}
                          </p>
                        </td>
                        <td className="px-2 py-1 w-2/12">{` $${(item.call_earnings / 100).toFixed(2)}`}</td>
                        <td className="px-2 py-1 w-2/12">{`${item.paid_office_hours_cap}`}</td>
                        <td className="px-2 py-1 w-2/12">
                          <p className="ml-2">{`$${(item.office_hour_rate / 100).toFixed(2)}`}</p>
                        </td>
                        <td className="px-2 py-1 w-3/12">
                          <p className="ml-4">{`${item.minimum_office_hours}`}</p>
                        </td>
                        <td className="px-2 py-1 w-2/12">{`$${(item.call_bonus / 100).toFixed(2)}`}</td>
                        <td className="px-2 py-1 w-2/12">
                          <div className="flex items-center justify-between">
                            <div> {`${item.is_default ? 'Yes' : 'No'}`}</div>
                            <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                              <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiersList: state.tiers.TiersList?.data || null,
    pending: state.tiers?.pending || null,
  };
};

export default connect(mapStateToProps)(TierListing);
